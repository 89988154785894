import React, { useEffect, useState } from "react";
import Dialog from "@components/Dialog";
import { observer } from "mobx-react-lite";
import { useLang } from "@src/hooks/useLang";
import { Column, Row } from "@src/components/Flex";
import styled from "@emotion/styled";
import Text from "@components/Text";
import SizedBox from "@components/SizedBox";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment, { Moment } from "moment";
import withDragAndDrop from "react-big-calendar/lib/addons/dragAndDrop";

import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { IDialogPropTypes } from "rc-dialog/es/IDialogPropTypes";
import DatePicker from "@components/DatePicker";
import Select from "@components/Select";
import { useDeviceCalibrationScreenVM } from "@screens/DeviceCalibrationScreen/DeviceCalibrationScreenVM";
import Input from "@components/Input";
import Button from "@components/Button";
import { TCreateCalibrationParams } from "@services/calibrationService";
import { toast } from "react-toastify";
import Loading from "@components/Loading";

const localizer = momentLocalizer(moment);
const DnDCalendar = withDragAndDrop(Calendar as any);
const Root = styled(Dialog)`
  margin: 0;
  .rc-dialog-body {
    padding: 0;
  }
`;
interface IProps extends IDialogPropTypes {
  id?: string;
  onSubmit?: (
    params: TCreateCalibrationParams & { id?: string }
  ) => Promise<void>;
  onClose?: () => void;
}

const StyledDnDCalendar = styled(DnDCalendar as any)`
  width: 100%;
  height: calc(100vh - 280px);

  .rbc-time-header {
    display: none;
  }

  .rbc-time-content {
    border: none;
  }

  .rbc-time-view {
    border-radius: 4px;
    padding: 8px 16px;
    background: #f8f8f8;
    border: none;
  }

  .rbc-timeslot-group {
    border: none;
    height: 32px;
  }

  .rbc-today {
    background: transparent;
  }

  .rbc-time-slot {
    text-align: left;
    display: flex;
    align-items: flex-end;
  }

  .rbc-day-slot .rbc-time-slot {
    &:nth-of-type(2) {
      border-top: 1px dashed #cbd5d8;
    }
  }

  .rbc-label {
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 17px;
    color: #494c5b;
  }

  .rbc-event {
    margin-top: 20px;
    background: rgba(104, 173, 24, 0.7);
    border: none;
    .rbc-event-label {
      padding-top: 8px;
      font-family: Nunito Sans, sans-serif;
      font-weight: 700;
      font-size: 13px;
      line-height: 20px;
    }
    .rbc-event-content {
      font-family: Roboto, sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
    }
  }
  .rbc-current-time-indicator {
    background-color: transparent;
  }
  .rbc-addons-dnd-resize-ns-anchor {
    padding: 2px 0;
    box-sizing: border-box;
    transform: scale(2);
  }
`;

type TEvent = { start: Date; end: Date; title: string };

const getEventTitle = (startTime: Moment, endTime: Moment) => {
  const duration = moment.duration(endTime.diff(startTime));
  const hours = parseInt(String(duration.asHours()));
  const minutes = parseInt(String(duration.asMinutes())) % 60;
  return `${hours} Hours ${minutes} Minutes`;
};

const defaultEvent = {
  start: moment().startOf("day").add(9, "hours").toDate(),
  end: moment().startOf("day").add(12, "hours").toDate(),
  title: "3 Hours",
  default: true,
};

const DeviceCalibrationDialog: React.FC<IProps> = ({ onClose, ...rest }) => {
  const lang = useLang();
  const vm = useDeviceCalibrationScreenVM();
  const calibration = vm.calibrations.find(
    (c) => c.id === vm.editCalibrationId
  );

  const [date, setDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [cropId, setCropId] = useState<string>("");
  const [weight, setWeight] = useState<number>(0);
  const [width, setWidth] = useState<number>(0);
  const [event, setEvent] = useState<TEvent>(defaultEvent);
  const onEventDrop = (data: { start: Date; end: Date; title: string }) => {
    if (data.start.getDate() !== data.end.getDate()) return;
    setEvent({
      start: data.start,
      end: data.end,
      title: getEventTitle(moment(data.start), moment(data.end)),
    });
  };

  const onEventResize = (data: { start: Date; end: Date; title: string }) => {
    if (data.start.getDate() !== data.end.getDate()) return;
    setEvent({
      start: data.start,
      end: data.end,
      title: getEventTitle(moment(data.start), moment(data.end)),
    });
  };

  const resetAndClose = () => {
    setWeight(0);
    setWidth(0);
    setEvent(defaultEvent);
    setDate(new Date());
    onClose && onClose();
  };

  const handleSubmit = () => {
    const start = moment(date)
      .set("hours", event.start.getHours())
      .set("minutes", event.start.getMinutes())
      .set("seconds", 0);
    const end = moment(date)
      .set("hours", event.end.getHours())
      .set("minutes", event.end.getMinutes())
      .set("seconds", 0);
    if (
      width == null ||
      weight == null ||
      rest.onSubmit == null ||
      !vm.crops.map(({ id }) => id).includes(cropId)
    ) {
      toast.warning("You need to fill in all the data");
      return;
    }
    setLoading(true);

    const params: TCreateCalibrationParams & { id?: string } = {
      id: calibration?.id,
      culture_id: cropId,
      start: start.toISOString(),
      stop: end.toISOString(),
      reaper_width: { value: width, unit: "m" },
      weight: { value: weight, unit: "kg" },
    };
    rest
      .onSubmit(params)
      .then(resetAndClose)
      .catch((e) => toast.error(e.message ?? e.toString()))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (width === 0) {
      const deviceWidth = vm.device?.harvester.default_reaper.width;
      setWidth(calibration?.reaper_width.value ?? deviceWidth ?? 0);
    }
    if (weight === 0) {
      setWeight(calibration?.weight.value ?? 0);
    }
    if (cropId === "") {
      setCropId(calibration?.culture.id ?? "");
    }
    if ((event as any).default && calibration != null) {
      const startTime = moment(calibration.start);
      const endTime = moment(calibration.stop);
      setDate(startTime.toDate());
      const start = moment()
        .set("hours", startTime.get("hours"))
        .set("minutes", startTime.get("minutes"))
        .toDate();
      const end = moment()
        .set("hours", endTime.get("hours"))
        .set("minutes", endTime.get("minutes"))
        .toDate();
      const title = getEventTitle(moment(start), moment(end));
      setEvent({ start, end, title });
    }
  }, [
    width,
    vm.device?.harvester.default_reaper.width,
    event,
    calibration,
    weight,
    cropId,
  ]);

  return (
    <Root
      destroyOnClose
      style={{ width: 649 }}
      closeIcon={<div />}
      onClose={resetAndClose}
      {...rest}
    >
      {vm.dialogVisible ? (
        <Row
          style={{
            maxHeight: "calc(100vh - 48px)",
            overflow: "hidden",
            position: "relative",
            overflowY: "auto",
          }}
        >
          <Column
            mainAxisSize="stretch"
            style={{
              flex: 1,
              borderRight: "1px solid #CBD5D8",
              padding: 24,
              boxSizing: "border-box",
              height: "100%",
            }}
          >
            <Text size={18} type="heading" color="#494C5B">
              {calibration
                ? lang.devices.editCalibrationTitle
                : lang.devices.calibrationTitle}
            </Text>
            <SizedBox height={4} />
            <Text size={15} color="#899093">
              {lang.devices.calibrationSubtitle}
            </Text>
            <SizedBox height={24} />
            <StyledDnDCalendar
              step={10}
              toolbar={false}
              // defaultDate={moment().toDate()}
              defaultView="day"
              events={[event]}
              scrollToTime={event.start}
              localizer={localizer}
              onEventDrop={onEventDrop}
              onEventResize={onEventResize}
              resizable
            />
          </Column>
          <Column
            mainAxisSize="stretch"
            style={{
              flex: 1,
              padding: 24,
              boxSizing: "border-box",
            }}
          >
            <SizedBox height={32} />
            <Row style={{ transform: "scale(1.5)" }} justifyContent="center">
              <DatePicker
                selected={date}
                onChange={(date) => setDate(date || new Date())}
              />
            </Row>
            <SizedBox height={56} />
            <Select
              label={lang.fields.newFieldCrop}
              value={cropId}
              onChange={setCropId}
              options={vm.crops.map((crop) => ({
                title: crop.name,
                value: crop.id,
              }))}
            />
            <SizedBox height={16} />
            <Input
              label={lang.devices.calibrationWeightTitle}
              value={weight}
              onChange={(e) =>
                !isNaN(Number(e.target.value)) &&
                setWeight(Number(e.target.value))
              }
            />
            <SizedBox height={16} />
            <Input
              label={lang.devices.calibrationHeaderTitle}
              value={width}
              onChange={(e) =>
                !isNaN(Number(e.target.value)) &&
                setWidth(Number(e.target.value))
              }
            />
            <SizedBox height={48} />
            <Row
              // style={{ position: "absolute", bottom: 24, right: 24 }}
              justifyContent="flex-end"
            >
              <Button
                onClick={resetAndClose}
                style={{ width: "fit-content" }}
                kind="outline"
              >
                {lang.devices.calibrationCancelButton}
              </Button>
              <SizedBox width={16} />
              <Button
                style={{ width: "fit-content", minWidth: 100 }}
                onClick={!loading ? handleSubmit : undefined}
              >
                {loading ? <Loading /> : lang.devices.calibrationSaveButton}
              </Button>
            </Row>
          </Column>
        </Row>
      ) : null}
    </Root>
  );
};
export default observer(DeviceCalibrationDialog);
