import styled from "@emotion/styled";
import React, { useState } from "react";
import { useLang } from "@src/hooks/useLang";
import Text from "@components/Text";
import SizedBox from "@components/SizedBox";
import { Row } from "@src/components/Flex";
import Button from "@components/Button";
import { useDeviceCalibrationScreenVM } from "@screens/DeviceCalibrationScreen/DeviceCalibrationScreenVM";
import { observer } from "mobx-react-lite";
import { ReactComponent as TrashIcon } from "@assets/icons/trash.svg";
import moment from "moment";
import RemoveConfirmDialog from "@screens/DeviceCalibrationScreen/dialogs/RemoveConfirmDialog";

interface IProps {}

const Root = styled.div`
  border-radius: 4px;
  overflow: scroll;
  width: 100%;
  box-sizing: border-box;
  max-height: 100vh;
  padding: 32px 24px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ebeeef;
  th,
  td {
    height: 48px;
    border: 1px solid #ebeeef;
    text-align: left;
    box-sizing: border-box;
    padding-left: 12px;
  }

  .actions {
    position: absolute;
    top: 0;
    bottom: 0;
    padding-top: 14px;
    right: 14px;
    transition: 0.4s;
    opacity: 0;
    max-width: fit-content;
    cursor: pointer;
    z-index: 10;
  }

  tbody > tr {
    cursor: pointer;
    height: 44px;
    position: relative;
    transition: 0.4s;
    :hover {
      & > td > div {
        color: #417900;
      }
      background: #f8f8f8;
      .actions {
        opacity: 1;
      }
    }
  }
`;

const DeviceCalibrationTable: React.FC<IProps> = () => {
  const lang = useLang();
  const vm = useDeviceCalibrationScreenVM();
  const [removeConfirmId, setRemoveConfirmId] = useState<string | null>();

  const handleRemove = () => {
    if (removeConfirmId != null) {
      vm.deleteCalibration(removeConfirmId).then(() =>
        setRemoveConfirmId(null)
      );
    }
  };

  return (
    <Root>
      <Text size={22} color="#494C5B" type="heading">
        {lang.devices.calibrationTitle}
      </Text>
      <SizedBox height={24} />
      <Row>
        <Row></Row>
        <Button
          style={{ width: "fit-content" }}
          onClick={() => vm.setDialogVisible(true)}
        >
          {lang.devices.calibrateButton}
        </Button>
      </Row>
      <SizedBox height={16} />
      <Table>
        <thead>
          <tr>
            <th>
              <Text color="#494C5B" type="heading" size={14}>
                {lang.devices.calTableCropTitle}
              </Text>
            </th>
            <th>
              <Text color="#494C5B" type="heading" size={14}>
                {lang.devices.calTableCollectTitle}
              </Text>
            </th>
            <th>
              <Text color="#494C5B" type="heading" size={14}>
                {lang.devices.calTableStartTitle}
              </Text>
            </th>
            <th>
              <Text color="#494C5B" type="heading" size={14}>
                {lang.devices.calTableStopTitle}
              </Text>
            </th>
            <th>
              <Text color="#494C5B" type="heading" size={14}>
                {lang.devices.calTableHeaderTitle}
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {vm.calibrations.map((data, i) => {
            const start = moment(data.start).format("DD.MM.YYYY HH:mm");
            const end = moment(data.stop).format("DD.MM.YYYY HH:mm");
            const select = () => {
              vm.setEditCalibrationId(data.id);
              vm.setDialogVisible(true);
            };
            return (
              <tr key={i} onClick={select}>
                <td>
                  <Text color="#494C5B" size={14}>
                    {data.culture.name}
                  </Text>
                </td>
                <td>
                  <Text color="#494C5B" size={14}>
                    {data.weight.value}
                    {data.weight.unit}
                  </Text>
                </td>
                <td>
                  <Text color="#494C5B" size={14}>
                    {start}
                  </Text>
                </td>
                <td>
                  <Text color="#494C5B" size={14}>
                    {end}
                  </Text>
                </td>
                <td>
                  <Text color="#494C5B" size={14}>
                    {data.reaper_width.value}
                    {data.reaper_width.unit}
                  </Text>
                  <div className="actions">
                    <TrashIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        setRemoveConfirmId(data.id);
                      }}
                    />
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
        <RemoveConfirmDialog
          visible={removeConfirmId != null}
          onClose={() => setRemoveConfirmId(null)}
          onSubmit={handleRemove}
        />
      </Table>
    </Root>
  );
};

export default observer(DeviceCalibrationTable);
