import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';

import {
  DevicesRequest,
  AttachedDevice,
  DeviceStatisticsRequest,
  DeviceStatisticsResponse,
} from "@proto/apis/devices/v1/api_pb";
import { APIClient } from "@proto/apis/devices/v1/ApiServiceClientPb";

export * from "@proto/apis/devices/v1/api_pb";

const BASE_URL = "https://at.greengrowth.tech";

const DevicesClient = new APIClient(BASE_URL, null, null);

type TDeviceHarvester = {
  name: string;
  model: string;
  default_reaper: {
    width: number;
  };
};

export type TCreateDeviceParams = {
  serial_number: string;
  harvester: TDeviceHarvester;
};

export type TEditDeviceParams = {
  harvester: TDeviceHarvester;
};

type TTagColor = {
  text: string;
  background: string;
};

export type TTag = {
  id: string;
  text: string;
  color: TTagColor;
};

export type TDeviceStatus = "inactive" | "active" | "archived";

export type TDevice = {
  // Attachment id
  id: string;
  // Electronic serial number
  esn: string;
  // User provided information
  harvester: TDeviceHarvester;
  // Array of tags
  // @unused: we did not come to implement this
  tags: Array<TTag>;
  // @unused: device always active
  status: TDeviceStatus;
};

type TDevicesListResponse = {
  devices: Array<TDevice>;
};

export const getDeviceById = async (
  id: string
): Promise<TDevice | undefined> => {
  const request = "https://at.greengrowth.tech/api/devices/v1/device";
  const response = await fetch(request, { credentials: "include" });
  const body = await response.json();
  if (response.status !== 200) {
    throw new Error(body.message);
  }
  return body.devices.find((device: TDevice) => id === device.id);
};

export const getDevicesData = async (): Promise<TDevicesListResponse> => {
  const response = await userDevices();

  return {
    devices: response.devices.map((device) => {
      return {
        id: device.getId(),
        esn: device.getEsn(),
        tags: [],
        status: "active",
        harvester: {
          name: device.getName(),
          model: device.getDescription(),
          default_reaper: { width: device.getReaperWidth() }
        },
      };
    }),
  };
};

export const createDevice = async (params: TCreateDeviceParams) => {
  const request = `https://at.greengrowth.tech/api/devices/v1/device`;

  const response = await fetch(request, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(params),
    mode: "cors",
    credentials: "include",
  });
  const body = await response.json();
  if (response.status !== 200) {
    throw new Error(body.message);
  }
};

export const editDevice = async (id: string, params: TEditDeviceParams) => {
  const request = `https://at.greengrowth.tech/api/devices/v1/device/${id}`;
  const response = await fetch(request, {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(params),
    mode: "cors",
    credentials: "include",
  });
  const body = await response.json();
  if (response.status !== 200) {
    throw new Error(body.message);
  }
};

export type BBox = [number, number, number, number];

type Devices = {
  devices: AttachedDevice[],
  bbox: BBox | null,
};

export const userDevices = async (): Promise<Devices> => {
  const response = await DevicesClient.devices(new DevicesRequest(), null);

  let bbox: BBox | null = null;
  if (response.getBboxList().length === 4) {
    bbox = response.getBboxList() as BBox;
  }

  return {
    devices: response.getDevicesList(),
    bbox: bbox,
  };
};

export const deviceStatistics = async (
  id: string,
  from: Date,
  to: Date,
): Promise<DeviceStatisticsResponse> => {
  return await DevicesClient
    .deviceStatistics(
      new DeviceStatisticsRequest()
        .setDeviceId(id)
        .setStart(Timestamp.fromDate(from))
        .setEnd(Timestamp.fromDate(to))
        .setPrecision(10)
        .addFields(DeviceStatisticsRequest.Field.FIELD_SAT_QTY)
        .addFields(DeviceStatisticsRequest.Field.FIELD_TIME)
        .addFields(DeviceStatisticsRequest.Field.FIELD_NORM)
        .addFields(DeviceStatisticsRequest.Field.FIELD_SPEED)
        .addFields(DeviceStatisticsRequest.Field.FIELD_COORDS),
      null);
};

