import React, { useState } from "react";
import { useProfileScreenVM } from "@screens/ProfileScreen/ProfileScreenVM";
import { useLang } from "@src/hooks/useLang";
import { toast } from "react-toastify";
import Layout from "@components/Layout";
import ProfileScreenHeadButtons from "@screens/ProfileScreen/ProfileScreenHeadButtons";
import ProfileScreenForm from "@screens/ProfileScreen/ProfileScreenForm";
import LogoutConfirmDialog from "@screens/ProfileScreen/dialogs/LogoutConfirmDialog";
import SaveDataPasswordConfirmDialog from "@screens/ProfileScreen/dialogs/SaveDataPasswordConfirmDialog";
import ChangePasswordDialog from "@screens/ProfileScreen/dialogs/ChangePasswordDialog";
import { updatePassword } from "@services/profileService";
import SuccessDialog from "@screens/ProfileScreen/dialogs/SuccessDialog";
import { observer } from "mobx-react-lite";

interface IProps {}

const ProfileScreenImpl: React.FC<IProps> = () => {
  const vm = useProfileScreenVM();
  const lang = useLang();
  const [logoutDialogOpened, setLogoutDialogOpened] = useState(false);
  const [passConfirmDialogOpened, setPassConfirmDialogOpened] = useState(false);
  const [changePassDialogOpened, setChangePassDialogOpened] = useState(false);
  const [passChangedDialogOpened, setPassChangedDialogOpened] = useState(false);
  const [mailChangedDialogOpened, setMailChangedDialogOpened] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    setLoading(true);
    return vm.formData?.contacts.email === vm.profileData?.contacts.email
      ? await vm
          .updateProfileData()
          .catch((e) => toast(e.message ?? e.toString(), { type: "error" }))
          .finally(() => setLoading(false))
      : setPassConfirmDialogOpened(true);
  };

  return (
    <Layout
      title={lang.profile.profileTitle}
      subtitle={lang.profile.profileSubtitle}
      extra={
        <ProfileScreenHeadButtons
          onChangePass={() => setChangePassDialogOpened(true)}
          onLogOut={() => setLogoutDialogOpened(true)}
        />
      }
    >
      <ProfileScreenForm
        loading={loading}
        onCancel={vm.resetFormData}
        onSave={handleSave}
      />
      <LogoutConfirmDialog
        visible={logoutDialogOpened}
        onClose={() => setLogoutDialogOpened(false)}
      />
      <SaveDataPasswordConfirmDialog
        visible={passConfirmDialogOpened}
        onClose={() => setPassConfirmDialogOpened(false)}
        onSubmit={() =>
          vm
            .updateProfileData()
            .then(() => setMailChangedDialogOpened(true))
            .catch((e) => toast(e.message ?? e.toString(), { type: "error" }))
            .finally(() => setLoading(false))
            .then(() => setPassConfirmDialogOpened(false))
        }
      />
      <ChangePasswordDialog
        visible={changePassDialogOpened}
        onClose={() => setChangePassDialogOpened(false)}
        onSubmit={(data) =>
          updatePassword(data)
            .then(() => {
              setChangePassDialogOpened(false);
              setPassChangedDialogOpened(true);
            })
            .catch((e) => toast(e.message ?? e.toString(), { type: "error" }))
        }
      />
      <SuccessDialog
        title={lang.profile.newPasswordAlert}
        visible={passChangedDialogOpened}
        onClose={() => setPassChangedDialogOpened(false)}
      />
      <SuccessDialog
        title={lang.profile.changeMailSuccessAlert}
        visible={mailChangedDialogOpened}
        onClose={() => setMailChangedDialogOpened(false)}
      />
    </Layout>
  );
};
export default observer(ProfileScreenImpl);
