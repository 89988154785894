import styled from "@emotion/styled";
import React from "react";
import { observer } from "mobx-react-lite";
import { Column, Row } from "@components/Flex";
import { ReactComponent as BackIcon } from "@assets/icons/dubleArrowLeft.svg";
import Text from "@components/Text";
import SizedBox from "@components/SizedBox";
import { Link } from "react-router-dom";
import { ROUTES } from "@stores/AccountStore";
import { useDeviceCalibrationScreenVM } from "@screens/DeviceCalibrationScreen/DeviceCalibrationScreenVM";
import { useLang } from "@src/hooks/useLang";
import Input from "@components/Input";

interface IProps {}

const Root = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 16px 0;
  width: 290px;
  max-height: 100vh;
  border-right: 1px solid #cbd5d8;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 16px;
`;

const DeviceCalibrationMenu: React.FC<IProps> = () => {
  const vm = useDeviceCalibrationScreenVM();
  const lang = useLang();
  return (
    <Root>
      <Body>
        <Column crossAxisSize="max">
          <Link to={ROUTES.DEVICES}>
            <Row alignItems="center">
              <BackIcon style={{ marginRight: 4 }} />
              <Text size={12} color="#899093">
                {lang.devices.devicesTitle}
              </Text>
            </Row>
          </Link>
          <SizedBox height={24} />
          <Text type="heading" color="#494C5B" size={24}>
            {vm.device?.harvester.name}
          </Text>
          <SizedBox height={4} />
          <Text color="#899093" size={14}>
            {vm.device?.harvester.model}
          </Text>
          {/*<SizedBox height={24} />*/}
          {/*<DeviceTrackDetails />*/}
          {/*<SizedBox height={24} />*/}
          {/*<Text type="heading" color="#494C5B" size={14}>*/}
          {/*  {lang.devices.tagsListTitle}*/}
          {/*</Text>*/}
          {/*<SizedBox height={8} />*/}
          {/*<DeviceTagsList tags={[]} />*/}
          <SizedBox height={16} />
          <Input
            readOnly
            value={vm.device?.harvester.default_reaper.width}
            label={lang.devices.calibrationHeaderTitle}
          />
        </Column>
        {/*<Button kind="secondary">*/}
        {/*  {lang.devices.deviceSelectedTrackButton}*/}
        {/*</Button>*/}
      </Body>
    </Root>
  );
};
export default observer(DeviceCalibrationMenu);
