// source: apis/devices/v1/api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.grass.devices.v1.AttachedDevice', null, global);
goog.exportSymbol('proto.grass.devices.v1.Calibration', null, global);
goog.exportSymbol('proto.grass.devices.v1.CalibrationsForIntervalRequest', null, global);
goog.exportSymbol('proto.grass.devices.v1.CalibrationsForIntervalResponse', null, global);
goog.exportSymbol('proto.grass.devices.v1.DeviceSetting', null, global);
goog.exportSymbol('proto.grass.devices.v1.DeviceSettingsRequest', null, global);
goog.exportSymbol('proto.grass.devices.v1.DeviceSettingsResponse', null, global);
goog.exportSymbol('proto.grass.devices.v1.DeviceStatisticsRequest', null, global);
goog.exportSymbol('proto.grass.devices.v1.DeviceStatisticsRequest.Field', null, global);
goog.exportSymbol('proto.grass.devices.v1.DeviceStatisticsResponse', null, global);
goog.exportSymbol('proto.grass.devices.v1.DeviceStatus', null, global);
goog.exportSymbol('proto.grass.devices.v1.DeviceType', null, global);
goog.exportSymbol('proto.grass.devices.v1.DevicesRequest', null, global);
goog.exportSymbol('proto.grass.devices.v1.DevicesResponse', null, global);
goog.exportSymbol('proto.grass.devices.v1.GetDeviceRequest', null, global);
goog.exportSymbol('proto.grass.devices.v1.GetDeviceResponse', null, global);
goog.exportSymbol('proto.grass.devices.v1.LastStatusRequest', null, global);
goog.exportSymbol('proto.grass.devices.v1.LastStatusResponse', null, global);
goog.exportSymbol('proto.grass.devices.v1.RunTracksBackupRequest', null, global);
goog.exportSymbol('proto.grass.devices.v1.RunTracksBackupResponse', null, global);
goog.exportSymbol('proto.grass.devices.v1.SensorMode', null, global);
goog.exportSymbol('proto.grass.devices.v1.SensorThresholds', null, global);
goog.exportSymbol('proto.grass.devices.v1.StatisticsRequest', null, global);
goog.exportSymbol('proto.grass.devices.v1.StatisticsRequest.Field', null, global);
goog.exportSymbol('proto.grass.devices.v1.StatisticsResponse', null, global);
goog.exportSymbol('proto.grass.devices.v1.UpdateDeviceRequest', null, global);
goog.exportSymbol('proto.grass.devices.v1.UpdateDeviceResponse', null, global);
goog.exportSymbol('proto.grass.devices.v1.UpdateDeviceSettingsRequest', null, global);
goog.exportSymbol('proto.grass.devices.v1.UpdateDeviceSettingsResponse', null, global);
goog.exportSymbol('proto.grass.devices.v1.UserDevice', null, global);
goog.exportSymbol('proto.grass.devices.v1.UserDevicesRequest', null, global);
goog.exportSymbol('proto.grass.devices.v1.UserDevicesResponse', null, global);
goog.exportSymbol('proto.grass.devices.v1.UserStatisticsRequest', null, global);
goog.exportSymbol('proto.grass.devices.v1.UserStatisticsRequest.Field', null, global);
goog.exportSymbol('proto.grass.devices.v1.UserStatisticsRequest.Interval', null, global);
goog.exportSymbol('proto.grass.devices.v1.UserStatisticsResponse', null, global);
goog.exportSymbol('proto.grass.devices.v1.UserStatisticsResponse.Statistics', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.LastStatusRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grass.devices.v1.LastStatusRequest.repeatedFields_, null);
};
goog.inherits(proto.grass.devices.v1.LastStatusRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.LastStatusRequest.displayName = 'proto.grass.devices.v1.LastStatusRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.DeviceStatus = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grass.devices.v1.DeviceStatus.repeatedFields_, null);
};
goog.inherits(proto.grass.devices.v1.DeviceStatus, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.DeviceStatus.displayName = 'proto.grass.devices.v1.DeviceStatus';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.LastStatusResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grass.devices.v1.LastStatusResponse.repeatedFields_, null);
};
goog.inherits(proto.grass.devices.v1.LastStatusResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.LastStatusResponse.displayName = 'proto.grass.devices.v1.LastStatusResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.StatisticsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grass.devices.v1.StatisticsRequest.repeatedFields_, null);
};
goog.inherits(proto.grass.devices.v1.StatisticsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.StatisticsRequest.displayName = 'proto.grass.devices.v1.StatisticsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.StatisticsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grass.devices.v1.StatisticsResponse.repeatedFields_, null);
};
goog.inherits(proto.grass.devices.v1.StatisticsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.StatisticsResponse.displayName = 'proto.grass.devices.v1.StatisticsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.UserDevicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.devices.v1.UserDevicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.UserDevicesRequest.displayName = 'proto.grass.devices.v1.UserDevicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.UserDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grass.devices.v1.UserDevice.repeatedFields_, null);
};
goog.inherits(proto.grass.devices.v1.UserDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.UserDevice.displayName = 'proto.grass.devices.v1.UserDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.UserDevicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grass.devices.v1.UserDevicesResponse.repeatedFields_, null);
};
goog.inherits(proto.grass.devices.v1.UserDevicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.UserDevicesResponse.displayName = 'proto.grass.devices.v1.UserDevicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.UserStatisticsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grass.devices.v1.UserStatisticsRequest.repeatedFields_, null);
};
goog.inherits(proto.grass.devices.v1.UserStatisticsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.UserStatisticsRequest.displayName = 'proto.grass.devices.v1.UserStatisticsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.UserStatisticsRequest.Interval = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.devices.v1.UserStatisticsRequest.Interval, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.UserStatisticsRequest.Interval.displayName = 'proto.grass.devices.v1.UserStatisticsRequest.Interval';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.UserStatisticsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grass.devices.v1.UserStatisticsResponse.repeatedFields_, null);
};
goog.inherits(proto.grass.devices.v1.UserStatisticsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.UserStatisticsResponse.displayName = 'proto.grass.devices.v1.UserStatisticsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grass.devices.v1.UserStatisticsResponse.Statistics.repeatedFields_, null);
};
goog.inherits(proto.grass.devices.v1.UserStatisticsResponse.Statistics, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.UserStatisticsResponse.Statistics.displayName = 'proto.grass.devices.v1.UserStatisticsResponse.Statistics';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.GetDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.devices.v1.GetDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.GetDeviceRequest.displayName = 'proto.grass.devices.v1.GetDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.GetDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.devices.v1.GetDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.GetDeviceResponse.displayName = 'proto.grass.devices.v1.GetDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.SensorThresholds = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.devices.v1.SensorThresholds, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.SensorThresholds.displayName = 'proto.grass.devices.v1.SensorThresholds';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.UpdateDeviceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.devices.v1.UpdateDeviceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.UpdateDeviceRequest.displayName = 'proto.grass.devices.v1.UpdateDeviceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.UpdateDeviceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.devices.v1.UpdateDeviceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.UpdateDeviceResponse.displayName = 'proto.grass.devices.v1.UpdateDeviceResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.devices.v1.UpdateDeviceSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.UpdateDeviceSettingsRequest.displayName = 'proto.grass.devices.v1.UpdateDeviceSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.UpdateDeviceSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.devices.v1.UpdateDeviceSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.UpdateDeviceSettingsResponse.displayName = 'proto.grass.devices.v1.UpdateDeviceSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.DeviceStatisticsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grass.devices.v1.DeviceStatisticsRequest.repeatedFields_, null);
};
goog.inherits(proto.grass.devices.v1.DeviceStatisticsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.DeviceStatisticsRequest.displayName = 'proto.grass.devices.v1.DeviceStatisticsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.DeviceStatisticsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grass.devices.v1.DeviceStatisticsResponse.repeatedFields_, null);
};
goog.inherits(proto.grass.devices.v1.DeviceStatisticsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.DeviceStatisticsResponse.displayName = 'proto.grass.devices.v1.DeviceStatisticsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.DevicesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.devices.v1.DevicesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.DevicesRequest.displayName = 'proto.grass.devices.v1.DevicesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.AttachedDevice = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grass.devices.v1.AttachedDevice.repeatedFields_, null);
};
goog.inherits(proto.grass.devices.v1.AttachedDevice, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.AttachedDevice.displayName = 'proto.grass.devices.v1.AttachedDevice';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.DevicesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grass.devices.v1.DevicesResponse.repeatedFields_, null);
};
goog.inherits(proto.grass.devices.v1.DevicesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.DevicesResponse.displayName = 'proto.grass.devices.v1.DevicesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.DeviceSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grass.devices.v1.DeviceSettingsRequest.repeatedFields_, null);
};
goog.inherits(proto.grass.devices.v1.DeviceSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.DeviceSettingsRequest.displayName = 'proto.grass.devices.v1.DeviceSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.DeviceSetting = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.devices.v1.DeviceSetting, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.DeviceSetting.displayName = 'proto.grass.devices.v1.DeviceSetting';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.DeviceSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grass.devices.v1.DeviceSettingsResponse.repeatedFields_, null);
};
goog.inherits(proto.grass.devices.v1.DeviceSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.DeviceSettingsResponse.displayName = 'proto.grass.devices.v1.DeviceSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.Calibration = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.devices.v1.Calibration, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.Calibration.displayName = 'proto.grass.devices.v1.Calibration';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.CalibrationsForIntervalRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.devices.v1.CalibrationsForIntervalRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.CalibrationsForIntervalRequest.displayName = 'proto.grass.devices.v1.CalibrationsForIntervalRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.CalibrationsForIntervalResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grass.devices.v1.CalibrationsForIntervalResponse.repeatedFields_, null);
};
goog.inherits(proto.grass.devices.v1.CalibrationsForIntervalResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.CalibrationsForIntervalResponse.displayName = 'proto.grass.devices.v1.CalibrationsForIntervalResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.RunTracksBackupRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.devices.v1.RunTracksBackupRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.RunTracksBackupRequest.displayName = 'proto.grass.devices.v1.RunTracksBackupRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.devices.v1.RunTracksBackupResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.devices.v1.RunTracksBackupResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.devices.v1.RunTracksBackupResponse.displayName = 'proto.grass.devices.v1.RunTracksBackupResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grass.devices.v1.LastStatusRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.LastStatusRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.LastStatusRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.LastStatusRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.LastStatusRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    esnList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.LastStatusRequest}
 */
proto.grass.devices.v1.LastStatusRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.LastStatusRequest;
  return proto.grass.devices.v1.LastStatusRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.LastStatusRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.LastStatusRequest}
 */
proto.grass.devices.v1.LastStatusRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addEsn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.LastStatusRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.LastStatusRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.LastStatusRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.LastStatusRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEsnList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
};


/**
 * repeated string esn = 1;
 * @return {!Array<string>}
 */
proto.grass.devices.v1.LastStatusRequest.prototype.getEsnList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grass.devices.v1.LastStatusRequest} returns this
 */
proto.grass.devices.v1.LastStatusRequest.prototype.setEsnList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.LastStatusRequest} returns this
 */
proto.grass.devices.v1.LastStatusRequest.prototype.addEsn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.LastStatusRequest} returns this
 */
proto.grass.devices.v1.LastStatusRequest.prototype.clearEsnList = function() {
  return this.setEsnList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grass.devices.v1.DeviceStatus.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.DeviceStatus.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.DeviceStatus.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.DeviceStatus} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.DeviceStatus.toObject = function(includeInstance, msg) {
  var f, obj = {
    esn: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    positionList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 3)) == null ? undefined : f,
    time: (f = msg.getTime()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    alias: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.DeviceStatus}
 */
proto.grass.devices.v1.DeviceStatus.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.DeviceStatus;
  return proto.grass.devices.v1.DeviceStatus.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.DeviceStatus} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.DeviceStatus}
 */
proto.grass.devices.v1.DeviceStatus.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEsn(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addPosition(values[i]);
      }
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.DeviceStatus.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.DeviceStatus.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.DeviceStatus} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.DeviceStatus.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEsn();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getPositionList();
  if (f.length > 0) {
    writer.writePackedDouble(
      3,
      f
    );
  }
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string esn = 1;
 * @return {string}
 */
proto.grass.devices.v1.DeviceStatus.prototype.getEsn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.DeviceStatus} returns this
 */
proto.grass.devices.v1.DeviceStatus.prototype.setEsn = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.grass.devices.v1.DeviceStatus.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.DeviceStatus} returns this
 */
proto.grass.devices.v1.DeviceStatus.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * repeated double position = 3;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.DeviceStatus.prototype.getPositionList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.DeviceStatus} returns this
 */
proto.grass.devices.v1.DeviceStatus.prototype.setPositionList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.DeviceStatus} returns this
 */
proto.grass.devices.v1.DeviceStatus.prototype.addPosition = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.DeviceStatus} returns this
 */
proto.grass.devices.v1.DeviceStatus.prototype.clearPositionList = function() {
  return this.setPositionList([]);
};


/**
 * optional google.protobuf.Timestamp time = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.grass.devices.v1.DeviceStatus.prototype.getTime = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.grass.devices.v1.DeviceStatus} returns this
*/
proto.grass.devices.v1.DeviceStatus.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.devices.v1.DeviceStatus} returns this
 */
proto.grass.devices.v1.DeviceStatus.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.devices.v1.DeviceStatus.prototype.hasTime = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional string alias = 5;
 * @return {string}
 */
proto.grass.devices.v1.DeviceStatus.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.DeviceStatus} returns this
 */
proto.grass.devices.v1.DeviceStatus.prototype.setAlias = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grass.devices.v1.DeviceStatus} returns this
 */
proto.grass.devices.v1.DeviceStatus.prototype.clearAlias = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.devices.v1.DeviceStatus.prototype.hasAlias = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grass.devices.v1.LastStatusResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.LastStatusResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.LastStatusResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.LastStatusResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.LastStatusResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.grass.devices.v1.DeviceStatus.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.LastStatusResponse}
 */
proto.grass.devices.v1.LastStatusResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.LastStatusResponse;
  return proto.grass.devices.v1.LastStatusResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.LastStatusResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.LastStatusResponse}
 */
proto.grass.devices.v1.LastStatusResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grass.devices.v1.DeviceStatus;
      reader.readMessage(value,proto.grass.devices.v1.DeviceStatus.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.LastStatusResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.LastStatusResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.LastStatusResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.LastStatusResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grass.devices.v1.DeviceStatus.serializeBinaryToWriter
    );
  }
};


/**
 * repeated DeviceStatus devices = 1;
 * @return {!Array<!proto.grass.devices.v1.DeviceStatus>}
 */
proto.grass.devices.v1.LastStatusResponse.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.grass.devices.v1.DeviceStatus>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grass.devices.v1.DeviceStatus, 1));
};


/**
 * @param {!Array<!proto.grass.devices.v1.DeviceStatus>} value
 * @return {!proto.grass.devices.v1.LastStatusResponse} returns this
*/
proto.grass.devices.v1.LastStatusResponse.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grass.devices.v1.DeviceStatus=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.DeviceStatus}
 */
proto.grass.devices.v1.LastStatusResponse.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grass.devices.v1.DeviceStatus, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.LastStatusResponse} returns this
 */
proto.grass.devices.v1.LastStatusResponse.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grass.devices.v1.StatisticsRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.StatisticsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.StatisticsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.StatisticsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.StatisticsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    esn: jspb.Message.getFieldWithDefault(msg, 1, ""),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    precision: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.StatisticsRequest}
 */
proto.grass.devices.v1.StatisticsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.StatisticsRequest;
  return proto.grass.devices.v1.StatisticsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.StatisticsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.StatisticsRequest}
 */
proto.grass.devices.v1.StatisticsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEsn(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.grass.devices.v1.StatisticsRequest.Field>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFields(values[i]);
      }
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrecision(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.StatisticsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.StatisticsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.StatisticsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.StatisticsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEsn();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getPrecision();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.grass.devices.v1.StatisticsRequest.Field = {
  FIELD_INVALID: 0,
  FIELD_TIME: 1,
  FIELD_COORDS: 2,
  FIELD_SENSOR: 3,
  FIELD_SPEED: 4,
  FIELD_FREQUENCY: 5,
  FIELD_SAT_QTY: 6
};

/**
 * optional string esn = 1;
 * @return {string}
 */
proto.grass.devices.v1.StatisticsRequest.prototype.getEsn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.StatisticsRequest} returns this
 */
proto.grass.devices.v1.StatisticsRequest.prototype.setEsn = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp start = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.grass.devices.v1.StatisticsRequest.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.grass.devices.v1.StatisticsRequest} returns this
*/
proto.grass.devices.v1.StatisticsRequest.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.devices.v1.StatisticsRequest} returns this
 */
proto.grass.devices.v1.StatisticsRequest.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.devices.v1.StatisticsRequest.prototype.hasStart = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.grass.devices.v1.StatisticsRequest.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.grass.devices.v1.StatisticsRequest} returns this
*/
proto.grass.devices.v1.StatisticsRequest.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.devices.v1.StatisticsRequest} returns this
 */
proto.grass.devices.v1.StatisticsRequest.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.devices.v1.StatisticsRequest.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Field fields = 4;
 * @return {!Array<!proto.grass.devices.v1.StatisticsRequest.Field>}
 */
proto.grass.devices.v1.StatisticsRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<!proto.grass.devices.v1.StatisticsRequest.Field>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.grass.devices.v1.StatisticsRequest.Field>} value
 * @return {!proto.grass.devices.v1.StatisticsRequest} returns this
 */
proto.grass.devices.v1.StatisticsRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.grass.devices.v1.StatisticsRequest.Field} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.StatisticsRequest} returns this
 */
proto.grass.devices.v1.StatisticsRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.StatisticsRequest} returns this
 */
proto.grass.devices.v1.StatisticsRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional uint32 precision = 5;
 * @return {number}
 */
proto.grass.devices.v1.StatisticsRequest.prototype.getPrecision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.StatisticsRequest} returns this
 */
proto.grass.devices.v1.StatisticsRequest.prototype.setPrecision = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grass.devices.v1.StatisticsResponse.repeatedFields_ = [2,3,5,6,7,8];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.StatisticsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.StatisticsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.StatisticsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.StatisticsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    length: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timeList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    coordsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    sensorList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 5)) == null ? undefined : f,
    speedList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 6)) == null ? undefined : f,
    frequencyList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 7)) == null ? undefined : f,
    satQtyList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.StatisticsResponse}
 */
proto.grass.devices.v1.StatisticsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.StatisticsResponse;
  return proto.grass.devices.v1.StatisticsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.StatisticsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.StatisticsResponse}
 */
proto.grass.devices.v1.StatisticsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLength(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFixed32() : [reader.readFixed32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTime(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSint64() : [reader.readSint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCoords(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSensor(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSpeed(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFrequency(values[i]);
      }
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSatQty(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.StatisticsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.StatisticsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.StatisticsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.StatisticsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLength();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTimeList();
  if (f.length > 0) {
    writer.writePackedFixed32(
      2,
      f
    );
  }
  f = message.getCoordsList();
  if (f.length > 0) {
    writer.writePackedSint64(
      3,
      f
    );
  }
  f = message.getSensorList();
  if (f.length > 0) {
    writer.writePackedDouble(
      5,
      f
    );
  }
  f = message.getSpeedList();
  if (f.length > 0) {
    writer.writePackedDouble(
      6,
      f
    );
  }
  f = message.getFrequencyList();
  if (f.length > 0) {
    writer.writePackedDouble(
      7,
      f
    );
  }
  f = message.getSatQtyList();
  if (f.length > 0) {
    writer.writePackedUint32(
      8,
      f
    );
  }
};


/**
 * optional uint32 length = 1;
 * @return {number}
 */
proto.grass.devices.v1.StatisticsResponse.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.StatisticsResponse} returns this
 */
proto.grass.devices.v1.StatisticsResponse.prototype.setLength = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated fixed32 time = 2;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.StatisticsResponse.prototype.getTimeList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.StatisticsResponse} returns this
 */
proto.grass.devices.v1.StatisticsResponse.prototype.setTimeList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.StatisticsResponse} returns this
 */
proto.grass.devices.v1.StatisticsResponse.prototype.addTime = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.StatisticsResponse} returns this
 */
proto.grass.devices.v1.StatisticsResponse.prototype.clearTimeList = function() {
  return this.setTimeList([]);
};


/**
 * repeated sint64 coords = 3;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.StatisticsResponse.prototype.getCoordsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.StatisticsResponse} returns this
 */
proto.grass.devices.v1.StatisticsResponse.prototype.setCoordsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.StatisticsResponse} returns this
 */
proto.grass.devices.v1.StatisticsResponse.prototype.addCoords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.StatisticsResponse} returns this
 */
proto.grass.devices.v1.StatisticsResponse.prototype.clearCoordsList = function() {
  return this.setCoordsList([]);
};


/**
 * repeated double sensor = 5;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.StatisticsResponse.prototype.getSensorList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.StatisticsResponse} returns this
 */
proto.grass.devices.v1.StatisticsResponse.prototype.setSensorList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.StatisticsResponse} returns this
 */
proto.grass.devices.v1.StatisticsResponse.prototype.addSensor = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.StatisticsResponse} returns this
 */
proto.grass.devices.v1.StatisticsResponse.prototype.clearSensorList = function() {
  return this.setSensorList([]);
};


/**
 * repeated double speed = 6;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.StatisticsResponse.prototype.getSpeedList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.StatisticsResponse} returns this
 */
proto.grass.devices.v1.StatisticsResponse.prototype.setSpeedList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.StatisticsResponse} returns this
 */
proto.grass.devices.v1.StatisticsResponse.prototype.addSpeed = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.StatisticsResponse} returns this
 */
proto.grass.devices.v1.StatisticsResponse.prototype.clearSpeedList = function() {
  return this.setSpeedList([]);
};


/**
 * repeated double frequency = 7;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.StatisticsResponse.prototype.getFrequencyList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.StatisticsResponse} returns this
 */
proto.grass.devices.v1.StatisticsResponse.prototype.setFrequencyList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.StatisticsResponse} returns this
 */
proto.grass.devices.v1.StatisticsResponse.prototype.addFrequency = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.StatisticsResponse} returns this
 */
proto.grass.devices.v1.StatisticsResponse.prototype.clearFrequencyList = function() {
  return this.setFrequencyList([]);
};


/**
 * repeated uint32 sat_qty = 8;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.StatisticsResponse.prototype.getSatQtyList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.StatisticsResponse} returns this
 */
proto.grass.devices.v1.StatisticsResponse.prototype.setSatQtyList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.StatisticsResponse} returns this
 */
proto.grass.devices.v1.StatisticsResponse.prototype.addSatQty = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.StatisticsResponse} returns this
 */
proto.grass.devices.v1.StatisticsResponse.prototype.clearSatQtyList = function() {
  return this.setSatQtyList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.UserDevicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.UserDevicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.UserDevicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.UserDevicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.UserDevicesRequest}
 */
proto.grass.devices.v1.UserDevicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.UserDevicesRequest;
  return proto.grass.devices.v1.UserDevicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.UserDevicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.UserDevicesRequest}
 */
proto.grass.devices.v1.UserDevicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.UserDevicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.UserDevicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.UserDevicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.UserDevicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.grass.devices.v1.UserDevicesRequest.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.UserDevicesRequest} returns this
 */
proto.grass.devices.v1.UserDevicesRequest.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grass.devices.v1.UserDevice.repeatedFields_ = [5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.UserDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.UserDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.UserDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.UserDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    esn: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    lastSeenAt: (f = msg.getLastSeenAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastPositionList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.UserDevice}
 */
proto.grass.devices.v1.UserDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.UserDevice;
  return proto.grass.devices.v1.UserDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.UserDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.UserDevice}
 */
proto.grass.devices.v1.UserDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEsn(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastSeenAt(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLastPosition(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.UserDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.UserDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.UserDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.UserDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEsn();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getLastSeenAt();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastPositionList();
  if (f.length > 0) {
    writer.writePackedDouble(
      5,
      f
    );
  }
};


/**
 * optional string esn = 1;
 * @return {string}
 */
proto.grass.devices.v1.UserDevice.prototype.getEsn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.UserDevice} returns this
 */
proto.grass.devices.v1.UserDevice.prototype.setEsn = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.grass.devices.v1.UserDevice.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.UserDevice} returns this
 */
proto.grass.devices.v1.UserDevice.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.grass.devices.v1.UserDevice.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.UserDevice} returns this
 */
proto.grass.devices.v1.UserDevice.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.Timestamp last_seen_at = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.grass.devices.v1.UserDevice.prototype.getLastSeenAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.grass.devices.v1.UserDevice} returns this
*/
proto.grass.devices.v1.UserDevice.prototype.setLastSeenAt = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.devices.v1.UserDevice} returns this
 */
proto.grass.devices.v1.UserDevice.prototype.clearLastSeenAt = function() {
  return this.setLastSeenAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.devices.v1.UserDevice.prototype.hasLastSeenAt = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated double last_position = 5;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.UserDevice.prototype.getLastPositionList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.UserDevice} returns this
 */
proto.grass.devices.v1.UserDevice.prototype.setLastPositionList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.UserDevice} returns this
 */
proto.grass.devices.v1.UserDevice.prototype.addLastPosition = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.UserDevice} returns this
 */
proto.grass.devices.v1.UserDevice.prototype.clearLastPositionList = function() {
  return this.setLastPositionList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grass.devices.v1.UserDevicesResponse.repeatedFields_ = [1,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.UserDevicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.UserDevicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.UserDevicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.UserDevicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.grass.devices.v1.UserDevice.toObject, includeInstance),
    bboxList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.UserDevicesResponse}
 */
proto.grass.devices.v1.UserDevicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.UserDevicesResponse;
  return proto.grass.devices.v1.UserDevicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.UserDevicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.UserDevicesResponse}
 */
proto.grass.devices.v1.UserDevicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grass.devices.v1.UserDevice;
      reader.readMessage(value,proto.grass.devices.v1.UserDevice.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBbox(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.UserDevicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.UserDevicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.UserDevicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.UserDevicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grass.devices.v1.UserDevice.serializeBinaryToWriter
    );
  }
  f = message.getBboxList();
  if (f.length > 0) {
    writer.writePackedDouble(
      10,
      f
    );
  }
};


/**
 * repeated UserDevice devices = 1;
 * @return {!Array<!proto.grass.devices.v1.UserDevice>}
 */
proto.grass.devices.v1.UserDevicesResponse.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.grass.devices.v1.UserDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grass.devices.v1.UserDevice, 1));
};


/**
 * @param {!Array<!proto.grass.devices.v1.UserDevice>} value
 * @return {!proto.grass.devices.v1.UserDevicesResponse} returns this
*/
proto.grass.devices.v1.UserDevicesResponse.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grass.devices.v1.UserDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.UserDevice}
 */
proto.grass.devices.v1.UserDevicesResponse.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grass.devices.v1.UserDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.UserDevicesResponse} returns this
 */
proto.grass.devices.v1.UserDevicesResponse.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};


/**
 * repeated double bbox = 10;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.UserDevicesResponse.prototype.getBboxList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.UserDevicesResponse} returns this
 */
proto.grass.devices.v1.UserDevicesResponse.prototype.setBboxList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.UserDevicesResponse} returns this
 */
proto.grass.devices.v1.UserDevicesResponse.prototype.addBbox = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.UserDevicesResponse} returns this
 */
proto.grass.devices.v1.UserDevicesResponse.prototype.clearBboxList = function() {
  return this.setBboxList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grass.devices.v1.UserStatisticsRequest.repeatedFields_ = [3,5];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.UserStatisticsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.UserStatisticsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.UserStatisticsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.UserStatisticsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    precision: jspb.Message.getFieldWithDefault(msg, 2, 0),
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    interval: (f = msg.getInterval()) && proto.grass.devices.v1.UserStatisticsRequest.Interval.toObject(includeInstance, f),
    bboxList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 5)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.UserStatisticsRequest}
 */
proto.grass.devices.v1.UserStatisticsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.UserStatisticsRequest;
  return proto.grass.devices.v1.UserStatisticsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.UserStatisticsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.UserStatisticsRequest}
 */
proto.grass.devices.v1.UserStatisticsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrecision(value);
      break;
    case 3:
      var values = /** @type {!Array<!proto.grass.devices.v1.UserStatisticsRequest.Field>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFields(values[i]);
      }
      break;
    case 4:
      var value = new proto.grass.devices.v1.UserStatisticsRequest.Interval;
      reader.readMessage(value,proto.grass.devices.v1.UserStatisticsRequest.Interval.deserializeBinaryFromReader);
      msg.setInterval(value);
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBbox(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.UserStatisticsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.UserStatisticsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.UserStatisticsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.UserStatisticsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPrecision();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      3,
      f
    );
  }
  f = message.getInterval();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.grass.devices.v1.UserStatisticsRequest.Interval.serializeBinaryToWriter
    );
  }
  f = message.getBboxList();
  if (f.length > 0) {
    writer.writePackedDouble(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.grass.devices.v1.UserStatisticsRequest.Field = {
  FIELD_INVALID: 0,
  FIELD_TIME: 1,
  FIELD_COORDS: 2,
  FIELD_SENSOR: 3,
  FIELD_SPEED: 4,
  FIELD_FREQUENCY: 5,
  FIELD_SAT_QTY: 6
};




if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.UserStatisticsRequest.Interval.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.UserStatisticsRequest.Interval.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.UserStatisticsRequest.Interval} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.UserStatisticsRequest.Interval.toObject = function(includeInstance, msg) {
  var f, obj = {
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.UserStatisticsRequest.Interval}
 */
proto.grass.devices.v1.UserStatisticsRequest.Interval.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.UserStatisticsRequest.Interval;
  return proto.grass.devices.v1.UserStatisticsRequest.Interval.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.UserStatisticsRequest.Interval} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.UserStatisticsRequest.Interval}
 */
proto.grass.devices.v1.UserStatisticsRequest.Interval.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.UserStatisticsRequest.Interval.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.UserStatisticsRequest.Interval.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.UserStatisticsRequest.Interval} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.UserStatisticsRequest.Interval.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional google.protobuf.Timestamp start = 1;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.grass.devices.v1.UserStatisticsRequest.Interval.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 1));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.grass.devices.v1.UserStatisticsRequest.Interval} returns this
*/
proto.grass.devices.v1.UserStatisticsRequest.Interval.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.devices.v1.UserStatisticsRequest.Interval} returns this
 */
proto.grass.devices.v1.UserStatisticsRequest.Interval.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.devices.v1.UserStatisticsRequest.Interval.prototype.hasStart = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional google.protobuf.Timestamp end = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.grass.devices.v1.UserStatisticsRequest.Interval.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.grass.devices.v1.UserStatisticsRequest.Interval} returns this
*/
proto.grass.devices.v1.UserStatisticsRequest.Interval.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.devices.v1.UserStatisticsRequest.Interval} returns this
 */
proto.grass.devices.v1.UserStatisticsRequest.Interval.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.devices.v1.UserStatisticsRequest.Interval.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.grass.devices.v1.UserStatisticsRequest.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.UserStatisticsRequest} returns this
 */
proto.grass.devices.v1.UserStatisticsRequest.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 precision = 2;
 * @return {number}
 */
proto.grass.devices.v1.UserStatisticsRequest.prototype.getPrecision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.UserStatisticsRequest} returns this
 */
proto.grass.devices.v1.UserStatisticsRequest.prototype.setPrecision = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated Field fields = 3;
 * @return {!Array<!proto.grass.devices.v1.UserStatisticsRequest.Field>}
 */
proto.grass.devices.v1.UserStatisticsRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<!proto.grass.devices.v1.UserStatisticsRequest.Field>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<!proto.grass.devices.v1.UserStatisticsRequest.Field>} value
 * @return {!proto.grass.devices.v1.UserStatisticsRequest} returns this
 */
proto.grass.devices.v1.UserStatisticsRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {!proto.grass.devices.v1.UserStatisticsRequest.Field} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.UserStatisticsRequest} returns this
 */
proto.grass.devices.v1.UserStatisticsRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.UserStatisticsRequest} returns this
 */
proto.grass.devices.v1.UserStatisticsRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional Interval interval = 4;
 * @return {?proto.grass.devices.v1.UserStatisticsRequest.Interval}
 */
proto.grass.devices.v1.UserStatisticsRequest.prototype.getInterval = function() {
  return /** @type{?proto.grass.devices.v1.UserStatisticsRequest.Interval} */ (
    jspb.Message.getWrapperField(this, proto.grass.devices.v1.UserStatisticsRequest.Interval, 4));
};


/**
 * @param {?proto.grass.devices.v1.UserStatisticsRequest.Interval|undefined} value
 * @return {!proto.grass.devices.v1.UserStatisticsRequest} returns this
*/
proto.grass.devices.v1.UserStatisticsRequest.prototype.setInterval = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.devices.v1.UserStatisticsRequest} returns this
 */
proto.grass.devices.v1.UserStatisticsRequest.prototype.clearInterval = function() {
  return this.setInterval(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.devices.v1.UserStatisticsRequest.prototype.hasInterval = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated double bbox = 5;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.UserStatisticsRequest.prototype.getBboxList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.UserStatisticsRequest} returns this
 */
proto.grass.devices.v1.UserStatisticsRequest.prototype.setBboxList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.UserStatisticsRequest} returns this
 */
proto.grass.devices.v1.UserStatisticsRequest.prototype.addBbox = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.UserStatisticsRequest} returns this
 */
proto.grass.devices.v1.UserStatisticsRequest.prototype.clearBboxList = function() {
  return this.setBboxList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grass.devices.v1.UserStatisticsResponse.repeatedFields_ = [1,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.UserStatisticsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.UserStatisticsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.UserStatisticsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.UserStatisticsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    statisticsList: jspb.Message.toObjectList(msg.getStatisticsList(),
    proto.grass.devices.v1.UserStatisticsResponse.Statistics.toObject, includeInstance),
    precision: jspb.Message.getFieldWithDefault(msg, 2, 0),
    bboxList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.UserStatisticsResponse}
 */
proto.grass.devices.v1.UserStatisticsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.UserStatisticsResponse;
  return proto.grass.devices.v1.UserStatisticsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.UserStatisticsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.UserStatisticsResponse}
 */
proto.grass.devices.v1.UserStatisticsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grass.devices.v1.UserStatisticsResponse.Statistics;
      reader.readMessage(value,proto.grass.devices.v1.UserStatisticsResponse.Statistics.deserializeBinaryFromReader);
      msg.addStatistics(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrecision(value);
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBbox(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.UserStatisticsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.UserStatisticsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.UserStatisticsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.UserStatisticsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStatisticsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grass.devices.v1.UserStatisticsResponse.Statistics.serializeBinaryToWriter
    );
  }
  f = message.getPrecision();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getBboxList();
  if (f.length > 0) {
    writer.writePackedDouble(
      10,
      f
    );
  }
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.repeatedFields_ = [3,4,6,7,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.UserStatisticsResponse.Statistics.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.UserStatisticsResponse.Statistics} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.toObject = function(includeInstance, msg) {
  var f, obj = {
    esn: jspb.Message.getFieldWithDefault(msg, 1, ""),
    length: jspb.Message.getFieldWithDefault(msg, 2, 0),
    timeList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    coordsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    sensorList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 6)) == null ? undefined : f,
    speedList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 7)) == null ? undefined : f,
    frequencyList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 8)) == null ? undefined : f,
    satQtyList: (f = jspb.Message.getRepeatedField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.UserStatisticsResponse.Statistics}
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.UserStatisticsResponse.Statistics;
  return proto.grass.devices.v1.UserStatisticsResponse.Statistics.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.UserStatisticsResponse.Statistics} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.UserStatisticsResponse.Statistics}
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEsn(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLength(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFixed32() : [reader.readFixed32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTime(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSint64() : [reader.readSint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCoords(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSensor(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSpeed(values[i]);
      }
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFrequency(values[i]);
      }
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSatQty(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.UserStatisticsResponse.Statistics.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.UserStatisticsResponse.Statistics} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEsn();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLength();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getTimeList();
  if (f.length > 0) {
    writer.writePackedFixed32(
      3,
      f
    );
  }
  f = message.getCoordsList();
  if (f.length > 0) {
    writer.writePackedSint64(
      4,
      f
    );
  }
  f = message.getSensorList();
  if (f.length > 0) {
    writer.writePackedDouble(
      6,
      f
    );
  }
  f = message.getSpeedList();
  if (f.length > 0) {
    writer.writePackedDouble(
      7,
      f
    );
  }
  f = message.getFrequencyList();
  if (f.length > 0) {
    writer.writePackedDouble(
      8,
      f
    );
  }
  f = message.getSatQtyList();
  if (f.length > 0) {
    writer.writePackedUint32(
      9,
      f
    );
  }
};


/**
 * optional string esn = 1;
 * @return {string}
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.getEsn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.UserStatisticsResponse.Statistics} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.setEsn = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 length = 2;
 * @return {number}
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.UserStatisticsResponse.Statistics} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.setLength = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated fixed32 time = 3;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.getTimeList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.UserStatisticsResponse.Statistics} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.setTimeList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.UserStatisticsResponse.Statistics} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.addTime = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.UserStatisticsResponse.Statistics} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.clearTimeList = function() {
  return this.setTimeList([]);
};


/**
 * repeated sint64 coords = 4;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.getCoordsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.UserStatisticsResponse.Statistics} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.setCoordsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.UserStatisticsResponse.Statistics} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.addCoords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.UserStatisticsResponse.Statistics} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.clearCoordsList = function() {
  return this.setCoordsList([]);
};


/**
 * repeated double sensor = 6;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.getSensorList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.UserStatisticsResponse.Statistics} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.setSensorList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.UserStatisticsResponse.Statistics} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.addSensor = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.UserStatisticsResponse.Statistics} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.clearSensorList = function() {
  return this.setSensorList([]);
};


/**
 * repeated double speed = 7;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.getSpeedList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.UserStatisticsResponse.Statistics} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.setSpeedList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.UserStatisticsResponse.Statistics} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.addSpeed = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.UserStatisticsResponse.Statistics} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.clearSpeedList = function() {
  return this.setSpeedList([]);
};


/**
 * repeated double frequency = 8;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.getFrequencyList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.UserStatisticsResponse.Statistics} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.setFrequencyList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.UserStatisticsResponse.Statistics} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.addFrequency = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.UserStatisticsResponse.Statistics} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.clearFrequencyList = function() {
  return this.setFrequencyList([]);
};


/**
 * repeated uint32 sat_qty = 9;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.getSatQtyList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.UserStatisticsResponse.Statistics} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.setSatQtyList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.UserStatisticsResponse.Statistics} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.addSatQty = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.UserStatisticsResponse.Statistics} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.Statistics.prototype.clearSatQtyList = function() {
  return this.setSatQtyList([]);
};


/**
 * repeated Statistics statistics = 1;
 * @return {!Array<!proto.grass.devices.v1.UserStatisticsResponse.Statistics>}
 */
proto.grass.devices.v1.UserStatisticsResponse.prototype.getStatisticsList = function() {
  return /** @type{!Array<!proto.grass.devices.v1.UserStatisticsResponse.Statistics>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grass.devices.v1.UserStatisticsResponse.Statistics, 1));
};


/**
 * @param {!Array<!proto.grass.devices.v1.UserStatisticsResponse.Statistics>} value
 * @return {!proto.grass.devices.v1.UserStatisticsResponse} returns this
*/
proto.grass.devices.v1.UserStatisticsResponse.prototype.setStatisticsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grass.devices.v1.UserStatisticsResponse.Statistics=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.UserStatisticsResponse.Statistics}
 */
proto.grass.devices.v1.UserStatisticsResponse.prototype.addStatistics = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grass.devices.v1.UserStatisticsResponse.Statistics, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.UserStatisticsResponse} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.prototype.clearStatisticsList = function() {
  return this.setStatisticsList([]);
};


/**
 * optional uint32 precision = 2;
 * @return {number}
 */
proto.grass.devices.v1.UserStatisticsResponse.prototype.getPrecision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.UserStatisticsResponse} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.prototype.setPrecision = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * repeated double bbox = 10;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.UserStatisticsResponse.prototype.getBboxList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.UserStatisticsResponse} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.prototype.setBboxList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.UserStatisticsResponse} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.prototype.addBbox = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.UserStatisticsResponse} returns this
 */
proto.grass.devices.v1.UserStatisticsResponse.prototype.clearBboxList = function() {
  return this.setBboxList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.GetDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.GetDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.GetDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.GetDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    esn: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.GetDeviceRequest}
 */
proto.grass.devices.v1.GetDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.GetDeviceRequest;
  return proto.grass.devices.v1.GetDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.GetDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.GetDeviceRequest}
 */
proto.grass.devices.v1.GetDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEsn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.GetDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.GetDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.GetDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.GetDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEsn();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string esn = 1;
 * @return {string}
 */
proto.grass.devices.v1.GetDeviceRequest.prototype.getEsn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.GetDeviceRequest} returns this
 */
proto.grass.devices.v1.GetDeviceRequest.prototype.setEsn = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.GetDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.GetDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.GetDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.GetDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    esn: jspb.Message.getFieldWithDefault(msg, 1, ""),
    alias: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    model: jspb.Message.getFieldWithDefault(msg, 5, ""),
    sensorThresholds: (f = msg.getSensorThresholds()) && proto.grass.devices.v1.SensorThresholds.toObject(includeInstance, f),
    width: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    distance: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    sensorMode: jspb.Message.getFieldWithDefault(msg, 9, 0),
    delay: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.GetDeviceResponse}
 */
proto.grass.devices.v1.GetDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.GetDeviceResponse;
  return proto.grass.devices.v1.GetDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.GetDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.GetDeviceResponse}
 */
proto.grass.devices.v1.GetDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEsn(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.grass.devices.v1.DeviceType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 6:
      var value = new proto.grass.devices.v1.SensorThresholds;
      reader.readMessage(value,proto.grass.devices.v1.SensorThresholds.deserializeBinaryFromReader);
      msg.setSensorThresholds(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWidth(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDistance(value);
      break;
    case 9:
      var value = /** @type {!proto.grass.devices.v1.SensorMode} */ (reader.readEnum());
      msg.setSensorMode(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDelay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.GetDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.GetDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.GetDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.GetDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEsn();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSensorThresholds();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.grass.devices.v1.SensorThresholds.serializeBinaryToWriter
    );
  }
  f = message.getWidth();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getDistance();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getSensorMode();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getDelay();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
};


/**
 * optional string esn = 1;
 * @return {string}
 */
proto.grass.devices.v1.GetDeviceResponse.prototype.getEsn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.GetDeviceResponse} returns this
 */
proto.grass.devices.v1.GetDeviceResponse.prototype.setEsn = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string alias = 2;
 * @return {string}
 */
proto.grass.devices.v1.GetDeviceResponse.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.GetDeviceResponse} returns this
 */
proto.grass.devices.v1.GetDeviceResponse.prototype.setAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.grass.devices.v1.GetDeviceResponse.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.GetDeviceResponse} returns this
 */
proto.grass.devices.v1.GetDeviceResponse.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional DeviceType type = 4;
 * @return {!proto.grass.devices.v1.DeviceType}
 */
proto.grass.devices.v1.GetDeviceResponse.prototype.getType = function() {
  return /** @type {!proto.grass.devices.v1.DeviceType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.grass.devices.v1.DeviceType} value
 * @return {!proto.grass.devices.v1.GetDeviceResponse} returns this
 */
proto.grass.devices.v1.GetDeviceResponse.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string model = 5;
 * @return {string}
 */
proto.grass.devices.v1.GetDeviceResponse.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.GetDeviceResponse} returns this
 */
proto.grass.devices.v1.GetDeviceResponse.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional SensorThresholds sensor_thresholds = 6;
 * @return {?proto.grass.devices.v1.SensorThresholds}
 */
proto.grass.devices.v1.GetDeviceResponse.prototype.getSensorThresholds = function() {
  return /** @type{?proto.grass.devices.v1.SensorThresholds} */ (
    jspb.Message.getWrapperField(this, proto.grass.devices.v1.SensorThresholds, 6));
};


/**
 * @param {?proto.grass.devices.v1.SensorThresholds|undefined} value
 * @return {!proto.grass.devices.v1.GetDeviceResponse} returns this
*/
proto.grass.devices.v1.GetDeviceResponse.prototype.setSensorThresholds = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.devices.v1.GetDeviceResponse} returns this
 */
proto.grass.devices.v1.GetDeviceResponse.prototype.clearSensorThresholds = function() {
  return this.setSensorThresholds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.devices.v1.GetDeviceResponse.prototype.hasSensorThresholds = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional double width = 7;
 * @return {number}
 */
proto.grass.devices.v1.GetDeviceResponse.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.GetDeviceResponse} returns this
 */
proto.grass.devices.v1.GetDeviceResponse.prototype.setWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double distance = 8;
 * @return {number}
 */
proto.grass.devices.v1.GetDeviceResponse.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.GetDeviceResponse} returns this
 */
proto.grass.devices.v1.GetDeviceResponse.prototype.setDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional SensorMode sensor_mode = 9;
 * @return {!proto.grass.devices.v1.SensorMode}
 */
proto.grass.devices.v1.GetDeviceResponse.prototype.getSensorMode = function() {
  return /** @type {!proto.grass.devices.v1.SensorMode} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.grass.devices.v1.SensorMode} value
 * @return {!proto.grass.devices.v1.GetDeviceResponse} returns this
 */
proto.grass.devices.v1.GetDeviceResponse.prototype.setSensorMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional uint32 delay = 10;
 * @return {number}
 */
proto.grass.devices.v1.GetDeviceResponse.prototype.getDelay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.GetDeviceResponse} returns this
 */
proto.grass.devices.v1.GetDeviceResponse.prototype.setDelay = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.SensorThresholds.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.SensorThresholds.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.SensorThresholds} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.SensorThresholds.toObject = function(includeInstance, msg) {
  var f, obj = {
    min: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0),
    max: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.SensorThresholds}
 */
proto.grass.devices.v1.SensorThresholds.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.SensorThresholds;
  return proto.grass.devices.v1.SensorThresholds.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.SensorThresholds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.SensorThresholds}
 */
proto.grass.devices.v1.SensorThresholds.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMin(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMax(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.SensorThresholds.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.SensorThresholds.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.SensorThresholds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.SensorThresholds.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMin();
  if (f !== 0.0) {
    writer.writeDouble(
      1,
      f
    );
  }
  f = message.getMax();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
};


/**
 * optional double min = 1;
 * @return {number}
 */
proto.grass.devices.v1.SensorThresholds.prototype.getMin = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.SensorThresholds} returns this
 */
proto.grass.devices.v1.SensorThresholds.prototype.setMin = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};


/**
 * optional double max = 2;
 * @return {number}
 */
proto.grass.devices.v1.SensorThresholds.prototype.getMax = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.SensorThresholds} returns this
 */
proto.grass.devices.v1.SensorThresholds.prototype.setMax = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.UpdateDeviceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.UpdateDeviceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.UpdateDeviceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.UpdateDeviceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    esn: jspb.Message.getFieldWithDefault(msg, 1, ""),
    alias: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    type: jspb.Message.getFieldWithDefault(msg, 4, 0),
    model: jspb.Message.getFieldWithDefault(msg, 5, ""),
    sensorThresholds: (f = msg.getSensorThresholds()) && proto.grass.devices.v1.SensorThresholds.toObject(includeInstance, f),
    width: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    distance: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    sensorMode: jspb.Message.getFieldWithDefault(msg, 9, 0),
    delay: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.UpdateDeviceRequest}
 */
proto.grass.devices.v1.UpdateDeviceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.UpdateDeviceRequest;
  return proto.grass.devices.v1.UpdateDeviceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.UpdateDeviceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.UpdateDeviceRequest}
 */
proto.grass.devices.v1.UpdateDeviceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEsn(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setAlias(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 4:
      var value = /** @type {!proto.grass.devices.v1.DeviceType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 6:
      var value = new proto.grass.devices.v1.SensorThresholds;
      reader.readMessage(value,proto.grass.devices.v1.SensorThresholds.deserializeBinaryFromReader);
      msg.setSensorThresholds(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWidth(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDistance(value);
      break;
    case 9:
      var value = /** @type {!proto.grass.devices.v1.SensorMode} */ (reader.readEnum());
      msg.setSensorMode(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDelay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.UpdateDeviceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.UpdateDeviceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.UpdateDeviceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.UpdateDeviceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEsn();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getAlias();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSensorThresholds();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.grass.devices.v1.SensorThresholds.serializeBinaryToWriter
    );
  }
  f = message.getWidth();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getDistance();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getSensorMode();
  if (f !== 0.0) {
    writer.writeEnum(
      9,
      f
    );
  }
  f = message.getDelay();
  if (f !== 0) {
    writer.writeUint32(
      10,
      f
    );
  }
};


/**
 * optional string esn = 1;
 * @return {string}
 */
proto.grass.devices.v1.UpdateDeviceRequest.prototype.getEsn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.UpdateDeviceRequest} returns this
 */
proto.grass.devices.v1.UpdateDeviceRequest.prototype.setEsn = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string alias = 2;
 * @return {string}
 */
proto.grass.devices.v1.UpdateDeviceRequest.prototype.getAlias = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.UpdateDeviceRequest} returns this
 */
proto.grass.devices.v1.UpdateDeviceRequest.prototype.setAlias = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.grass.devices.v1.UpdateDeviceRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.UpdateDeviceRequest} returns this
 */
proto.grass.devices.v1.UpdateDeviceRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional DeviceType type = 4;
 * @return {!proto.grass.devices.v1.DeviceType}
 */
proto.grass.devices.v1.UpdateDeviceRequest.prototype.getType = function() {
  return /** @type {!proto.grass.devices.v1.DeviceType} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.grass.devices.v1.DeviceType} value
 * @return {!proto.grass.devices.v1.UpdateDeviceRequest} returns this
 */
proto.grass.devices.v1.UpdateDeviceRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string model = 5;
 * @return {string}
 */
proto.grass.devices.v1.UpdateDeviceRequest.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.UpdateDeviceRequest} returns this
 */
proto.grass.devices.v1.UpdateDeviceRequest.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional SensorThresholds sensor_thresholds = 6;
 * @return {?proto.grass.devices.v1.SensorThresholds}
 */
proto.grass.devices.v1.UpdateDeviceRequest.prototype.getSensorThresholds = function() {
  return /** @type{?proto.grass.devices.v1.SensorThresholds} */ (
    jspb.Message.getWrapperField(this, proto.grass.devices.v1.SensorThresholds, 6));
};


/**
 * @param {?proto.grass.devices.v1.SensorThresholds|undefined} value
 * @return {!proto.grass.devices.v1.UpdateDeviceRequest} returns this
*/
proto.grass.devices.v1.UpdateDeviceRequest.prototype.setSensorThresholds = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.devices.v1.UpdateDeviceRequest} returns this
 */
proto.grass.devices.v1.UpdateDeviceRequest.prototype.clearSensorThresholds = function() {
  return this.setSensorThresholds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.devices.v1.UpdateDeviceRequest.prototype.hasSensorThresholds = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional double width = 7;
 * @return {number}
 */
proto.grass.devices.v1.UpdateDeviceRequest.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.UpdateDeviceRequest} returns this
 */
proto.grass.devices.v1.UpdateDeviceRequest.prototype.setWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double distance = 8;
 * @return {number}
 */
proto.grass.devices.v1.UpdateDeviceRequest.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.UpdateDeviceRequest} returns this
 */
proto.grass.devices.v1.UpdateDeviceRequest.prototype.setDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional SensorMode sensor_mode = 9;
 * @return {!proto.grass.devices.v1.SensorMode}
 */
proto.grass.devices.v1.UpdateDeviceRequest.prototype.getSensorMode = function() {
  return /** @type {!proto.grass.devices.v1.SensorMode} */ (jspb.Message.getFieldWithDefault(this, 9, 0));
};


/**
 * @param {!proto.grass.devices.v1.SensorMode} value
 * @return {!proto.grass.devices.v1.UpdateDeviceRequest} returns this
 */
proto.grass.devices.v1.UpdateDeviceRequest.prototype.setSensorMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 9, value);
};


/**
 * optional uint32 delay = 10;
 * @return {number}
 */
proto.grass.devices.v1.UpdateDeviceRequest.prototype.getDelay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.UpdateDeviceRequest} returns this
 */
proto.grass.devices.v1.UpdateDeviceRequest.prototype.setDelay = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.UpdateDeviceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.UpdateDeviceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.UpdateDeviceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.UpdateDeviceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.UpdateDeviceResponse}
 */
proto.grass.devices.v1.UpdateDeviceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.UpdateDeviceResponse;
  return proto.grass.devices.v1.UpdateDeviceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.UpdateDeviceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.UpdateDeviceResponse}
 */
proto.grass.devices.v1.UpdateDeviceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.UpdateDeviceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.UpdateDeviceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.UpdateDeviceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.UpdateDeviceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.UpdateDeviceSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.UpdateDeviceSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    esn: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    model: jspb.Message.getFieldWithDefault(msg, 3, ""),
    sensorThresholds: (f = msg.getSensorThresholds()) && proto.grass.devices.v1.SensorThresholds.toObject(includeInstance, f),
    width: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    distance: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    maxSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    revision: jspb.Message.getFieldWithDefault(msg, 99, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.UpdateDeviceSettingsRequest}
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.UpdateDeviceSettingsRequest;
  return proto.grass.devices.v1.UpdateDeviceSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.UpdateDeviceSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.UpdateDeviceSettingsRequest}
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEsn(value);
      break;
    case 2:
      var value = /** @type {!proto.grass.devices.v1.DeviceType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setModel(value);
      break;
    case 4:
      var value = new proto.grass.devices.v1.SensorThresholds;
      reader.readMessage(value,proto.grass.devices.v1.SensorThresholds.deserializeBinaryFromReader);
      msg.setSensorThresholds(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWidth(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDistance(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxSpeed(value);
      break;
    case 99:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRevision(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.UpdateDeviceSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.UpdateDeviceSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEsn();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getModel();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getSensorThresholds();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.grass.devices.v1.SensorThresholds.serializeBinaryToWriter
    );
  }
  f = message.getWidth();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getDistance();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getMaxSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getRevision();
  if (f !== 0) {
    writer.writeUint32(
      99,
      f
    );
  }
};


/**
 * optional string esn = 1;
 * @return {string}
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest.prototype.getEsn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.UpdateDeviceSettingsRequest} returns this
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest.prototype.setEsn = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional DeviceType type = 2;
 * @return {!proto.grass.devices.v1.DeviceType}
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest.prototype.getType = function() {
  return /** @type {!proto.grass.devices.v1.DeviceType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.grass.devices.v1.DeviceType} value
 * @return {!proto.grass.devices.v1.UpdateDeviceSettingsRequest} returns this
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * optional string model = 3;
 * @return {string}
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest.prototype.getModel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.UpdateDeviceSettingsRequest} returns this
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest.prototype.setModel = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional SensorThresholds sensor_thresholds = 4;
 * @return {?proto.grass.devices.v1.SensorThresholds}
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest.prototype.getSensorThresholds = function() {
  return /** @type{?proto.grass.devices.v1.SensorThresholds} */ (
    jspb.Message.getWrapperField(this, proto.grass.devices.v1.SensorThresholds, 4));
};


/**
 * @param {?proto.grass.devices.v1.SensorThresholds|undefined} value
 * @return {!proto.grass.devices.v1.UpdateDeviceSettingsRequest} returns this
*/
proto.grass.devices.v1.UpdateDeviceSettingsRequest.prototype.setSensorThresholds = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.devices.v1.UpdateDeviceSettingsRequest} returns this
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest.prototype.clearSensorThresholds = function() {
  return this.setSensorThresholds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest.prototype.hasSensorThresholds = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional double width = 5;
 * @return {number}
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.UpdateDeviceSettingsRequest} returns this
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest.prototype.setWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double distance = 6;
 * @return {number}
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.UpdateDeviceSettingsRequest} returns this
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest.prototype.setDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double max_speed = 7;
 * @return {number}
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest.prototype.getMaxSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.UpdateDeviceSettingsRequest} returns this
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest.prototype.setMaxSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional uint32 revision = 99;
 * @return {number}
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest.prototype.getRevision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 99, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.UpdateDeviceSettingsRequest} returns this
 */
proto.grass.devices.v1.UpdateDeviceSettingsRequest.prototype.setRevision = function(value) {
  return jspb.Message.setProto3IntField(this, 99, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.UpdateDeviceSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.UpdateDeviceSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.UpdateDeviceSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.UpdateDeviceSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    revision: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.UpdateDeviceSettingsResponse}
 */
proto.grass.devices.v1.UpdateDeviceSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.UpdateDeviceSettingsResponse;
  return proto.grass.devices.v1.UpdateDeviceSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.UpdateDeviceSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.UpdateDeviceSettingsResponse}
 */
proto.grass.devices.v1.UpdateDeviceSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRevision(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.UpdateDeviceSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.UpdateDeviceSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.UpdateDeviceSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.UpdateDeviceSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRevision();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
};


/**
 * optional uint32 revision = 1;
 * @return {number}
 */
proto.grass.devices.v1.UpdateDeviceSettingsResponse.prototype.getRevision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.UpdateDeviceSettingsResponse} returns this
 */
proto.grass.devices.v1.UpdateDeviceSettingsResponse.prototype.setRevision = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grass.devices.v1.DeviceStatisticsRequest.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.DeviceStatisticsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.DeviceStatisticsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.DeviceStatisticsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.DeviceStatisticsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    deviceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    fieldsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    precision: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.DeviceStatisticsRequest}
 */
proto.grass.devices.v1.DeviceStatisticsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.DeviceStatisticsRequest;
  return proto.grass.devices.v1.DeviceStatisticsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.DeviceStatisticsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.DeviceStatisticsRequest}
 */
proto.grass.devices.v1.DeviceStatisticsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDeviceId(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 4:
      var values = /** @type {!Array<!proto.grass.devices.v1.DeviceStatisticsRequest.Field>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFields(values[i]);
      }
      break;
    case 5:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrecision(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.DeviceStatisticsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.DeviceStatisticsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.DeviceStatisticsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.DeviceStatisticsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeviceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getFieldsList();
  if (f.length > 0) {
    writer.writePackedEnum(
      4,
      f
    );
  }
  f = message.getPrecision();
  if (f !== 0) {
    writer.writeUint32(
      5,
      f
    );
  }
};


/**
 * @enum {number}
 */
proto.grass.devices.v1.DeviceStatisticsRequest.Field = {
  FIELD_INVALID: 0,
  FIELD_TIME: 1,
  FIELD_COORDS: 2,
  FIELD_SENSOR: 3,
  FIELD_SPEED: 4,
  FIELD_FREQUENCY: 5,
  FIELD_SAT_QTY: 6,
  FIELD_NORM: 7
};

/**
 * optional string device_id = 1;
 * @return {string}
 */
proto.grass.devices.v1.DeviceStatisticsRequest.prototype.getDeviceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.DeviceStatisticsRequest} returns this
 */
proto.grass.devices.v1.DeviceStatisticsRequest.prototype.setDeviceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp start = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.grass.devices.v1.DeviceStatisticsRequest.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.grass.devices.v1.DeviceStatisticsRequest} returns this
*/
proto.grass.devices.v1.DeviceStatisticsRequest.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.devices.v1.DeviceStatisticsRequest} returns this
 */
proto.grass.devices.v1.DeviceStatisticsRequest.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.devices.v1.DeviceStatisticsRequest.prototype.hasStart = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.grass.devices.v1.DeviceStatisticsRequest.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.grass.devices.v1.DeviceStatisticsRequest} returns this
*/
proto.grass.devices.v1.DeviceStatisticsRequest.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.devices.v1.DeviceStatisticsRequest} returns this
 */
proto.grass.devices.v1.DeviceStatisticsRequest.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.devices.v1.DeviceStatisticsRequest.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated Field fields = 4;
 * @return {!Array<!proto.grass.devices.v1.DeviceStatisticsRequest.Field>}
 */
proto.grass.devices.v1.DeviceStatisticsRequest.prototype.getFieldsList = function() {
  return /** @type {!Array<!proto.grass.devices.v1.DeviceStatisticsRequest.Field>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<!proto.grass.devices.v1.DeviceStatisticsRequest.Field>} value
 * @return {!proto.grass.devices.v1.DeviceStatisticsRequest} returns this
 */
proto.grass.devices.v1.DeviceStatisticsRequest.prototype.setFieldsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {!proto.grass.devices.v1.DeviceStatisticsRequest.Field} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.DeviceStatisticsRequest} returns this
 */
proto.grass.devices.v1.DeviceStatisticsRequest.prototype.addFields = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.DeviceStatisticsRequest} returns this
 */
proto.grass.devices.v1.DeviceStatisticsRequest.prototype.clearFieldsList = function() {
  return this.setFieldsList([]);
};


/**
 * optional uint32 precision = 5;
 * @return {number}
 */
proto.grass.devices.v1.DeviceStatisticsRequest.prototype.getPrecision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.DeviceStatisticsRequest} returns this
 */
proto.grass.devices.v1.DeviceStatisticsRequest.prototype.setPrecision = function(value) {
  return jspb.Message.setProto3IntField(this, 5, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grass.devices.v1.DeviceStatisticsResponse.repeatedFields_ = [2,3,5,6,7,8,9];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.DeviceStatisticsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.DeviceStatisticsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.DeviceStatisticsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    length: jspb.Message.getFieldWithDefault(msg, 1, 0),
    timeList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    coordsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    sensorList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 5)) == null ? undefined : f,
    speedList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 6)) == null ? undefined : f,
    frequencyList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 7)) == null ? undefined : f,
    satQtyList: (f = jspb.Message.getRepeatedField(msg, 8)) == null ? undefined : f,
    normList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 9)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse}
 */
proto.grass.devices.v1.DeviceStatisticsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.DeviceStatisticsResponse;
  return proto.grass.devices.v1.DeviceStatisticsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.DeviceStatisticsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse}
 */
proto.grass.devices.v1.DeviceStatisticsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLength(value);
      break;
    case 2:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedFixed32() : [reader.readFixed32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTime(values[i]);
      }
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSint64() : [reader.readSint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCoords(values[i]);
      }
      break;
    case 5:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSensor(values[i]);
      }
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSpeed(values[i]);
      }
      break;
    case 7:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addFrequency(values[i]);
      }
      break;
    case 8:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addSatQty(values[i]);
      }
      break;
    case 9:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addNorm(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.DeviceStatisticsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.DeviceStatisticsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.DeviceStatisticsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLength();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getTimeList();
  if (f.length > 0) {
    writer.writePackedFixed32(
      2,
      f
    );
  }
  f = message.getCoordsList();
  if (f.length > 0) {
    writer.writePackedSint64(
      3,
      f
    );
  }
  f = message.getSensorList();
  if (f.length > 0) {
    writer.writePackedDouble(
      5,
      f
    );
  }
  f = message.getSpeedList();
  if (f.length > 0) {
    writer.writePackedDouble(
      6,
      f
    );
  }
  f = message.getFrequencyList();
  if (f.length > 0) {
    writer.writePackedDouble(
      7,
      f
    );
  }
  f = message.getSatQtyList();
  if (f.length > 0) {
    writer.writePackedUint32(
      8,
      f
    );
  }
  f = message.getNormList();
  if (f.length > 0) {
    writer.writePackedDouble(
      9,
      f
    );
  }
};


/**
 * optional uint32 length = 1;
 * @return {number}
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.getLength = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse} returns this
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.setLength = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated fixed32 time = 2;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.getTimeList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse} returns this
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.setTimeList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse} returns this
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.addTime = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse} returns this
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.clearTimeList = function() {
  return this.setTimeList([]);
};


/**
 * repeated sint64 coords = 3;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.getCoordsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse} returns this
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.setCoordsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse} returns this
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.addCoords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse} returns this
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.clearCoordsList = function() {
  return this.setCoordsList([]);
};


/**
 * repeated double sensor = 5;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.getSensorList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 5));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse} returns this
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.setSensorList = function(value) {
  return jspb.Message.setField(this, 5, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse} returns this
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.addSensor = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 5, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse} returns this
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.clearSensorList = function() {
  return this.setSensorList([]);
};


/**
 * repeated double speed = 6;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.getSpeedList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse} returns this
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.setSpeedList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse} returns this
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.addSpeed = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse} returns this
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.clearSpeedList = function() {
  return this.setSpeedList([]);
};


/**
 * repeated double frequency = 7;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.getFrequencyList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 7));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse} returns this
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.setFrequencyList = function(value) {
  return jspb.Message.setField(this, 7, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse} returns this
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.addFrequency = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 7, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse} returns this
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.clearFrequencyList = function() {
  return this.setFrequencyList([]);
};


/**
 * repeated uint32 sat_qty = 8;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.getSatQtyList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 8));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse} returns this
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.setSatQtyList = function(value) {
  return jspb.Message.setField(this, 8, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse} returns this
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.addSatQty = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 8, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse} returns this
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.clearSatQtyList = function() {
  return this.setSatQtyList([]);
};


/**
 * repeated double norm = 9;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.getNormList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 9));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse} returns this
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.setNormList = function(value) {
  return jspb.Message.setField(this, 9, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse} returns this
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.addNorm = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 9, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.DeviceStatisticsResponse} returns this
 */
proto.grass.devices.v1.DeviceStatisticsResponse.prototype.clearNormList = function() {
  return this.setNormList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.DevicesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.DevicesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.DevicesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.DevicesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.DevicesRequest}
 */
proto.grass.devices.v1.DevicesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.DevicesRequest;
  return proto.grass.devices.v1.DevicesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.DevicesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.DevicesRequest}
 */
proto.grass.devices.v1.DevicesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.DevicesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.DevicesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.DevicesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.DevicesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grass.devices.v1.AttachedDevice.repeatedFields_ = [6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.AttachedDevice.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.AttachedDevice.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.AttachedDevice} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.AttachedDevice.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    esn: jspb.Message.getFieldWithDefault(msg, 2, ""),
    name: jspb.Message.getFieldWithDefault(msg, 3, ""),
    description: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastSeenAt: (f = msg.getLastSeenAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    lastPositionList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 6)) == null ? undefined : f,
    sensorThresholds: (f = msg.getSensorThresholds()) && proto.grass.devices.v1.SensorThresholds.toObject(includeInstance, f),
    reaperWidth: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    settings: (f = msg.getSettings()) && proto.grass.devices.v1.DeviceSetting.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.AttachedDevice}
 */
proto.grass.devices.v1.AttachedDevice.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.AttachedDevice;
  return proto.grass.devices.v1.AttachedDevice.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.AttachedDevice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.AttachedDevice}
 */
proto.grass.devices.v1.AttachedDevice.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEsn(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setLastSeenAt(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLastPosition(values[i]);
      }
      break;
    case 7:
      var value = new proto.grass.devices.v1.SensorThresholds;
      reader.readMessage(value,proto.grass.devices.v1.SensorThresholds.deserializeBinaryFromReader);
      msg.setSensorThresholds(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setReaperWidth(value);
      break;
    case 9:
      var value = new proto.grass.devices.v1.DeviceSetting;
      reader.readMessage(value,proto.grass.devices.v1.DeviceSetting.deserializeBinaryFromReader);
      msg.setSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.AttachedDevice.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.AttachedDevice.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.AttachedDevice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.AttachedDevice.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEsn();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastSeenAt();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getLastPositionList();
  if (f.length > 0) {
    writer.writePackedDouble(
      6,
      f
    );
  }
  f = message.getSensorThresholds();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.grass.devices.v1.SensorThresholds.serializeBinaryToWriter
    );
  }
  f = message.getReaperWidth();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getSettings();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.grass.devices.v1.DeviceSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.grass.devices.v1.AttachedDevice.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.AttachedDevice} returns this
 */
proto.grass.devices.v1.AttachedDevice.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string esn = 2;
 * @return {string}
 */
proto.grass.devices.v1.AttachedDevice.prototype.getEsn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.AttachedDevice} returns this
 */
proto.grass.devices.v1.AttachedDevice.prototype.setEsn = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string name = 3;
 * @return {string}
 */
proto.grass.devices.v1.AttachedDevice.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.AttachedDevice} returns this
 */
proto.grass.devices.v1.AttachedDevice.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string description = 4;
 * @return {string}
 */
proto.grass.devices.v1.AttachedDevice.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.AttachedDevice} returns this
 */
proto.grass.devices.v1.AttachedDevice.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp last_seen_at = 5;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.grass.devices.v1.AttachedDevice.prototype.getLastSeenAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 5));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.grass.devices.v1.AttachedDevice} returns this
*/
proto.grass.devices.v1.AttachedDevice.prototype.setLastSeenAt = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.devices.v1.AttachedDevice} returns this
 */
proto.grass.devices.v1.AttachedDevice.prototype.clearLastSeenAt = function() {
  return this.setLastSeenAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.devices.v1.AttachedDevice.prototype.hasLastSeenAt = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * repeated double last_position = 6;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.AttachedDevice.prototype.getLastPositionList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.AttachedDevice} returns this
 */
proto.grass.devices.v1.AttachedDevice.prototype.setLastPositionList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.AttachedDevice} returns this
 */
proto.grass.devices.v1.AttachedDevice.prototype.addLastPosition = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.AttachedDevice} returns this
 */
proto.grass.devices.v1.AttachedDevice.prototype.clearLastPositionList = function() {
  return this.setLastPositionList([]);
};


/**
 * optional SensorThresholds sensor_thresholds = 7;
 * @return {?proto.grass.devices.v1.SensorThresholds}
 */
proto.grass.devices.v1.AttachedDevice.prototype.getSensorThresholds = function() {
  return /** @type{?proto.grass.devices.v1.SensorThresholds} */ (
    jspb.Message.getWrapperField(this, proto.grass.devices.v1.SensorThresholds, 7));
};


/**
 * @param {?proto.grass.devices.v1.SensorThresholds|undefined} value
 * @return {!proto.grass.devices.v1.AttachedDevice} returns this
*/
proto.grass.devices.v1.AttachedDevice.prototype.setSensorThresholds = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.devices.v1.AttachedDevice} returns this
 */
proto.grass.devices.v1.AttachedDevice.prototype.clearSensorThresholds = function() {
  return this.setSensorThresholds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.devices.v1.AttachedDevice.prototype.hasSensorThresholds = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional double reaper_width = 8;
 * @return {number}
 */
proto.grass.devices.v1.AttachedDevice.prototype.getReaperWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.AttachedDevice} returns this
 */
proto.grass.devices.v1.AttachedDevice.prototype.setReaperWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional DeviceSetting settings = 9;
 * @return {?proto.grass.devices.v1.DeviceSetting}
 */
proto.grass.devices.v1.AttachedDevice.prototype.getSettings = function() {
  return /** @type{?proto.grass.devices.v1.DeviceSetting} */ (
    jspb.Message.getWrapperField(this, proto.grass.devices.v1.DeviceSetting, 9));
};


/**
 * @param {?proto.grass.devices.v1.DeviceSetting|undefined} value
 * @return {!proto.grass.devices.v1.AttachedDevice} returns this
*/
proto.grass.devices.v1.AttachedDevice.prototype.setSettings = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.devices.v1.AttachedDevice} returns this
 */
proto.grass.devices.v1.AttachedDevice.prototype.clearSettings = function() {
  return this.setSettings(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.devices.v1.AttachedDevice.prototype.hasSettings = function() {
  return jspb.Message.getField(this, 9) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grass.devices.v1.DevicesResponse.repeatedFields_ = [1,10];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.DevicesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.DevicesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.DevicesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.DevicesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    devicesList: jspb.Message.toObjectList(msg.getDevicesList(),
    proto.grass.devices.v1.AttachedDevice.toObject, includeInstance),
    bboxList: (f = jspb.Message.getRepeatedFloatingPointField(msg, 10)) == null ? undefined : f
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.DevicesResponse}
 */
proto.grass.devices.v1.DevicesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.DevicesResponse;
  return proto.grass.devices.v1.DevicesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.DevicesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.DevicesResponse}
 */
proto.grass.devices.v1.DevicesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grass.devices.v1.AttachedDevice;
      reader.readMessage(value,proto.grass.devices.v1.AttachedDevice.deserializeBinaryFromReader);
      msg.addDevices(value);
      break;
    case 10:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedDouble() : [reader.readDouble()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBbox(values[i]);
      }
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.DevicesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.DevicesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.DevicesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.DevicesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDevicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grass.devices.v1.AttachedDevice.serializeBinaryToWriter
    );
  }
  f = message.getBboxList();
  if (f.length > 0) {
    writer.writePackedDouble(
      10,
      f
    );
  }
};


/**
 * repeated AttachedDevice devices = 1;
 * @return {!Array<!proto.grass.devices.v1.AttachedDevice>}
 */
proto.grass.devices.v1.DevicesResponse.prototype.getDevicesList = function() {
  return /** @type{!Array<!proto.grass.devices.v1.AttachedDevice>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grass.devices.v1.AttachedDevice, 1));
};


/**
 * @param {!Array<!proto.grass.devices.v1.AttachedDevice>} value
 * @return {!proto.grass.devices.v1.DevicesResponse} returns this
*/
proto.grass.devices.v1.DevicesResponse.prototype.setDevicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grass.devices.v1.AttachedDevice=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.AttachedDevice}
 */
proto.grass.devices.v1.DevicesResponse.prototype.addDevices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grass.devices.v1.AttachedDevice, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.DevicesResponse} returns this
 */
proto.grass.devices.v1.DevicesResponse.prototype.clearDevicesList = function() {
  return this.setDevicesList([]);
};


/**
 * repeated double bbox = 10;
 * @return {!Array<number>}
 */
proto.grass.devices.v1.DevicesResponse.prototype.getBboxList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 10));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.devices.v1.DevicesResponse} returns this
 */
proto.grass.devices.v1.DevicesResponse.prototype.setBboxList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.DevicesResponse} returns this
 */
proto.grass.devices.v1.DevicesResponse.prototype.addBbox = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.DevicesResponse} returns this
 */
proto.grass.devices.v1.DevicesResponse.prototype.clearBboxList = function() {
  return this.setBboxList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grass.devices.v1.DeviceSettingsRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.DeviceSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.DeviceSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.DeviceSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.DeviceSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    esnList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    lastRevision: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.DeviceSettingsRequest}
 */
proto.grass.devices.v1.DeviceSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.DeviceSettingsRequest;
  return proto.grass.devices.v1.DeviceSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.DeviceSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.DeviceSettingsRequest}
 */
proto.grass.devices.v1.DeviceSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addEsn(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setLastRevision(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.DeviceSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.DeviceSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.DeviceSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.DeviceSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEsnList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getLastRevision();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
};


/**
 * repeated string esn = 1;
 * @return {!Array<string>}
 */
proto.grass.devices.v1.DeviceSettingsRequest.prototype.getEsnList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grass.devices.v1.DeviceSettingsRequest} returns this
 */
proto.grass.devices.v1.DeviceSettingsRequest.prototype.setEsnList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.DeviceSettingsRequest} returns this
 */
proto.grass.devices.v1.DeviceSettingsRequest.prototype.addEsn = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.DeviceSettingsRequest} returns this
 */
proto.grass.devices.v1.DeviceSettingsRequest.prototype.clearEsnList = function() {
  return this.setEsnList([]);
};


/**
 * optional uint32 last_revision = 2;
 * @return {number}
 */
proto.grass.devices.v1.DeviceSettingsRequest.prototype.getLastRevision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.DeviceSettingsRequest} returns this
 */
proto.grass.devices.v1.DeviceSettingsRequest.prototype.setLastRevision = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.DeviceSetting.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.DeviceSetting.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.DeviceSetting} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.DeviceSetting.toObject = function(includeInstance, msg) {
  var f, obj = {
    esn: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sensorThresholds: (f = msg.getSensorThresholds()) && proto.grass.devices.v1.SensorThresholds.toObject(includeInstance, f),
    width: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    distance: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    maxSpeed: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    type: jspb.Message.getFieldWithDefault(msg, 6, 0),
    sensorMode: jspb.Message.getFieldWithDefault(msg, 7, 0),
    delay: jspb.Message.getFieldWithDefault(msg, 8, 0),
    revision: jspb.Message.getFieldWithDefault(msg, 99, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.DeviceSetting}
 */
proto.grass.devices.v1.DeviceSetting.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.DeviceSetting;
  return proto.grass.devices.v1.DeviceSetting.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.DeviceSetting} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.DeviceSetting}
 */
proto.grass.devices.v1.DeviceSetting.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setEsn(value);
      break;
    case 2:
      var value = new proto.grass.devices.v1.SensorThresholds;
      reader.readMessage(value,proto.grass.devices.v1.SensorThresholds.deserializeBinaryFromReader);
      msg.setSensorThresholds(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWidth(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDistance(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setMaxSpeed(value);
      break;
    case 6:
      var value = /** @type {!proto.grass.devices.v1.DeviceType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 7:
      var value = /** @type {!proto.grass.devices.v1.SensorMode} */ (reader.readEnum());
      msg.setSensorMode(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDelay(value);
      break;
    case 99:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setRevision(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.DeviceSetting.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.DeviceSetting.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.DeviceSetting} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.DeviceSetting.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEsn();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSensorThresholds();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.grass.devices.v1.SensorThresholds.serializeBinaryToWriter
    );
  }
  f = message.getWidth();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getDistance();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getMaxSpeed();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getSensorMode();
  if (f !== 0.0) {
    writer.writeEnum(
      7,
      f
    );
  }
  f = message.getDelay();
  if (f !== 0) {
    writer.writeUint32(
      8,
      f
    );
  }
  f = message.getRevision();
  if (f !== 0) {
    writer.writeUint32(
      99,
      f
    );
  }
};


/**
 * optional string esn = 1;
 * @return {string}
 */
proto.grass.devices.v1.DeviceSetting.prototype.getEsn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.DeviceSetting} returns this
 */
proto.grass.devices.v1.DeviceSetting.prototype.setEsn = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SensorThresholds sensor_thresholds = 2;
 * @return {?proto.grass.devices.v1.SensorThresholds}
 */
proto.grass.devices.v1.DeviceSetting.prototype.getSensorThresholds = function() {
  return /** @type{?proto.grass.devices.v1.SensorThresholds} */ (
    jspb.Message.getWrapperField(this, proto.grass.devices.v1.SensorThresholds, 2));
};


/**
 * @param {?proto.grass.devices.v1.SensorThresholds|undefined} value
 * @return {!proto.grass.devices.v1.DeviceSetting} returns this
*/
proto.grass.devices.v1.DeviceSetting.prototype.setSensorThresholds = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.devices.v1.DeviceSetting} returns this
 */
proto.grass.devices.v1.DeviceSetting.prototype.clearSensorThresholds = function() {
  return this.setSensorThresholds(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.devices.v1.DeviceSetting.prototype.hasSensorThresholds = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional double width = 3;
 * @return {number}
 */
proto.grass.devices.v1.DeviceSetting.prototype.getWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.DeviceSetting} returns this
 */
proto.grass.devices.v1.DeviceSetting.prototype.setWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double distance = 4;
 * @return {number}
 */
proto.grass.devices.v1.DeviceSetting.prototype.getDistance = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.DeviceSetting} returns this
 */
proto.grass.devices.v1.DeviceSetting.prototype.setDistance = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double max_speed = 5;
 * @return {number}
 */
proto.grass.devices.v1.DeviceSetting.prototype.getMaxSpeed = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.DeviceSetting} returns this
 */
proto.grass.devices.v1.DeviceSetting.prototype.setMaxSpeed = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional DeviceType type = 6;
 * @return {!proto.grass.devices.v1.DeviceType}
 */
proto.grass.devices.v1.DeviceSetting.prototype.getType = function() {
  return /** @type {!proto.grass.devices.v1.DeviceType} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.grass.devices.v1.DeviceType} value
 * @return {!proto.grass.devices.v1.DeviceSetting} returns this
 */
proto.grass.devices.v1.DeviceSetting.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional SensorMode sensor_mode = 7;
 * @return {!proto.grass.devices.v1.SensorMode}
 */
proto.grass.devices.v1.DeviceSetting.prototype.getSensorMode = function() {
  return /** @type {!proto.grass.devices.v1.SensorMode} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {!proto.grass.devices.v1.SensorMode} value
 * @return {!proto.grass.devices.v1.DeviceSetting} returns this
 */
proto.grass.devices.v1.DeviceSetting.prototype.setSensorMode = function(value) {
  return jspb.Message.setProto3EnumField(this, 7, value);
};


/**
 * optional uint32 delay = 8;
 * @return {number}
 */
proto.grass.devices.v1.DeviceSetting.prototype.getDelay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.DeviceSetting} returns this
 */
proto.grass.devices.v1.DeviceSetting.prototype.setDelay = function(value) {
  return jspb.Message.setProto3IntField(this, 8, value);
};


/**
 * optional uint32 revision = 99;
 * @return {number}
 */
proto.grass.devices.v1.DeviceSetting.prototype.getRevision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 99, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.DeviceSetting} returns this
 */
proto.grass.devices.v1.DeviceSetting.prototype.setRevision = function(value) {
  return jspb.Message.setProto3IntField(this, 99, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grass.devices.v1.DeviceSettingsResponse.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.DeviceSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.DeviceSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.DeviceSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.DeviceSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    maxRevision: jspb.Message.getFieldWithDefault(msg, 1, 0),
    settingsList: jspb.Message.toObjectList(msg.getSettingsList(),
    proto.grass.devices.v1.DeviceSetting.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.DeviceSettingsResponse}
 */
proto.grass.devices.v1.DeviceSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.DeviceSettingsResponse;
  return proto.grass.devices.v1.DeviceSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.DeviceSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.DeviceSettingsResponse}
 */
proto.grass.devices.v1.DeviceSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMaxRevision(value);
      break;
    case 2:
      var value = new proto.grass.devices.v1.DeviceSetting;
      reader.readMessage(value,proto.grass.devices.v1.DeviceSetting.deserializeBinaryFromReader);
      msg.addSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.DeviceSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.DeviceSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.DeviceSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.DeviceSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMaxRevision();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getSettingsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.grass.devices.v1.DeviceSetting.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 max_revision = 1;
 * @return {number}
 */
proto.grass.devices.v1.DeviceSettingsResponse.prototype.getMaxRevision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.DeviceSettingsResponse} returns this
 */
proto.grass.devices.v1.DeviceSettingsResponse.prototype.setMaxRevision = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated DeviceSetting settings = 2;
 * @return {!Array<!proto.grass.devices.v1.DeviceSetting>}
 */
proto.grass.devices.v1.DeviceSettingsResponse.prototype.getSettingsList = function() {
  return /** @type{!Array<!proto.grass.devices.v1.DeviceSetting>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grass.devices.v1.DeviceSetting, 2));
};


/**
 * @param {!Array<!proto.grass.devices.v1.DeviceSetting>} value
 * @return {!proto.grass.devices.v1.DeviceSettingsResponse} returns this
*/
proto.grass.devices.v1.DeviceSettingsResponse.prototype.setSettingsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.grass.devices.v1.DeviceSetting=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.DeviceSetting}
 */
proto.grass.devices.v1.DeviceSettingsResponse.prototype.addSettings = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.grass.devices.v1.DeviceSetting, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.DeviceSettingsResponse} returns this
 */
proto.grass.devices.v1.DeviceSettingsResponse.prototype.clearSettingsList = function() {
  return this.setSettingsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.Calibration.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.Calibration.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.Calibration} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.Calibration.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    revision: jspb.Message.getFieldWithDefault(msg, 2, 0),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    weight: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    reaperWidth: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    esn: jspb.Message.getFieldWithDefault(msg, 7, ""),
    uid: jspb.Message.getFieldWithDefault(msg, 8, ""),
    attachmentId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.Calibration}
 */
proto.grass.devices.v1.Calibration.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.Calibration;
  return proto.grass.devices.v1.Calibration.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.Calibration} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.Calibration}
 */
proto.grass.devices.v1.Calibration.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setRevision(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 4:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setWeight(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setReaperWidth(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setEsn(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setAttachmentId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.Calibration.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.Calibration.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.Calibration} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.Calibration.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRevision();
  if (f !== 0) {
    writer.writeInt64(
      2,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getWeight();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getReaperWidth();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getEsn();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getAttachmentId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.grass.devices.v1.Calibration.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.Calibration} returns this
 */
proto.grass.devices.v1.Calibration.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int64 revision = 2;
 * @return {number}
 */
proto.grass.devices.v1.Calibration.prototype.getRevision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.Calibration} returns this
 */
proto.grass.devices.v1.Calibration.prototype.setRevision = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional google.protobuf.Timestamp start = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.grass.devices.v1.Calibration.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.grass.devices.v1.Calibration} returns this
*/
proto.grass.devices.v1.Calibration.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.devices.v1.Calibration} returns this
 */
proto.grass.devices.v1.Calibration.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.devices.v1.Calibration.prototype.hasStart = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.Timestamp end = 4;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.grass.devices.v1.Calibration.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 4));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.grass.devices.v1.Calibration} returns this
*/
proto.grass.devices.v1.Calibration.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.devices.v1.Calibration} returns this
 */
proto.grass.devices.v1.Calibration.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.devices.v1.Calibration.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional double weight = 5;
 * @return {number}
 */
proto.grass.devices.v1.Calibration.prototype.getWeight = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.Calibration} returns this
 */
proto.grass.devices.v1.Calibration.prototype.setWeight = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double reaper_width = 6;
 * @return {number}
 */
proto.grass.devices.v1.Calibration.prototype.getReaperWidth = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.Calibration} returns this
 */
proto.grass.devices.v1.Calibration.prototype.setReaperWidth = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional string esn = 7;
 * @return {string}
 */
proto.grass.devices.v1.Calibration.prototype.getEsn = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.Calibration} returns this
 */
proto.grass.devices.v1.Calibration.prototype.setEsn = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string uid = 8;
 * @return {string}
 */
proto.grass.devices.v1.Calibration.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.Calibration} returns this
 */
proto.grass.devices.v1.Calibration.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string attachment_id = 9;
 * @return {string}
 */
proto.grass.devices.v1.Calibration.prototype.getAttachmentId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.Calibration} returns this
 */
proto.grass.devices.v1.Calibration.prototype.setAttachmentId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.CalibrationsForIntervalRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.CalibrationsForIntervalRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.CalibrationsForIntervalRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.CalibrationsForIntervalRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.CalibrationsForIntervalRequest}
 */
proto.grass.devices.v1.CalibrationsForIntervalRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.CalibrationsForIntervalRequest;
  return proto.grass.devices.v1.CalibrationsForIntervalRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.CalibrationsForIntervalRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.CalibrationsForIntervalRequest}
 */
proto.grass.devices.v1.CalibrationsForIntervalRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.CalibrationsForIntervalRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.CalibrationsForIntervalRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.CalibrationsForIntervalRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.CalibrationsForIntervalRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.grass.devices.v1.CalibrationsForIntervalRequest.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.CalibrationsForIntervalRequest} returns this
 */
proto.grass.devices.v1.CalibrationsForIntervalRequest.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional google.protobuf.Timestamp start = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.grass.devices.v1.CalibrationsForIntervalRequest.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.grass.devices.v1.CalibrationsForIntervalRequest} returns this
*/
proto.grass.devices.v1.CalibrationsForIntervalRequest.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.devices.v1.CalibrationsForIntervalRequest} returns this
 */
proto.grass.devices.v1.CalibrationsForIntervalRequest.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.devices.v1.CalibrationsForIntervalRequest.prototype.hasStart = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.grass.devices.v1.CalibrationsForIntervalRequest.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.grass.devices.v1.CalibrationsForIntervalRequest} returns this
*/
proto.grass.devices.v1.CalibrationsForIntervalRequest.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.devices.v1.CalibrationsForIntervalRequest} returns this
 */
proto.grass.devices.v1.CalibrationsForIntervalRequest.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.devices.v1.CalibrationsForIntervalRequest.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grass.devices.v1.CalibrationsForIntervalResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.CalibrationsForIntervalResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.CalibrationsForIntervalResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.CalibrationsForIntervalResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.CalibrationsForIntervalResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    calibrationsList: jspb.Message.toObjectList(msg.getCalibrationsList(),
    proto.grass.devices.v1.Calibration.toObject, includeInstance),
    start: (f = msg.getStart()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f),
    end: (f = msg.getEnd()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.CalibrationsForIntervalResponse}
 */
proto.grass.devices.v1.CalibrationsForIntervalResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.CalibrationsForIntervalResponse;
  return proto.grass.devices.v1.CalibrationsForIntervalResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.CalibrationsForIntervalResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.CalibrationsForIntervalResponse}
 */
proto.grass.devices.v1.CalibrationsForIntervalResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grass.devices.v1.Calibration;
      reader.readMessage(value,proto.grass.devices.v1.Calibration.deserializeBinaryFromReader);
      msg.addCalibrations(value);
      break;
    case 2:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setStart(value);
      break;
    case 3:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setEnd(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.CalibrationsForIntervalResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.CalibrationsForIntervalResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.CalibrationsForIntervalResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.CalibrationsForIntervalResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCalibrationsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.grass.devices.v1.Calibration.serializeBinaryToWriter
    );
  }
  f = message.getStart();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
  f = message.getEnd();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Calibration calibrations = 1;
 * @return {!Array<!proto.grass.devices.v1.Calibration>}
 */
proto.grass.devices.v1.CalibrationsForIntervalResponse.prototype.getCalibrationsList = function() {
  return /** @type{!Array<!proto.grass.devices.v1.Calibration>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grass.devices.v1.Calibration, 1));
};


/**
 * @param {!Array<!proto.grass.devices.v1.Calibration>} value
 * @return {!proto.grass.devices.v1.CalibrationsForIntervalResponse} returns this
*/
proto.grass.devices.v1.CalibrationsForIntervalResponse.prototype.setCalibrationsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.grass.devices.v1.Calibration=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grass.devices.v1.Calibration}
 */
proto.grass.devices.v1.CalibrationsForIntervalResponse.prototype.addCalibrations = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.grass.devices.v1.Calibration, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.devices.v1.CalibrationsForIntervalResponse} returns this
 */
proto.grass.devices.v1.CalibrationsForIntervalResponse.prototype.clearCalibrationsList = function() {
  return this.setCalibrationsList([]);
};


/**
 * optional google.protobuf.Timestamp start = 2;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.grass.devices.v1.CalibrationsForIntervalResponse.prototype.getStart = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 2));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.grass.devices.v1.CalibrationsForIntervalResponse} returns this
*/
proto.grass.devices.v1.CalibrationsForIntervalResponse.prototype.setStart = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.devices.v1.CalibrationsForIntervalResponse} returns this
 */
proto.grass.devices.v1.CalibrationsForIntervalResponse.prototype.clearStart = function() {
  return this.setStart(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.devices.v1.CalibrationsForIntervalResponse.prototype.hasStart = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional google.protobuf.Timestamp end = 3;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.grass.devices.v1.CalibrationsForIntervalResponse.prototype.getEnd = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 3));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.grass.devices.v1.CalibrationsForIntervalResponse} returns this
*/
proto.grass.devices.v1.CalibrationsForIntervalResponse.prototype.setEnd = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.devices.v1.CalibrationsForIntervalResponse} returns this
 */
proto.grass.devices.v1.CalibrationsForIntervalResponse.prototype.clearEnd = function() {
  return this.setEnd(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.devices.v1.CalibrationsForIntervalResponse.prototype.hasEnd = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.RunTracksBackupRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.RunTracksBackupRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.RunTracksBackupRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.RunTracksBackupRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    year: jspb.Message.getFieldWithDefault(msg, 1, 0),
    month: jspb.Message.getFieldWithDefault(msg, 2, 0),
    day: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.RunTracksBackupRequest}
 */
proto.grass.devices.v1.RunTracksBackupRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.RunTracksBackupRequest;
  return proto.grass.devices.v1.RunTracksBackupRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.RunTracksBackupRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.RunTracksBackupRequest}
 */
proto.grass.devices.v1.RunTracksBackupRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setYear(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMonth(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setDay(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.RunTracksBackupRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.RunTracksBackupRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.RunTracksBackupRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.RunTracksBackupRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getYear();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMonth();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getDay();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 year = 1;
 * @return {number}
 */
proto.grass.devices.v1.RunTracksBackupRequest.prototype.getYear = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.RunTracksBackupRequest} returns this
 */
proto.grass.devices.v1.RunTracksBackupRequest.prototype.setYear = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 month = 2;
 * @return {number}
 */
proto.grass.devices.v1.RunTracksBackupRequest.prototype.getMonth = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.RunTracksBackupRequest} returns this
 */
proto.grass.devices.v1.RunTracksBackupRequest.prototype.setMonth = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 day = 3;
 * @return {number}
 */
proto.grass.devices.v1.RunTracksBackupRequest.prototype.getDay = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.devices.v1.RunTracksBackupRequest} returns this
 */
proto.grass.devices.v1.RunTracksBackupRequest.prototype.setDay = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.devices.v1.RunTracksBackupResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.devices.v1.RunTracksBackupResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.devices.v1.RunTracksBackupResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.RunTracksBackupResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    logs: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.devices.v1.RunTracksBackupResponse}
 */
proto.grass.devices.v1.RunTracksBackupResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.devices.v1.RunTracksBackupResponse;
  return proto.grass.devices.v1.RunTracksBackupResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.devices.v1.RunTracksBackupResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.devices.v1.RunTracksBackupResponse}
 */
proto.grass.devices.v1.RunTracksBackupResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogs(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.devices.v1.RunTracksBackupResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.devices.v1.RunTracksBackupResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.devices.v1.RunTracksBackupResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.devices.v1.RunTracksBackupResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLogs();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string logs = 1;
 * @return {string}
 */
proto.grass.devices.v1.RunTracksBackupResponse.prototype.getLogs = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.devices.v1.RunTracksBackupResponse} returns this
 */
proto.grass.devices.v1.RunTracksBackupResponse.prototype.setLogs = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * @enum {number}
 */
proto.grass.devices.v1.DeviceType = {
  DEVICE_TYPE_INVALID: 0,
  DEVICE_TYPE_RETROFIT: 1,
  DEVICE_TYPE_CAN: 2,
  DEVICE_TYPE_POTATO: 3
};

/**
 * @enum {number}
 */
proto.grass.devices.v1.SensorMode = {
  SENSOR_MODE_INVALID: 0,
  SENSOR_MODE_SENSOR: 1,
  SENSOR_MODE_FREQUENCY: 2,
  SENSOR_MODE_AVERAGE: 3
};

goog.object.extend(exports, proto.grass.devices.v1);
