import styled from "@emotion/styled";
import React, { useRef, useState } from "react";
import { ReactComponent as CalendarIcon } from "@assets/icons/calendar.svg";
import DatePicker from "@components/DatePicker/DatePicker";
import useOnClickOutside from "@src/hooks/useOnClickOutside";
import Input from "@components/Input";

interface IProps {
  value?: Date | null;
  onChange?: (date: Date | null) => void;
}

const Root = styled.div`
  position: relative;
  & > .icon {
    position: absolute;
    top: 11px;
    right: 12px;
  }
`;

const Tooltip = styled.div<{ expanded: boolean }>`
  height: ${({ expanded }) => (expanded ? "190px" : 0)};
  transition: 0.4s;
  position: absolute;
  left: 0;
  top: 0;
  background: #ffffff;
  /* Shadow */
  overflow: hidden;
  box-shadow: 0 2px 20px rgba(78, 78, 81, 0.1);
  border-radius: 6px;
  z-index: 10;
`;

const DatePickerInput: React.FC<IProps> = ({
  value: date = null,
  onChange: setDate = () => null,
}) => {
  const [expanded, setExpanded] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setExpanded(false));
  const handleSelect = (date: Date | null) => {
    setDate(date);
    setExpanded(false);
  };
  return (
    <Root>
      <Input
        onClick={() => setExpanded(true)}
        readOnly
        value={date?.toLocaleDateString("ru-RU")}
      />
      <CalendarIcon onClick={() => setExpanded(true)} className="icon" />
      <Tooltip ref={ref} expanded={expanded}>
        <DatePicker selected={date} onChange={handleSelect} />
      </Tooltip>
    </Root>
  );
};
export default DatePickerInput;
