import styled from "@emotion/styled";
import React from "react";
import { observer } from "mobx-react-lite";
import { useFieldsScreenVM } from "@screens/FieldsScreen/FieldsScreenVM";
import ViewFieldsMenu from "@screens/FieldsScreen/FieldsMenu/ViewFieldsMenu";
import CreateFieldsMenu from "@screens/FieldsScreen/FieldsMenu/CreateFieldsMenu";
import EditFieldsMenu from "@screens/FieldsScreen/FieldsMenu/EditFieldsMenu";
import LoadingFieldsMenu from "@screens/FieldsScreen/FieldsMenu/LoadingFieldsMenu";

interface IProps {}

const Root = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 16px 0;
  width: 268px;
  max-height: 100vh;
  box-shadow: 0 2px 20px rgba(78, 78, 81, 0.1);
`;

const FieldsMenu: React.FC<IProps> = () => {
  const vm = useFieldsScreenVM();
  return (
    <Root>
      {vm.loading && <LoadingFieldsMenu />}
      {!vm.loading && vm.isFieldEdit && vm.selectedField != null && (
        <EditFieldsMenu />
      )}
      {!vm.loading && vm.isFieldEdit && vm.selectedField == null && (
        <CreateFieldsMenu />
      )}
      {!vm.loading && !vm.isFieldEdit && <ViewFieldsMenu />}
    </Root>
  );
};
export default observer(FieldsMenu);
