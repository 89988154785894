import styled from "@emotion/styled";
import React from "react";
import { SearchInput } from "@screens/CropsScreen/CropsScreen";
import { ReactComponent as SearchIcon } from "@assets/icons/search.svg";
import { Row } from "@components/Flex";
import { observer } from "mobx-react-lite";
import { useLang } from "@src/hooks/useLang";
import { useDevicesScreenVM } from "@screens/DevicesScreen/DevicesScreenVM";

interface IProps {}

const Root = styled(Row)`
  margin-top: 24px;
`;

const DevicesListHeader: React.FC<IProps> = () => {
  const lang = useLang();
  const vm = useDevicesScreenVM();
  return (
    <Root>
      <SearchInput
        wrapperStyle={{ width: "fit-content" }}
        placeholder={lang.crops.searchFill}
        icon={<SearchIcon />}
        value={vm.search}
        onChange={(e) => vm.setSearch(e.target.value)}
      />
      {/*<SizedBox width={8} />*/}
      {/*<Button*/}
      {/*  style={{ width: "fit-content", whiteSpace: "nowrap" }}*/}
      {/*  kind="secondary"*/}
      {/*>*/}
      {/*  {lang.devices.createTagsButton}*/}
      {/*</Button>*/}
    </Root>
  );
};
export default observer(DevicesListHeader);
