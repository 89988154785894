import React from "react";
import Dialog from "@components/Dialog";
import Text from "@components/Text";
import SizedBox from "@components/SizedBox";
import { Row } from "@components/Flex";
import Button from "@components/Button";
import { observer } from "mobx-react-lite";
import { useLang } from "@src/hooks/useLang";
import { useStores } from "@stores";

interface IProps {
  visible: boolean;
  onClose: () => void;
}

const LogoutConfirmDialog: React.FC<IProps> = ({ ...rest }) => {
  const lang = useLang();
  const { accountStore } = useStores();
  return (
    <Dialog style={{ width: 408 }} title={lang.profile.logoutTitle} {...rest}>
      <Text size={15} color="#899093" style={{ width: 250 }}>
        {lang.profile.logoutQuestion}
      </Text>
      <SizedBox height={24} />
      <Row>
        <Button kind="outline" onClick={accountStore.logout}>
          {lang.profile.logoutYes}
        </Button>
        <SizedBox width={16} />
        <Button onClick={rest.onClose}>{lang.profile.logoutNo}</Button>
      </Row>
    </Dialog>
  );
};
export default observer(LogoutConfirmDialog);
