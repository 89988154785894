import React from "react";
import { Row } from "@components/Flex";
import Button from "@components/Button";
import SizedBox from "@components/SizedBox";
import { ReactComponent as SignOutIcon } from "@assets/icons/signOut.svg";
import { observer } from "mobx-react-lite";
import { useLang } from "@src/hooks/useLang";

interface IProps {
  onChangePass: () => void;
  onLogOut: () => void;
}

const ProfileScreenHeadButtons: React.FC<IProps> = ({
  onLogOut,
  onChangePass,
}) => {
  const lang = useLang();
  return (
    <Row justifyContent="flex-end">
      <Button
        onClick={onChangePass}
        kind="secondary"
        style={{ padding: "0 20px", maxWidth: "fit-content" }}
      >
        {lang.profile.changePasswordButton}
      </Button>
      <SizedBox width={8} />
      <Button
        onClick={onLogOut}
        kind="secondary"
        style={{ padding: "0 16px", maxWidth: "fit-content" }}
      >
        {lang.profile.exitButton}
        <SizedBox width={10} />
        <SignOutIcon />
      </Button>
    </Row>
  );
};

export default observer(ProfileScreenHeadButtons);
