import styled from "@emotion/styled";

export type TTextType = "heading" | "text";
export type TTextSize = 24 | 22 | 18 | 16 | 15 | 14 | 13 | 12 | 11 | 10;
export type TTextAlign = "center" | "left" | "right" | "justify";

const Text = styled.div<{
  weight?: 400 | 500;
  size?: TTextSize;
  fitContent?: boolean;
  nowrap?: boolean;
  textAlign?: TTextAlign;
  color?: string;
  type?: TTextType;
}>`
  user-select: none;
  width: ${({ fitContent }) => (fitContent ? "fit-content" : "100%")};
  white-space: ${({ nowrap }) => (nowrap ? "nowrap" : "unset")};
  text-align: ${({ textAlign }) => textAlign ?? "left"};
  color: ${({ color }) => color};
  ${({ type }) =>
    (() => {
      switch (type) {
        case "heading":
          return "font-family: Nunito Sans; font-weight: bold;";
        case "text":
          return "font-family: Roboto; font-weight: normal;";
        default:
          return "font-family: Roboto; font-weight: normal;";
      }
    })()}
  ${({ size }) =>
    (() => {
      switch (size) {
        case 24:
          return "font-size: 24px; line-height: 34px;";
        case 22:
          return "font-size: 22px; line-height: 30px;";
        case 18:
          return "font-size: 18px; line-height: 26px;";
        case 16:
          return "font-size: 16px; line-height: 24px;";
        case 15:
          return "font-size: 15px; line-height: 24px;";
        case 14:
          return "font-size: 14px; line-height: 22px;";
        case 13:
          return "font-size: 13px; line-height: 20px;";
        case 12:
          return "font-size: 12px; line-height: 20px;";
        case 11:
          return "font-size: 11px; line-height: 18px;";
        case 10:
          return "font-size: 10px; line-height: 17px;";
      }
    })()}
  ${({ weight }) => (weight ? `font-weight: ${weight}` : "")};
`;

export default Text;
