import styled from "@emotion/styled";
import React, { CSSProperties } from "react";
import { usePopperTooltip } from "react-popper-tooltip";
import { Config } from "react-popper-tooltip/dist/types";

interface IProps {
  content: string | JSX.Element;
  config?: Config;
  contentWrapperStyles?: CSSProperties;
}

const Root = styled.div`
  display: flex;
  width: max-content;
  box-sizing: border-box;
  padding: 12px;

  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.04), 0px 4px 10px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  z-index: 1;
`;
const Tooltip: React.FC<IProps> = ({
  contentWrapperStyles,
  children,
  content,
  config,
}) => {
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({ ...config });
  const props = getTooltipProps();
  return (
    <div>
      <div ref={setTriggerRef} style={{ cursor: "pointer" }}>
        {children}
      </div>
      {visible && (
        <Root
          ref={setTooltipRef}
          {...props}
          style={{ ...props.style, ...contentWrapperStyles }}
        >
          {content}
        </Root>
      )}
    </div>
  );
};
export default Tooltip;
