import { LANGUAGE } from "@stores/SettingsStore";

const en = [
  "en",
  "en-au",
  "en-bz",
  "en-ca",
  "en-ie",
  "en-jm",
  "en-nz",
  "en-za",
  "en-tt",
  "en-gb",
  "en-us",
];
const es = [
  "es-ar",
  "es-bo",
  "es-cl",
  "es-co",
  "es-cr",
  "es-do",
  "es-ec",
  "es-sv",
  "es-gt",
  "es-hn",
  "es-mx",
  "es-ni",
  "es-pa",
  "es-py",
  "es-pe",
  "es-pr",
  "es",
  "es-uy",
  "es-ve",
];
const ru = [
  "ru",
  "ru-RU", 
  "ru-md",
];

const ro = [
  "ro",
  "ro-mo",
];

const hu = [
  "hu",
];

const getLanguageByCode = (code: string): LANGUAGE => {
  switch (true) {
    case en.includes(code):
      return LANGUAGE.EN;
    case es.includes(code):
      return LANGUAGE.ES;
    case ru.includes(code):
      return LANGUAGE.RU;
    case ro.includes(code):
      return LANGUAGE.RO;
    case hu.includes(code):
      return LANGUAGE.HU;
    default:
      return LANGUAGE.EN;
  }
};
export default getLanguageByCode;
