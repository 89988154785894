import * as geojson from "geojson";
import _ from "lodash";

import * as gb from "@proto/types/v1/geobuf_pb";

import { precision as codec, ConvertFn } from "./precision";


class GeobufEncoder {
  private precision: number;
  // Coordinates encoder
  private codec: ConvertFn;

  // Geobuf properties
  private props: {
    [name: string]: {
      idx: number;
      typ: gb.ValueType;
    }
  };
  private props_length: number;

  constructor(precision: number) {
    const { encode } = codec(precision);

    this.precision = precision;
    this.props = {};
    this.props_length = 0;
    this.codec = encode;
  }

  public encode(src: geojson.GeoJSON): gb.Geobuf {
    // NOTE(nk2ge5k): reset properties
    this.props = {};
    this.props_length = 0;

    let buf = new gb.Geobuf();
    buf.setPrecision(this.precision);

    switch (src.type) {
      case "FeatureCollection":
        buf.setFeatureCollection(this.encodeFeatureCollection(src));
        break;
      case "Feature":
        buf.setFeature(this.encodeFeature(src));
        break;
      default:
        buf.setGeometry(this.encodeGeometry(src));
    }

    for (let key in this.props) {
      const { idx, typ } = this.props[key];
      buf.addKeys(key, idx);
      buf.addTypes(typ, idx);
    }

    return buf;
  }

  // encodeFeatureCollection encodes GeoJSOn FeatureCollection as Geobuf FeatureCollection
  private encodeFeatureCollection(fc: geojson.FeatureCollection): gb.FeatureCollection {
    let result = new gb.FeatureCollection();
    result.setFeaturesList(fc.features.map((f) => {
      return this.encodeFeature(f);
    }));
    return result;
  }

  // encodeFeatureCollection encodes GeoJSON Feature as Geobuf Feature
  private encodeFeature(f: geojson.Feature): gb.Feature {
    let result = new gb.Feature();
    if (f.id) {
      result.setId(f.id.toString());
    }
    result.setProperties(this.encodeProperties(f.properties));
    result.setGeometry(this.encodeGeometry(f.geometry));
    return result;
  }

  // encodeGeometry encodes GeoJSON geometry as Geobuf Geometry
  private encodeGeometry(g: geojson.Geometry): gb.Geometry {
    switch (g.type) {
      case "Point":
        return this.encodePoint(g);
      case "MultiPoint":
        return this.encodeMultiPoint(g);
      case "LineString":
        return this.encodeLineString(g);
      case "MultiLineString":
        return this.encodeMultiLineString(g);
      case "Polygon":
        return this.encodePolygon(g);
      case "MultiPolygon":
        return this.encodeMultiPolygon(g);
      case "GeometryCollection":
        let result = new gb.Geometry();
        result.setType(gb.GeometryType.GEOMETRY_TYPE_GEOMETRYCOLLECTION);
        result.setGeometriesList(g.geometries.map((g) => {
          return this.encodeGeometry(g);
        }));
        return result;
      default:
        throw new Error("Unexpected geometry type");
    }
  }

  // encodeProperties returns geobuf representation of GeoJSON properties
  private encodeProperties(props: geojson.GeoJsonProperties): gb.Properties {
    if (!props) {
      return new gb.Properties();
    }

    let result = new gb.Properties();
    for (let key in props) {
      const value = props[key];

      // NOTE(nk2ge5k): field format where field type is specified explicitly
      if ("$type" in value && "$value" in value && _.isString(value["$type"])) {
        const typ: string = value["$type"];
        const val: any = value["$value"];

        if (typ === "string") {
          result.addKeys(this.keyn(key, gb.ValueType.VALUE_TYPE_STRING));
          result.addValues(new gb.Value().setStringValue(val));
          continue;
        } else if (typ === "int") {
          result.addKeys(this.keyn(key, gb.ValueType.VALUE_TYPE_INTEGER));
          result.addValues(new gb.Value().setDoubleValue(val));
          continue;
        } else if (typ === "float") {
          result.addKeys(this.keyn(key, gb.ValueType.VALUE_TYPE_DOUBLE));
          result.addValues(new gb.Value().setDoubleValue(val));
          continue;
        } else if (typ === "bool") {
          result.addKeys(this.keyn(key, gb.ValueType.VALUE_TYPE_BOOL));
          result.addValues(new gb.Value().setBoolValue(val));
          continue;
        }
      }

      if (_.isString(value)) {
        result.addKeys(this.keyn(key, gb.ValueType.VALUE_TYPE_STRING));
        result.addValues(new gb.Value().setStringValue(value));
      } else if (_.isNumber(value)) {
        result.addKeys(this.keyn(key, gb.ValueType.VALUE_TYPE_DOUBLE));
        result.addValues(new gb.Value().setDoubleValue(value));
      } else if (_.isBoolean(value)) {
        result.addKeys(this.keyn(key, gb.ValueType.VALUE_TYPE_BOOL));
        result.addValues(new gb.Value().setBoolValue(value));
      }
    }

    return result;
  };

  // encodePoint return geobuf representation of the GeoJSON point
  private encodePoint(p: geojson.Point): gb.Geometry {
    let result = new gb.Geometry();
    result.setType(gb.GeometryType.GEOMETRY_TYPE_POINT);
    result.setCoordsList([
      this.codec(p.coordinates[0]),
      this.codec(p.coordinates[1]),
    ]);
    return result;
  }

  // encodeMultiPoint return geobuf representation of the GeoJSON MuliPoint
  private encodeMultiPoint(mp: geojson.MultiPoint): gb.Geometry {
    let coords: number[] = [];
    mp.coordinates.forEach((p) => {
      coords.push(this.codec(p[0]), this.codec(p[1]));
    });

    let result = new gb.Geometry();
    result.setType(gb.GeometryType.GEOMETRY_TYPE_MULTIPOINT);
    result.addLengths(mp.coordinates.length);
    result.setCoordsList(coords);

    return result;
  }

  private encodeLineString(ls: geojson.LineString): gb.Geometry {
    let coords: number[] = [];
    ls.coordinates.forEach((p) => {
      coords.push(this.codec(p[0]), this.codec(p[1]));
    });

    let result = new gb.Geometry();
    result.setType(gb.GeometryType.GEOMETRY_TYPE_POINT);
    result.addLengths(ls.coordinates.length);
    result.setCoordsList(coords);

    return result;
  }

  private encodeMultiLineString(mls: geojson.MultiLineString): gb.Geometry {
    let result = new gb.Geometry();

    result.setType(gb.GeometryType.GEOMETRY_TYPE_MULTILINESTRING);
    result.addLengths(mls.coordinates.length);

    mls.coordinates.forEach((ls) => {
      result.addLengths(ls.length);
      ls.forEach((p) => {
        result.addCoords(this.codec(p[0]));
        result.addCoords(this.codec(p[1]));
      });
    });

    return result;
  }

  private encodePolygon(p: geojson.Polygon): gb.Geometry {
    let result = new gb.Geometry();

    result.setType(gb.GeometryType.GEOMETRY_TYPE_POLYGON);
    result.addLengths(p.coordinates.length);

    p.coordinates.forEach((ls) => {
      result.addLengths(ls.length);
      ls.forEach((p) => {
        result.addCoords(this.codec(p[0]));
        result.addCoords(this.codec(p[1]));
      });
    });

    return result;
  }

  private encodeMultiPolygon(mp: geojson.MultiPolygon): gb.Geometry {
    let result = new gb.Geometry();

    result.setType(gb.GeometryType.GEOMETRY_TYPE_MULTIPOLYGON);
    result.addLengths(mp.coordinates.length);

    mp.coordinates.forEach((p) => {
      result.addLengths(p.length);
      p.forEach((ls) => {
        result.addLengths(ls.length);
        ls.forEach((p) => {
          result.addCoords(this.codec(p[0]));
          result.addCoords(this.codec(p[1]));
        });
      });
    });

    return result;
  }

  private keyn(key: string, t: gb.ValueType): number {
    if (!(key in this.props)) {
      this.props[key] = {
        idx: this.props_length,
        typ: t,
      };
      this.props_length++;
    }

    const { idx, typ } = this.props[key];
    if (typ !== t) {
      throw new Error(`Property "${key}" type mismatch ${t} != ${typ}`);
    }

    return idx;
  }
}

export const encode = (src: geojson.GeoJSON, precision: number): gb.Geobuf => {
  const encoder = new GeobufEncoder(precision);
  return encoder.encode(src);
};
