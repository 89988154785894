import styled from "@emotion/styled";
import React from "react";
import { Row } from "@components/Flex";
import getTextWidth from "@src/utils/getTextWidth";

interface IProps {
  title?: string;
  area?: string;
}

const Title = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Nunito Sans, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #494c5b;
`;

const font =
  "font-family: Nunito Sans; font-weight: bold;font-size: 14px; line-height: 18px;";

const FieldName: React.FC<IProps> = ({ title, area }) => {
  const dividerWidth = getTextWidth(" • ", font);
  const areaWidth = getTextWidth(area ?? "", font);
  const maxTitleWidth = 178 - 48 - dividerWidth - areaWidth;
  return (
    <Row>
      <Title style={{ maxWidth: maxTitleWidth }}>{title}</Title>
      {area && (
        <>
          <Title style={{ color: "rgb(204, 213, 216)" }}>&nbsp;•&nbsp;</Title>
          <Title>{area}</Title>
        </>
      )}
    </Row>
  );
};
export default FieldName;
