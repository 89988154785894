import styled from "@emotion/styled";
import React from "react";
import SizedBox from "@components/SizedBox";
import { ReactComponent as PlusIcon } from "@assets/icons/PlusIcon.svg";
import Button from "@components/Button";
import { useLang } from "@src/hooks/useLang";
import { observer } from "mobx-react-lite";
import { useDevicesScreenVM } from "@screens/DevicesScreen/DevicesScreenVM";

interface IProps {}

const Root = styled(Button)`
  width: fit-content;
  white-space: nowrap;
`;

const CreateDeviceButton: React.FC<IProps> = () => {
  const lang = useLang();
  const vm = useDevicesScreenVM();

  const handleClick = () => {
    vm.setEditedDevice(null);
    vm.setCreateDialogOpened(true);
  };

  return (
    <Root onClick={handleClick}>
      {lang.devices.addDeviceButton}
      <SizedBox width={6} />
      <PlusIcon />
    </Root>
  );
};
export default observer(CreateDeviceButton);
