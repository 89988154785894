import React, { useState } from "react";
import AuthLayout from "@components/AuthLayout";
import Input from "@components/Input";
import SizedBox from "@components/SizedBox";
import Button from "@components/Button";
import Checkbox from "@components/Checkbox";
import { Column, Row } from "@src/components/Flex";
import Text from "@components/Text";
import styled from "@emotion/styled";
import validateEmail from "@src/utils/validateEmail";
import validatePass from "@src/utils/validatePassword";
import { useLang } from "@src/hooks/useLang";
import { observer } from "mobx-react-lite";
import Loading from "@components/Loading";
import { useStores } from "@stores";
import { Navigate, useNavigate } from "react-router-dom";
import { ROUTES } from "@stores/AccountStore";
import { pageView } from "@src/utils/analytics";

interface IProps {}

const PrivacyText = styled(Text)`
  color: #899093;
  font-size: 10px;
  line-height: 17px;
  user-select: none;
  a {
    color: #68ad18;
  }
`;

const RegisterScreen: React.FC<IProps> = () => {
  pageView("Register");

  const navigate = useNavigate();
  const lang = useLang();
  const { accountStore } = useStores();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const disabled =
    loading || !checked || !validateEmail(email) || !validatePass(password);
  const handleSubmit = async () => {
    if (disabled) return;
    setError(undefined);
    setLoading(true);
    await accountStore
      .register({ email, password })
      .then(() => new Promise((r) => setTimeout(r, 1000)))
      .then(() => navigate(ROUTES.PROFILE))
      .catch((e) => {
        console.error(e);
        setError(e.toString());
        setLoading(false);
      });
  };
  if (accountStore.authenticated) return <Navigate to={ROUTES.PROFILE} />;
  return (
    <AuthLayout error={error}>
      <Input
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        placeholder={lang.auth.emailField}
        label={lang.auth.emailTitle}
        type="email"
      />
      <SizedBox height={16} />
      <Input
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        type="password"
        placeholder={lang.auth.passwordField}
        label={lang.auth.passwordTitle}
      />
      <SizedBox height={24} />
      <Row>
        <Checkbox checked={checked} onChange={setChecked} />
        <SizedBox width={10} />
        <Column>
          <PrivacyText>
            {lang.auth.privacyText}{" "}
            <a
              href={lang.auth.privacyLink}
              target="_blank"
              rel="noopener noreferer noreferrer"
            >
              {lang.auth.privacyButton}
            </a>
          </PrivacyText>
          <PrivacyText>
            {lang.auth.licenseText}{" "}
            <a
              href={lang.auth.licenseLink}
              target="_blank"
              rel="noopener noreferer noreferrer"
            >
              {lang.auth.licenseButton}
            </a>
          </PrivacyText>
        </Column>
      </Row>
      <SizedBox height={32} />
      <Button onClick={handleSubmit} disabled={disabled}>
        {!loading ? lang.auth.registerButton : <Loading />}
      </Button>
    </AuthLayout>
  );
};
export default observer(RegisterScreen);
