import styled from "@emotion/styled";
import React, { CSSProperties, HTMLAttributes } from "react";
import { observer } from "mobx-react-lite";
import LeftBar from "./LeftBar";
import Text from "@components/Text";
import SizedBox from "@components/SizedBox";
import { Row } from "@components/Flex";
interface IProps extends HTMLAttributes<HTMLDivElement> {
  title?: string;
  extra?: JSX.Element | JSX.Element[] | null;
  subtitle?: string;
  leftBarStyle?: CSSProperties;
}

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  align-items: stretch;
  min-width: 1152px;
  max-width: 1920px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
  padding: 24px 24px 32px 0;
`;

const Layout: React.FC<IProps> = ({
  extra,
  title,
  subtitle,
  leftBarStyle,
  children,
  ...rest
}) => {
  return (
    <Root {...rest}>
      <LeftBar style={leftBarStyle} />
      <Content>
        {title && (
          <Row justifyContent="space-between">
            <Text
              type="heading"
              style={{ marginBottom: 14 }}
              size={24}
              color="#494C5B"
            >
              {title}
            </Text>
            {extra}
          </Row>
        )}
        {subtitle && (
          <Text
            style={{ marginBottom: 8, maxWidth: 800 }}
            size={15}
            color="#494C5B"
          >
            {subtitle}
          </Text>
        )}
        <SizedBox height={8} />
        {children}
      </Content>
    </Root>
  );
};
export default observer(Layout);
