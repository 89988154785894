import React, { useState } from "react";
import AuthLayout from "@components/AuthLayout";
import Input from "@components/Input";
import SizedBox from "@components/SizedBox";
import Button from "@components/Button";
import Text from "@components/Text";
import { Row } from "@src/components/Flex";
import validatePassword from "@src/utils/validatePassword";
import { useLang } from "@src/hooks/useLang";
import { observer } from "mobx-react-lite";
import { pageView } from "@src/utils/analytics";

interface IProps {}

const PasswordResetScreen: React.FC<IProps> = () => {
  pageView("ResetPassword");

  const [password1, setPassword1] = useState("");
  const [password2, setPassword2] = useState("");
  const lang = useLang();
  const disabled =
    !validatePassword(password1) ||
    !validatePassword(password2) ||
    password1 !== password2;
  const handleSubmit = () => {
    if (disabled) return;
    alert(
      `Reset password data:\nPassword1: ${password1}\nPassword2: ${password2}`
    );
  };
  return (
    <AuthLayout disableTabs>
      <Row alignItems="center" style={{ marginTop: 16 }}>
        <Text type="heading" size={15} color="#181B1F">
          {lang.auth.forgotPasswordSection}
        </Text>
      </Row>
      <SizedBox height={24} />
      <Input
        onChange={(e) => setPassword1(e.target.value)}
        value={password1}
        placeholder={lang.auth.newPasswordField}
        label={lang.auth.newPasswordTitle}
        type="password"
      />
      <SizedBox height={16} />
      <Input
        onChange={(e) => setPassword2(e.target.value)}
        value={password2}
        placeholder={lang.auth.repeatPasswordField}
        label={lang.auth.repeatPasswordTitle}
        type="password"
      />
      <SizedBox height={24} />
      <Button onClick={handleSubmit} disabled={disabled}>
        {lang.auth.saveNewPasswordButton}
      </Button>
    </AuthLayout>
  );
};
export default observer(PasswordResetScreen);
