import React, { useMemo } from "react";
import useVM from "@src/hooks/useVM";
import { makeAutoObservable } from "mobx";
import { RootStore, useStores } from "@stores";
import * as profileService from "@services/profileService";
import { TProfile } from "@services/profileService";
import { login } from "@services/peopleService";

const ctx = React.createContext<ProfileScreenVM | null>(null);

export const ProfileScreenVMProvider: React.FC = ({ children }) => {
  const rootStore = useStores();
  const store = useMemo(() => new ProfileScreenVM(rootStore), [rootStore]);
  return <ctx.Provider value={store}>{children}</ctx.Provider>;
};

export const useProfileScreenVM = () => useVM(ctx);

class ProfileScreenVM {
  loading: boolean = true;
  private setLoading = (v: boolean) => (this.loading = v);

  profileData: TProfile | null = null;
  private setProfileData = (v: TProfile) => (this.profileData = v);

  formData: TProfile | null = null;
  setFormData = (v: TProfile | null) => (this.formData = v);

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
    this.sync()
      .then(this.resetFormData)
      .then(() => this.setLoading(false));
  }

  resetFormData = () => this.setFormData(this.profileData);

  updateProfileData = async () => {
    this.formData &&
      (await profileService
        .updateProfileData(this.formData)
        .then((data) => this.setProfileData(data)));
  };

  validatePassword = (password: string) =>
    new Promise((resolve) =>
      login({ password, email: this.profileData!.contacts.email })
        .then(() => resolve(true))
        .catch(() => resolve(false))
    );

  sync = async () =>
    profileService.getProfileData().then((data) => this.setProfileData(data));
}
