import React, { useMemo } from "react";
import useVM from "@src/hooks/useVM";
import { makeAutoObservable } from "mobx";
import { RootStore, useStores } from "@stores";
import * as devicesService from "@services/devicesService";
import { TDevice } from "@services/devicesService";
import * as calibrationService from "@services/calibrationService";
import { TCalibration, TCreateCalibrationParams } from "@services/calibrationService";
import { toast } from "react-toastify";
import { ROUTES } from "@stores/AccountStore";
import * as fieldsService from "@services/fieldsService";
import { TCrop } from "@services/fieldsService";

const ctx = React.createContext<DeviceCalibrationScreenVM | null>(null);

export const DeviceCalibrationScreenVMProvider: React.FC = ({ children }) => {
  const rootStore = useStores();
  const store = useMemo(
    () => new DeviceCalibrationScreenVM(rootStore),
    [rootStore]
  );
  return <ctx.Provider value={store}>{children}</ctx.Provider>;
};

export const useDeviceCalibrationScreenVM = () => useVM(ctx);

class DeviceCalibrationScreenVM {
  loading: boolean = false;
  private setLoading = (v: boolean) => (this.loading = v);

  editCalibrationId?: string;
  setEditCalibrationId = (v?: string) => (this.editCalibrationId = v);

  dialogVisible: boolean = false;
  setDialogVisible = (v: boolean) => {
    !v && this.setEditCalibrationId(undefined);
    this.dialogVisible = v;
  };

  initialized: boolean = false;
  private setInitialized = (v: boolean) => (this.initialized = v);

  device: TDevice | null = null;
  private setDevice = (v: TDevice) => (this.device = v);

  crops: TCrop[] = [];
  private setCrops = (crops: TCrop[]) => (this.crops = crops);

  calibrations: TCalibration[] = [];
  private setCalibrations = (v: TCalibration[]) => (this.calibrations = v);

  // private calibrationsStart = 0;
  // private setCalibrationsStart = (v: number) => (this.calibrationsStart = v);
  //
  // private calibrationsEnd = 10;
  // private setCalibrationsEnd = (v: number) => (this.calibrationsEnd = v);

  createCalibration = async (calibration: TCreateCalibrationParams) => {
    if (this.device == null) return;
    await calibrationService.createCalibration(this.device?.id, calibration);
    DeviceCalibrationScreenVM.deviceId &&
      (await this.sync(DeviceCalibrationScreenVM.deviceId));
  };

  editCalibration = async (
    calibration: TCreateCalibrationParams & { id?: string }
  ) => {
    if (this.device == null || calibration?.id == null) return;
    await calibrationService.editCalibration(
      this.device?.id,
      calibration?.id,
      calibration
    );
    DeviceCalibrationScreenVM.deviceId &&
      (await this.sync(DeviceCalibrationScreenVM.deviceId));
  };

  deleteCalibration = async (id: string) => {
    if (this.device == null) return;
    await calibrationService.deleteCalibration(this.device?.id, id);
    DeviceCalibrationScreenVM.deviceId &&
      (await this.sync(DeviceCalibrationScreenVM.deviceId));
  };

  private static get deviceId() {
    const pathname = window.location.pathname;
    const regex = new RegExp(ROUTES.DEVICES_CALIBRATION.replace(":id", "(.*)"));
    const match = pathname.match(regex);
    return match && match[1] ? match[1] : null;
  }

  sync = (id: string) =>
    Promise.all([
      devicesService
        .getDeviceById(id)
        .then((device) => device != null && this.setDevice(device))
        .catch((e) => toast(e.message, { type: "error" })),
      fieldsService
        .getCropsData()
        .then(this.setCrops)
        .catch((e) => toast(e.message, { type: "error" })),
      calibrationService
        .getDeviceCalibrations(id)
        .then(this.setCalibrations)
        .catch((e) => toast(e.message, { type: "error" })),
    ]);

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
    if (DeviceCalibrationScreenVM.deviceId != null) {
      this.sync(DeviceCalibrationScreenVM.deviceId).then(() =>
        this.setInitialized(true)
      );
    } else {
      this.setInitialized(true);
    }
  }
}
