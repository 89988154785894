import styled from "@emotion/styled";
import React, { useRef, useState } from "react";
import Text from "@components/Text";
import { ReactComponent as SettingsIcon } from "@assets/icons/settings.svg";
import { useLang } from "@src/hooks/useLang";
import useOnClickOutside from "@src/hooks/useOnClickOutside";
import LeftBarSettingsMenuItem from "./LeftBarSettingsMenuItem";
import { observer } from "mobx-react-lite";
import {
  LanguageRow,
  LanguageRowIndicator,
} from "@components/LanguageSelectTooltip/LanguageSelectBody";
import { LANGUAGE, LANGUAGE_NAMES } from "@stores/SettingsStore";
import { useStores } from "@stores";

interface IProps {
  expanded: boolean;
  menuWidth: number;
}

const Root = styled.div`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0 0 16px;
  height: 38px;
  overflow: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
  text-overflow: ellipsis;
  color: #899093;
  :after {
    content: "";
    position: absolute;
    width: 3px;
    height: 30px;
    top: 4px;
    right: 0;
    background: #68ad18;
    border-radius: 4px 0 0 4px;
    transition: 0.4s;
    opacity: 0;
  }
  svg {
    min-width: 20px;
  }
  position: relative;
  :hover {
    :after {
      opacity: 1;
    }
  }
`;

const Tooltip = styled.div<{
  expanded: boolean;
  offset: number;
  subMenu?: boolean;
}>`
  position: absolute;
  left: ${({ offset, subMenu }) => (subMenu ? 165 : 0) + offset}px;
  bottom: 4px;
  width: ${({ expanded }) => (expanded ? 160 : 0)}px;
  background: #ffffff;
  transition: 0.4s;
  font-family: Nunito Sans, sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  flex-direction: column;
  //align-items: center;
  color: #545961;
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 6px 6px 6px ${({ subMenu }) => (subMenu ? 6 : 0)}px;
  box-shadow: ${({ subMenu }) =>
    subMenu
      ? "0px 1px 4px rgba(0, 0, 0, 0.04), 0px 4px 10px rgba(0, 0, 0, 0.16)"
      : "0 2px 20px rgba(78, 78, 81, 0.2)"};
  z-index: 1;
`;

export const Divider = styled.div`
  height: 1px;
  background: rgba(137, 144, 147, 0.3);
  width: 100%;
`;

const SubMenuContainer = styled.div`
  padding-left: 12px;
  box-sizing: border-box;
  width: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
`;

const LeftBarSettings: React.FC<IProps> = ({ expanded, menuWidth }) => {
  const lang = useLang();
  const { settingsStore } = useStores();

  const [opened, setOpened] = useState(false);
  const [, setDimMenuOpened] = useState(false);
  const [langMenuOpened, setLangMenuOpened] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => handleClose());

  const offset = expanded ? menuWidth : 52;

  const handleOpen = () => setOpened(true);
  const handleClose = () => {
    setOpened(false);
    setDimMenuOpened(false);
    setLangMenuOpened(false);
  };
  const handleOpenSubMenu = (type: "lng" | "dim") => {
    setDimMenuOpened(type === "dim");
    setLangMenuOpened(type === "lng");
  };

  return (
    <div style={{ position: "relative", width: "100%" }} ref={ref}>
      <Root onClick={opened ? handleClose : handleOpen}>
        <SettingsIcon />
        {expanded && (
          <Text style={{ marginLeft: 12 }} size={12}>
            {lang.menu.settingsMenuOption}
          </Text>
        )}
      </Root>
      <Tooltip offset={offset} expanded={opened}>
        {/*<LeftBarSettingsMenuItem*/}
        {/*  title={lang.settings.measures}*/}
        {/*  value="Metric"*/}
        {/*  onClick={() => handleOpenSubMenu("dim")}*/}
        {/*/>*/}
        {/*<Divider />*/}
        <LeftBarSettingsMenuItem
          title={lang.settings.languages}
          value={LANGUAGE_NAMES[settingsStore.language]}
          onClick={() => handleOpenSubMenu("lng")}
        />
      </Tooltip>
      <Tooltip subMenu offset={offset} expanded={langMenuOpened}>
        <SubMenuContainer>
          {Object.entries(LANGUAGE_NAMES).map(([lang, title]) => (
            <LanguageRow
              style={{ padding: "10px 12px", width: "100%" }}
              className="language-row"
              key={lang}
              selected={settingsStore.language === lang}
              onClick={() => settingsStore.setLanguage(lang as LANGUAGE)}
            >
              <div className="indicatorWrapper">
                <LanguageRowIndicator />
              </div>
              {title}
            </LanguageRow>
          ))}
        </SubMenuContainer>
      </Tooltip>
    </div>
  );
};
export default observer(LeftBarSettings);
