import React from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "@stores";
import { Navigate } from "react-router-dom";
import { ROUTES } from "@stores/AccountStore";
import { ContextProvider } from "@stores/SeasonStore";

interface IProps { }

const PrivateRoute: React.FC<IProps> = ({ children }) => {
  const { accountStore } = useStores();
  if (!accountStore.initialized) return null;
  return accountStore.authenticated ? (
    <ContextProvider>{children}</ContextProvider>
  ) : (
    <Navigate to={ROUTES.LOGIN} />
  );
};
export default observer(PrivateRoute);
