import React from "react";
import { ReactComponent as CheckOnIcon } from "@assets/icons/checkboxOn.svg";
import { ReactComponent as CheckOffIcon } from "@assets/icons/checkboxOff.svg";

interface IProps {
  checked?: boolean;
  onChange?: (state: boolean) => void;
}

const Checkbox: React.FC<IProps> = ({ checked, onChange }) => {
  return checked ? (
    <CheckOnIcon
      onClick={() => onChange && onChange(false)}
      style={{ cursor: "pointer" }}
    />
  ) : (
    <CheckOffIcon
      onClick={() => onChange && onChange(true)}
      style={{ cursor: "pointer" }}
    />
  );
};
export default Checkbox;
