import React from "react";

import { toast } from "react-toastify";
import { makeAutoObservable, autorun } from "mobx";

import { RootStore, useStores } from "@stores";
import useVM from "@src/hooks/useVM";
import {
  Season,
  listSeasons,
  currentSeason,
  createSeason
} from "@services/fieldsService";

class SeasonsStore {
  // Loading state.
  // Should be true while fetching list of seasons and current season
  // from the server.
  loading: boolean = true;
  // Allows to change loading state.
  private setLoading = (loading: boolean) => {
    this.loading = loading;
  }

  // List of seasons.
  seasons: Season[] = [];
  // Allows to change list of seasons.
  private setSeasons = (seasons: Season[]) => {
    this.seasons = seasons;
  }

  // Current season id.
  //
  // NOTE(nk2ge5k): there is a difference between current season on the
  // backend and this one - beacause this one should be set by the user to
  // view the data for the specific season and backend one is the season
  // that will receive the new data from the harvesters.
  currentSeasonId?: string;
  // Allows to change current season id
  setCurrentSeasonId = (id: string) => {
    this.currentSeasonId = id;
  }

  get currentSeason(): Season | null {
    return this.seasons.find((s) => s.id === this.currentSeasonId) || null;
  }

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
    autorun(() => {
      if (this.rootStore.accountStore.authenticated) {
        this.loadSeasons()
          .then(() => { return currentSeason() })
          .then((currentSeason) => this.setCurrentSeasonId(currentSeason.id))
          .catch((e) => toast.error("Failed to get current season: " + e.toString()))
          .finally(() => {
            this.setLoading(false)
          });
      }
    });
  }

  // createSeason creates a new season for the user and returns its id.
  createSeason = async (from: Date, to: Date, season_id?: string): Promise<string> => {
    const id = await createSeason(from, to, season_id);

    // @question: Is it possible in JS to run async function without waiting
    // for it to finiish execution?
    //
    // NOTE(nk2ge5k): updating seasons list after creating a new season.
    this.loadSeasons();

    return id;
  }

  private loadSeasons = async () => {
    return listSeasons().then((seasons) => {
      this.setSeasons(seasons);
    }).catch((e) => {
      toast.error("Failed to load seasons list: " + e.toString());
    });
  }
}



const ctx = React.createContext<SeasonsStore | null>(null);

export const useSeasonsVM = () => useVM(ctx);

export const useSeasonId = () => {
  const vm = useSeasonsVM();
  return vm.currentSeasonId;
}

export const ContextProvider: React.FC = ({ children }) => {
  const rootStore = useStores();
  return <ctx.Provider value={rootStore.seasonStore}>{children}</ctx.Provider>;
};

export default SeasonsStore;
