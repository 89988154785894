import isDev from "@src/utils/isDev";

export interface IRegisterRequestParams {
  email: string;
  password: string;
}

interface IAuthorizeRequestParams {
  email: string;
  password: string;
}

export interface IStateInfo {
  email: string;
  name: string | null;
}

interface IStateResponse {
  info: IStateInfo;
};

const baseUrl = "https://at.greengrowth.tech/api/people/v1";

const clientId: string = (() => {
  const result = isDev
    ? process.env.REACT_APP_CLIENT_ID_DEV
    : process.env.REACT_APP_CLIENT_ID_PROD;
  if (!result) {
    throw new Error("Missing authorization client id");
  }
  return result;
})();

class Client {
  /**
   * Base url for interacting with backend APIs.
   */
  private baseUrl: string;

  /**
   * Client id for application authentication.
   */
  private clientId: string;

  constructor(baseUrl: string, clientId: string) {
    this.baseUrl = baseUrl;
    this.clientId = clientId;
  }

  /**
   * Returns path with query parameters
   */
  private withQuery(
    path: string,
    params: Map<string, string | null | undefined> | null = null
  ): string {
    if (params == null) {
      params = new Map<string, string>();
    }

    let query: Record<string, string> = { "client_id": this.clientId };
    Object.entries(params).forEach(
      ([key, value]) => {
        if (value !== null && value !== undefined) {
          query[key] = value;
        }
      }
    );

    return this.baseUrl + path + "?" + new URLSearchParams(query).toString();
  }

  async get(path: string, params: Map<string, string> | null = null) {
    return await fetch(this.withQuery(path, params), {
      method: "GET",
      mode: "cors",
      credentials: "include",
    });
  }

  async post(path: string, body: any, params: Map<string, string> | null = null) {
    return await fetch(this.withQuery(path, params), {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(body),
      mode: "cors",
      credentials: "include",
    });
  }
}

const client = new Client(baseUrl, clientId);

export const register = async (credentials: IRegisterRequestParams) => {
  const response = await client.post("/register", { credentials });
  if (response.status !== 204) {
    const error = await response.json();
    throw new Error(error.message);
  }
};

export const login = async (credentials: IAuthorizeRequestParams) => {
  const response = await client.post("/login", { credentials });
  if (response.status !== 204) {
    const error = await response.json();
    throw new Error(error.message);
  }
};

export const auth = async (): Promise<IStateResponse | null> => {
  const response = await client.get("/state");
  if (response.status === 200) {
    return await response.json();
  }
  return null;
};

export const logout = async () => {
  return await client.get("/logout");
};
