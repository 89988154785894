import React, { useState } from "react";
import Dialog from "@components/Dialog";
import SizedBox from "@components/SizedBox";
import { useLang } from "@src/hooks/useLang";
import Button from "@components/Button";
import { Row } from "@src/components/Flex";
import Text from "@components/Text";
import Loading from "@components/Loading";

interface IProps {
  visible: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
}

const RemoveConfirmDialog: React.FC<IProps> = ({ onSubmit, ...rest }) => {
  const lang = useLang();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    if (onSubmit) await onSubmit();
    setLoading(false);
  };
  return (
    <Dialog
      style={{ width: 408 }}
      title={lang.devices.deleteCalibrationTitle}
      {...rest}
    >
      <Text size={15} color="#899093" style={{ width: 250 }}>
        {lang.devices.deleteCalibrationSubtitle}
      </Text>
      <SizedBox height={24} />
      <Row>
        <Button kind="outline" onClick={handleSubmit}>
          {!loading ? lang.devices.deleteCalibrationDelButton : <Loading />}
        </Button>
        <SizedBox width={16} />
        <Button onClick={rest.onClose}>
          {lang.devices.deleteCalibrationCancelButton}
        </Button>
      </Row>
    </Dialog>
  );
};
export default RemoveConfirmDialog;
