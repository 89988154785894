import React, { useState } from "react";
import Dialog from "@components/Dialog";
import Text from "@components/Text";
import SizedBox from "@components/SizedBox";
import { Row } from "@components/Flex";
import Button from "@components/Button";
import { toast } from "react-toastify";
import { useLang } from "@src/hooks/useLang";
import { observer } from "mobx-react-lite";

interface IProps {
  visible: boolean;
  onSubmit: (file: File) => void;
  onClose: () => void;
}

const UploadFromFileDialog: React.FC<IProps> = ({
  visible,
  onClose,
  onSubmit,
}) => {
  const lang = useLang();
  const [file, setFile] = useState<File>();
  const onSetFile = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const file = target.files && target.files[0] && target.files[0];
    if (file && file.name.split(".").pop() === "kml") {
      setFile(file);
    } else {
      toast.error(<Text>{lang.fields.uploadInvalidfileError}</Text>);
    }
  };

  return (
    <Dialog
      title={lang.fields.uploadButton}
      visible={visible}
      onClose={onClose}
      style={{ maxWidth: 500 }}
    >
      <Text style={{ whiteSpace: "pre-wrap" }}>
        {lang.fields.uploadDialogText}
      </Text>
      <SizedBox height={32} />
      <input type="file" accept="application/inkml+xml" onChange={onSetFile} />
      <SizedBox height={32} />
      <Row justifyContent="flex-end">
        <Button onClick={onClose} kind="outline">
          {lang.fields.uploadDialogCancelButton}
        </Button>
        <SizedBox width={16} />
        <Button onClick={() => file && onSubmit(file)} disabled={file == null}>
          {lang.fields.uploadDialogSaveButton}
        </Button>
      </Row>
    </Dialog>
  );
};
export default observer(UploadFromFileDialog);
