import styled from "@emotion/styled";
import React from "react";
import Text from "@components/Text";
import { Row } from "@components/Flex";
import { ReactComponent as TrashIcon } from "@assets/icons/trash.svg";
import { ReactComponent as EditIcon } from "@assets/icons/edit.svg";
import { ColorBox } from "@screens/CropsScreen/CropsScreen";
import { observer } from "mobx-react-lite";
import { useLang } from "@src/hooks/useLang";
import { useCropsScreenVM } from "@screens/CropsScreen/CropsScreenVM";

interface IProps {
  onRemoveClick: (id: string) => void;
  onEditClick: (id: string) => void;
}

const Root = styled.div`
  border-radius: 4px;
  border: 1px solid #ebeeef;
  max-height: calc(100vh - 170px);
  overflow: scroll;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border-style: hidden;

  th,
  td {
    height: 48px;
    border: 1px solid #ebeeef;
    text-align: left;
    box-sizing: border-box;
    &:not(:first-of-type) {
      padding-left: 12px;
    }
  }

  .actions {
    position: absolute;
    top: 14px;
    right: 14px;
    transition: 0.4s;
    opacity: 0;
    max-width: fit-content;
    cursor: pointer;
  }

  tbody > tr {
    height: 44px;
    position: relative;
    :hover {
      background: #f8f8f8;
      .actions {
        opacity: 1;
      }
    }
  }
`;

const CropIcon = styled.img`
  width: 24px;
  height: 24px;
`;

const CropsTable: React.FC<IProps> = ({ onEditClick, onRemoveClick }) => {
  const lang = useLang();
  const vm = useCropsScreenVM();
  return (
    <Root>
      <Table>
        <thead>
          <tr>
            <th style={{ width: 48 }} />
            <th style={{ width: 320 }}>
              <Text color="#494C5B" type="heading" size={14}>
                {lang.crops.cropNameTable}
              </Text>
            </th>
            <th>
              <Text color="#494C5B" type="heading" size={14}>
                {lang.crops.cropColorTable}
              </Text>
            </th>
          </tr>
        </thead>
        <tbody>
          {vm.crops
            .filter(({ name }) =>
              name.toLowerCase().includes(vm.search.toLowerCase())
            )
            .map((crop, i) => (
              <tr key={i}>
                <td>
                  <Row alignItems="center" justifyContent="center">
                    <CropIcon src={crop.category.icon.url} alt={crop.name} />
                  </Row>
                </td>
                <td>
                  <Text color="#262626" size={14}>
                    {crop.name}
                  </Text>
                </td>
                <td>
                  <ColorBox color={crop.category.color} />
                  <Row className="actions">
                    <TrashIcon
                      onClick={() => onRemoveClick(crop.id)}
                      style={{ width: 20, height: 20, marginRight: 16 }}
                    />
                    <EditIcon onClick={() => onEditClick(crop.id)} />
                  </Row>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Root>
  );
};
export default observer(CropsTable);
