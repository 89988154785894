/**
 * This file contains utility function that allow to convert 
 * DeviceStatisticsResponse to the GeoJSON geometries in various of differnet
 * ways.
 */
import { FeatureCollection, Feature, LineString, Position, BBox, Polygon } from "geojson";
import moment from "moment";
import { distance, destination, bearing, point } from "@turf/turf";

import { DeviceStatisticsResponse } from "@services/devicesService";
import { precision } from "@geobuf";

// Minumal amount of the satelites for the point to be valid
const MIN_SATELITES = 5;
// Maximum interval (in secodes) between two point for them to be in the same line.
const MAX_DURATION_BETWEEN_POINTS = 5 * 60;
// Color pallet for sensor values
const SENSOR_PALLET = [
  "#A50026",
  "#D73027",
  "#F46D43",
  "#FDAE61",
  "#F8E559",
  "#A6D96A",
  "#66BD63",
  "#1A9850",
  "#006837",
  "#252525",
];
// Color pallet for speed valeus
const SPEED_PALLET = [
  "#1A9850",
  "#66BD63",
  "#A6D96A",
  "#F8E559",
  "#FDAE61",
  "#F46D43",
  "#D73027",
  "#A50026",
];

// normalizeSensor returns normalized sensor value in the range for 0 to 12.
export function normalizeSensor(norm: number): number {
  return Math.round(norm * (SENSOR_PALLET.length - 1));
}


// Returns the direction of the point c relative to the vector a -> b.
//     1 if c is ccw (left) from p1->p2,
//    -1 if c is cw (right) from p1->p2,
//     0 if c is colinear with p1->p2
function signedArea(a: number[], b: number[], c: number[]): number {
  const dx1 = b[0] - a[0];
  const dy1 = b[1] - a[1];
  const dx2 = c[0] - b[0];
  const dy2 = c[1] - b[1];

  return dx1 * dy2 - dx2 * dy1;
}

// Returns orientation of the polygon
//     1 if is ccw (left) oriented
//    -1 if is cw (right) oriented
//     0 if is colinear
function polygonOrientation(poly: Array<Position>): number {
  if (poly.length < 3) {
    return 0;
  }

  let area: number = 0;
  for (let i = 1; i < poly.length - 1; i++) {
    area += signedArea(poly[i - 1], poly[i], poly[i + 1]);
  }

  return Math.sign(area);
}

function expandBBox(box: BBox | undefined, p: Position) : BBox {
  if (!box) {
    return [p[0], p[1], p[0], p[1]];
  }

  box[0] = Math.min(box[0], p[0]);
  box[1] = Math.min(box[1], p[1]);
  box[2] = Math.max(box[2], p[0]);
  box[3] = Math.max(box[3], p[1]);

  return box;
}

function smoothLine(src: Array<Position>, level: number): Array<Position> {
  if (level <= 1) {
      return src
  }

  const dst = Array.from(src);

  for (var lvl = 0; lvl < level; lvl++) {
    const tmp = Array.from(dst);

    for (var i = 1; i < dst.length; i++) {
      const v1 = tmp[i - 1];
      const v2 = tmp[i];


      dst[i - 1] = [
        0.75 * v1[0] + 0.25 * v2[0],
        0.75 * v1[1] + 0.25 * v2[1],
      ];
      dst[i] = [
        0.25 * v1[0] + 0.75 * v2[0],
        0.25 * v1[1] + 0.75 * v2[1],
      ];
    }
  }

  return dst
}

function parseLine(response: DeviceStatisticsResponse): Array<Position> {
  const line   = new Array<Position>(response.getLength());
  const coords = response.getCoordsList();
  const { decode } = precision(10);

  for (let i = 0; i < response.getLength(); i++) {
    const j = i * 2;
    // Integer representation of the device 
    const lng = decode(coords[j]);
    const lat = decode(coords[j + 1]);

    line[i] = [lng, lat];
  }

  return line;
}

// computeBearings computes bearing for each point
function computeBearings(line: Array<Position>): Array<number> {
  const bearings = new Array<number>(line.length);

  if (line.length >= 2) {
    for (let i = 0; i < line.length - 1; i++) {
      bearings[i] = bearing(line[i], line[i + 1]);
    }

    bearings[line.length - 1] = bearing(
      line[line.length - 2], line[line.length - 1], { final: true });
  }

  return bearings;
}

function computeTrackFeature(
  response: DeviceStatisticsResponse,
  width: number,
  delay: number,
  from?: Date,
  to?: Date,
): FeatureCollection<Polygon> {
  // @thoughts: I think that I can make do without computing bearings as an array
  const line      = smoothLine(parseLine(response), 10); // @complexity: O((2 + level) * N)
  const bearings  = computeBearings(line);               // @complexity: O(N)
  const unix_from = from ? moment(from).unix() : null;
  const unix_to   = to   ? moment(to).unix()   : null;

  const half_width = width * 0.5;

  // Shorcuts:
  const length = response.getLength();
  const times  = response.getTimeList();
  const sensor = response.getNormList();

  let bbox: BBox | undefined;
  let features      = new Array<Feature<Polygon>>();
  let segment_value = Math.floor(sensor[0] * 100);
  let segment_start = 0; // index of the point where current segment starts
  const finalize_segment = (end: number, value: number) => {
    if (end > segment_start) {
      const ring = new Array<Position>();

      // Collect points form the other size of the track
      for (let i = end; i >= segment_start; i--) {
        const f = destination(line[i], half_width, bearings[i] + 90.0, {units: "meters"});
        const p = f.geometry.coordinates;
        bbox    = expandBBox(bbox, p);

        ring.push(p);
      }

      // Collect points from the one side of the track
      for (let i = segment_start; i <= end; i++) {
        const f = destination(line[i], half_width, bearings[i] - 90.0, {units: "meters"});
        const p = f.geometry.coordinates;
        bbox    = expandBBox(bbox, p);

        ring.push(p);
      }

      if (polygonOrientation(ring) === -1) {
        ring.reverse();
      }

      if (ring.length !== 0) {
        ring.push(ring[0]);
      }

      features.push({
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [ring],
        },
        properties: {
          sensor: segment_value,
        }
      })
    }

    segment_start = end;
    segment_value = value;
  };

  // Index of the point from which I get GPS data
  // @fixme: I need to skip zero times
  let gps_cursor = 0;
  let i = 0;
  for (; i < length; i++) {
    const gps_time    = times[gps_cursor];
    const sensor_time = times[i];
    const diff        = sensor_time - gps_time;

    // Skipping points until we will match delay
    if (diff < 0 || delay <= diff) {
      continue;
    }

    if (unix_to != null && gps_time > unix_to) {
      break;
    } else if (unix_from != null && gps_time < unix_from) {
      gps_cursor++;
      segment_start++;

      continue;
    }

    if (0 < gps_cursor && gps_cursor < length) {
      const val = Math.floor(sensor[i] * 100);
      const gap = times[gps_cursor] - times[gps_cursor - 1];
      if (gap >= 5) {
        finalize_segment(gps_cursor - 1, val);
        // @important: moving cursor forward to create visible gap in the track.
        segment_start = gps_cursor;
      } else if (val !== segment_value || (gps_cursor - segment_start) > 20) {
        finalize_segment(gps_cursor, val);
      }
    }

    gps_cursor++;
  }

  finalize_segment(Math.min(gps_cursor, i - 1), 0);

  return {
    type: "FeatureCollection",
    features: features,
    bbox: bbox,
  }
}



// forEachPoint iterates through the response data converting points to
// the geojson respresentation.
// Returns bounding box that contains every point of the data or undefined
// if response is empty.
function forEachPoint(
  response: DeviceStatisticsResponse,
  from: Date | undefined,
  to: Date | undefined,
  fn: (position: Position, index: number) => boolean,
): BBox | undefined {
  if (response.getLength() === 0) {
    return undefined;
  }

  // TODO(nk2ge5k): it would be nice to know correct precision, may be I should
  // return it from the server for clarity.
  const { decode } = precision(10);

  const times = response.getTimeList();
  const coords = response.getCoordsList();
  const satelites = response.getSatQtyList();

  const unix_from = from ? moment(from).unix() : null;
  const unix_to = to ? moment(to).unix() : null;

  // Bounding box that contains entire track
  let bbox: BBox | undefined;

  // Cicle through every point of the statistics and create lines.
  for (let i = 0; i < response.getLength(); i++) {
    if (i > 0 && times[i] === times[i - 1]) {
      continue;
    }

    if (unix_from !== null && unix_to !== null && unix_from <= unix_to) {
      if (times[i] < unix_from || unix_to < times[i]) {
        continue;
      }
    }

    // Index of the current point in coords array. Length of the coords array
    // must be exactly two times greater then response.length.
    const j = i * 2;
    // Integer representation of the device 
    const ilng = coords[j];
    const ilat = coords[j + 1];

    // Checking if data was gathered with sufficient accuracy.
    // We dont want to show track that is going all over the place - sometimes
    // GPS can send coordinates of 0,0.
    // The coordinates 0,0 in the ocean so we can safetly assume that it is in
    // fact invalid coordinates.
    if (satelites[i] < MIN_SATELITES || (ilng === 0 && ilat === 0)) {
      continue;
    }

    const lng = decode(ilng);
    const lat = decode(ilat);

    if (fn([lng, lat], i)) {
      if (bbox === undefined) {
        // Initializing bounding box with givien coordinates.
        bbox = [lng, lat, lng, lat];
      } else {
        // Expanding bounding box if needed
        bbox[0] = Math.min(bbox[0], lng);
        bbox[1] = Math.min(bbox[1], lat);
        bbox[2] = Math.max(bbox[2], lng);
        bbox[3] = Math.max(bbox[3], lat);
      }
    }
  }

  return bbox;
};


// sensorColor returns color of the normalizeSensor value
export const sensorColor = (value: number): string => {
  if (value < 0) {
    value = 0;
  } else if (value > SENSOR_PALLET.length - 1) {
    value = SENSOR_PALLET.length - 1;
  }
  return SENSOR_PALLET[value];
}

// sensorFeatures resturns GeoJSON FeatureCollection with lines colored
// according to the sensor value of the device.
// TODO:
// - width 
// - delay
function sensorFeatures(
  response: DeviceStatisticsResponse,
  width: number,
  delay: number,
  from?: Date,
  to?: Date,
): FeatureCollection<Polygon> {
  if (response.getLength() === 0) {
    return {
      type: "FeatureCollection",
      features: [],
    };
  }

  const result = computeTrackFeature(response, width, delay, from, to);
  if (result.bbox && !from && !to) {
    const diagonal = distance(
      point([result.bbox[0], result.bbox[1]]),
      point([result.bbox[2], result.bbox[3]]),
      {units: "meters"}
    );
    if (diagonal < 100) {
      return {
        type: "FeatureCollection",
        features: [],
      };
    }
  }

  return result;
}

export const speedColor = (value: number, max?: number): string => {
  max = max ?? 30;

  if (value < 0) {
    value = 0;
  } else if (value > max) {
    value = max;
  }

  const idx = Math.round(value / max * (SPEED_PALLET.length - 1));

  return SPEED_PALLET[idx];
}

// speedFeatures resturns GeoJSON FeatureCollection with lines colored
// according to the speed  of the device.
function speedFeatures(
  response: DeviceStatisticsResponse,
  from?: Date,
  to?: Date,
): FeatureCollection<LineString> {

  if (response.getLength() === 0) {
    return {
      type: "FeatureCollection",
      features: [],
    };
  }

  const speed = response.getSpeedList();
  const times = response.getTimeList();

  // List of features that will end up in the result.
  let features: Feature<LineString>[] = [];
  // Current line
  let part: Position[] = [];
  // Value of the previous point in the line.
  let previous_value: number | null = null;
  // Index of the last point
  // NOTE(nk2ge5k): This is needed because forEachPoint reomves some of the
  // points and does not pass them to the caller, therfore index-1 is not
  // the same as the previous index.
  let previous_index: number = 0;

  // finishPart is a helper function that adds current part of the line
  // to the list of features and start new line.
  const finishPart = () => {
    if (part.length >= 2) {
      features.push({
        type: "Feature",
        properties: { "line-color": speedColor(previous_value!) },
        geometry: {
          type: "LineString",
          coordinates: part,
        }
      });
    }

    part = [];
  };

  const bbox = forEachPoint(response, from, to, (position: Position, index: number): boolean => {
    const value = speed[index];
    if (previous_value !== null) {
      if (Math.abs(times[index] - times[previous_index]) > MAX_DURATION_BETWEEN_POINTS) {
        // If to much time passed between two points - separating the line.
        finishPart();
      } else if (part.length > 0 && distance(position, part[part.length - 1]) > 1) {
        return false;
      } else if (value !== previous_value) {
        // If value changed - separating the line but connecting them because
        // otherwise resulting line would be chopped.
        part.push(position);
        finishPart();
      }
    }

    previous_value = value;
    previous_index = index;
    part.push(position);

    return true;
  });

  if (part.length > 0) {
    finishPart();
  }

  return {
    type: "FeatureCollection",
    features: features,
    bbox: bbox,
  };
}

export { sensorFeatures, speedFeatures };
