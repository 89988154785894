import React from "react";
import RcDialog from "rc-dialog";
import "rc-dialog/assets/index.css";
import "./styles.css";
import { IDialogPropTypes } from "rc-dialog/lib/IDialogPropTypes";
import { ReactComponent as CloseIcon } from "@src/assets/icons/close.svg";
import Button from "@components/Button";
import { ErrorAlert } from "@components/AuthLayout";
import styled from "@emotion/styled";

interface IProps extends IDialogPropTypes {
  error?: string | null;
}

const StyledErrorAlert = styled(ErrorAlert)`
  position: absolute;
  top: -40px;
  left: 0;
  right: 0;
  width: auto;
`;

const Dialog: React.FC<IProps> = ({ children, error, closeIcon, ...rest }) => {
  return (
    <RcDialog
      closeIcon={
        closeIcon ?? (
          <Button
            kind="secondary"
            style={{ width: 36, height: 24, padding: 0 }}
          >
            <CloseIcon />
          </Button>
        )
      }
      animation="zoom"
      maskAnimation="fade"
      {...rest}
    >
      <StyledErrorAlert enabled={!!error}>{error}</StyledErrorAlert>
      {children}
    </RcDialog>
  );
}; //

export default Dialog;
