import styled from "@emotion/styled";
import React from "react";
import Text from "@components/Text";
import { observer } from "mobx-react-lite";
import { useLang } from "@src/hooks/useLang";
import { Column } from "@src/components/Flex";
import Spinner from "@components/Spinner";

interface IProps {}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  margin-bottom: 12px;
  padding: 0 16px;
  box-sizing: border-box;
  border-bottom: 1px solid rgba(137, 144, 147, 0.3);
`;

const LoadingFieldsMenu: React.FC<IProps> = () => {
  const lang = useLang();
  return (
    <Root>
      <Header>
        <Text color="#494C5B" type="heading" size={24}>
          {lang.menu.fieldMenuOption}
        </Text>
      </Header>
      <Column
        alignItems="center"
        justifyContent="center"
        mainAxisSize="stretch"
        crossAxisSize="max"
      >
        <Spinner />
      </Column>
    </Root>
  );
};
export default observer(LoadingFieldsMenu);
