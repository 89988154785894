import React from "react";
import Dialog from "@components/Dialog";
import Text from "@components/Text";
import SizedBox from "@components/SizedBox";
import { ReactComponent as SuccessIcon } from "@assets/icons/success.svg";
import { Column } from "@src/components/Flex";

interface IProps {
  visible: boolean;
  title: string;
  onClose: () => void;
}

const SuccessDialog: React.FC<IProps> = ({ title, ...rest }) => {
  return (
    <Dialog style={{ width: 228 }} {...rest} closeIcon={<div />}>
      <Column
        onClick={rest.onClose}
        justifyContent="center"
        alignItems="center"
        mainAxisSize="stretch"
        crossAxisSize="max"
      >
        <SizedBox height={16} />
        <SuccessIcon />
        <SizedBox height={18} />
        <Text
          style={{ textAlign: "center" }}
          type="heading"
          size={18}
          color="#494C5B"
        >
          {title}
        </Text>
      </Column>
    </Dialog>
  );
};
export default SuccessDialog;
