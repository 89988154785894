import React, { useState } from "react";
import Dialog from "@components/Dialog";
import SizedBox from "@components/SizedBox";
import Input from "@components/Input";
import { observer } from "mobx-react-lite";
import { useLang } from "@src/hooks/useLang";
import CategoriesSelect from "@screens/CropsScreen/CategoriesSelect";
import Button from "@components/Button";
import { Row } from "@src/components/Flex";
import { useCropsScreenVM } from "@screens/CropsScreen/CropsScreenVM";
import { TCategory } from "@services/fieldsService";
import Loading from "@components/Loading";

interface IProps {
  visible: boolean;
  onClose: () => void;
}

const AddCropDialog: React.FC<IProps> = ({ ...rest }) => {
  const lang = useLang();
  const vm = useCropsScreenVM();
  const [name, setName] = useState("");
  const [category, setCategory] = useState<TCategory | null>(null);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!name || category == null) return;
    setLoading(true);
    await vm.createCrop({ name, category_id: category.id });
    setLoading(false);
    rest.onClose();
  };

  return (
    <Dialog style={{ width: 336 }} title={lang.crops.addCropTitle} {...rest}>
      <Input
        value={name}
        onChange={(e) => setName(e.target.value)}
        label={lang.crops.addCropName}
      />
      <SizedBox height={16} />
      <CategoriesSelect
        value={category}
        categories={vm.categories}
        onChange={setCategory}
        label={lang.crops.addCropCategory}
      />

      <SizedBox height={24} />
      <Row>
        <Button kind="outline" onClick={rest.onClose}>
          {lang.crops.addCropCancelButton}
        </Button>
        <SizedBox width={16} />
        <Button onClick={handleSubmit} disabled={!category || !name}>
          {!loading ? lang.crops.addCropAddButton : <Loading />}
        </Button>
      </Row>
    </Dialog>
  );
};
export default observer(AddCropDialog);
