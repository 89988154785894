import React, { useState } from "react";
import Layout from "@components/Layout";
import Input from "@components/Input";
import styled from "@emotion/styled";
import { ReactComponent as SearchIcon } from "@assets/icons/search.svg";
import { ReactComponent as PlusIcon } from "@assets/icons/PlusIcon.svg";

import SizedBox from "@components/SizedBox";
import { Row } from "@src/components/Flex";
import Button from "@components/Button";
import AddCropDialog from "@screens/CropsScreen/dialogs/AddCropDialog";
import EditCropDialog from "@screens/CropsScreen/dialogs//EditCropDialog";
import RemoveConfirmDialog from "@screens/CropsScreen/dialogs/RemoveConfirmDialog";
import { Observer } from "mobx-react-lite";
import {
  CropsScreenVMProvider,
  useCropsScreenVM,
} from "@screens/CropsScreen/CropsScreenVM";
import CropsTable from "@screens/CropsScreen/CropsTable";
import { TCreateCropRequest } from "@services/fieldsService";
import { useStores } from "@stores";
import localizations from "@src/localizations";
import { pageView } from "@src/utils/analytics";

interface IProps { }

export const SearchInput = styled(Input)`
  height: 36px;
  width: 280px;
  padding-left: 32px;
  box-sizing: border-box;
  ::placeholder {
    font-family: Nunito Sans, sans-serif;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    color: #899093;
  }
`;

export const ColorBox = styled.div<{ color: string }>`
  width: 72px;
  height: 16px;
  border-radius: 2px;
  background-color: ${({ color }) => color};
`;

const CropsScreen = () => {
  pageView("Crops");
  return (
    <CropsScreenVMProvider>
      <CropsScreenImpl />
    </CropsScreenVMProvider>
  );
};

type TDialogState = { opened: boolean; id?: string };

const CropsScreenImpl: React.FC<IProps> = () => {
  const vm = useCropsScreenVM();
  const { settingsStore } = useStores();
  const [addOpened, setAddOpened] = useState(false);
  const [editDialogState, setEditDialogState] = useState<TDialogState>({
    opened: false,
  });
  const [removeDialogState, setRemoveDialogState] = useState<TDialogState>({
    opened: false,
  });

  const handleRemoveCrop = () => {
    if (removeDialogState.id == null) return;
    vm.removeCrop(removeDialogState.id).finally(() =>
      setRemoveDialogState({ opened: false, id: undefined })
    );
  };

  const handleEditCrop = (id: string, crop: TCreateCropRequest) => {
    if (editDialogState.id == null) return;
    vm.editCrop(editDialogState.id, crop).finally(() =>
      setEditDialogState({ opened: false, id: undefined })
    );
  };

  return (
    <Observer>
      {() => {
        const lang = localizations[settingsStore.language];
        return (
          <>
            <Layout title={lang.crops.cropsTitle}>
              <Row>
                <SearchInput
                  value={vm.search}
                  onChange={(e) => vm.setSearch(e.target.value)}
                  placeholder={lang.crops.searchFill}
                  icon={<SearchIcon />}
                />
                <Button
                  style={{ width: "auto", whiteSpace: "nowrap" }}
                  onClick={() => setAddOpened(true)}
                >
                  {lang.crops.addCropButton}{" "}
                  <PlusIcon style={{ marginLeft: 6 }} />
                </Button>
              </Row>
              <SizedBox height={16} />
              <CropsTable
                onRemoveClick={(id) =>
                  setRemoveDialogState({ opened: true, id })
                }
                onEditClick={(id) => {
                  setEditDialogState({ opened: true, id });
                }}
              />
            </Layout>
            <AddCropDialog
              visible={addOpened}
              onClose={() => setAddOpened(false)}
            />
            <EditCropDialog
              id={editDialogState.id}
              visible={editDialogState.opened}
              onSubmit={handleEditCrop}
              onClose={() =>
                setEditDialogState({ opened: false, id: undefined })
              }
            />
            <RemoveConfirmDialog
              id={removeDialogState.id}
              visible={removeDialogState.opened}
              onSubmit={handleRemoveCrop}
              onClose={() =>
                setRemoveDialogState({ opened: false, id: undefined })
              }
            />
          </>
        );
      }}
    </Observer>
  );
};
export default CropsScreen;
