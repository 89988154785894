import styled from "@emotion/styled";
import React, { useState } from "react";
import Text from "@components/Text";
import FieldRow from "@screens/FieldsScreen/FieldsMenu/FieldRow";
import {
  getFieldArea,
  useFieldsScreenVM,
} from "@screens/FieldsScreen/FieldsScreenVM";
import { observer } from "mobx-react-lite";
import { useLang } from "@src/hooks/useLang";
import SizedBox from "@components/SizedBox";
import { ReactComponent as BackIcon } from "@assets/icons/dubleArrowLeft.svg";
import Button from "@components/Button";
import { Column, Row } from "@src/components/Flex";
import { ReactComponent as DeleteIcon } from "@assets/icons/trash.svg";
import Input from "@components/Input";
import Select from "@components/Select";
import { TFieldProperties } from "@services/fieldsService";

interface IProps {}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 16px;
`;

const StyledFieldRow = styled(FieldRow)`
  border-top: none;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  cursor: auto;
  :hover {
    background: #fff;
  }
`;

const EditFieldsMenu: React.FC<IProps> = () => {
  const lang = useLang();
  const vm = useFieldsScreenVM();
  const field = vm.selectedField;
  const properties = field?.getProperties() as TFieldProperties | undefined;
  const id = field?.getId();
  const geometry = field?.getGeometry();
  const [name, setName] = useState<string>(properties?.name ?? "");
  const [cropId, setCropId] = useState<string>(properties?.culture_id ?? "");
  const handleSave = () => vm.save({ name, cropId });
  return (
    <Root>
      <Column crossAxisSize="max">
        <Row alignItems="center" style={{ cursor: "pointer" }}>
          <BackIcon style={{ marginRight: 4 }} />
          <Text size={12} color="#899093" onClick={vm.handleCancel}>
            {lang.fields.fieldEdintPointer}
          </Text>
        </Row>
        <SizedBox height={24} />
        <StyledFieldRow
          id={id}
          geometry={geometry}
          title={name}
          crop={vm.crops.find(({ id }) => id === cropId)?.name}
          area={properties?.area ?? getFieldArea(field ?? undefined)}
          extra={
            properties?.id && (
              <DeleteIcon
                style={{ flexShrink: 0, cursor: "pointer" }}
                onClick={() => id && vm.deleteField(id)}
              />
            )
          }
        />
        <SizedBox height={16} />
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          label={lang.fields.newFieldName}
        />
        <SizedBox height={16} />
        <Select
          label={lang.fields.newFieldCrop}
          value={cropId}
          onChange={setCropId}
          options={vm.crops.map((crop) => ({
            title: crop.name,
            value: crop.id,
          }))}
        />
        <SizedBox height={16} />
        {/*<Input*/}
        {/*  value={hybrid}*/}
        {/*  onChange={(e) => setHybrid(e.target.value)}*/}
        {/*  label={lang.fields.newFieldHybrid}*/}
        {/*/>*/}
      </Column>
      <Row>
        <Button kind="outline" onClick={vm.handleCancel}>
          {lang.fields.drawFieldsCancelButton}
        </Button>
        <SizedBox width={8} />
        <Button onClick={handleSave}>{lang.fields.drawFieldsOkButton}</Button>
      </Row>
    </Root>
  );
};
export default observer(EditFieldsMenu);
