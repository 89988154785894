import React, { useRef, useState } from "react";

import moment from "moment";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { toast } from "react-toastify";

import CreateSeasonDialog from "@components/Layout/Seasons/CreateSeasonDialog";
import LeftBarSettingsMenuItem from "@components/Layout/LeftBar/LeftBarSettingsMenuItem";
import Text from "@components/Text";
import useOnClickOutside from "@src/hooks/useOnClickOutside";
import { Divider } from "@components/Layout/LeftBar/LeftBarSettings";
import { ReactComponent as DirectionIcon } from "@assets/icons/direction.svg";
import { ReactComponent as PlusIcon } from "@assets/icons/PlusIcon.svg";
import { Row } from "@components/Flex";
import { useLang } from "@src/hooks/useLang";
import { useSeasonsVM } from "@stores/SeasonStore";

interface IProps {
  expanded: boolean;
  menuWidth: number;
}

const Root = styled(Row)`
  display: flex;
  align-items: center;
  height: 38px;
  cursor: pointer;
  margin-top: 6px;
  overflow: hidden;
`;

const SeasonText = styled(Text)`
  font-size: 9px;
  line-height: 0;
  padding-bottom: 2px;
`;

const Tooltip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  transition: 0.4s;
  overflow: hidden;
  //height: 92px;
  top: 0;
  background: #ffffff;
  box-shadow: 0 2px 20px rgba(78, 78, 81, 0.2);
  border-radius: 6px 6px 6px 0;
`;

const SeasonsImpl: React.FC<IProps> = observer(({ expanded, menuWidth }) => {
  const lang = useLang();
  const vm = useSeasonsVM();

  const [dialogOpened, setDialogOpened] = useState(false);
  const [tooltipOpened, setTooltipOpened] = useState(false);

  const offset = expanded ? menuWidth : 52;
  const ref = useRef(null);

  useOnClickOutside(ref, () => setTooltipOpened(false));

  if (vm.loading) {
    return (
      <div style={{ position: "relative" }}>
        <Root justifyContent="center">
        </Root>
      </div>
    );
  }

  return (
    <div style={{ position: "relative" }}>
      <Root justifyContent={expanded ? "space-between" : "center"}>
        {vm.currentSeason ? (
          <div style={{ width: "100%" }} ref={ref}>
            <Row
              alignItems="center"
              justifyContent="space-between"
              onClick={() => setTooltipOpened(!tooltipOpened)}
            >
              {expanded && (
                <div style={{ width: "100%" }}>
                  <SeasonText type="heading" color="#899093">
                    {lang.menu.sesonTitle}
                  </SeasonText>
                  <Text type="heading" color="#494C5B" size={12}>
                    {vm.currentSeason.name}
                  </Text>
                </div>
              )}
              <DirectionIcon style={{ minWidth: 24 }} />
            </Row>
            <Tooltip
              style={{ left: offset - 12, width: tooltipOpened ? 208 : 0 }}
            >
              {vm.seasons.map((s) => {
                const active = s.id === vm.currentSeasonId;

                if (s.default) {
                  return (
                    <LeftBarSettingsMenuItem
                      key={s.id}
                      style={{ "cursor": active ? "default" : "pointer" }}
                      className={active ? "active" : ""}
                      title={lang.seasons.defaultSeasonName}
                      onClick={() => {
                        if (!active) {
                          vm.setCurrentSeasonId(s.id)
                          setTooltipOpened(false);
                        }
                      }}
                    />
                  );
                }

                const from = moment(s.from);
                const to = moment(s.to);
                const desc = from.format("MMM D YYYY") + " → " + to.format("MMM D YYYY");

                return (
                  <LeftBarSettingsMenuItem
                    key={s.id}
                    style={{ "cursor": active ? "default" : "pointer" }}
                    className={active ? "active" : ""}
                    title={s.name}
                    value={desc}
                    onClick={() => {
                      if (!active) {
                        vm.setCurrentSeasonId(s.id);
                        setTooltipOpened(false);
                      }
                    }}
                  />
                );
              })}
              <Divider />
              <LeftBarSettingsMenuItem
                onClick={() => {
                  setDialogOpened(true)
                  setTooltipOpened(false);
                }}
                title={lang.menu.newSeasonButton}
                icon={<PlusIcon style={{ marginRight: 16 }} />}
              />
            </Tooltip>
          </div>
        ) : (
          <Row onClick={() => setDialogOpened(true)} alignItems="center">
            {expanded && (
              <Text type="heading" color="#494C5B" size={12}>
                {lang.seasons.createSeasonButton}
              </Text>
            )}
            <PlusIcon style={{ minWidth: 24 }} />
          </Row>
        )}
        <CreateSeasonDialog
          visible={dialogOpened}
          endOfTheLastSeason={vm.seasons.find((s) => !s.default)?.to}
          onSubmit={(from, to) => {
            vm.createSeason(from, to, vm.currentSeasonId).then(() => {
              setDialogOpened(false);
            }).catch((e) => {
              console.error("Failed to create season", e);
              toast.error(e.message ?? e.toString());
            });
          }}
          onClose={() => setDialogOpened(false)}
        />
      </Root>
    </div>
  );
});

const Seasons: React.FC<IProps> = (props) => {
  return <SeasonsImpl {...props} />;
}

export default observer(Seasons);
