import * as geojson from "geojson";

import * as gb from "@proto/types/v1/geobuf_pb";

import { precision as codec, ConvertFn } from "./precision";

const noop = (i: number) => { return i };

type Point = [number, number];
type Line = Point[];

const pointReader = (coords: number[], conv: ConvertFn): (i?: number) => Point => {
  let cursor = 0;
  return (i?: number): [number, number] => {
    if (coords.length < cursor + 2) {
      if (i) {
        throw new Error(`not enough coordinates to read point ${i}`);
      }
      throw new Error("not enough coordinates to read point");
    }
    const result: Point = [
      conv(coords[cursor]),
      conv(coords[cursor + 1]),
    ];
    cursor += 2;
    return result;
  };
};

const lineReader = (coords: number[], conv: ConvertFn): (length: number) => [Line, geojson.BBox] => {
  const readPoint = pointReader(coords, conv);
  return (length: number): [Line, geojson.BBox] => {
    let line: Line = [];
    let bbox: geojson.BBox = [Infinity, Infinity, -Infinity, -Infinity];
    for (let i = 0; i < length; i++) {
      const point = readPoint(i);
      bbox = [
        Math.min(bbox[0], point[0]),
        Math.min(bbox[1], point[1]),
        Math.max(bbox[2], point[0]),
        Math.max(bbox[3], point[1]),
      ];
      line.push(point);
    }

    return [line, bbox];
  };
};

class GeobufDecoder {
  // Map of properties indicies to their names
  private props: { [key: number]: string };
  // Coordinates encoder
  private codec: ConvertFn;

  constructor() {
    this.props = {};
    this.codec = noop;
  }

  // decode attempts to convert Geobuf to GeoJSON representation, may throw
  // error if Geobuf data is invalid.
  public decode(buf: gb.Geobuf): geojson.GeoJSON {
    this.props = {};

    // Create coordinates conversion function
    const { decode } = codec(buf.getPrecision());
    this.codec = decode;

    buf.getKeysList().forEach((k, i) => { this.props[i] = k; });

    switch (buf.getDataTypeCase()) {
      case gb.Geobuf.DataTypeCase.FEATURE_COLLECTION:
        return this.decodeFeatureCollection(buf.getFeatureCollection()!);
      case gb.Geobuf.DataTypeCase.FEATURE:
        return this.decodeFeature(buf.getFeature()!);
      case gb.Geobuf.DataTypeCase.GEOMETRY:
        return this.decodeGeometry(buf.getGeometry()!);
      default:
        throw new Error("unknown Geobuf data type");
    }
  }

  // decodeFeatureCollection decodes geobuf.FeatureCollection to GeoJSON format.
  private decodeFeatureCollection(fc: gb.FeatureCollection): geojson.FeatureCollection {
    return {
      type: "FeatureCollection",
      features: fc.getFeaturesList().map((feature) => {
        return this.decodeFeature(feature);
      }),
    };
  }

  // decodFeature decodes geobuf.Feature to GeoJSON format.
  private decodeFeature(f: gb.Feature): geojson.Feature {
    return {
      id: f.getId(),
      type: "Feature",
      geometry: this.decodeGeometry(f.getGeometry()!),
      properties: this.decodeProperties(f.getProperties()),
    };
  }

  // decodFeature decodes geobuf.Geometry to GeoJSON format.
  private decodeGeometry(g: gb.Geometry): geojson.Geometry {
    switch (g.getType()) {
      case gb.GeometryType.GEOMETRY_TYPE_POINT:
        return this.decodePoint(g);
      case gb.GeometryType.GEOMETRY_TYPE_MULTIPOINT:
        return this.decodeMultiPoint(g);
      case gb.GeometryType.GEOMETRY_TYPE_LINESTRING:
        return this.decodeLineString(g);
      case gb.GeometryType.GEOMETRY_TYPE_MULTILINESTRING:
        return this.decodeMultiLineString(g);
      case gb.GeometryType.GEOMETRY_TYPE_POLYGON:
        return this.decodePolygon(g);
      case gb.GeometryType.GEOMETRY_TYPE_MULTIPOLYGON:
        return this.decodeMultiPolygon(g);
      case gb.GeometryType.GEOMETRY_TYPE_GEOMETRYCOLLECTION:
        return {
          type: "GeometryCollection",
          geometries: g.getGeometriesList().map((sg) => {
            return this.decodeGeometry(sg);
          }),
        };
      default:
        throw new Error("unexpected geometry type");
    }
  }

  private decodeProperties(p: gb.Properties | undefined): geojson.GeoJsonProperties {
    let result: { [name: string]: any } = {};

    if (!p) {
      return result;
    }

    const keys = p.getKeysList();
    const vals = p.getValuesList();

    for (let i = 0; i < Math.min(keys.length, vals.length); i++) {
      const kn = keys[i];
      if (kn in this.props) {
        const key = this.props[kn];

        switch (vals[i].getValueTypeCase()) {
          case gb.Value.ValueTypeCase.STRING_VALUE:
            result[key] = vals[i].getStringValue();
            break;
          case gb.Value.ValueTypeCase.DOUBLE_VALUE:
            result[key] = vals[i].getDoubleValue();
            break;
          case gb.Value.ValueTypeCase.INTEGER_VALUE:
            result[key] = vals[i].getIntegerValue();
            break;
          case gb.Value.ValueTypeCase.BOOL_VALUE:
            result[key] = vals[i].getBoolValue();
            break;
        }
      }
    }

    return result;
  }

  // decodePoint attempts to decode Point from geobuf.Geometry
  private decodePoint(g: gb.Geometry): geojson.Point {
    const read = pointReader(g.getCoordsList(), this.codec);
    return {
      type: "Point",
      coordinates: read(),
    };
  }

  // decodeMultiPoint attempts to decode MultiPoint from geobuf.Geometry
  private decodeMultiPoint(g: gb.Geometry): geojson.MultiPoint {
    const lengths = g.getLengthsList();
    if (lengths.length < 1) {
      throw new Error("Cannot decode MultiPoint: not enough lengths");
    }

    const npoint = lengths[0];
    const coords = g.getCoordsList();

    if (npoint * 2 > coords.length) {
      throw new Error("Cannot decoode MultiPoint: not enough coodrds");
    }

    const readLine = lineReader(coords, this.codec);
    const [line, bbox] = readLine(npoint);
    return {
      type: "MultiPoint",
      coordinates: line,
      bbox: bbox,
    };
  }

  // decodeLineString attempts to decode LineString from geobuf.Geometry
  private decodeLineString(g: gb.Geometry): geojson.LineString {
    const lengths = g.getLengthsList();
    if (lengths.length < 1) {
      throw new Error("Cannot decode LineString: not enough lengths");
    }

    const npoint = lengths[0];
    const coords = g.getCoordsList();

    if (npoint * 2 > coords.length) {
      throw new Error("Cannot decoode LineString: not enough coodrds");
    }

    const readLine = lineReader(coords, this.codec);
    const [line, bbox] = readLine(npoint);
    return {
      type: "LineString",
      coordinates: line,
      bbox: bbox,
    };
  }

  // decodeMultiLineString attempts to decode LineString from geobuf.Geometry
  private decodeMultiLineString(g: gb.Geometry): geojson.MultiLineString {
    const lengths = g.getLengthsList();
    if (lengths.length < 2) {
      throw new Error("Cannot decode MultiLineString: not enough lengths");
    }

    const nlines = lengths[0];
    if (nlines > lengths.length - 1) {
      throw new Error("Cannot decode MultiLineString: not enough lengths");
    }

    const readLine = lineReader(g.getCoordsList(), this.codec);

    let lines: Line[] = [];
    let bbox: geojson.BBox = [Infinity, Infinity, -Infinity, -Infinity];
    // NOTE(nk2ge5k): loop starts form the 1 because we want to use i as the
    // index of lengths array.
    for (let i = 1; i < (nlines + 1); i++) {
      const [line, lbox] = readLine(lengths[i]);
      bbox = [
        Math.min(bbox[0], lbox[0]),
        Math.min(bbox[1], lbox[1]),
        Math.max(bbox[2], lbox[2]),
        Math.max(bbox[3], lbox[3]),
      ];
      lines.push(line);
    }

    return {
      type: "MultiLineString",
      coordinates: lines,
      bbox: bbox,
    };
  }

  // decodePolygon attemtps to decode Polygon from geobuf.Geometry
  private decodePolygon(g: gb.Geometry): geojson.Polygon {
    const lengths = g.getLengthsList();
    if (lengths.length < 2) {
      throw new Error("Cannot decode Polygon: not enough lengths");
    }

    const nlines = lengths[0];
    if (nlines > lengths.length - 1) {
      throw new Error("Cannot decode Polygon: not enough lengths");
    }

    const readLine = lineReader(g.getCoordsList(), this.codec);

    let lines: Line[] = [];
    let bbox: geojson.BBox = [Infinity, Infinity, -Infinity, -Infinity];
    // NOTE(nk2ge5k): loop starts form the 1 because we want to use i as the
    // index of lengths array.
    for (let i = 1; i < (nlines + 1); i++) {
      const [line, lbox] = readLine(lengths[i]);
      bbox = [
        Math.min(bbox[0], lbox[0]),
        Math.min(bbox[1], lbox[1]),
        Math.max(bbox[2], lbox[2]),
        Math.max(bbox[3], lbox[3]),
      ];
      lines.push(line);
    }

    return {
      type: "Polygon",
      coordinates: lines,
      bbox: bbox,
    };
  }

  // decodeMultiPolygon attemtps to decode MultiPolygon from geobuf.Geometry
  private decodeMultiPolygon(g: gb.Geometry): geojson.MultiPolygon {
    const lengths = g.getLengthsList();
    if (lengths.length < 2) {
      throw new Error("Cannot decode MultiPolygon: not enough lengths");
    }

    const npolygon = lengths[0];
    const readLine = lineReader(g.getCoordsList(), this.codec);

    let cursor = 1;
    let coordinates: Line[][] = [];
    let bbox: geojson.BBox = [Infinity, Infinity, -Infinity, -Infinity];

    for (let i = 0; i < npolygon; i++) {
      if (lengths.length <= cursor) {
        throw new Error("Cannot decode MultiPolygon: not enough lengths");
      }

      const nlines = lengths[cursor];
      cursor++;

      if (lengths.length < cursor + nlines) {
        throw new Error("Cannot decode MultiPolygon: not enough lengths");
      }

      let polygon: Line[] = [];
      for (let j = 0; j < nlines; j++) {
        const [line, lbox] = readLine(lengths[cursor]);
        bbox = [
          Math.min(bbox[0], lbox[0]),
          Math.min(bbox[1], lbox[1]),
          Math.max(bbox[2], lbox[2]),
          Math.max(bbox[3], lbox[3]),
        ];
        polygon.push(line);
        cursor++;
      }

      coordinates.push(polygon);
    }

    return {
      type: "MultiPolygon",
      coordinates: coordinates,
      bbox: bbox,
    };
  }
}

export const decode = (src: gb.Geobuf): geojson.GeoJSON => {
  const decoder = new GeobufDecoder();
  return decoder.decode(src);
};
