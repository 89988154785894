import React from "react";
import { Column, Row } from "@components/Flex";
import Text from "@components/Text";
import SizedBox from "@components/SizedBox";
import Input from "@components/Input";
import Button from "@components/Button";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { useLang } from "@src/hooks/useLang";
import { useProfileScreenVM } from "@screens/ProfileScreen/ProfileScreenVM";
import {
  TProfileContacts,
  TProfileName,
  TProfileOrganization,
} from "@services/profileService";
import Loading from "@components/Loading";

interface IProps {
  loading?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: space-between;
`;

const InputGrid = styled.div`
  width: 100%;
  display: grid;
  column-gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
`;

const ProfileScreenForm: React.FC<IProps> = ({ loading, onSave, onCancel }) => {
  const lang = useLang();
  const vm = useProfileScreenVM();
  const setName = (value: Partial<TProfileName>) =>
    vm.formData && vm.setFormData({ ...vm.formData, ...value });

  const setContacts = (value: Partial<TProfileContacts>) =>
    vm.formData &&
    vm.setFormData({
      ...vm.formData,
      contacts: { ...vm.formData.contacts, ...value },
    });

  const setOrganization = (value: Partial<TProfileOrganization>) =>
    vm.formData &&
    vm.setFormData({
      ...vm.formData,
      organization: { ...vm.formData.organization, ...value },
    });

  return (
    <Root>
      <Column crossAxisSize="max">
        <Text
          type="heading"
          size={11}
          color="#899093"
          style={{ textTransform: "uppercase" }}
        >
          {/* @ts-ignore*/}
          {lang.profile.companyTitle}
        </Text>
        <SizedBox height={8} />
        <InputGrid>
          <Input
            value={vm.formData?.organization?.name}
            onChange={(e) => setOrganization({ name: e.target.value })}
            style={{ flex: 1, height: 36 }}
            placeholder={lang.profile.organizationNamePlaceholder}
            label={lang.profile.organizationNameTitle}
          />
          <Input
            value={vm.formData?.contacts?.phone}
            onChange={(e) => setContacts({ phone: e.target.value })}
            style={{ flex: 1, height: 36 }}
            placeholder={lang.profile.contactPhoneFill}
            label={lang.profile.contactPhoneTitle}
          />
          <Input
            value={vm.formData?.contacts?.email}
            onChange={(e) => setContacts({ email: e.target.value })}
            style={{ flex: 1, height: 36 }}
            placeholder={lang.profile.emailFill}
            label={lang.profile.emailTitle}
          />
        </InputGrid>
        <SizedBox height={24} />
        <Text
          type="heading"
          size={11}
          color="#899093"
          style={{ textTransform: "uppercase" }}
        >
          {lang.profile.personalTitle}
        </Text>
        <SizedBox height={8} />
        <InputGrid>
          {/*<Input*/}
          {/*  value={vm.formData?.last_name}*/}
          {/*  onChange={(e) => setName({ last_name: e.target.value })}*/}
          {/*  style={{ flex: 1, height: 36 }}*/}
          {/*  placeholder={lang.profile.lastNameFill}*/}
          {/*  label={lang.profile.lastNameTitle}*/}
          {/*/>*/}
          {/*<SizedBox width={16} />*/}
          <Input
            value={vm.formData?.first_name}
            onChange={(e) => setName({ first_name: e.target.value })}
            style={{ flex: 1, height: 36 }}
            placeholder={lang.profile.nameFill}
            label={lang.profile.nameTitle}
          />
          {/*{settingsStore.language === LANGUAGE.RU && (*/}
          {/*  <React.Fragment>*/}
          {/*    <SizedBox width={16} />*/}
          {/*    <Input*/}
          {/*      value={vm.formData?.middle_name}*/}
          {/*      onChange={(e) => setName({ middle_name: e.target.value })}*/}
          {/*      style={{ flex: 1, height: 36 }}*/}
          {/*      placeholder={lang.profile.middleNameFill}*/}
          {/*      label={lang.profile.middleNameTittle}*/}
          {/*    />*/}
          {/*  </React.Fragment>*/}
          {/*)}*/}
        </InputGrid>
      </Column>
      <Row justifyContent="flex-end">
        <Button
          onClick={onCancel}
          kind="outline"
          style={{ padding: "0 20px", maxWidth: "fit-content" }}
        >
          {lang.profile.profileCancelButton}
        </Button>
        <SizedBox width={8} />
        <Button
          disabled={loading}
          onClick={onSave}
          style={{ padding: "0 16px", width: 210 }}
        >
          {!loading ? lang.profile.profileSaveButton : <Loading />}
        </Button>
      </Row>
    </Root>
  );
};
export default observer(ProfileScreenForm);
