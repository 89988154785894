import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { MapBrowserEvent } from "ol";
import { useFieldsScreenVM } from "@screens/FieldsScreen/FieldsScreenVM";
import { observer } from "mobx-react-lite";
import MapToolbar from "@screens/FieldsScreen/MapToolbar";
import FieldViewSelect from "@screens/FieldsScreen/FieldViewSelect";
import Spinner from "@components/Spinner";
import MapYieldLegend from "@screens/FieldsScreen/MapYieldLegend";
import MapYieldStatistics from "@screens/FieldsScreen/MapYieldStatistics";

const Root = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  & > .ol-viewport {
    height: 100vh !important;
  }
`;

const targetId = "map-holder";

const LoadingLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  background: rgba(256, 256, 256, 0.5);
`;

const Map = () => {
  const vm = useFieldsScreenVM();

  const handleMapClick = (e: MapBrowserEvent<any>) =>
    vm.map.forEachFeatureAtPixel(
      e.pixel,
      (feature, layer) =>
        layer === vm.fieldsLayer && vm.selectField(feature.getId() as string)
    );

  useEffect(() => {
    vm.map.setTarget(targetId);
    vm.map.on("click", handleMapClick);
    // vm.map.on("moveend", () =>
    //   console.log({
    //     center: vm.map.getView().getCenter(),
    //     zoom: vm.map.getView().getZoom(),
    //   })
    // );
  });
  return (
    <Root id={targetId}>
      <MapToolbar />
      <FieldViewSelect />
      <MapYieldLegend />
      <MapYieldStatistics />
      {vm.loading && (
        <LoadingLayout>
          <Spinner />
        </LoadingLayout>
      )}
    </Root>
  );
};

export default observer(Map);
