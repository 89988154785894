import styled from "@emotion/styled";
import React from "react";
import { Row } from "@components/Flex";
import Card from "@components/Card";
import DeviceRow from "@screens/DevicesScreen/DeviceRow";
import SizedBox from "@components/SizedBox";
import { useLang } from "@src/hooks/useLang";
import { useDevicesScreenVM } from "@screens/DevicesScreen/DevicesScreenVM";
import { observer } from "mobx-react-lite";
import { TDevice } from "@services/devicesService";

interface IProps {}

const Root = styled(Row)`
  margin-top: 24px;
`;

const DevicesList: React.FC<IProps> = () => {
  const lang = useLang();
  const vm = useDevicesScreenVM();
  const filter = (device: TDevice) =>
    vm.search == null || vm.search === ""
      ? true
      : [
          device.harvester.name.toLocaleLowerCase(),
          device.harvester.model.toLocaleLowerCase(),
        ].some((v) => v.includes(vm.search.toLocaleLowerCase()));

  return (
    <Root crossAxisSize="max">
      <Card
        style={{ flex: 1 }}
        title={`${lang.devices.onlineDevicesTitle} (${vm.activeDevices.length})`}
      >
        {vm.activeDevices.filter(filter).map((device) => (
          <DeviceRow key={device.id} device={device} />
        ))}
      </Card>
      <SizedBox width={14} />
      <Card
        style={{ flex: 1 }}
        title={`${lang.devices.offlineDevicesTitle} (${vm.inactiveDevices.length})`}
      >
        {vm.inactiveDevices.filter(filter).map((device) => (
          <DeviceRow key={device.id} device={device} />
        ))}
      </Card>
    </Root>
  );
};
export default observer(DevicesList);
