export type TProfileContacts = {
  email: string;
  phone?: string;
};

export type TProfileOrganization = {
  name?: string;
};

export type TProfileName = {
  first_name?: string;
  middle_name?: string;
  last_name?: string;
};

export type TProfile = {
  contacts: TProfileContacts;
  organization?: TProfileOrganization;
} & TProfileName;

export const getProfileData = async (): Promise<TProfile> => {
  const request = "https://at.greengrowth.tech/api/people/v1/user/profile";
  const response = await fetch(request, { credentials: "include" });
  return (await response.json()).profile;
};

export const updateProfileData = async (
  profile: TProfile
): Promise<TProfile> => {
  const request = "https://at.greengrowth.tech/api/people/v1/user/profile";
  const body = JSON.stringify({ profile });
  const response = await fetch(request, {
    method: "PATCH", credentials: "include", body
  });
  if (response.status !== 200) throw new Error("Cannot update data");
  const res = JSON.parse(await response.text());
  return res.profile;
};

export type TUpdatePassRequestData = {
  verification: string;
  password: string;
};

export const updatePassword = async (data: TUpdatePassRequestData) => {
  const req = "https://at.greengrowth.tech/api/people/v1/user/profile/password";
  const body = JSON.stringify(data);
  const response = await fetch(req, {
    method: "POST", credentials: "include", body,
  });
  if (response.status !== 204) throw new Error((await response.json()).message);
};
