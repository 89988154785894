import React from "react";

import styled from "@emotion/styled";

import Text, { TTextSize } from "@components/Text";
import { ReactComponent as ArrowDownIcon } from "@assets/icons/arrowDown.svg";

const Root = styled.div<{ opened?: boolean }>`
  display: flex;
  align-items: center;
  position: absolute;
  inset: 18px auto auto 24px;
  padding: 6px 8px 6px 12px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0 2px 20px rgba(78, 78, 81, 0.1);
  border-radius: 4px;
  z-index: 1;
  cursor: pointer;
  transition: 0.4s;
  min-width: 200px;
  & > .arrow {
    min-width: 20px;
    height: auto;
    transition: 0.4s;
    transform: rotate(${({ opened }) => (opened ? -90 : 0)}deg);
    path {
      fill: #494c5b;
    }
  }
`;

const Tooltip = styled.div<{ opened: boolean }>`
  width: fit-content;
  position: absolute;
  left: 24px;
  top: 54px;
  background: #ffffff;
  height: ${({ opened }) => (opened ? "auto" : 0)};
  overflow: ${({ opened }) => (opened ? "auto" : "hidden")};
  border: ${({ opened }) => (opened ? 1 : 0)}px solid #cbd5d8;
  padding: ${({ opened }) => (opened ? "8px 0" : "0")};
  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  max-height: 300px;
  overflow: auto;
  transition: 0.4s;
  z-index: 1;
  min-width: 200px;
`;

const SelectRow = styled(Text) <{ selected?: boolean }>`
  padding: 6px 8px;
  box-sizing: border-box;
  cursor: pointer;
  background: ${({ selected }) => (selected ? "#f8f8f8" : "#fff")};
  transition: 0.4s;
  width: 100%;
  :hover {
    background: #f8f8f8;
  }
`;


// Option is a configuration for a single dropdown option.
export type Option = {
  // Human-readable title of the option
  title: string;
  // Machine value of the option
  value: string;
};

export type DropdownOptions = {
  // Dropdown title/hint
  title?: string;
  // Dropdown options from which user can select.
  // NOTE(nk2ge5k): first value is used as default value
  options: Option[];
  // selected option value
  selected?: string,
  // Callback that will be triggered when user selects an option.
  onSelect: (value: string) => void,
}

const Dropdown: React.FC<DropdownOptions> = ({ title, options, selected, onSelect }) => {
  let selectedOption = options.find((option) => option.value === selected) ?? options[0];

  const [opened, setOpened] = React.useState(false);
  const [current, setSelected] = React.useState(selectedOption);

  if (options.length === 0) {
    return null;
  }

  // NOTE(nk2ge5k): do not blame me it is a copypasted code which i do not
  // understand completely
  const textProps = {
    color: "#494C5B",
    size: 14 as TTextSize,
    nowrap: true,
    fitContent: true,
  };

  return (
    <div>
      <Root onClick={() => setOpened(!opened)} opened={opened}>
        {title && (
          <>
            <Text {...textProps} type="heading">{title}:</Text>
            &nbsp;
          </>
        )}
        <Text color="#494C5B" size={14} nowrap>{current.title}</Text>
        <ArrowDownIcon className="arrow" />
      </Root>
      <Tooltip opened={opened}>
        {
          options.map((option) => {
            return (
              <SelectRow
                key={option.value}
                {...textProps}
                onClick={() => {
                  setSelected(option);
                  onSelect(option.value);
                  setOpened(false);
                }}
                selected={current.value === option.value}
              >
                {option.title}
              </SelectRow>
            );
          })
        }
      </Tooltip>
    </div>
  );
};

export default Dropdown;
