import React, { useState } from "react";
import AuthLayout from "@components/AuthLayout";
import Input from "@components/Input";
import SizedBox from "@components/SizedBox";
import Button from "@components/Button";
import Text from "@components/Text";
import validateEmail from "@src/utils/validateEmail";
import { ReactComponent as ArrowLeftIcon } from "@assets/icons/arrowLeft.svg";
import { Row } from "@src/components/Flex";
import { Link } from "react-router-dom";
import { ROUTES } from "@stores/AccountStore";
import { useLang } from "@src/hooks/useLang";
import { observer } from "mobx-react-lite";
import { pageView } from "@src/utils/analytics";

interface IProps { }

const PasswordResetScreen: React.FC<IProps> = () => {
  pageView("ForgotPassword");

  const lang = useLang();
  const [email, setEmail] = useState("");
  const disabled = !validateEmail(email);
  const handleSubmit = () => {
    if (disabled) return;
    alert(`Reset password data:\nEmail: ${email}`);
  };
  return (
    <AuthLayout disableTabs>
      <Link to={ROUTES.LOGIN} style={{ textDecoration: "none" }}>
        <Row alignItems="center" style={{ cursor: "pointer", marginTop: 16 }}>
          <ArrowLeftIcon style={{ marginRight: 10 }} />
          <Text type="heading" size={15} color="#181B1F">
            {lang.auth.restoreTitle}
          </Text>
        </Row>
      </Link>
      <SizedBox height={24} />
      <Text color="#899093" size={14}>
        {lang.auth.restorePasswordEmail}
      </Text>
      <SizedBox height={16} />
      <Input
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        placeholder={lang.auth.emailField}
        label={lang.auth.emailTitle}
        type="email"
      />
      <SizedBox height={32} />
      <Button onClick={handleSubmit} disabled={disabled}>
        {/*@ts-ignore*/}
        {lang.auth.restorePasswordButton}
      </Button>
    </AuthLayout>
  );
};
export default observer(PasswordResetScreen);
