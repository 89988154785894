import moment from "moment";
import geojson from "geojson";
import { Timestamp } from 'google-protobuf/google/protobuf/timestamp_pb';
import { rgb } from "d3-color";

import { Feature } from "geojson";
import { Coordinate } from "ol/coordinate";
import { APIClient } from "@proto/apis/fields/v1/ApiServiceClientPb";
import { encode, decode } from "@geobuf";
import {
  FieldsRequest,
  FieldCreateRequest,
  FieldUpdateRequest,
  Tag,
  TagType,
  SeasonCreateRequest,
  SeasonRequest,
  SeasonsRequest,
} from "@proto/apis/fields/v1/api_pb";

import * as gb from "@proto/types/v1/geobuf_pb";
export * from "@proto/apis/fields/v1/api_pb";

const BASE_URL = "https://at.greengrowth.tech";

const FieldsClient = new APIClient(BASE_URL, null, null);

export type TFieldGeometry = {
  type: string;
  coordinates: Array<Array<Array<Coordinate>>>;
};

export type TFieldStats = {
  min: number;
  max: number;
  avg: number;
  mass_kg: number;
};

export type TFieldProperties = {
  type: string;
  id: string;
  name: string;
  season_id: string;
  culture_id: string;
  fill: string;
  stroke: string;
  area: string;
  stats: TFieldStats | null;
};

export type TFieldResponse = {
  type: string;
  geometry: geojson.Polygon;
  properties: TFieldProperties;
};

export type TLegendItem = { range: string; percent: number; color: string };
export type TStatsItem = { avg: string; total: string };

export type TFieldFeatures = {
  features: {
    type: "FeatureCollection";
    // NOTE(nk2ge5k): this suppose to be a part of the FeatureCollection
    features: TFieldResponse[]
  };
};

type THeatmapResponse = TFieldFeatures & {
  legend: Array<TLegendItem>;
  stats: TStatsItem;
};

function squareMeterToHectare(meter: number): string {
  return (meter / 10000).toFixed(2) + " ha";
}

function decodePolygon(field_id: string, geobuf: gb.Geobuf) {
  try {
    return decode(geobuf);
  } catch (e) {
    console.error(`Failed to decode field ${field_id}: ${e}`);
    return;
  }
};

export const getFieldsData = async (season_id?: string): Promise<TFieldFeatures> => {
  const request = new FieldsRequest().setPrecision(15);
  if (season_id) {
    request.setSeasonId(season_id);
  }

  const response = await FieldsClient.fields(request, null);

  let features: TFieldResponse[] = [];
  response.getFieldsList().forEach((field) => {
    const geobuf = field.getGeometry();
    if (!geobuf) {
      console.error("Missing field geometry", field.getId());
      return;
    }

    const tags = field.getTagsList();
    if (tags.length === 0) {
      console.error("Tag list is empty for field", field.getId());
      return;
    }

    const geometry = decodePolygon(field.getId(), geobuf);
    if (!geometry) {
      return;
    }

    if (geometry.type !== "Polygon") {
      // TODO(nk2ge5k): Add sentry support
      // TODO(nk2ge5k): Support other geometry types on the fields screen.
      console.warn(
        "Invalid field geometry type", geometry.type, "for field", field.getId());
      return;
    }

    let stats: TFieldStats | null = null;

    const summary = field.getSummary();
    if (summary) {
      stats = {
        min: summary.getMin(),
        max: summary.getMax(),
        avg: summary.getAvg(),
        mass_kg: summary.getMass(),
      };
    }

    const color = rgb(tags[0].getColor());
    color.opacity = 0.6;

    features.push({
      type: "Feature",
      geometry: geometry,
      properties: {
        type: "field",
        id: field.getId(),
        name: field.getName(),
        season_id: field.getSeasonId(),
        culture_id: tags[0].getId(),
        fill: color.toString(),
        stroke: tags[0].getColor(),
        area: squareMeterToHectare(field.getArea()),
        stats: stats,
      },
    });
  });

  return {
    features: {
      type: "FeatureCollection",
      features: features,
    },
  };
}

export const getHeatmapData = async (id: string): Promise<THeatmapResponse> => {
  const request = `${BASE_URL}/api/fields/v1/field/${id}/heatmap`;
  const response = await fetch(request, { credentials: "include" });
  return await response.json();
};

export const importFromFile = async (file: File, season_id?: string) => {
  const uploadReq = BASE_URL + "/api/media/v1/file";
  const data = new FormData();
  data.append("file", file);

  const uploadRes = await fetch(uploadReq, {
    method: "POST",
    body: data,
    credentials: "include",
    headers: {},
  });

  if (uploadRes.status !== 200) {
    const error = await uploadRes.json();
    throw new Error(`File upload failed: ${error.message}`);
  }

  const { id } = await uploadRes.json();
  const importReq = `https://at.greengrowth.tech/api/fields/v1/import`;
  const importRes = await fetch(importReq, {
    method: "POST",
    body: JSON.stringify({
      file: { id: id },
      season_id: season_id ?? null,
    }),
    credentials: "include",
    headers: {},
  });

  if (importRes.status !== 200) {
    const error = await importRes.json();
    throw new Error(error.message);
  }

  return await importRes.json();
};

export const updateField = async (feature: Feature) => {
  if (feature.properties!["id"]) {
    const request = new FieldUpdateRequest()
      .setId(feature.properties!["id"])
      .setName(feature.properties!["name"])
      .setSeasonId(feature.properties!["season_id"])
      .setGeometry(encode(feature.geometry, 16))
      .setTagsList([
        new Tag()
          .setId(feature.properties!["culture_id"])
          .setType(TagType.TAG_TYPE_USER)
          .setColor(feature.properties!["fill"]),
      ]);

    await FieldsClient.fieldUpdate(request, null);
  } else {
    const request = new FieldCreateRequest()
      .setName(feature.properties!["name"])
      .setSeasonId(feature.properties!["season_id"])
      .setGeometry(encode(feature.geometry, 16))
      .setTagsList([
        new Tag()
          .setId(feature.properties!["culture_id"])
          .setType(TagType.TAG_TYPE_USER)
          .setColor(feature.properties!["fill"]),
      ]);
    await FieldsClient.fieldCreate(request, null);
  }
}

export const createField = async (feature: Feature, season_id?: string) => {
  const request = new FieldCreateRequest()
    .setName(feature.properties!["name"])
    .setGeometry(encode(feature.geometry, 16))
    .setTagsList([
      new Tag()
        .setId(feature.properties!["culture_id"])
        .setType(TagType.TAG_TYPE_USER)
        .setColor(feature.properties!["fill"]),
    ]);
  if (season_id) {
    request.setSeasonId(season_id);
  }
  await FieldsClient.fieldCreate(request, null);
}

export const deleteField = (id: string | number) => {
  const request = `${BASE_URL}/api/fields/v1/field/${id}`;
  return fetch(request, {
    credentials: "include",
    method: "DELETE",
  });
};

export type TCategory = {
  color: string;
  icon: { url: string };
  id: string;
  name: string;
};

export type TCrop = {
  category: TCategory;
  id: string;
  is_builtin: boolean;
  name: string;
};

export const getCropsData = async (): Promise<TCrop[]> => {
  const request = BASE_URL + "/api/fields/v1/culture";
  const response = await fetch(request, { credentials: "include" });
  return (await response.json()).cultures;
};

export const getCategories = async (): Promise<TCategory[]> => {
  const request = BASE_URL + "/api/fields/v1/culture/category";
  const response = await fetch(request, { credentials: "include" });
  return (await response.json()).categories;
};

export type TCreateCropRequest = {
  name: string;
  category_id: string;
};

export const createCrop = async (culture: TCreateCropRequest) => {
  const request = BASE_URL + "/api/fields/v1/culture";
  const response = await fetch(request, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ culture }),
    mode: "cors",
    credentials: "include",
  });
  return await response.json();
};

export const removeCrop = async (id: string) => {
  const request = `${BASE_URL}/api/fields/v1/culture/${id}`;
  await fetch(request, {
    method: "DELETE",
    credentials: "include",
  });
};
export const editCrop = async (id: string, culture: TCreateCropRequest) => {
  const request = `${BASE_URL}/api/fields/v1/culture/${id}`;
  await fetch(request, {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ culture }),
    mode: "cors",
    credentials: "include",
  });
};

export interface Season {
  // Season id
  id: string;
  // Season name
  name: string;
  // Season start date
  from: Date,
  // Season end date
  to: Date;
  // Is season default?
  default: boolean;
};

// TODO(nk2ge5k): Add localization
export const seasonName = (start: Date, end: Date, is_default: boolean): string => {
  if (is_default) {
    return "Default";
  }

  const from = moment(start);
  const to = moment(end);

  if (from.year() !== to.year()) {
    return from.format("YYYY MMM") + " - " + to.format("YYYY MMM");
  }
  if (from.month() !== to.month()) {
    return from.format("YYYY MMM") + " - " + to.format("MMM");
  }
  return from.format("YYYY MMM DD") + " - " + to.format("DD");
}

export const listSeasons = async (): Promise<Season[]> => {
  const response = await FieldsClient.seasons(new SeasonsRequest(), null);
  return response.getSeasonsList().map((season) => {
    const from = season.getFrom()!.toDate();
    const to = season.getTo()!.toDate();

    return {
      id: season.getSeasonId(),
      name: seasonName(from, to, season.getIsDefault()),
      from: from,
      to: to,
      default: season.getIsDefault(),
    };
  });
}

export const currentSeason = async (): Promise<Season> => {
  const response = await FieldsClient.season(new SeasonRequest(), null);
  const from = response.getFrom()!.toDate();
  const to = response.getTo()!.toDate();

  return {
    id: response.getSeasonId(),
    name: seasonName(from, to, response.getIsDefault()),
    from: from,
    to: to,
    default: response.getIsDefault(),
  };
}

export const createSeason = async (from: Date, to: Date, season_id?: string): Promise<string> => {
  const request = new SeasonCreateRequest()
    .setEmpty(false)
    .setFrom(Timestamp.fromDate(from))
    .setTo(Timestamp.fromDate(to));
  if (season_id) {
    request.setSeasonId(season_id);
  }

  const response = await FieldsClient.seasonCreate(request, null)
  return response.getSeasonId();
}
