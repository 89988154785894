import { Fill, Stroke, Style, Text } from "ol/style";
import { Feature } from "ol";
import { Geometry } from "ol/geom";

type TUpdateFeatureStyleOptions = {
  selected?: boolean;
};

const updateFeatureStyle = (
  feature: Feature<Geometry>,
  options?: TUpdateFeatureStyleOptions
) => {
  const style = (feature.getStyle() as unknown as Style) ?? new Style();
  const selected = options?.selected;
  let name = feature.get("name");
  if (name !== undefined) {
    style.setText(
      new Text({
        padding: [4, 7, 4, 7],
        textAlign: "center",
        textBaseline: "ideographic",
        font: "12px/1.2 Nunito Sans",
        text: feature.get("name"),
        fill: new Fill({ color: selected ? "#494C5B" : "#ffffff" }),
        backgroundFill: new Fill({ color: selected ? "#ffffff" : "#494C5B" }),
        offsetX: 0,
        offsetY: 0,
        placement: "point",
        maxAngle: 0.7853981633974483,
        overflow: false,
        rotation: 0,
      })
    );
  }

  const fillColor = feature.get("fill");
  if (fillColor !== undefined) {
    const fill = style.getFill() ?? new Fill();
    fill.setColor(fillColor);
    style.setFill(fill);
  }

  const strokeColor = feature.get("stroke");
  if (strokeColor !== undefined) {
    const stroke = style.getStroke() ?? new Stroke();
    stroke.setWidth(0.5);
    stroke.setColor(selected ? "#ffffff" : strokeColor);
    style.setStroke(stroke);
  }

  feature.setStyle(style);
};

export default updateFeatureStyle;
