import styled from "@emotion/styled";
import React, { CSSProperties } from "react";
import Text from "@src/components/Text";

type ITab = {
  name: string;
};

interface IProps {
  tabs: ITab[];
  activeTab: number;
  setActive: (index: number) => void;
  style?: CSSProperties;
  tabStyle?: CSSProperties;
}

const Root = styled.div`
  display: flex;
  border-bottom: 1px solid #ebeeef;
  width: 100%;
  box-sizing: border-box;
`;
const Tab = styled.div<{ active?: boolean }>`
  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 24px;
  transition: all 0.3s ease;
  text-align: center;
  padding: 0 24px 14px 24px;
  border-bottom: 2px solid #EBEEEF;
  cursor: pointer;
  color: ${({ active }) => (active ? "#181B1F" : "#899093")};
  border-bottom: ${({ active }) =>
    active ? `2px solid #68AD18` : "2px solid transparent"};
  //margin-bottom: -1px;
  user-select: none;
  :hover {
    border-bottom: ${({ active }) => !active && `2px solid #68AD18`}
  }
}
`;
const Tabs: React.FC<IProps> = ({
  tabs,
  activeTab,
  setActive,
  style,
  tabStyle,
}) => {
  return (
    <Root style={style}>
      {tabs.map(({ name }, index) => (
        <Tab
          key={index}
          active={index === activeTab}
          onClick={() => setActive(index)}
          style={tabStyle}
        >
          <Text size={15} type="heading">
            {name}
          </Text>
        </Tab>
      ))}
    </Root>
  );
};
export default Tabs;
