import styled from "@emotion/styled";

import Input from "@components/Input";

export const SearchInput = styled(Input)`
  height: 36px;
  width: 280px;
  padding-left: 32px;
  box-sizing: border-box;
  ::placeholder {
    font-family: Nunito Sans, sans-serif;
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    color: #899093;
  }
`;

export default SearchInput;
