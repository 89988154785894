import React, { HTMLAttributes } from "react";
import Text from "@components/Text";
import { Column, Row } from "@components/Flex";
import styled from "@emotion/styled";
interface IProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
  value?: string;
  icon?: JSX.Element;
}

const Root = styled(Row)`
  align-items: center;
  cursor: pointer;
  padding-left: 16px;
  box-sizing: border-box;
  transition: 0.4s;
  height: 46px;
  & .icon {
    transition: 0.4s;
  }
  &.active {
    background: #f8f8f8;
  }
  :hover {
    background: #f8f8f8;

    & .icon {
      transform: rotate(-90deg);
    }
  }
`;

const LeftBarSettingsMenuItem: React.FC<IProps> = ({
  title,
  value,
  icon,
  ...rest
}) => {
  return (
    <Root {...rest}>
      <Column crossAxisSize="max" style={{ padding: 4 }}>
        <Text color="#494C5B" size={14} type="heading" nowrap>
          {title}
        </Text>
        {value && (
          <Text color="#899093" size={10} type="heading" nowrap>
            {value}
          </Text>
        )}
      </Column>
      {icon}
    </Root>
  );
};
export default LeftBarSettingsMenuItem;
