import styled from "@emotion/styled";
import React, {useState} from "react";
import Text from "@components/Text";
import FieldRow from "@screens/FieldsScreen/FieldsMenu/FieldRow";
import {useFieldsScreenVM,} from "@screens/FieldsScreen/FieldsScreenVM";
import {observer} from "mobx-react-lite";
import {useLang} from "@src/hooks/useLang";
import SizedBox from "@components/SizedBox";
import {ReactComponent as BackIcon} from "@assets/icons/dubleArrowLeft.svg";
import Button from "@components/Button";
import {Column, Row} from "@src/components/Flex";
import Input from "@components/Input";
import Select from "@components/Select";

interface IProps {}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 16px;
`;

const StyledFieldRow = styled(FieldRow)`
  border-top: none;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  cursor: auto;

  :hover {
    background: #fff;
  }
`;

const CreateFieldsMenu: React.FC<IProps> = () => {
  const vm = useFieldsScreenVM();
  const [name, setName] = useState<string>("");
  const [cropId, setCropId] = useState<string>("");
  // const [hybrid, setHybrid] = useState<string>();
  const lang = useLang();
  const handleSave = () => vm.create({ name, cropId });

  return (
    <Root>
      <Column crossAxisSize="max">
        <Row alignItems="center" style={{ cursor: "pointer" }}>
          <BackIcon style={{ marginRight: 4 }} />
          <Text size={12} color="#899093" onClick={vm.handleCancel}>
            {lang.fields.fieldAddPointer}
          </Text>
        </Row>
        <SizedBox height={24} />
        <StyledFieldRow
          title={name}
          crop={vm.crops.find(({ id }) => id === cropId)?.name}
        />
        <SizedBox height={16} />
        <Input
          value={name}
          onChange={(e) => setName(e.target.value)}
          label={lang.fields.newFieldName}
        />
        <SizedBox height={16} />
        <Select
          label={lang.fields.newFieldCrop}
          value={cropId}
          onChange={setCropId}
          options={vm.crops.map((crop) => ({
            title: crop.name,
            value: crop.id,
          }))}
        />
        <SizedBox height={16} />
        {/*<Input*/}
        {/*  value={hybrid}*/}
        {/*  onChange={(e) => setHybrid(e.target.value)}*/}
        {/*  label={lang.fields.newFieldHybrid}*/}
        {/*/>*/}
      </Column>
      <Row>
        <Button kind="outline" onClick={vm.handleCancel}>
          {lang.fields.drawFieldsCancelButton}
        </Button>
        <SizedBox width={8} />
        <Button onClick={handleSave}>{lang.fields.drawFieldsOkButton}</Button>
      </Row>
    </Root>
  );
};
export default observer(CreateFieldsMenu);
