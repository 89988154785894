import React, { useState } from "react";
import Dialog from "@components/Dialog";
import SizedBox from "@components/SizedBox";
import { Row } from "@components/Flex";
import Button from "@components/Button";
import Input from "@components/Input";
import { observer } from "mobx-react-lite";
import { useLang } from "@src/hooks/useLang";
import { useProfileScreenVM } from "@screens/ProfileScreen/ProfileScreenVM";
import validatePassword from "@src/utils/validatePassword";
import { TUpdatePassRequestData } from "@services/profileService";

interface IProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: (data: TUpdatePassRequestData) => Promise<any>;
}

const ChangePasswordDialog: React.FC<IProps> = ({ onSubmit, ...rest }) => {
  const lang = useLang();
  const vm = useProfileScreenVM();
  const [password, setPassword] = useState("");
  const [verification, setVerification] = useState("");
  const [error, setError] = useState<string | null>(null);
  const handleSubmit = async () => {
    const succeed = await vm.validatePassword(verification);
    succeed
      ? onSubmit({ password, verification }).then(() => {
          setVerification("");
          setPassword("");
        })
      : setError("Incorrect password");
  };
  return (
    <Dialog
      error={error}
      style={{ width: 336 }}
      title={lang.profile.passChangeTitle}
      {...rest}
    >
      <Input
        value={verification}
        onChange={(e) => setVerification(e.target.value)}
        label={lang.profile.passChangeOld}
        placeholder={lang.profile.passChangePlaceholder}
        type="password"
      />
      <SizedBox height={16} />
      <Input
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        label={lang.profile.passChangeNew}
        placeholder={lang.profile.passChangePlaceholder}
        type="password"
      />

      <SizedBox height={24} />
      <Row>
        <Button kind="outline" onClick={rest.onClose}>
          {lang.profile.passCancelButtton}
        </Button>
        <SizedBox width={16} />
        <Button
          onClick={handleSubmit}
          disabled={
            !validatePassword(password) || !validatePassword(verification)
          }
        >
          {lang.profile.passChangeButton}
        </Button>
      </Row>
    </Dialog>
  );
};
export default observer(ChangePasswordDialog);
