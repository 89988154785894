import styled from "@emotion/styled";
import React from "react";
import { ROUTES } from "@stores/AccountStore";
import Text from "@components/Text";
import { Link, useLocation } from "react-router-dom";
import useHover from "@src/hooks/useHover";

interface IProps {
  title: string;
  route: ROUTES;
  icon: JSX.Element;
  expanded: boolean;
  type?: "primary" | "secondary";
}

const Root = styled(Link)<{
  selected: boolean;
  color: string;
}>`
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 0 0 16px;
  height: 38px;
  overflow: hidden;
  white-space: nowrap;
  flex-wrap: nowrap;
  text-overflow: ellipsis;
  color: ${({ selected, color }) => (selected ? "#68ad18" : color)};
  :after {
    content: "";
    position: absolute;
    width: 3px;
    height: 30px;
    top: 4px;
    right: 0;
    background: #68ad18;
    border-radius: 4px 0 0 4px;
    transition: 0.4s;
    opacity: ${({ selected }) => (selected ? 1 : 0)};
  }
  svg {
    min-width: 20px;
  }
  path {
    fill: ${({ selected, color }) => (selected ? "#68ad18" : color)};
  }
  position: relative;
  :hover {
    :after {
      opacity: 1;
    }
  }
`;

const Tooltip = styled.div<{ expanded: boolean }>`
  position: absolute;
  height: 30px;
  left: 52px;
  top: 4px;
  width: ${({ expanded }) => (expanded ? 160 : 0)}px;
  padding-left: ${({ expanded }) => (expanded ? 16 : 0)}px;
  background: #ffffff;
  box-shadow: 0 2px 20px rgba(78, 78, 81, 0.2);
  border-radius: 0 6px 6px 0;
  transition: 0.4s;
  font-family: Nunito Sans, sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #545961;
  box-sizing: border-box;
  overflow: hidden;
  z-index: 1;
`;

const LeftBarMenuItem: React.FC<IProps> = ({
  icon,
  title,
  route,
  type,
  expanded,
}) => {
  const [hoverRef, isHovered] = useHover<HTMLDivElement>();
  const location = useLocation();
  const defaultColor = type === "secondary" ? "#899093" : "#494c5b";
  return (
    <div style={{ position: "relative", width: "100%" }} ref={hoverRef}>
      <Root
        to={route}
        selected={location.pathname === route}
        color={defaultColor}
      >
        {icon}
        {expanded && (
          <Text style={{ marginLeft: 12 }} size={12}>
            {title}
          </Text>
        )}
      </Root>
      {!expanded && <Tooltip expanded={isHovered}>{title}</Tooltip>}
    </div>
  );
};
export default LeftBarMenuItem;
