import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import Tabs from "@components/Tabs";
import { Column, Row } from "@src/components/Flex";
import SizedBox from "@components/SizedBox";
import Text from "@components/Text";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "@stores/AccountStore";
import { ReactComponent as Logo } from "@assets/images/bigLogo.svg";
import LanguageSelectTooltip from "@components/LanguageSelectTooltip";
import { observer } from "mobx-react-lite";
import { useLang } from "@src/hooks/useLang";
import { useStores } from "@stores";

interface IProps {
  disableTabs?: boolean;
  error?: string;
}

const Root = styled(Row)`
  align-items: center;
  justify-content: center;
  background: #f8f8f8;
  min-height: 100vh;
  min-width: 1152px;
`;

const LogoWrapper = styled(Column)`
  padding-left: 102px;
  box-sizing: border-box;
  max-width: fit-content;
`;

const FormWrapper = styled(Column)<{ initialized: boolean }>`
  width: ${({ initialized }) => (initialized ? "100%" : 0)};
  overflow: hidden;
  transition: 0.4s;
  padding-left: ${({ initialized }) => (initialized ? "102px" : 0)};
  padding-right: ${({ initialized }) => (initialized ? "80px" : 0)};
  box-sizing: border-box;
  max-width: fit-content;
`;

const AuthFromLayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 2px 20px rgba(78, 78, 81, 0.1);
  border-radius: 6px;
  width: 336px;
  box-sizing: border-box;
`;

const Body = styled(Column)`
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
  margin-bottom: 24px;
`;

export const ErrorAlert = styled.div<{ enabled: boolean }>`
  font-family: Nunito Sans, sans-serif;
  font-weight: bold;
  font-size: 11px;
  line-height: 18px;
  letter-spacing: 0.65px;
  text-transform: uppercase;
  color: #ffffff;
  transition: 0.4s;
  height: ${({ enabled }) => (enabled ? "32px" : 0)};
  padding: ${({ enabled }) => (enabled ? "7px 16px" : "0 16px")};
  margin-bottom: ${({ enabled }) => (enabled ? "8px" : 0)};
  background: #d82a35;
  border-radius: 4px;
  align-items: center;
  box-sizing: border-box;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 336px;
`;

const AuthLayout: React.FC<IProps> = ({ disableTabs, error, children }) => {
  const { accountStore } = useStores();
  const [activeTab, setActiveTab] = useState<number>(-1);
  const location = useLocation();
  const navigate = useNavigate();
  const lang = useLang();
  useEffect(() => {
    if (location.pathname === ROUTES.LOGIN) setActiveTab(0);
    if (location.pathname === ROUTES.REGISTER) setActiveTab(1);
  }, [location.pathname]);

  const allowedRoutes = [
    ROUTES.LOGIN,
    ROUTES.REGISTER,
    ROUTES.PASSWORD_RESET,
    ROUTES.PASSWORD_FORGOT,
  ];
  if (!allowedRoutes.includes(location.pathname as ROUTES)) {
    return Navigate({ to: ROUTES.LOGIN });
  }

  const handleSetActiveTab = (tab: number) => {
    if (tab === 0) {
      navigate(ROUTES.LOGIN);
    } else if (tab === 1) {
      navigate(ROUTES.REGISTER);
    }
  };

  return (
    <Root>
      <LogoWrapper justifyContent="center" crossAxisSize="max">
        <Logo />
      </LogoWrapper>
      <FormWrapper initialized={accountStore.initialized}>
        <ErrorAlert enabled={!!error}>{error}</ErrorAlert>
        <AuthFromLayoutRoot>
          {!disableTabs && (
            <Tabs
              style={{ margin: "16px 0 24px 0" }}
              tabs={[
                { name: lang.auth.signInButton },
                { name: lang.auth.signUpButton },
              ]}
              activeTab={activeTab}
              setActive={handleSetActiveTab}
            />
          )}
          <Body>{children}</Body>
        </AuthFromLayoutRoot>
        <SizedBox height={16} />
        <Row>
          <Text color="#899093" size={10} fitContent>
            {lang.auth.bottomInfo}
          </Text>
          <SizedBox width={16} />
          <LanguageSelectTooltip />
        </Row>
      </FormWrapper>
    </Root>
  );
};

export default observer(AuthLayout);
