// source: types/v1/geobuf.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

goog.exportSymbol('proto.grass.v1.Feature', null, global);
goog.exportSymbol('proto.grass.v1.FeatureCollection', null, global);
goog.exportSymbol('proto.grass.v1.Geobuf', null, global);
goog.exportSymbol('proto.grass.v1.Geobuf.DataTypeCase', null, global);
goog.exportSymbol('proto.grass.v1.Geometry', null, global);
goog.exportSymbol('proto.grass.v1.GeometryType', null, global);
goog.exportSymbol('proto.grass.v1.Properties', null, global);
goog.exportSymbol('proto.grass.v1.Value', null, global);
goog.exportSymbol('proto.grass.v1.Value.Empty', null, global);
goog.exportSymbol('proto.grass.v1.Value.ValueTypeCase', null, global);
goog.exportSymbol('proto.grass.v1.ValueType', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.v1.Geobuf = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grass.v1.Geobuf.repeatedFields_, proto.grass.v1.Geobuf.oneofGroups_);
};
goog.inherits(proto.grass.v1.Geobuf, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.v1.Geobuf.displayName = 'proto.grass.v1.Geobuf';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.v1.Value = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.grass.v1.Value.oneofGroups_);
};
goog.inherits(proto.grass.v1.Value, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.v1.Value.displayName = 'proto.grass.v1.Value';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.v1.Value.Empty = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.v1.Value.Empty, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.v1.Value.Empty.displayName = 'proto.grass.v1.Value.Empty';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.v1.Properties = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grass.v1.Properties.repeatedFields_, null);
};
goog.inherits(proto.grass.v1.Properties, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.v1.Properties.displayName = 'proto.grass.v1.Properties';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.v1.Feature = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.v1.Feature, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.v1.Feature.displayName = 'proto.grass.v1.Feature';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.v1.FeatureCollection = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grass.v1.FeatureCollection.repeatedFields_, null);
};
goog.inherits(proto.grass.v1.FeatureCollection, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.v1.FeatureCollection.displayName = 'proto.grass.v1.FeatureCollection';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.v1.Geometry = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grass.v1.Geometry.repeatedFields_, null);
};
goog.inherits(proto.grass.v1.Geometry, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.v1.Geometry.displayName = 'proto.grass.v1.Geometry';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grass.v1.Geobuf.repeatedFields_ = [2,10];

/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.grass.v1.Geobuf.oneofGroups_ = [[3,4,5]];

/**
 * @enum {number}
 */
proto.grass.v1.Geobuf.DataTypeCase = {
  DATA_TYPE_NOT_SET: 0,
  FEATURE_COLLECTION: 3,
  FEATURE: 4,
  GEOMETRY: 5
};

/**
 * @return {proto.grass.v1.Geobuf.DataTypeCase}
 */
proto.grass.v1.Geobuf.prototype.getDataTypeCase = function() {
  return /** @type {proto.grass.v1.Geobuf.DataTypeCase} */(jspb.Message.computeOneofCase(this, proto.grass.v1.Geobuf.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.v1.Geobuf.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.v1.Geobuf.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.v1.Geobuf} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.v1.Geobuf.toObject = function(includeInstance, msg) {
  var f, obj = {
    precision: jspb.Message.getFieldWithDefault(msg, 1, 0),
    keysList: (f = jspb.Message.getRepeatedField(msg, 2)) == null ? undefined : f,
    typesList: (f = jspb.Message.getRepeatedField(msg, 10)) == null ? undefined : f,
    featureCollection: (f = msg.getFeatureCollection()) && proto.grass.v1.FeatureCollection.toObject(includeInstance, f),
    feature: (f = msg.getFeature()) && proto.grass.v1.Feature.toObject(includeInstance, f),
    geometry: (f = msg.getGeometry()) && proto.grass.v1.Geometry.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.v1.Geobuf}
 */
proto.grass.v1.Geobuf.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.v1.Geobuf;
  return proto.grass.v1.Geobuf.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.v1.Geobuf} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.v1.Geobuf}
 */
proto.grass.v1.Geobuf.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPrecision(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.addKeys(value);
      break;
    case 10:
      var values = /** @type {!Array<!proto.grass.v1.ValueType>} */ (reader.isDelimited() ? reader.readPackedEnum() : [reader.readEnum()]);
      for (var i = 0; i < values.length; i++) {
        msg.addTypes(values[i]);
      }
      break;
    case 3:
      var value = new proto.grass.v1.FeatureCollection;
      reader.readMessage(value,proto.grass.v1.FeatureCollection.deserializeBinaryFromReader);
      msg.setFeatureCollection(value);
      break;
    case 4:
      var value = new proto.grass.v1.Feature;
      reader.readMessage(value,proto.grass.v1.Feature.deserializeBinaryFromReader);
      msg.setFeature(value);
      break;
    case 5:
      var value = new proto.grass.v1.Geometry;
      reader.readMessage(value,proto.grass.v1.Geometry.deserializeBinaryFromReader);
      msg.setGeometry(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.v1.Geobuf.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.v1.Geobuf.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.v1.Geobuf} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.v1.Geobuf.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPrecision();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getKeysList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      2,
      f
    );
  }
  f = message.getTypesList();
  if (f.length > 0) {
    writer.writePackedEnum(
      10,
      f
    );
  }
  f = message.getFeatureCollection();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.grass.v1.FeatureCollection.serializeBinaryToWriter
    );
  }
  f = message.getFeature();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      proto.grass.v1.Feature.serializeBinaryToWriter
    );
  }
  f = message.getGeometry();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.grass.v1.Geometry.serializeBinaryToWriter
    );
  }
};


/**
 * optional uint32 precision = 1;
 * @return {number}
 */
proto.grass.v1.Geobuf.prototype.getPrecision = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.v1.Geobuf} returns this
 */
proto.grass.v1.Geobuf.prototype.setPrecision = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * repeated string keys = 2;
 * @return {!Array<string>}
 */
proto.grass.v1.Geobuf.prototype.getKeysList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 2));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grass.v1.Geobuf} returns this
 */
proto.grass.v1.Geobuf.prototype.setKeysList = function(value) {
  return jspb.Message.setField(this, 2, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grass.v1.Geobuf} returns this
 */
proto.grass.v1.Geobuf.prototype.addKeys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 2, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.v1.Geobuf} returns this
 */
proto.grass.v1.Geobuf.prototype.clearKeysList = function() {
  return this.setKeysList([]);
};


/**
 * repeated ValueType types = 10;
 * @return {!Array<!proto.grass.v1.ValueType>}
 */
proto.grass.v1.Geobuf.prototype.getTypesList = function() {
  return /** @type {!Array<!proto.grass.v1.ValueType>} */ (jspb.Message.getRepeatedField(this, 10));
};


/**
 * @param {!Array<!proto.grass.v1.ValueType>} value
 * @return {!proto.grass.v1.Geobuf} returns this
 */
proto.grass.v1.Geobuf.prototype.setTypesList = function(value) {
  return jspb.Message.setField(this, 10, value || []);
};


/**
 * @param {!proto.grass.v1.ValueType} value
 * @param {number=} opt_index
 * @return {!proto.grass.v1.Geobuf} returns this
 */
proto.grass.v1.Geobuf.prototype.addTypes = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 10, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.v1.Geobuf} returns this
 */
proto.grass.v1.Geobuf.prototype.clearTypesList = function() {
  return this.setTypesList([]);
};


/**
 * optional FeatureCollection feature_collection = 3;
 * @return {?proto.grass.v1.FeatureCollection}
 */
proto.grass.v1.Geobuf.prototype.getFeatureCollection = function() {
  return /** @type{?proto.grass.v1.FeatureCollection} */ (
    jspb.Message.getWrapperField(this, proto.grass.v1.FeatureCollection, 3));
};


/**
 * @param {?proto.grass.v1.FeatureCollection|undefined} value
 * @return {!proto.grass.v1.Geobuf} returns this
*/
proto.grass.v1.Geobuf.prototype.setFeatureCollection = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.grass.v1.Geobuf.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.v1.Geobuf} returns this
 */
proto.grass.v1.Geobuf.prototype.clearFeatureCollection = function() {
  return this.setFeatureCollection(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.v1.Geobuf.prototype.hasFeatureCollection = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Feature feature = 4;
 * @return {?proto.grass.v1.Feature}
 */
proto.grass.v1.Geobuf.prototype.getFeature = function() {
  return /** @type{?proto.grass.v1.Feature} */ (
    jspb.Message.getWrapperField(this, proto.grass.v1.Feature, 4));
};


/**
 * @param {?proto.grass.v1.Feature|undefined} value
 * @return {!proto.grass.v1.Geobuf} returns this
*/
proto.grass.v1.Geobuf.prototype.setFeature = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.grass.v1.Geobuf.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.v1.Geobuf} returns this
 */
proto.grass.v1.Geobuf.prototype.clearFeature = function() {
  return this.setFeature(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.v1.Geobuf.prototype.hasFeature = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Geometry geometry = 5;
 * @return {?proto.grass.v1.Geometry}
 */
proto.grass.v1.Geobuf.prototype.getGeometry = function() {
  return /** @type{?proto.grass.v1.Geometry} */ (
    jspb.Message.getWrapperField(this, proto.grass.v1.Geometry, 5));
};


/**
 * @param {?proto.grass.v1.Geometry|undefined} value
 * @return {!proto.grass.v1.Geobuf} returns this
*/
proto.grass.v1.Geobuf.prototype.setGeometry = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.grass.v1.Geobuf.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.v1.Geobuf} returns this
 */
proto.grass.v1.Geobuf.prototype.clearGeometry = function() {
  return this.setGeometry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.v1.Geobuf.prototype.hasGeometry = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.grass.v1.Value.oneofGroups_ = [[1,2,3,4,5]];

/**
 * @enum {number}
 */
proto.grass.v1.Value.ValueTypeCase = {
  VALUE_TYPE_NOT_SET: 0,
  STRING_VALUE: 1,
  DOUBLE_VALUE: 2,
  INTEGER_VALUE: 3,
  BOOL_VALUE: 4,
  EMPTY_VALUE: 5
};

/**
 * @return {proto.grass.v1.Value.ValueTypeCase}
 */
proto.grass.v1.Value.prototype.getValueTypeCase = function() {
  return /** @type {proto.grass.v1.Value.ValueTypeCase} */(jspb.Message.computeOneofCase(this, proto.grass.v1.Value.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.v1.Value.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.v1.Value.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.v1.Value} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.v1.Value.toObject = function(includeInstance, msg) {
  var f, obj = {
    stringValue: jspb.Message.getFieldWithDefault(msg, 1, ""),
    doubleValue: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    integerValue: jspb.Message.getFieldWithDefault(msg, 3, 0),
    boolValue: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    emptyValue: (f = msg.getEmptyValue()) && proto.grass.v1.Value.Empty.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.v1.Value}
 */
proto.grass.v1.Value.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.v1.Value;
  return proto.grass.v1.Value.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.v1.Value} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.v1.Value}
 */
proto.grass.v1.Value.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStringValue(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setDoubleValue(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readSint64());
      msg.setIntegerValue(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setBoolValue(value);
      break;
    case 5:
      var value = new proto.grass.v1.Value.Empty;
      reader.readMessage(value,proto.grass.v1.Value.Empty.deserializeBinaryFromReader);
      msg.setEmptyValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.v1.Value.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.v1.Value.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.v1.Value} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.v1.Value.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = /** @type {number} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeSint64(
      3,
      f
    );
  }
  f = /** @type {boolean} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getEmptyValue();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      proto.grass.v1.Value.Empty.serializeBinaryToWriter
    );
  }
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.v1.Value.Empty.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.v1.Value.Empty.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.v1.Value.Empty} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.v1.Value.Empty.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.v1.Value.Empty}
 */
proto.grass.v1.Value.Empty.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.v1.Value.Empty;
  return proto.grass.v1.Value.Empty.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.v1.Value.Empty} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.v1.Value.Empty}
 */
proto.grass.v1.Value.Empty.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.v1.Value.Empty.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.v1.Value.Empty.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.v1.Value.Empty} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.v1.Value.Empty.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


/**
 * optional string string_value = 1;
 * @return {string}
 */
proto.grass.v1.Value.prototype.getStringValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.v1.Value} returns this
 */
proto.grass.v1.Value.prototype.setStringValue = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.grass.v1.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grass.v1.Value} returns this
 */
proto.grass.v1.Value.prototype.clearStringValue = function() {
  return jspb.Message.setOneofField(this, 1, proto.grass.v1.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.v1.Value.prototype.hasStringValue = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional double double_value = 2;
 * @return {number}
 */
proto.grass.v1.Value.prototype.getDoubleValue = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.grass.v1.Value} returns this
 */
proto.grass.v1.Value.prototype.setDoubleValue = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.grass.v1.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grass.v1.Value} returns this
 */
proto.grass.v1.Value.prototype.clearDoubleValue = function() {
  return jspb.Message.setOneofField(this, 2, proto.grass.v1.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.v1.Value.prototype.hasDoubleValue = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional sint64 integer_value = 3;
 * @return {number}
 */
proto.grass.v1.Value.prototype.getIntegerValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.v1.Value} returns this
 */
proto.grass.v1.Value.prototype.setIntegerValue = function(value) {
  return jspb.Message.setOneofField(this, 3, proto.grass.v1.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grass.v1.Value} returns this
 */
proto.grass.v1.Value.prototype.clearIntegerValue = function() {
  return jspb.Message.setOneofField(this, 3, proto.grass.v1.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.v1.Value.prototype.hasIntegerValue = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool bool_value = 4;
 * @return {boolean}
 */
proto.grass.v1.Value.prototype.getBoolValue = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grass.v1.Value} returns this
 */
proto.grass.v1.Value.prototype.setBoolValue = function(value) {
  return jspb.Message.setOneofField(this, 4, proto.grass.v1.Value.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grass.v1.Value} returns this
 */
proto.grass.v1.Value.prototype.clearBoolValue = function() {
  return jspb.Message.setOneofField(this, 4, proto.grass.v1.Value.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.v1.Value.prototype.hasBoolValue = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Empty empty_value = 5;
 * @return {?proto.grass.v1.Value.Empty}
 */
proto.grass.v1.Value.prototype.getEmptyValue = function() {
  return /** @type{?proto.grass.v1.Value.Empty} */ (
    jspb.Message.getWrapperField(this, proto.grass.v1.Value.Empty, 5));
};


/**
 * @param {?proto.grass.v1.Value.Empty|undefined} value
 * @return {!proto.grass.v1.Value} returns this
*/
proto.grass.v1.Value.prototype.setEmptyValue = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.grass.v1.Value.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.v1.Value} returns this
 */
proto.grass.v1.Value.prototype.clearEmptyValue = function() {
  return this.setEmptyValue(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.v1.Value.prototype.hasEmptyValue = function() {
  return jspb.Message.getField(this, 5) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grass.v1.Properties.repeatedFields_ = [1,2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.v1.Properties.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.v1.Properties.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.v1.Properties} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.v1.Properties.toObject = function(includeInstance, msg) {
  var f, obj = {
    keysList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    valuesList: jspb.Message.toObjectList(msg.getValuesList(),
    proto.grass.v1.Value.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.v1.Properties}
 */
proto.grass.v1.Properties.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.v1.Properties;
  return proto.grass.v1.Properties.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.v1.Properties} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.v1.Properties}
 */
proto.grass.v1.Properties.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addKeys(values[i]);
      }
      break;
    case 2:
      var value = new proto.grass.v1.Value;
      reader.readMessage(value,proto.grass.v1.Value.deserializeBinaryFromReader);
      msg.addValues(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.v1.Properties.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.v1.Properties.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.v1.Properties} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.v1.Properties.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getKeysList();
  if (f.length > 0) {
    writer.writePackedUint32(
      1,
      f
    );
  }
  f = message.getValuesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.grass.v1.Value.serializeBinaryToWriter
    );
  }
};


/**
 * repeated uint32 keys = 1;
 * @return {!Array<number>}
 */
proto.grass.v1.Properties.prototype.getKeysList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.v1.Properties} returns this
 */
proto.grass.v1.Properties.prototype.setKeysList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.v1.Properties} returns this
 */
proto.grass.v1.Properties.prototype.addKeys = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.v1.Properties} returns this
 */
proto.grass.v1.Properties.prototype.clearKeysList = function() {
  return this.setKeysList([]);
};


/**
 * repeated Value values = 2;
 * @return {!Array<!proto.grass.v1.Value>}
 */
proto.grass.v1.Properties.prototype.getValuesList = function() {
  return /** @type{!Array<!proto.grass.v1.Value>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grass.v1.Value, 2));
};


/**
 * @param {!Array<!proto.grass.v1.Value>} value
 * @return {!proto.grass.v1.Properties} returns this
*/
proto.grass.v1.Properties.prototype.setValuesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.grass.v1.Value=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grass.v1.Value}
 */
proto.grass.v1.Properties.prototype.addValues = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.grass.v1.Value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.v1.Properties} returns this
 */
proto.grass.v1.Properties.prototype.clearValuesList = function() {
  return this.setValuesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.v1.Feature.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.v1.Feature.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.v1.Feature} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.v1.Feature.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    geometry: (f = msg.getGeometry()) && proto.grass.v1.Geometry.toObject(includeInstance, f),
    properties: (f = msg.getProperties()) && proto.grass.v1.Properties.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.v1.Feature}
 */
proto.grass.v1.Feature.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.v1.Feature;
  return proto.grass.v1.Feature.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.v1.Feature} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.v1.Feature}
 */
proto.grass.v1.Feature.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.grass.v1.Geometry;
      reader.readMessage(value,proto.grass.v1.Geometry.deserializeBinaryFromReader);
      msg.setGeometry(value);
      break;
    case 10:
      var value = new proto.grass.v1.Properties;
      reader.readMessage(value,proto.grass.v1.Properties.deserializeBinaryFromReader);
      msg.setProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.v1.Feature.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.v1.Feature.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.v1.Feature} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.v1.Feature.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getGeometry();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.grass.v1.Geometry.serializeBinaryToWriter
    );
  }
  f = message.getProperties();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.grass.v1.Properties.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.grass.v1.Feature.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.v1.Feature} returns this
 */
proto.grass.v1.Feature.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Geometry geometry = 2;
 * @return {?proto.grass.v1.Geometry}
 */
proto.grass.v1.Feature.prototype.getGeometry = function() {
  return /** @type{?proto.grass.v1.Geometry} */ (
    jspb.Message.getWrapperField(this, proto.grass.v1.Geometry, 2));
};


/**
 * @param {?proto.grass.v1.Geometry|undefined} value
 * @return {!proto.grass.v1.Feature} returns this
*/
proto.grass.v1.Feature.prototype.setGeometry = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.v1.Feature} returns this
 */
proto.grass.v1.Feature.prototype.clearGeometry = function() {
  return this.setGeometry(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.v1.Feature.prototype.hasGeometry = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Properties properties = 10;
 * @return {?proto.grass.v1.Properties}
 */
proto.grass.v1.Feature.prototype.getProperties = function() {
  return /** @type{?proto.grass.v1.Properties} */ (
    jspb.Message.getWrapperField(this, proto.grass.v1.Properties, 10));
};


/**
 * @param {?proto.grass.v1.Properties|undefined} value
 * @return {!proto.grass.v1.Feature} returns this
*/
proto.grass.v1.Feature.prototype.setProperties = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.v1.Feature} returns this
 */
proto.grass.v1.Feature.prototype.clearProperties = function() {
  return this.setProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.v1.Feature.prototype.hasProperties = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grass.v1.FeatureCollection.repeatedFields_ = [2];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.v1.FeatureCollection.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.v1.FeatureCollection.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.v1.FeatureCollection} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.v1.FeatureCollection.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    featuresList: jspb.Message.toObjectList(msg.getFeaturesList(),
    proto.grass.v1.Feature.toObject, includeInstance),
    properties: (f = msg.getProperties()) && proto.grass.v1.Properties.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.v1.FeatureCollection}
 */
proto.grass.v1.FeatureCollection.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.v1.FeatureCollection;
  return proto.grass.v1.FeatureCollection.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.v1.FeatureCollection} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.v1.FeatureCollection}
 */
proto.grass.v1.FeatureCollection.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = new proto.grass.v1.Feature;
      reader.readMessage(value,proto.grass.v1.Feature.deserializeBinaryFromReader);
      msg.addFeatures(value);
      break;
    case 10:
      var value = new proto.grass.v1.Properties;
      reader.readMessage(value,proto.grass.v1.Properties.deserializeBinaryFromReader);
      msg.setProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.v1.FeatureCollection.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.v1.FeatureCollection.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.v1.FeatureCollection} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.v1.FeatureCollection.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFeaturesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      2,
      f,
      proto.grass.v1.Feature.serializeBinaryToWriter
    );
  }
  f = message.getProperties();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.grass.v1.Properties.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.grass.v1.FeatureCollection.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.v1.FeatureCollection} returns this
 */
proto.grass.v1.FeatureCollection.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * repeated Feature features = 2;
 * @return {!Array<!proto.grass.v1.Feature>}
 */
proto.grass.v1.FeatureCollection.prototype.getFeaturesList = function() {
  return /** @type{!Array<!proto.grass.v1.Feature>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grass.v1.Feature, 2));
};


/**
 * @param {!Array<!proto.grass.v1.Feature>} value
 * @return {!proto.grass.v1.FeatureCollection} returns this
*/
proto.grass.v1.FeatureCollection.prototype.setFeaturesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 2, value);
};


/**
 * @param {!proto.grass.v1.Feature=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grass.v1.Feature}
 */
proto.grass.v1.FeatureCollection.prototype.addFeatures = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 2, opt_value, proto.grass.v1.Feature, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.v1.FeatureCollection} returns this
 */
proto.grass.v1.FeatureCollection.prototype.clearFeaturesList = function() {
  return this.setFeaturesList([]);
};


/**
 * optional Properties properties = 10;
 * @return {?proto.grass.v1.Properties}
 */
proto.grass.v1.FeatureCollection.prototype.getProperties = function() {
  return /** @type{?proto.grass.v1.Properties} */ (
    jspb.Message.getWrapperField(this, proto.grass.v1.Properties, 10));
};


/**
 * @param {?proto.grass.v1.Properties|undefined} value
 * @return {!proto.grass.v1.FeatureCollection} returns this
*/
proto.grass.v1.FeatureCollection.prototype.setProperties = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.v1.FeatureCollection} returns this
 */
proto.grass.v1.FeatureCollection.prototype.clearProperties = function() {
  return this.setProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.v1.FeatureCollection.prototype.hasProperties = function() {
  return jspb.Message.getField(this, 10) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grass.v1.Geometry.repeatedFields_ = [3,4,5,6];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.v1.Geometry.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.v1.Geometry.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.v1.Geometry} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.v1.Geometry.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    type: jspb.Message.getFieldWithDefault(msg, 2, 0),
    lengthsList: (f = jspb.Message.getRepeatedField(msg, 3)) == null ? undefined : f,
    coordsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    geometriesList: jspb.Message.toObjectList(msg.getGeometriesList(),
    proto.grass.v1.Geometry.toObject, includeInstance),
    bboxList: (f = jspb.Message.getRepeatedField(msg, 6)) == null ? undefined : f,
    properties: (f = msg.getProperties()) && proto.grass.v1.Properties.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.v1.Geometry}
 */
proto.grass.v1.Geometry.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.v1.Geometry;
  return proto.grass.v1.Geometry.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.v1.Geometry} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.v1.Geometry}
 */
proto.grass.v1.Geometry.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.grass.v1.GeometryType} */ (reader.readEnum());
      msg.setType(value);
      break;
    case 3:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedUint32() : [reader.readUint32()]);
      for (var i = 0; i < values.length; i++) {
        msg.addLengths(values[i]);
      }
      break;
    case 4:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSint64() : [reader.readSint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addCoords(values[i]);
      }
      break;
    case 5:
      var value = new proto.grass.v1.Geometry;
      reader.readMessage(value,proto.grass.v1.Geometry.deserializeBinaryFromReader);
      msg.addGeometries(value);
      break;
    case 6:
      var values = /** @type {!Array<number>} */ (reader.isDelimited() ? reader.readPackedSint64() : [reader.readSint64()]);
      for (var i = 0; i < values.length; i++) {
        msg.addBbox(values[i]);
      }
      break;
    case 10:
      var value = new proto.grass.v1.Properties;
      reader.readMessage(value,proto.grass.v1.Properties.deserializeBinaryFromReader);
      msg.setProperties(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.v1.Geometry.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.v1.Geometry.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.v1.Geometry} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.v1.Geometry.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getType();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getLengthsList();
  if (f.length > 0) {
    writer.writePackedUint32(
      3,
      f
    );
  }
  f = message.getCoordsList();
  if (f.length > 0) {
    writer.writePackedSint64(
      4,
      f
    );
  }
  f = message.getGeometriesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      proto.grass.v1.Geometry.serializeBinaryToWriter
    );
  }
  f = message.getBboxList();
  if (f.length > 0) {
    writer.writePackedSint64(
      6,
      f
    );
  }
  f = message.getProperties();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      proto.grass.v1.Properties.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.grass.v1.Geometry.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.v1.Geometry} returns this
 */
proto.grass.v1.Geometry.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional GeometryType type = 2;
 * @return {!proto.grass.v1.GeometryType}
 */
proto.grass.v1.Geometry.prototype.getType = function() {
  return /** @type {!proto.grass.v1.GeometryType} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.grass.v1.GeometryType} value
 * @return {!proto.grass.v1.Geometry} returns this
 */
proto.grass.v1.Geometry.prototype.setType = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated uint32 lengths = 3;
 * @return {!Array<number>}
 */
proto.grass.v1.Geometry.prototype.getLengthsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 3));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.v1.Geometry} returns this
 */
proto.grass.v1.Geometry.prototype.setLengthsList = function(value) {
  return jspb.Message.setField(this, 3, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.v1.Geometry} returns this
 */
proto.grass.v1.Geometry.prototype.addLengths = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 3, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.v1.Geometry} returns this
 */
proto.grass.v1.Geometry.prototype.clearLengthsList = function() {
  return this.setLengthsList([]);
};


/**
 * repeated sint64 coords = 4;
 * @return {!Array<number>}
 */
proto.grass.v1.Geometry.prototype.getCoordsList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.v1.Geometry} returns this
 */
proto.grass.v1.Geometry.prototype.setCoordsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.v1.Geometry} returns this
 */
proto.grass.v1.Geometry.prototype.addCoords = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.v1.Geometry} returns this
 */
proto.grass.v1.Geometry.prototype.clearCoordsList = function() {
  return this.setCoordsList([]);
};


/**
 * repeated Geometry geometries = 5;
 * @return {!Array<!proto.grass.v1.Geometry>}
 */
proto.grass.v1.Geometry.prototype.getGeometriesList = function() {
  return /** @type{!Array<!proto.grass.v1.Geometry>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.grass.v1.Geometry, 5));
};


/**
 * @param {!Array<!proto.grass.v1.Geometry>} value
 * @return {!proto.grass.v1.Geometry} returns this
*/
proto.grass.v1.Geometry.prototype.setGeometriesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.grass.v1.Geometry=} opt_value
 * @param {number=} opt_index
 * @return {!proto.grass.v1.Geometry}
 */
proto.grass.v1.Geometry.prototype.addGeometries = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.grass.v1.Geometry, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.v1.Geometry} returns this
 */
proto.grass.v1.Geometry.prototype.clearGeometriesList = function() {
  return this.setGeometriesList([]);
};


/**
 * repeated sint64 bbox = 6;
 * @return {!Array<number>}
 */
proto.grass.v1.Geometry.prototype.getBboxList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 6));
};


/**
 * @param {!Array<number>} value
 * @return {!proto.grass.v1.Geometry} returns this
 */
proto.grass.v1.Geometry.prototype.setBboxList = function(value) {
  return jspb.Message.setField(this, 6, value || []);
};


/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.grass.v1.Geometry} returns this
 */
proto.grass.v1.Geometry.prototype.addBbox = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 6, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.v1.Geometry} returns this
 */
proto.grass.v1.Geometry.prototype.clearBboxList = function() {
  return this.setBboxList([]);
};


/**
 * optional Properties properties = 10;
 * @return {?proto.grass.v1.Properties}
 */
proto.grass.v1.Geometry.prototype.getProperties = function() {
  return /** @type{?proto.grass.v1.Properties} */ (
    jspb.Message.getWrapperField(this, proto.grass.v1.Properties, 10));
};


/**
 * @param {?proto.grass.v1.Properties|undefined} value
 * @return {!proto.grass.v1.Geometry} returns this
*/
proto.grass.v1.Geometry.prototype.setProperties = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.v1.Geometry} returns this
 */
proto.grass.v1.Geometry.prototype.clearProperties = function() {
  return this.setProperties(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.v1.Geometry.prototype.hasProperties = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * @enum {number}
 */
proto.grass.v1.ValueType = {
  VALUE_TYPE_INVALID: 0,
  VALUE_TYPE_STRING: 1,
  VALUE_TYPE_DOUBLE: 2,
  VALUE_TYPE_INTEGER: 3,
  VALUE_TYPE_BOOL: 4
};

/**
 * @enum {number}
 */
proto.grass.v1.GeometryType = {
  GEOMETRY_TYPE_INVALID: 0,
  GEOMETRY_TYPE_POINT: 1,
  GEOMETRY_TYPE_MULTIPOINT: 2,
  GEOMETRY_TYPE_LINESTRING: 3,
  GEOMETRY_TYPE_MULTILINESTRING: 4,
  GEOMETRY_TYPE_POLYGON: 5,
  GEOMETRY_TYPE_MULTIPOLYGON: 6,
  GEOMETRY_TYPE_GEOMETRYCOLLECTION: 7
};

goog.object.extend(exports, proto.grass.v1);
