import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import firebaseConfig from "@src/firebase.json";

export const pageView = (title: string, path?: string) => {
  const app = initializeApp(firebaseConfig, "Green Growth Web");
  const analytics = getAnalytics(app);
  logEvent(analytics, 'page_view', {
    page_title: title,
    page_path: path,
  });
};

export const click = (item: string) => {
  const app = initializeApp(firebaseConfig, "Green Growth Web");
  const analytics = getAnalytics(app);
  logEvent(analytics, "click", { 'name': item });
};
