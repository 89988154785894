import styled from "@emotion/styled";
import React, { HTMLAttributes } from "react";
import { Column, Row } from "@components/Flex";
import { LANGUAGE, LANGUAGE_SHORT_NAMES } from "@stores/SettingsStore";
import { observer } from "mobx-react-lite";
import { useStores } from "@stores";

interface IProps extends HTMLAttributes<HTMLDivElement> {
  langs?: Record<LANGUAGE, string>;
}

export const LanguageRow = styled(Row)<{ selected?: boolean }>`
  position: relative;
  align-items: center;
  font-family: Nunito Sans, sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: ${({ selected }) => (selected ? "#494c5b" : "#899093")};
  padding: 5px 12px;
  width: fit-content;
  cursor: pointer;
  box-sizing: border-box;
  :hover {
    color: #494c5b;
  }
  .indicatorWrapper {
    display: ${({ selected }) => (selected ? "flex" : "none")};
    flex-direction: column;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    justify-content: center;
  }
`;

export const LanguageRowIndicator = styled.div`
  width: 2px;
  height: 6px;
  background: #090d11;
  border-radius: 2px;
`;

const LanguageSelectBody: React.FC<IProps> = ({ langs, ...rest }) => {
  const { settingsStore } = useStores();
  return (
    <Column {...rest}>
      {Object.entries(langs ?? LANGUAGE).map(([lang, title]) => (
        <LanguageRow
          className="language-row"
          key={lang}
          selected={settingsStore.language === lang}
          onClick={() => settingsStore.setLanguage(lang as LANGUAGE)}
        >
          <div className="indicatorWrapper">
            <LanguageRowIndicator />
          </div>
          {/*@ts-ignore*/}
          {LANGUAGE_SHORT_NAMES[title]}
        </LanguageRow>
      ))}
    </Column>
  );
};
export default observer(LanguageSelectBody);
