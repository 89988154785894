import styled from "@emotion/styled";

const StyleDot = styled.span`
  display: inline-block;
  border-radius: 9999px;
  height: 0.6rem;
  width: 0.6rem;
`

export const Gray = styled(StyleDot)`
  background-color: rgb(209, 213, 219);
`;

export const Green = styled(StyleDot)`
  background-color: rgb(30, 166, 114);
`;

export const Yellow = styled(StyleDot)`
  background-color: rgb(253, 224, 71);
`;

export const Red = styled(StyleDot)`
  background-color: rgb(248, 113, 113);
`;

