import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import "ol/ol.css";
import Map from "ol/Map";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import View from "ol/View";
import { Fill, Stroke, Style } from "ol/style";
import { ReactComponent as FieldIcon } from "@assets/icons/field.svg";
import { Geometry, SimpleGeometry } from "ol/geom";
import { getCenter } from "ol/extent";
import { Feature } from "ol";

interface IProps {
  id?: string | number;
  geometry?: Geometry;
}

const style = [
  new Style({
    stroke: new Stroke({ color: "rgba(65, 121, 0, 1)", width: 1 }),
    fill: new Fill({ color: "#ACDD73" }),
  }),
];

const Root = styled.div`
  width: 46px;
  height: 46px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f8f8;
  border-radius: 6px;
  margin-right: 16px;
`;

const FieldPreview: React.FC<IProps> = ({ geometry, id }) => {
  const [initialized, setInitialized] = useState(false);
  const handleFit = (view: View, polygon?: Geometry) =>
    polygon &&
    view.fit(polygon as SimpleGeometry, {
      padding: [5, 5, 5, 5],
      maxZoom: 1000,
    });

  useEffect(() => {
    if (initialized || geometry == null || id == null) return;
    const feature = new Feature({ geometry });
    const source = new VectorSource({ features: [feature] });
    const layer = new VectorLayer({ source, style });
    const view = new View({ center: getCenter(geometry.getExtent()) });
    const map = new Map({ layers: [layer], target: "map", view, controls: [] });
    map.setTarget(id.toString());
    map.on("moveend", () => handleFit(view, geometry));
    handleFit(view, geometry);
    setInitialized(true);
  }, [initialized, geometry, id]);
  return (
    <Root id={String(id)}>
      {id === null || (geometry == null && <FieldIcon />)}
    </Root>
  );
};
export default FieldPreview;
