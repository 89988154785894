import React from "react";
import { observer } from "mobx-react-lite";
import { ProfileScreenVMProvider } from "@screens/ProfileScreen/ProfileScreenVM";
import ProfileScreenImpl from "@screens/ProfileScreen/ProfileScreenImpl";
import { pageView } from "@src/utils/analytics";

const ProfileScreen = () => {
  pageView("Profile");

  return (
    <ProfileScreenVMProvider>
      <ProfileScreenImpl />
    </ProfileScreenVMProvider>
  );
};

export default observer(ProfileScreen);
