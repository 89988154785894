import React, { useMemo } from "react";
import useVM from "@src/hooks/useVM";
import { makeAutoObservable } from "mobx";
import { RootStore, useStores } from "@stores";
import * as devicesService from "@services/devicesService";
import { toast } from "react-toastify";
import { TCreateDeviceParams, TDevice } from "@services/devicesService";
const ctx = React.createContext<DevicesScreenVM | null>(null);

export const DevicesScreenVMProvider: React.FC = ({ children }) => {
  const rootStore = useStores();
  const store = useMemo(() => new DevicesScreenVM(rootStore), [rootStore]);
  return <ctx.Provider value={store}>{children}</ctx.Provider>;
};

export const useDevicesScreenVM = () => useVM(ctx);

class DevicesScreenVM {
  loading: boolean = false;
  private setLoading = (v: boolean) => (this.loading = v);

  initialized: boolean = false;
  private setInitialized = (v: boolean) => (this.initialized = v);

  search: string = "";
  setSearch = (v: string) => (this.search = v);

  editedDevice: TDevice | null = null;
  setEditedDevice = (v: TDevice | null) => (this.editedDevice = v);

  createDialogOpened: boolean = false;
  setCreateDialogOpened = (v: boolean) => (this.createDialogOpened = v);

  devices: TDevice[] = [];
  private setDevices = (v: TDevice[]) => (this.devices = v);

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
    this.sync().then(() => this.setInitialized(true));
  }

  create = async (device: TDevice) => {
    this.setLoading(true);
    const params: TCreateDeviceParams = {
      serial_number: device.id,
      harvester: device.harvester,
    };
    devicesService
      .createDevice(params)
      .then(this.sync)
      .then(() => this.setCreateDialogOpened(false))
      .catch((e) => toast(e.message, { type: "error" }))
      .finally(() => this.setLoading(false));
  };

  edit = async (device: TDevice) => {
    this.setLoading(true);
    devicesService
      .editDevice(device.id, { harvester: device.harvester })
      .then(this.sync)
      .then(() => this.setCreateDialogOpened(false))
      .catch((e) => toast(e.message, { type: "error" }))
      .finally(() => this.setLoading(false));
  };

  sync = () =>
    devicesService
      .getDevicesData()
      .then(({ devices }) => this.setDevices(devices))
      .catch((e) => toast(e.message, { type: "error" }));

  get inactiveDevices() {
    return this.devices.filter(({ status }) => status === "inactive");
  }
  get activeDevices() {
    return this.devices.filter(({ status }) => status === "active");
  }
}
