import _ from "lodash";
import styled from "@emotion/styled";
import { Row } from "@src/components/Flex";
import React from "react";
import Text from "@components/Text";
import SizedBox from "@components/SizedBox";
import { TDevice } from "@services/devicesService";
import { useDevicesScreenVM } from "@screens/DevicesScreen/DevicesScreenVM";
import Button from "@components/Button";
import { observer } from "mobx-react-lite";
import { useLang } from "@src/hooks/useLang";
import DeviceTagsList from "@screens/DevicesScreen/DeviceTagsList";
import { Link } from "react-router-dom";
import { ROUTES } from "@stores/AccountStore";

interface IProps {
  device: TDevice;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 16px;
  box-sizing: border-box;
  min-height: 94px;
  transition: 0.4s;
  background: #fff;
  border-bottom: 1px solid rgba(137, 144, 147, 0.3);
  position: relative;
  :hover {
    background: #f8f8f8;
    .plus-button {
      background: #fff;
    }
  }
`;

const CalibrateButton = styled(Button)`
  max-width: fit-content;
  height: 24px;
  font-size: 12px;
  line-height: 20px;
`;

const DeviceRow: React.FC<IProps> = ({ device }) => {
  const lang = useLang();
  const vm = useDevicesScreenVM();
  const handleEdit = () => {
    vm.setEditedDevice(device);
    vm.setCreateDialogOpened(true);
  };
  return (
    <Root onClick={handleEdit}>
      <Row>
        <Row style={{ marginBottom: 16 }}>
          <Text size={18} color="#494C5B" type="heading" fitContent>
            {
              _.isEmpty(device.harvester.name) 
                ? device.esn.toUpperCase()
                : device.harvester.name
            }
          </Text>
          <SizedBox width={4} />
          <Text size={14} color="#CBD5D8" type="heading" fitContent>
            •
          </Text>
          <SizedBox width={4} />
          <Text size={18} color="#899093" type="heading" fitContent>
            {device.harvester.model}
          </Text>
        </Row>
        {/*<DeviceTrackDetails justifyContent="flex-end" />*/}
      </Row>
      <Row justifyContent="space-between">
        <DeviceTagsList tags={[]} />
        {/*todo uncomment*/}
        <Link to={ROUTES.DEVICES_CALIBRATION.replace(":id", device.id)}>
          <CalibrateButton>{lang.devices.calibrateButton}</CalibrateButton>
        </Link>
      </Row>
    </Root>
  );
};
export default observer(DeviceRow);
