import React from "react";

import { DevicesScreenVMProvider } from "@screens/DevicesScreen/DevicesScreenVM";
import DevicesScreenImpl from "@screens/DevicesScreen/DevicesScreenImpl";
import { pageView } from "@src/utils/analytics";

const DevicesScreen = () => {
  pageView("Devices");
  return (
    <DevicesScreenVMProvider>
      <DevicesScreenImpl />
    </DevicesScreenVMProvider>
  );
};

export default DevicesScreen;
