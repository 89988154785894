import moment from "moment";

import localizations from "@src/localizations";
import { useStores } from "@stores";

export const useLang = () => {
  const { settingsStore } = useStores();

  const code = settingsStore.language.toLowerCase();
  if (moment.locale() !== code) {
    moment.locale(code);
  }

  return localizations[settingsStore.language];
};

export const useLangCode = () => {
  const { settingsStore } = useStores();
  return settingsStore.language.toLowerCase();
};
