import React from "react";

import mapboxgl from "mapbox-gl";
import Map from "react-map-gl";
export * from "react-map-gl";

const ACCESS_TOKEN = "pk.eyJ1IjoibmsyZ2U1ayIsImEiOiJja3U3YTNrN20xdmRsMndxa2kxejZ3ZTVrIn0.t92qRIJCkYa5Y6C8I1EaYw";

// eslint-disable-next-line import/no-webpack-loader-syntax
(mapboxgl as any).workerClass = require(
  "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
mapboxgl.accessToken = ACCESS_TOKEN;

type MapProps = Omit<React.ComponentProps<typeof Map>, 'mapboxAccessToken'>;

const Component: React.FC<MapProps> = (props) => (
  <Map
    mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
    initialViewState={{
      longitude: 0.0045445,
      latitude: 51.4874022,
      zoom: 3,
    }}
    {...props}
    mapboxAccessToken={ACCESS_TOKEN}
  />
);


export default Component;
