import styled from "@emotion/styled";
import React, { CSSProperties, useState } from "react";
import Text from "@components/Text";
import { Row } from "./Flex";
import { ReactComponent as EyeIcon } from "@assets/icons/eye.svg";
import { ReactComponent as EyeOffIcon } from "@assets/icons/eyeOff.svg";
import { ReactComponent as InfoIcon } from "@assets/icons/info.svg";
import Tooltip from "@components/Tooltip";

const InputImpl = styled.input`
  width: 100%;
  outline: none;
  padding: 0 12px 0 16px;
  height: 44px;
  background: #ffffff;
  border-radius: 4px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #494c5b;
  box-sizing: border-box;
  border: 1px solid rgb(226 232 240);
  transition: 0.4s;

  :disabled {
    border: 1px solid rgb(226 232 240) !important;
    background-color: rgb(248 250 252);
  }

  :hover,
  :focus {
    border: 1px solid #899093;
  }
  ::placeholder {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #cbd5d8;
  }
`;

export interface IInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  label?: string;
  extra?: string;
  onExtraClick?: () => void;
  wrapperStyle?: CSSProperties;
  icon?: JSX.Element;
  tip?: string;
}

const InputWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const EyeWrapper = styled(Row)`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 12px;
  cursor: pointer;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  width: fit-content;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
`;

const Input: React.FC<IInputProps> = ({
  wrapperStyle,
  label,
  extra,
  onExtraClick,
  icon,
  tip,
  ...rest
}) => {
  const [showPass, setShowPass] = useState(false);
  return (
    <div style={{ width: "100%", ...wrapperStyle }}>
      <Row justifyContent="space-between" alignItems="center">
        {label && (
          <Row alignItems="center" mainAxisSize="fit-content">
            <Text size={14} type="heading" color="#494C5B">
              {label}
            </Text>
            {tip != null && (
              <Tooltip
                content={
                  <Text size={12} style={{ maxWidth: 150 }}>
                    {tip}
                  </Text>
                }
              >
                <InfoIcon style={{ marginLeft: 8 }} />
              </Tooltip>
            )}
          </Row>
        )}
        {extra && (
          <Text
            size={14}
            color="#68AD18"
            style={{ cursor: "pointer" }}
            onClick={onExtraClick}
            fitContent
            nowrap
          >
            {extra}
          </Text>
        )}
      </Row>

      <InputWrapper>
        {icon && <IconWrapper>{icon}</IconWrapper>}
        <InputImpl
          {...rest}
          type={rest.type === "password" && showPass ? "text" : rest.type}
        />
        {rest.type === "password" && (
          <EyeWrapper onClick={() => setShowPass((v) => !v)}>
            {showPass ? <EyeIcon /> : <EyeOffIcon />}
          </EyeWrapper>
        )}
      </InputWrapper>
    </div>
  );
};

export default Input;
