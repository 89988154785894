import React, { CSSProperties } from "react";

const SizedBox: React.FunctionComponent<{
  width?: number;
  height?: number;
  style?: CSSProperties;
}> = ({ width, height, style }) => (
  <div style={{ width, height, display: "flex", flex: "0 0 auto", ...style }} />
);

export default SizedBox;
