import { Feature, FeatureCollection } from "geojson";
import { cellsToMultiPolygon } from "h3-js";

import { APIClient } from "@proto/apis/mower/v1/ApiServiceClientPb";
import { PrescriptionRequest } from "@proto/apis/mower/v1/api_pb";

export * from "@proto/apis/mower/v1/api_pb";

const BASE_URL = "https://at.greengrowth.tech";
const MowerClient = new APIClient(BASE_URL, null, null);

// fetchPrescription is a simple wrapper around MowerClient that allows
// to fetch prescriptions and convert them to the GeoJSON representation
// with colors assigned by interpolate function.
export const fetchPrescription = async (
  field_id: string,
  count: number,
  interpolate?: (n: number, max: number) => string,
): Promise<FeatureCollection> => {
  const response = await MowerClient.prescription(
    new PrescriptionRequest()
      .setSplit(count)
      .setFieldId(field_id),
    null,
  );

  let features: Feature[] = [];

  response.getBucketsList().reverse().forEach((bucket, index) => {
    const id = index + 1;
    cellsToMultiPolygon(bucket.getHexgonsList(), true).forEach((polygon) => {
      features.push({
        type: "Feature",
        properties: {
          zone: index + 1,
          fill: interpolate ? interpolate(id, count) : "",
        },
        geometry: {
          type: "Polygon",
          coordinates: polygon,
        }
      });
    });
  });

  return {
    type: "FeatureCollection",
    features: features,
  };
};

export default MowerClient;
