import styled from "@emotion/styled";
import React, { useState } from "react";
import Text from "@components/Text";
import FieldRow from "@screens/FieldsScreen/FieldsMenu/FieldRow";
import {
  isRecentlyUpdated,
  getFieldArea,
  useFieldsScreenVM,
} from "@screens/FieldsScreen/FieldsScreenVM";
import { observer } from "mobx-react-lite";
import { useLang } from "@src/hooks/useLang";
import SizedBox from "@components/SizedBox";
import Button from "@components/Button";
import { ReactComponent as EditIcon } from "@assets/icons/edit.svg";
import { Column } from "@src/components/Flex";
import { ReactComponent as SearchIcon } from "@assets/icons/search.svg";
import { SearchInput } from "@screens/CropsScreen/CropsScreen";
import UploadFromFileDialog from "@screens/FieldsScreen/dialogs/UploadFromFileDialog";
import { toast } from "react-toastify";
import { useSeasonId } from "@src/stores/SeasonStore";

interface IProps { }

const Body = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  justify-content: space-between;
`;

const Header = styled.div`
  width: 100%;
  margin-bottom: 12px;
  padding: 0 16px;
  box-sizing: border-box;
`;


const ViewFieldsMenu: React.FC<IProps> = () => {
  const vm = useFieldsScreenVM();
  const sid = useSeasonId();

  const handleSelect = (id: string | number) => vm.selectField(id);
  const lang = useLang();
  const [uploadDialogOpened, setUploadDialogOpened] = useState(false);
  const importFile = (f: File) =>
    vm
      .importFromFile(f, sid)
      .then(() => {
        setUploadDialogOpened(false);
        toast.success(lang.fields.uploadSuccess);
      })
      .catch((e) => {
        toast.error(e.message ?? e.toString());
        toast.error(lang.fields.uploadError);
      });

  return (
    <Column mainAxisSize="stretch" crossAxisSize="max">
      <Header>
        {vm.fields != null && vm.fields.length > 0 ? (
          <Column crossAxisSize="max">
            <Text color="#494C5B" type="heading" size={24}>
              {lang.fields.title} : {vm.fields?.length}
            </Text>
            <SizedBox height={12} />
            <SearchInput
              style={{ width: "100%" }}
              value={vm.search}
              onChange={(e) => vm.setSearch(e.target.value)}
              placeholder={lang.crops.searchFill}
              icon={<SearchIcon />}
            />
          </Column>
        ) : (
          <>
            {/*<BackIcon />*/}
            <SizedBox height={16} />
            <Text color="#494C5B" type="heading" size={24}>
              {lang.fields.drawFieldsTitle}
            </Text>
            <Text style={{ marginTop: 8 }} size={15} color="#899093">
              {lang.fields.drawFiledsSubtitle}
            </Text>
          </>
        )}
      </Header>
      <Body>
        <Column crossAxisSize="max">
          {vm.fields
            .filter((field) =>
              field
                .getProperties()
                .name.toLowerCase()
                .includes(vm.search.toLowerCase())
            )
            .map((field) => {
              const id = field.getId();
              return id ? (
                <FieldRow
                  highlighted={isRecentlyUpdated(field.getProperties()?.stats)}
                  id={id}
                  key={id}
                  selected={vm.selectedFieldId === id}
                  title={field.getProperties().name}
                  area={field.getProperties().area ?? getFieldArea(field)}
                  crop={vm.getCropById(field.getProperties().culture_id)?.name}
                  onClick={() => handleSelect(id)}
                  geometry={field.getGeometry()}
                  style={{ position: "relative" }}
                  extra={
                    <EditIcon
                      style={{ flexShrink: 0, cursor: "pointer" }}
                      onClick={() => vm.handleFieldUpdate(id)}
                    />
                  }
                />
              ) : null;
            })}
        </Column>
        <Column crossAxisSize="max">
          <Button
            onClick={vm.handleFieldCreate}
            style={{
              maxWidth: "calc(100% - 32px)",
              margin: "16px",
              flexShrink: 0,
            }}
          >
            {lang.fields.drawFieldsButton}
          </Button>
          <Button
            onClick={() => setUploadDialogOpened(true)}
            kind="outline"
            style={{
              maxWidth: "calc(100% - 32px)",
              margin: "0 16px",
              flexShrink: 0,
            }}
          >
            {lang.fields.uploadButton}
          </Button>
        </Column>
      </Body>
      <UploadFromFileDialog
        onSubmit={importFile}
        visible={uploadDialogOpened}
        onClose={() => setUploadDialogOpened(false)}
      />
    </Column>
  );
};
export default observer(ViewFieldsMenu);
