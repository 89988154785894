import React from "react";
import {
  DeviceCalibrationScreenVMProvider,
  useDeviceCalibrationScreenVM,
} from "@screens/DeviceCalibrationScreen/DeviceCalibrationScreenVM";
import { observer } from "mobx-react-lite";
import { Navigate } from "react-router-dom";
import { ROUTES } from "@stores/AccountStore";
import DeviceCalibrationMenu from "@screens/DeviceCalibrationScreen/DeviceCalibrationMenu";
import styled from "@emotion/styled";
import LeftBar from "@components/Layout/LeftBar";
import DeviceCalibrationTable from "@screens/DeviceCalibrationScreen/DeviceCalibrationTable";
import DeviceCalibrationDialog from "@screens/DeviceCalibrationScreen/dialogs/DeviceCalibrationDialog";
import { pageView } from "@src/utils/analytics";

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  align-items: stretch;
  min-width: 1152px;
`;

const DeviceCalibrationScreenImpl = observer(() => {
  const vm = useDeviceCalibrationScreenVM();

  if (vm.device == null && vm.initialized) {
    return <Navigate to={ROUTES.DEVICES} />;
  }

  return (
    <Root>
      <LeftBar style={{ marginRight: 0 }} />
      <DeviceCalibrationMenu />
      <DeviceCalibrationTable />
      <DeviceCalibrationDialog
        destroyOnClose
        id={vm.editCalibrationId}
        onSubmit={
          vm.editCalibrationId == null
            ? vm.createCalibration
            : vm.editCalibration
        }
        visible={vm.dialogVisible}
        onClose={() => vm.setDialogVisible(false)}
      />
    </Root>
  );
});

const DeviceCalibrationScreen = () => {
  pageView("DeviceCalibration");
  return (
    <DeviceCalibrationScreenVMProvider>
      <DeviceCalibrationScreenImpl />
    </DeviceCalibrationScreenVMProvider>
  );
};

export default DeviceCalibrationScreen;
