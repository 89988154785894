/**
 * @fileoverview gRPC-Web generated client stub for grass.devices.v1
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v5.29.0-dev
// source: apis/devices/v1/api.proto


/* eslint-disable */
// @ts-nocheck


import * as grpcWeb from 'grpc-web';

import * as apis_devices_v1_api_pb from '../../../apis/devices/v1/api_pb'; // proto import: "apis/devices/v1/api.proto"


export class DevicesAPIClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorLastStatus = new grpcWeb.MethodDescriptor(
    '/grass.devices.v1.DevicesAPI/LastStatus',
    grpcWeb.MethodType.UNARY,
    apis_devices_v1_api_pb.LastStatusRequest,
    apis_devices_v1_api_pb.LastStatusResponse,
    (request: apis_devices_v1_api_pb.LastStatusRequest) => {
      return request.serializeBinary();
    },
    apis_devices_v1_api_pb.LastStatusResponse.deserializeBinary
  );

  lastStatus(
    request: apis_devices_v1_api_pb.LastStatusRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_devices_v1_api_pb.LastStatusResponse>;

  lastStatus(
    request: apis_devices_v1_api_pb.LastStatusRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.LastStatusResponse) => void): grpcWeb.ClientReadableStream<apis_devices_v1_api_pb.LastStatusResponse>;

  lastStatus(
    request: apis_devices_v1_api_pb.LastStatusRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.LastStatusResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.devices.v1.DevicesAPI/LastStatus',
        request,
        metadata || {},
        this.methodDescriptorLastStatus,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.devices.v1.DevicesAPI/LastStatus',
    request,
    metadata || {},
    this.methodDescriptorLastStatus);
  }

  methodDescriptorStatistics = new grpcWeb.MethodDescriptor(
    '/grass.devices.v1.DevicesAPI/Statistics',
    grpcWeb.MethodType.UNARY,
    apis_devices_v1_api_pb.StatisticsRequest,
    apis_devices_v1_api_pb.StatisticsResponse,
    (request: apis_devices_v1_api_pb.StatisticsRequest) => {
      return request.serializeBinary();
    },
    apis_devices_v1_api_pb.StatisticsResponse.deserializeBinary
  );

  statistics(
    request: apis_devices_v1_api_pb.StatisticsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_devices_v1_api_pb.StatisticsResponse>;

  statistics(
    request: apis_devices_v1_api_pb.StatisticsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.StatisticsResponse) => void): grpcWeb.ClientReadableStream<apis_devices_v1_api_pb.StatisticsResponse>;

  statistics(
    request: apis_devices_v1_api_pb.StatisticsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.StatisticsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.devices.v1.DevicesAPI/Statistics',
        request,
        metadata || {},
        this.methodDescriptorStatistics,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.devices.v1.DevicesAPI/Statistics',
    request,
    metadata || {},
    this.methodDescriptorStatistics);
  }

  methodDescriptorUserDevices = new grpcWeb.MethodDescriptor(
    '/grass.devices.v1.DevicesAPI/UserDevices',
    grpcWeb.MethodType.UNARY,
    apis_devices_v1_api_pb.UserDevicesRequest,
    apis_devices_v1_api_pb.UserDevicesResponse,
    (request: apis_devices_v1_api_pb.UserDevicesRequest) => {
      return request.serializeBinary();
    },
    apis_devices_v1_api_pb.UserDevicesResponse.deserializeBinary
  );

  userDevices(
    request: apis_devices_v1_api_pb.UserDevicesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_devices_v1_api_pb.UserDevicesResponse>;

  userDevices(
    request: apis_devices_v1_api_pb.UserDevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.UserDevicesResponse) => void): grpcWeb.ClientReadableStream<apis_devices_v1_api_pb.UserDevicesResponse>;

  userDevices(
    request: apis_devices_v1_api_pb.UserDevicesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.UserDevicesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.devices.v1.DevicesAPI/UserDevices',
        request,
        metadata || {},
        this.methodDescriptorUserDevices,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.devices.v1.DevicesAPI/UserDevices',
    request,
    metadata || {},
    this.methodDescriptorUserDevices);
  }

  methodDescriptorUserStatistics = new grpcWeb.MethodDescriptor(
    '/grass.devices.v1.DevicesAPI/UserStatistics',
    grpcWeb.MethodType.UNARY,
    apis_devices_v1_api_pb.UserStatisticsRequest,
    apis_devices_v1_api_pb.UserStatisticsResponse,
    (request: apis_devices_v1_api_pb.UserStatisticsRequest) => {
      return request.serializeBinary();
    },
    apis_devices_v1_api_pb.UserStatisticsResponse.deserializeBinary
  );

  userStatistics(
    request: apis_devices_v1_api_pb.UserStatisticsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_devices_v1_api_pb.UserStatisticsResponse>;

  userStatistics(
    request: apis_devices_v1_api_pb.UserStatisticsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.UserStatisticsResponse) => void): grpcWeb.ClientReadableStream<apis_devices_v1_api_pb.UserStatisticsResponse>;

  userStatistics(
    request: apis_devices_v1_api_pb.UserStatisticsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.UserStatisticsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.devices.v1.DevicesAPI/UserStatistics',
        request,
        metadata || {},
        this.methodDescriptorUserStatistics,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.devices.v1.DevicesAPI/UserStatistics',
    request,
    metadata || {},
    this.methodDescriptorUserStatistics);
  }

  methodDescriptorGetDevice = new grpcWeb.MethodDescriptor(
    '/grass.devices.v1.DevicesAPI/GetDevice',
    grpcWeb.MethodType.UNARY,
    apis_devices_v1_api_pb.GetDeviceRequest,
    apis_devices_v1_api_pb.GetDeviceResponse,
    (request: apis_devices_v1_api_pb.GetDeviceRequest) => {
      return request.serializeBinary();
    },
    apis_devices_v1_api_pb.GetDeviceResponse.deserializeBinary
  );

  getDevice(
    request: apis_devices_v1_api_pb.GetDeviceRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_devices_v1_api_pb.GetDeviceResponse>;

  getDevice(
    request: apis_devices_v1_api_pb.GetDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.GetDeviceResponse) => void): grpcWeb.ClientReadableStream<apis_devices_v1_api_pb.GetDeviceResponse>;

  getDevice(
    request: apis_devices_v1_api_pb.GetDeviceRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.GetDeviceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.devices.v1.DevicesAPI/GetDevice',
        request,
        metadata || {},
        this.methodDescriptorGetDevice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.devices.v1.DevicesAPI/GetDevice',
    request,
    metadata || {},
    this.methodDescriptorGetDevice);
  }

  methodDescriptorUpdateDevice = new grpcWeb.MethodDescriptor(
    '/grass.devices.v1.DevicesAPI/UpdateDevice',
    grpcWeb.MethodType.UNARY,
    apis_devices_v1_api_pb.UpdateDeviceRequest,
    apis_devices_v1_api_pb.UpdateDeviceResponse,
    (request: apis_devices_v1_api_pb.UpdateDeviceRequest) => {
      return request.serializeBinary();
    },
    apis_devices_v1_api_pb.UpdateDeviceResponse.deserializeBinary
  );

  updateDevice(
    request: apis_devices_v1_api_pb.UpdateDeviceRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_devices_v1_api_pb.UpdateDeviceResponse>;

  updateDevice(
    request: apis_devices_v1_api_pb.UpdateDeviceRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.UpdateDeviceResponse) => void): grpcWeb.ClientReadableStream<apis_devices_v1_api_pb.UpdateDeviceResponse>;

  updateDevice(
    request: apis_devices_v1_api_pb.UpdateDeviceRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.UpdateDeviceResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.devices.v1.DevicesAPI/UpdateDevice',
        request,
        metadata || {},
        this.methodDescriptorUpdateDevice,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.devices.v1.DevicesAPI/UpdateDevice',
    request,
    metadata || {},
    this.methodDescriptorUpdateDevice);
  }

  methodDescriptorUpdateDeviceSettings = new grpcWeb.MethodDescriptor(
    '/grass.devices.v1.DevicesAPI/UpdateDeviceSettings',
    grpcWeb.MethodType.UNARY,
    apis_devices_v1_api_pb.UpdateDeviceSettingsRequest,
    apis_devices_v1_api_pb.UpdateDeviceSettingsResponse,
    (request: apis_devices_v1_api_pb.UpdateDeviceSettingsRequest) => {
      return request.serializeBinary();
    },
    apis_devices_v1_api_pb.UpdateDeviceSettingsResponse.deserializeBinary
  );

  updateDeviceSettings(
    request: apis_devices_v1_api_pb.UpdateDeviceSettingsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_devices_v1_api_pb.UpdateDeviceSettingsResponse>;

  updateDeviceSettings(
    request: apis_devices_v1_api_pb.UpdateDeviceSettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.UpdateDeviceSettingsResponse) => void): grpcWeb.ClientReadableStream<apis_devices_v1_api_pb.UpdateDeviceSettingsResponse>;

  updateDeviceSettings(
    request: apis_devices_v1_api_pb.UpdateDeviceSettingsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.UpdateDeviceSettingsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.devices.v1.DevicesAPI/UpdateDeviceSettings',
        request,
        metadata || {},
        this.methodDescriptorUpdateDeviceSettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.devices.v1.DevicesAPI/UpdateDeviceSettings',
    request,
    metadata || {},
    this.methodDescriptorUpdateDeviceSettings);
  }

  methodDescriptorCalibrationsForInterval = new grpcWeb.MethodDescriptor(
    '/grass.devices.v1.DevicesAPI/CalibrationsForInterval',
    grpcWeb.MethodType.UNARY,
    apis_devices_v1_api_pb.CalibrationsForIntervalRequest,
    apis_devices_v1_api_pb.CalibrationsForIntervalResponse,
    (request: apis_devices_v1_api_pb.CalibrationsForIntervalRequest) => {
      return request.serializeBinary();
    },
    apis_devices_v1_api_pb.CalibrationsForIntervalResponse.deserializeBinary
  );

  calibrationsForInterval(
    request: apis_devices_v1_api_pb.CalibrationsForIntervalRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_devices_v1_api_pb.CalibrationsForIntervalResponse>;

  calibrationsForInterval(
    request: apis_devices_v1_api_pb.CalibrationsForIntervalRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.CalibrationsForIntervalResponse) => void): grpcWeb.ClientReadableStream<apis_devices_v1_api_pb.CalibrationsForIntervalResponse>;

  calibrationsForInterval(
    request: apis_devices_v1_api_pb.CalibrationsForIntervalRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.CalibrationsForIntervalResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.devices.v1.DevicesAPI/CalibrationsForInterval',
        request,
        metadata || {},
        this.methodDescriptorCalibrationsForInterval,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.devices.v1.DevicesAPI/CalibrationsForInterval',
    request,
    metadata || {},
    this.methodDescriptorCalibrationsForInterval);
  }

}

export class APIClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorDevices = new grpcWeb.MethodDescriptor(
    '/grass.devices.v1.API/Devices',
    grpcWeb.MethodType.UNARY,
    apis_devices_v1_api_pb.DevicesRequest,
    apis_devices_v1_api_pb.DevicesResponse,
    (request: apis_devices_v1_api_pb.DevicesRequest) => {
      return request.serializeBinary();
    },
    apis_devices_v1_api_pb.DevicesResponse.deserializeBinary
  );

  devices(
    request: apis_devices_v1_api_pb.DevicesRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_devices_v1_api_pb.DevicesResponse>;

  devices(
    request: apis_devices_v1_api_pb.DevicesRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.DevicesResponse) => void): grpcWeb.ClientReadableStream<apis_devices_v1_api_pb.DevicesResponse>;

  devices(
    request: apis_devices_v1_api_pb.DevicesRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.DevicesResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.devices.v1.API/Devices',
        request,
        metadata || {},
        this.methodDescriptorDevices,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.devices.v1.API/Devices',
    request,
    metadata || {},
    this.methodDescriptorDevices);
  }

  methodDescriptorDeviceStatistics = new grpcWeb.MethodDescriptor(
    '/grass.devices.v1.API/DeviceStatistics',
    grpcWeb.MethodType.UNARY,
    apis_devices_v1_api_pb.DeviceStatisticsRequest,
    apis_devices_v1_api_pb.DeviceStatisticsResponse,
    (request: apis_devices_v1_api_pb.DeviceStatisticsRequest) => {
      return request.serializeBinary();
    },
    apis_devices_v1_api_pb.DeviceStatisticsResponse.deserializeBinary
  );

  deviceStatistics(
    request: apis_devices_v1_api_pb.DeviceStatisticsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_devices_v1_api_pb.DeviceStatisticsResponse>;

  deviceStatistics(
    request: apis_devices_v1_api_pb.DeviceStatisticsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.DeviceStatisticsResponse) => void): grpcWeb.ClientReadableStream<apis_devices_v1_api_pb.DeviceStatisticsResponse>;

  deviceStatistics(
    request: apis_devices_v1_api_pb.DeviceStatisticsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.DeviceStatisticsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.devices.v1.API/DeviceStatistics',
        request,
        metadata || {},
        this.methodDescriptorDeviceStatistics,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.devices.v1.API/DeviceStatistics',
    request,
    metadata || {},
    this.methodDescriptorDeviceStatistics);
  }

  methodDescriptorDeviceSettings = new grpcWeb.MethodDescriptor(
    '/grass.devices.v1.API/DeviceSettings',
    grpcWeb.MethodType.UNARY,
    apis_devices_v1_api_pb.DeviceSettingsRequest,
    apis_devices_v1_api_pb.DeviceSettingsResponse,
    (request: apis_devices_v1_api_pb.DeviceSettingsRequest) => {
      return request.serializeBinary();
    },
    apis_devices_v1_api_pb.DeviceSettingsResponse.deserializeBinary
  );

  deviceSettings(
    request: apis_devices_v1_api_pb.DeviceSettingsRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_devices_v1_api_pb.DeviceSettingsResponse>;

  deviceSettings(
    request: apis_devices_v1_api_pb.DeviceSettingsRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.DeviceSettingsResponse) => void): grpcWeb.ClientReadableStream<apis_devices_v1_api_pb.DeviceSettingsResponse>;

  deviceSettings(
    request: apis_devices_v1_api_pb.DeviceSettingsRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.DeviceSettingsResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.devices.v1.API/DeviceSettings',
        request,
        metadata || {},
        this.methodDescriptorDeviceSettings,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.devices.v1.API/DeviceSettings',
    request,
    metadata || {},
    this.methodDescriptorDeviceSettings);
  }

}

export class JobsAPIClient {
  client_: grpcWeb.AbstractClientBase;
  hostname_: string;
  credentials_: null | { [index: string]: string; };
  options_: null | { [index: string]: any; };

  constructor (hostname: string,
               credentials?: null | { [index: string]: string; },
               options?: null | { [index: string]: any; }) {
    if (!options) options = {};
    if (!credentials) credentials = {};
    options['format'] = 'text';

    this.client_ = new grpcWeb.GrpcWebClientBase(options);
    this.hostname_ = hostname.replace(/\/+$/, '');
    this.credentials_ = credentials;
    this.options_ = options;
  }

  methodDescriptorRunTracksBackup = new grpcWeb.MethodDescriptor(
    '/grass.devices.v1.JobsAPI/RunTracksBackup',
    grpcWeb.MethodType.UNARY,
    apis_devices_v1_api_pb.RunTracksBackupRequest,
    apis_devices_v1_api_pb.RunTracksBackupResponse,
    (request: apis_devices_v1_api_pb.RunTracksBackupRequest) => {
      return request.serializeBinary();
    },
    apis_devices_v1_api_pb.RunTracksBackupResponse.deserializeBinary
  );

  runTracksBackup(
    request: apis_devices_v1_api_pb.RunTracksBackupRequest,
    metadata?: grpcWeb.Metadata | null): Promise<apis_devices_v1_api_pb.RunTracksBackupResponse>;

  runTracksBackup(
    request: apis_devices_v1_api_pb.RunTracksBackupRequest,
    metadata: grpcWeb.Metadata | null,
    callback: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.RunTracksBackupResponse) => void): grpcWeb.ClientReadableStream<apis_devices_v1_api_pb.RunTracksBackupResponse>;

  runTracksBackup(
    request: apis_devices_v1_api_pb.RunTracksBackupRequest,
    metadata?: grpcWeb.Metadata | null,
    callback?: (err: grpcWeb.RpcError,
               response: apis_devices_v1_api_pb.RunTracksBackupResponse) => void) {
    if (callback !== undefined) {
      return this.client_.rpcCall(
        this.hostname_ +
          '/grass.devices.v1.JobsAPI/RunTracksBackup',
        request,
        metadata || {},
        this.methodDescriptorRunTracksBackup,
        callback);
    }
    return this.client_.unaryCall(
    this.hostname_ +
      '/grass.devices.v1.JobsAPI/RunTracksBackup',
    request,
    metadata || {},
    this.methodDescriptorRunTracksBackup);
  }

}

