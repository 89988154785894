// source: apis/demos/v1/api.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global =
    (typeof globalThis !== 'undefined' && globalThis) ||
    (typeof window !== 'undefined' && window) ||
    (typeof global !== 'undefined' && global) ||
    (typeof self !== 'undefined' && self) ||
    (function () { return this; }).call(null) ||
    Function('return this')();

var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js');
goog.object.extend(proto, google_protobuf_empty_pb);
var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js');
goog.object.extend(proto, google_protobuf_timestamp_pb);
goog.exportSymbol('proto.grass.demos.v1.DeleteSettingsRequest', null, global);
goog.exportSymbol('proto.grass.demos.v1.DeleteSettingsResponse', null, global);
goog.exportSymbol('proto.grass.demos.v1.FeaturesRequest', null, global);
goog.exportSymbol('proto.grass.demos.v1.FeaturesResponse', null, global);
goog.exportSymbol('proto.grass.demos.v1.GetSchemaResponse', null, global);
goog.exportSymbol('proto.grass.demos.v1.GetSettingsRequest', null, global);
goog.exportSymbol('proto.grass.demos.v1.GetSettingsResponse', null, global);
goog.exportSymbol('proto.grass.demos.v1.LocalizationPreferences', null, global);
goog.exportSymbol('proto.grass.demos.v1.MeResponse', null, global);
goog.exportSymbol('proto.grass.demos.v1.Preferences', null, global);
goog.exportSymbol('proto.grass.demos.v1.PreferencesRequest', null, global);
goog.exportSymbol('proto.grass.demos.v1.PreferencesResponse', null, global);
goog.exportSymbol('proto.grass.demos.v1.ResetUserPreferencesRequest', null, global);
goog.exportSymbol('proto.grass.demos.v1.ResetUserPreferencesResponse', null, global);
goog.exportSymbol('proto.grass.demos.v1.UpdatePreferencesRequest', null, global);
goog.exportSymbol('proto.grass.demos.v1.UpdatePreferencesResponse', null, global);
goog.exportSymbol('proto.grass.demos.v1.UpdateSchemaRequest', null, global);
goog.exportSymbol('proto.grass.demos.v1.UpdateSchemaResponse', null, global);
goog.exportSymbol('proto.grass.demos.v1.UpdateSettingsRequest', null, global);
goog.exportSymbol('proto.grass.demos.v1.UpdateSettingsResponse', null, global);
goog.exportSymbol('proto.grass.demos.v1.UpdateUserPreferencesRequest', null, global);
goog.exportSymbol('proto.grass.demos.v1.UpdateUserPreferencesResponse', null, global);
goog.exportSymbol('proto.grass.demos.v1.UserPreferencesRequest', null, global);
goog.exportSymbol('proto.grass.demos.v1.UserPreferencesResponse', null, global);
goog.exportSymbol('proto.grass.demos.v1.Version', null, global);
goog.exportSymbol('proto.grass.demos.v1.WebUIPreferences', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.MeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.MeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.MeResponse.displayName = 'proto.grass.demos.v1.MeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.Version = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.Version, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.Version.displayName = 'proto.grass.demos.v1.Version';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.GetSchemaResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.GetSchemaResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.GetSchemaResponse.displayName = 'proto.grass.demos.v1.GetSchemaResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.UpdateSchemaRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.UpdateSchemaRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.UpdateSchemaRequest.displayName = 'proto.grass.demos.v1.UpdateSchemaRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.UpdateSchemaResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.UpdateSchemaResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.UpdateSchemaResponse.displayName = 'proto.grass.demos.v1.UpdateSchemaResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.GetSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.GetSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.GetSettingsRequest.displayName = 'proto.grass.demos.v1.GetSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.GetSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.GetSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.GetSettingsResponse.displayName = 'proto.grass.demos.v1.GetSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.UpdateSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.UpdateSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.UpdateSettingsRequest.displayName = 'proto.grass.demos.v1.UpdateSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.UpdateSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.UpdateSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.UpdateSettingsResponse.displayName = 'proto.grass.demos.v1.UpdateSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.DeleteSettingsRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.DeleteSettingsRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.DeleteSettingsRequest.displayName = 'proto.grass.demos.v1.DeleteSettingsRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.DeleteSettingsResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.DeleteSettingsResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.DeleteSettingsResponse.displayName = 'proto.grass.demos.v1.DeleteSettingsResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.UserPreferencesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.UserPreferencesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.UserPreferencesRequest.displayName = 'proto.grass.demos.v1.UserPreferencesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.UserPreferencesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.UserPreferencesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.UserPreferencesResponse.displayName = 'proto.grass.demos.v1.UserPreferencesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.UpdateUserPreferencesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.UpdateUserPreferencesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.UpdateUserPreferencesRequest.displayName = 'proto.grass.demos.v1.UpdateUserPreferencesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.UpdateUserPreferencesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.UpdateUserPreferencesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.UpdateUserPreferencesResponse.displayName = 'proto.grass.demos.v1.UpdateUserPreferencesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.ResetUserPreferencesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.ResetUserPreferencesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.ResetUserPreferencesRequest.displayName = 'proto.grass.demos.v1.ResetUserPreferencesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.ResetUserPreferencesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.ResetUserPreferencesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.ResetUserPreferencesResponse.displayName = 'proto.grass.demos.v1.ResetUserPreferencesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.LocalizationPreferences = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.LocalizationPreferences, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.LocalizationPreferences.displayName = 'proto.grass.demos.v1.LocalizationPreferences';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.WebUIPreferences = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.WebUIPreferences, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.WebUIPreferences.displayName = 'proto.grass.demos.v1.WebUIPreferences';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.Preferences = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.Preferences, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.Preferences.displayName = 'proto.grass.demos.v1.Preferences';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.PreferencesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.PreferencesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.PreferencesRequest.displayName = 'proto.grass.demos.v1.PreferencesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.PreferencesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.PreferencesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.PreferencesResponse.displayName = 'proto.grass.demos.v1.PreferencesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.UpdatePreferencesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.UpdatePreferencesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.UpdatePreferencesRequest.displayName = 'proto.grass.demos.v1.UpdatePreferencesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.UpdatePreferencesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.UpdatePreferencesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.UpdatePreferencesResponse.displayName = 'proto.grass.demos.v1.UpdatePreferencesResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.FeaturesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.grass.demos.v1.FeaturesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.FeaturesRequest.displayName = 'proto.grass.demos.v1.FeaturesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.grass.demos.v1.FeaturesResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.grass.demos.v1.FeaturesResponse.repeatedFields_, null);
};
goog.inherits(proto.grass.demos.v1.FeaturesResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.grass.demos.v1.FeaturesResponse.displayName = 'proto.grass.demos.v1.FeaturesResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.MeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.MeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.MeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.MeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    loginName: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    profilePictureUrl: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.MeResponse}
 */
proto.grass.demos.v1.MeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.MeResponse;
  return proto.grass.demos.v1.MeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.MeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.MeResponse}
 */
proto.grass.demos.v1.MeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setLoginName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setProfilePictureUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.MeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.MeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.MeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.MeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getLoginName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getProfilePictureUrl();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.grass.demos.v1.MeResponse.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.demos.v1.MeResponse} returns this
 */
proto.grass.demos.v1.MeResponse.prototype.setUid = function(value) {
  return jspb.Message.setField(this, 1, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.grass.demos.v1.MeResponse} returns this
 */
proto.grass.demos.v1.MeResponse.prototype.clearUid = function() {
  return jspb.Message.setField(this, 1, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.demos.v1.MeResponse.prototype.hasUid = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string login_name = 2;
 * @return {string}
 */
proto.grass.demos.v1.MeResponse.prototype.getLoginName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.demos.v1.MeResponse} returns this
 */
proto.grass.demos.v1.MeResponse.prototype.setLoginName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.grass.demos.v1.MeResponse.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.demos.v1.MeResponse} returns this
 */
proto.grass.demos.v1.MeResponse.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string profile_picture_url = 4;
 * @return {string}
 */
proto.grass.demos.v1.MeResponse.prototype.getProfilePictureUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.demos.v1.MeResponse} returns this
 */
proto.grass.demos.v1.MeResponse.prototype.setProfilePictureUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.Version.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.Version.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.Version} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.Version.toObject = function(includeInstance, msg) {
  var f, obj = {
    major: jspb.Message.getFieldWithDefault(msg, 1, 0),
    minor: jspb.Message.getFieldWithDefault(msg, 2, 0),
    patch: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.Version}
 */
proto.grass.demos.v1.Version.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.Version;
  return proto.grass.demos.v1.Version.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.Version} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.Version}
 */
proto.grass.demos.v1.Version.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMajor(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setMinor(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setPatch(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.Version.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.Version.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.Version} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.Version.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMajor();
  if (f !== 0) {
    writer.writeUint32(
      1,
      f
    );
  }
  f = message.getMinor();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getPatch();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
};


/**
 * optional uint32 major = 1;
 * @return {number}
 */
proto.grass.demos.v1.Version.prototype.getMajor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.demos.v1.Version} returns this
 */
proto.grass.demos.v1.Version.prototype.setMajor = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional uint32 minor = 2;
 * @return {number}
 */
proto.grass.demos.v1.Version.prototype.getMinor = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.demos.v1.Version} returns this
 */
proto.grass.demos.v1.Version.prototype.setMinor = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 patch = 3;
 * @return {number}
 */
proto.grass.demos.v1.Version.prototype.getPatch = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.demos.v1.Version} returns this
 */
proto.grass.demos.v1.Version.prototype.setPatch = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.GetSchemaResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.GetSchemaResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.GetSchemaResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.GetSchemaResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: (f = msg.getVersion()) && proto.grass.demos.v1.Version.toObject(includeInstance, f),
    schema: msg.getSchema_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.GetSchemaResponse}
 */
proto.grass.demos.v1.GetSchemaResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.GetSchemaResponse;
  return proto.grass.demos.v1.GetSchemaResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.GetSchemaResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.GetSchemaResponse}
 */
proto.grass.demos.v1.GetSchemaResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grass.demos.v1.Version;
      reader.readMessage(value,proto.grass.demos.v1.Version.deserializeBinaryFromReader);
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSchema(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.GetSchemaResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.GetSchemaResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.GetSchemaResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.GetSchemaResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grass.demos.v1.Version.serializeBinaryToWriter
    );
  }
  f = message.getSchema_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional Version version = 1;
 * @return {?proto.grass.demos.v1.Version}
 */
proto.grass.demos.v1.GetSchemaResponse.prototype.getVersion = function() {
  return /** @type{?proto.grass.demos.v1.Version} */ (
    jspb.Message.getWrapperField(this, proto.grass.demos.v1.Version, 1));
};


/**
 * @param {?proto.grass.demos.v1.Version|undefined} value
 * @return {!proto.grass.demos.v1.GetSchemaResponse} returns this
*/
proto.grass.demos.v1.GetSchemaResponse.prototype.setVersion = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.demos.v1.GetSchemaResponse} returns this
 */
proto.grass.demos.v1.GetSchemaResponse.prototype.clearVersion = function() {
  return this.setVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.demos.v1.GetSchemaResponse.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes schema = 2;
 * @return {string}
 */
proto.grass.demos.v1.GetSchemaResponse.prototype.getSchema = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes schema = 2;
 * This is a type-conversion wrapper around `getSchema()`
 * @return {string}
 */
proto.grass.demos.v1.GetSchemaResponse.prototype.getSchema_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSchema()));
};


/**
 * optional bytes schema = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSchema()`
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.GetSchemaResponse.prototype.getSchema_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSchema()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.grass.demos.v1.GetSchemaResponse} returns this
 */
proto.grass.demos.v1.GetSchemaResponse.prototype.setSchema = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.UpdateSchemaRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.UpdateSchemaRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.UpdateSchemaRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.UpdateSchemaRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    schema: msg.getSchema_asB64(),
    allowIncomatible: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.UpdateSchemaRequest}
 */
proto.grass.demos.v1.UpdateSchemaRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.UpdateSchemaRequest;
  return proto.grass.demos.v1.UpdateSchemaRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.UpdateSchemaRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.UpdateSchemaRequest}
 */
proto.grass.demos.v1.UpdateSchemaRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSchema(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setAllowIncomatible(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.UpdateSchemaRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.UpdateSchemaRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.UpdateSchemaRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.UpdateSchemaRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSchema_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      1,
      f
    );
  }
  f = message.getAllowIncomatible();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * optional bytes schema = 1;
 * @return {string}
 */
proto.grass.demos.v1.UpdateSchemaRequest.prototype.getSchema = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * optional bytes schema = 1;
 * This is a type-conversion wrapper around `getSchema()`
 * @return {string}
 */
proto.grass.demos.v1.UpdateSchemaRequest.prototype.getSchema_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSchema()));
};


/**
 * optional bytes schema = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSchema()`
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.UpdateSchemaRequest.prototype.getSchema_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSchema()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.grass.demos.v1.UpdateSchemaRequest} returns this
 */
proto.grass.demos.v1.UpdateSchemaRequest.prototype.setSchema = function(value) {
  return jspb.Message.setProto3BytesField(this, 1, value);
};


/**
 * optional bool allow_incomatible = 2;
 * @return {boolean}
 */
proto.grass.demos.v1.UpdateSchemaRequest.prototype.getAllowIncomatible = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grass.demos.v1.UpdateSchemaRequest} returns this
 */
proto.grass.demos.v1.UpdateSchemaRequest.prototype.setAllowIncomatible = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.UpdateSchemaResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.UpdateSchemaResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.UpdateSchemaResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.UpdateSchemaResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    version: (f = msg.getVersion()) && proto.grass.demos.v1.Version.toObject(includeInstance, f),
    schema: msg.getSchema_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.UpdateSchemaResponse}
 */
proto.grass.demos.v1.UpdateSchemaResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.UpdateSchemaResponse;
  return proto.grass.demos.v1.UpdateSchemaResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.UpdateSchemaResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.UpdateSchemaResponse}
 */
proto.grass.demos.v1.UpdateSchemaResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grass.demos.v1.Version;
      reader.readMessage(value,proto.grass.demos.v1.Version.deserializeBinaryFromReader);
      msg.setVersion(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSchema(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.UpdateSchemaResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.UpdateSchemaResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.UpdateSchemaResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.UpdateSchemaResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getVersion();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grass.demos.v1.Version.serializeBinaryToWriter
    );
  }
  f = message.getSchema_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional Version version = 1;
 * @return {?proto.grass.demos.v1.Version}
 */
proto.grass.demos.v1.UpdateSchemaResponse.prototype.getVersion = function() {
  return /** @type{?proto.grass.demos.v1.Version} */ (
    jspb.Message.getWrapperField(this, proto.grass.demos.v1.Version, 1));
};


/**
 * @param {?proto.grass.demos.v1.Version|undefined} value
 * @return {!proto.grass.demos.v1.UpdateSchemaResponse} returns this
*/
proto.grass.demos.v1.UpdateSchemaResponse.prototype.setVersion = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.demos.v1.UpdateSchemaResponse} returns this
 */
proto.grass.demos.v1.UpdateSchemaResponse.prototype.clearVersion = function() {
  return this.setVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.demos.v1.UpdateSchemaResponse.prototype.hasVersion = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional bytes schema = 2;
 * @return {string}
 */
proto.grass.demos.v1.UpdateSchemaResponse.prototype.getSchema = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes schema = 2;
 * This is a type-conversion wrapper around `getSchema()`
 * @return {string}
 */
proto.grass.demos.v1.UpdateSchemaResponse.prototype.getSchema_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSchema()));
};


/**
 * optional bytes schema = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSchema()`
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.UpdateSchemaResponse.prototype.getSchema_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSchema()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.grass.demos.v1.UpdateSchemaResponse} returns this
 */
proto.grass.demos.v1.UpdateSchemaResponse.prototype.setSchema = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.GetSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.GetSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.GetSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.GetSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.GetSettingsRequest}
 */
proto.grass.demos.v1.GetSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.GetSettingsRequest;
  return proto.grass.demos.v1.GetSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.GetSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.GetSettingsRequest}
 */
proto.grass.demos.v1.GetSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.GetSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.GetSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.GetSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.GetSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.grass.demos.v1.GetSettingsRequest.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.demos.v1.GetSettingsRequest} returns this
 */
proto.grass.demos.v1.GetSettingsRequest.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.GetSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.GetSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.GetSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.GetSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    settings: msg.getSettings_asB64(),
    schemaVersion: (f = msg.getSchemaVersion()) && proto.grass.demos.v1.Version.toObject(includeInstance, f),
    defaults: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    updatedAt: (f = msg.getUpdatedAt()) && google_protobuf_timestamp_pb.Timestamp.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.GetSettingsResponse}
 */
proto.grass.demos.v1.GetSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.GetSettingsResponse;
  return proto.grass.demos.v1.GetSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.GetSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.GetSettingsResponse}
 */
proto.grass.demos.v1.GetSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSettings(value);
      break;
    case 3:
      var value = new proto.grass.demos.v1.Version;
      reader.readMessage(value,proto.grass.demos.v1.Version.deserializeBinaryFromReader);
      msg.setSchemaVersion(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefaults(value);
      break;
    case 10:
      var value = new google_protobuf_timestamp_pb.Timestamp;
      reader.readMessage(value,google_protobuf_timestamp_pb.Timestamp.deserializeBinaryFromReader);
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.GetSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.GetSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.GetSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.GetSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSettings_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getSchemaVersion();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.grass.demos.v1.Version.serializeBinaryToWriter
    );
  }
  f = message.getDefaults();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getUpdatedAt();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_timestamp_pb.Timestamp.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.grass.demos.v1.GetSettingsResponse.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.demos.v1.GetSettingsResponse} returns this
 */
proto.grass.demos.v1.GetSettingsResponse.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes settings = 2;
 * @return {string}
 */
proto.grass.demos.v1.GetSettingsResponse.prototype.getSettings = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes settings = 2;
 * This is a type-conversion wrapper around `getSettings()`
 * @return {string}
 */
proto.grass.demos.v1.GetSettingsResponse.prototype.getSettings_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSettings()));
};


/**
 * optional bytes settings = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSettings()`
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.GetSettingsResponse.prototype.getSettings_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSettings()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.grass.demos.v1.GetSettingsResponse} returns this
 */
proto.grass.demos.v1.GetSettingsResponse.prototype.setSettings = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional Version schema_version = 3;
 * @return {?proto.grass.demos.v1.Version}
 */
proto.grass.demos.v1.GetSettingsResponse.prototype.getSchemaVersion = function() {
  return /** @type{?proto.grass.demos.v1.Version} */ (
    jspb.Message.getWrapperField(this, proto.grass.demos.v1.Version, 3));
};


/**
 * @param {?proto.grass.demos.v1.Version|undefined} value
 * @return {!proto.grass.demos.v1.GetSettingsResponse} returns this
*/
proto.grass.demos.v1.GetSettingsResponse.prototype.setSchemaVersion = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.demos.v1.GetSettingsResponse} returns this
 */
proto.grass.demos.v1.GetSettingsResponse.prototype.clearSchemaVersion = function() {
  return this.setSchemaVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.demos.v1.GetSettingsResponse.prototype.hasSchemaVersion = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool defaults = 4;
 * @return {boolean}
 */
proto.grass.demos.v1.GetSettingsResponse.prototype.getDefaults = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grass.demos.v1.GetSettingsResponse} returns this
 */
proto.grass.demos.v1.GetSettingsResponse.prototype.setDefaults = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional google.protobuf.Timestamp updated_at = 10;
 * @return {?proto.google.protobuf.Timestamp}
 */
proto.grass.demos.v1.GetSettingsResponse.prototype.getUpdatedAt = function() {
  return /** @type{?proto.google.protobuf.Timestamp} */ (
    jspb.Message.getWrapperField(this, google_protobuf_timestamp_pb.Timestamp, 10));
};


/**
 * @param {?proto.google.protobuf.Timestamp|undefined} value
 * @return {!proto.grass.demos.v1.GetSettingsResponse} returns this
*/
proto.grass.demos.v1.GetSettingsResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.demos.v1.GetSettingsResponse} returns this
 */
proto.grass.demos.v1.GetSettingsResponse.prototype.clearUpdatedAt = function() {
  return this.setUpdatedAt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.demos.v1.GetSettingsResponse.prototype.hasUpdatedAt = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.UpdateSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.UpdateSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.UpdateSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.UpdateSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    settings: msg.getSettings_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.UpdateSettingsRequest}
 */
proto.grass.demos.v1.UpdateSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.UpdateSettingsRequest;
  return proto.grass.demos.v1.UpdateSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.UpdateSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.UpdateSettingsRequest}
 */
proto.grass.demos.v1.UpdateSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSettings(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.UpdateSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.UpdateSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.UpdateSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.UpdateSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSettings_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.grass.demos.v1.UpdateSettingsRequest.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.demos.v1.UpdateSettingsRequest} returns this
 */
proto.grass.demos.v1.UpdateSettingsRequest.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes settings = 2;
 * @return {string}
 */
proto.grass.demos.v1.UpdateSettingsRequest.prototype.getSettings = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes settings = 2;
 * This is a type-conversion wrapper around `getSettings()`
 * @return {string}
 */
proto.grass.demos.v1.UpdateSettingsRequest.prototype.getSettings_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSettings()));
};


/**
 * optional bytes settings = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSettings()`
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.UpdateSettingsRequest.prototype.getSettings_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSettings()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.grass.demos.v1.UpdateSettingsRequest} returns this
 */
proto.grass.demos.v1.UpdateSettingsRequest.prototype.setSettings = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.UpdateSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.UpdateSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.UpdateSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.UpdateSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    settings: msg.getSettings_asB64(),
    schemaVersion: (f = msg.getSchemaVersion()) && proto.grass.demos.v1.Version.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.UpdateSettingsResponse}
 */
proto.grass.demos.v1.UpdateSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.UpdateSettingsResponse;
  return proto.grass.demos.v1.UpdateSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.UpdateSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.UpdateSettingsResponse}
 */
proto.grass.demos.v1.UpdateSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSettings(value);
      break;
    case 3:
      var value = new proto.grass.demos.v1.Version;
      reader.readMessage(value,proto.grass.demos.v1.Version.deserializeBinaryFromReader);
      msg.setSchemaVersion(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.UpdateSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.UpdateSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.UpdateSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.UpdateSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSettings_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getSchemaVersion();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.grass.demos.v1.Version.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.grass.demos.v1.UpdateSettingsResponse.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.demos.v1.UpdateSettingsResponse} returns this
 */
proto.grass.demos.v1.UpdateSettingsResponse.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes settings = 2;
 * @return {string}
 */
proto.grass.demos.v1.UpdateSettingsResponse.prototype.getSettings = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes settings = 2;
 * This is a type-conversion wrapper around `getSettings()`
 * @return {string}
 */
proto.grass.demos.v1.UpdateSettingsResponse.prototype.getSettings_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSettings()));
};


/**
 * optional bytes settings = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSettings()`
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.UpdateSettingsResponse.prototype.getSettings_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSettings()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.grass.demos.v1.UpdateSettingsResponse} returns this
 */
proto.grass.demos.v1.UpdateSettingsResponse.prototype.setSettings = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional Version schema_version = 3;
 * @return {?proto.grass.demos.v1.Version}
 */
proto.grass.demos.v1.UpdateSettingsResponse.prototype.getSchemaVersion = function() {
  return /** @type{?proto.grass.demos.v1.Version} */ (
    jspb.Message.getWrapperField(this, proto.grass.demos.v1.Version, 3));
};


/**
 * @param {?proto.grass.demos.v1.Version|undefined} value
 * @return {!proto.grass.demos.v1.UpdateSettingsResponse} returns this
*/
proto.grass.demos.v1.UpdateSettingsResponse.prototype.setSchemaVersion = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.demos.v1.UpdateSettingsResponse} returns this
 */
proto.grass.demos.v1.UpdateSettingsResponse.prototype.clearSchemaVersion = function() {
  return this.setSchemaVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.demos.v1.UpdateSettingsResponse.prototype.hasSchemaVersion = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.DeleteSettingsRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.DeleteSettingsRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.DeleteSettingsRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.DeleteSettingsRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.DeleteSettingsRequest}
 */
proto.grass.demos.v1.DeleteSettingsRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.DeleteSettingsRequest;
  return proto.grass.demos.v1.DeleteSettingsRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.DeleteSettingsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.DeleteSettingsRequest}
 */
proto.grass.demos.v1.DeleteSettingsRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.DeleteSettingsRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.DeleteSettingsRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.DeleteSettingsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.DeleteSettingsRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.grass.demos.v1.DeleteSettingsRequest.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.demos.v1.DeleteSettingsRequest} returns this
 */
proto.grass.demos.v1.DeleteSettingsRequest.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.DeleteSettingsResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.DeleteSettingsResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.DeleteSettingsResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.DeleteSettingsResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    settings: msg.getSettings_asB64(),
    schemaVersion: (f = msg.getSchemaVersion()) && proto.grass.demos.v1.Version.toObject(includeInstance, f),
    defaults: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.DeleteSettingsResponse}
 */
proto.grass.demos.v1.DeleteSettingsResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.DeleteSettingsResponse;
  return proto.grass.demos.v1.DeleteSettingsResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.DeleteSettingsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.DeleteSettingsResponse}
 */
proto.grass.demos.v1.DeleteSettingsResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setSettings(value);
      break;
    case 3:
      var value = new proto.grass.demos.v1.Version;
      reader.readMessage(value,proto.grass.demos.v1.Version.deserializeBinaryFromReader);
      msg.setSchemaVersion(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setDefaults(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.DeleteSettingsResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.DeleteSettingsResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.DeleteSettingsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.DeleteSettingsResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSettings_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      2,
      f
    );
  }
  f = message.getSchemaVersion();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.grass.demos.v1.Version.serializeBinaryToWriter
    );
  }
  f = message.getDefaults();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.grass.demos.v1.DeleteSettingsResponse.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.demos.v1.DeleteSettingsResponse} returns this
 */
proto.grass.demos.v1.DeleteSettingsResponse.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional bytes settings = 2;
 * @return {string}
 */
proto.grass.demos.v1.DeleteSettingsResponse.prototype.getSettings = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * optional bytes settings = 2;
 * This is a type-conversion wrapper around `getSettings()`
 * @return {string}
 */
proto.grass.demos.v1.DeleteSettingsResponse.prototype.getSettings_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getSettings()));
};


/**
 * optional bytes settings = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getSettings()`
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.DeleteSettingsResponse.prototype.getSettings_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getSettings()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.grass.demos.v1.DeleteSettingsResponse} returns this
 */
proto.grass.demos.v1.DeleteSettingsResponse.prototype.setSettings = function(value) {
  return jspb.Message.setProto3BytesField(this, 2, value);
};


/**
 * optional Version schema_version = 3;
 * @return {?proto.grass.demos.v1.Version}
 */
proto.grass.demos.v1.DeleteSettingsResponse.prototype.getSchemaVersion = function() {
  return /** @type{?proto.grass.demos.v1.Version} */ (
    jspb.Message.getWrapperField(this, proto.grass.demos.v1.Version, 3));
};


/**
 * @param {?proto.grass.demos.v1.Version|undefined} value
 * @return {!proto.grass.demos.v1.DeleteSettingsResponse} returns this
*/
proto.grass.demos.v1.DeleteSettingsResponse.prototype.setSchemaVersion = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.demos.v1.DeleteSettingsResponse} returns this
 */
proto.grass.demos.v1.DeleteSettingsResponse.prototype.clearSchemaVersion = function() {
  return this.setSchemaVersion(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.demos.v1.DeleteSettingsResponse.prototype.hasSchemaVersion = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool defaults = 4;
 * @return {boolean}
 */
proto.grass.demos.v1.DeleteSettingsResponse.prototype.getDefaults = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grass.demos.v1.DeleteSettingsResponse} returns this
 */
proto.grass.demos.v1.DeleteSettingsResponse.prototype.setDefaults = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.UserPreferencesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.UserPreferencesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.UserPreferencesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.UserPreferencesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.UserPreferencesRequest}
 */
proto.grass.demos.v1.UserPreferencesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.UserPreferencesRequest;
  return proto.grass.demos.v1.UserPreferencesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.UserPreferencesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.UserPreferencesRequest}
 */
proto.grass.demos.v1.UserPreferencesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.UserPreferencesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.UserPreferencesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.UserPreferencesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.UserPreferencesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.grass.demos.v1.UserPreferencesRequest.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.demos.v1.UserPreferencesRequest} returns this
 */
proto.grass.demos.v1.UserPreferencesRequest.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.UserPreferencesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.UserPreferencesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.UserPreferencesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.UserPreferencesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    preferences: (f = msg.getPreferences()) && proto.grass.demos.v1.Preferences.toObject(includeInstance, f),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 101, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.UserPreferencesResponse}
 */
proto.grass.demos.v1.UserPreferencesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.UserPreferencesResponse;
  return proto.grass.demos.v1.UserPreferencesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.UserPreferencesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.UserPreferencesResponse}
 */
proto.grass.demos.v1.UserPreferencesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grass.demos.v1.Preferences;
      reader.readMessage(value,proto.grass.demos.v1.Preferences.deserializeBinaryFromReader);
      msg.setPreferences(value);
      break;
    case 101:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.UserPreferencesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.UserPreferencesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.UserPreferencesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.UserPreferencesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreferences();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grass.demos.v1.Preferences.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeUint64(
      101,
      f
    );
  }
};


/**
 * optional Preferences preferences = 1;
 * @return {?proto.grass.demos.v1.Preferences}
 */
proto.grass.demos.v1.UserPreferencesResponse.prototype.getPreferences = function() {
  return /** @type{?proto.grass.demos.v1.Preferences} */ (
    jspb.Message.getWrapperField(this, proto.grass.demos.v1.Preferences, 1));
};


/**
 * @param {?proto.grass.demos.v1.Preferences|undefined} value
 * @return {!proto.grass.demos.v1.UserPreferencesResponse} returns this
*/
proto.grass.demos.v1.UserPreferencesResponse.prototype.setPreferences = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.demos.v1.UserPreferencesResponse} returns this
 */
proto.grass.demos.v1.UserPreferencesResponse.prototype.clearPreferences = function() {
  return this.setPreferences(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.demos.v1.UserPreferencesResponse.prototype.hasPreferences = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 updated_at = 101;
 * @return {number}
 */
proto.grass.demos.v1.UserPreferencesResponse.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 101, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.demos.v1.UserPreferencesResponse} returns this
 */
proto.grass.demos.v1.UserPreferencesResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 101, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.UpdateUserPreferencesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.UpdateUserPreferencesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.UpdateUserPreferencesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.UpdateUserPreferencesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    preferences: (f = msg.getPreferences()) && proto.grass.demos.v1.Preferences.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.UpdateUserPreferencesRequest}
 */
proto.grass.demos.v1.UpdateUserPreferencesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.UpdateUserPreferencesRequest;
  return proto.grass.demos.v1.UpdateUserPreferencesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.UpdateUserPreferencesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.UpdateUserPreferencesRequest}
 */
proto.grass.demos.v1.UpdateUserPreferencesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    case 2:
      var value = new proto.grass.demos.v1.Preferences;
      reader.readMessage(value,proto.grass.demos.v1.Preferences.deserializeBinaryFromReader);
      msg.setPreferences(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.UpdateUserPreferencesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.UpdateUserPreferencesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.UpdateUserPreferencesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.UpdateUserPreferencesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPreferences();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.grass.demos.v1.Preferences.serializeBinaryToWriter
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.grass.demos.v1.UpdateUserPreferencesRequest.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.demos.v1.UpdateUserPreferencesRequest} returns this
 */
proto.grass.demos.v1.UpdateUserPreferencesRequest.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Preferences preferences = 2;
 * @return {?proto.grass.demos.v1.Preferences}
 */
proto.grass.demos.v1.UpdateUserPreferencesRequest.prototype.getPreferences = function() {
  return /** @type{?proto.grass.demos.v1.Preferences} */ (
    jspb.Message.getWrapperField(this, proto.grass.demos.v1.Preferences, 2));
};


/**
 * @param {?proto.grass.demos.v1.Preferences|undefined} value
 * @return {!proto.grass.demos.v1.UpdateUserPreferencesRequest} returns this
*/
proto.grass.demos.v1.UpdateUserPreferencesRequest.prototype.setPreferences = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.demos.v1.UpdateUserPreferencesRequest} returns this
 */
proto.grass.demos.v1.UpdateUserPreferencesRequest.prototype.clearPreferences = function() {
  return this.setPreferences(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.demos.v1.UpdateUserPreferencesRequest.prototype.hasPreferences = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.UpdateUserPreferencesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.UpdateUserPreferencesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.UpdateUserPreferencesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.UpdateUserPreferencesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.UpdateUserPreferencesResponse}
 */
proto.grass.demos.v1.UpdateUserPreferencesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.UpdateUserPreferencesResponse;
  return proto.grass.demos.v1.UpdateUserPreferencesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.UpdateUserPreferencesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.UpdateUserPreferencesResponse}
 */
proto.grass.demos.v1.UpdateUserPreferencesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.UpdateUserPreferencesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.UpdateUserPreferencesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.UpdateUserPreferencesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.UpdateUserPreferencesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.ResetUserPreferencesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.ResetUserPreferencesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.ResetUserPreferencesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.ResetUserPreferencesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    uid: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.ResetUserPreferencesRequest}
 */
proto.grass.demos.v1.ResetUserPreferencesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.ResetUserPreferencesRequest;
  return proto.grass.demos.v1.ResetUserPreferencesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.ResetUserPreferencesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.ResetUserPreferencesRequest}
 */
proto.grass.demos.v1.ResetUserPreferencesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.ResetUserPreferencesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.ResetUserPreferencesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.ResetUserPreferencesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.ResetUserPreferencesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string uid = 1;
 * @return {string}
 */
proto.grass.demos.v1.ResetUserPreferencesRequest.prototype.getUid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.demos.v1.ResetUserPreferencesRequest} returns this
 */
proto.grass.demos.v1.ResetUserPreferencesRequest.prototype.setUid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.ResetUserPreferencesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.ResetUserPreferencesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.ResetUserPreferencesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.ResetUserPreferencesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.ResetUserPreferencesResponse}
 */
proto.grass.demos.v1.ResetUserPreferencesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.ResetUserPreferencesResponse;
  return proto.grass.demos.v1.ResetUserPreferencesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.ResetUserPreferencesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.ResetUserPreferencesResponse}
 */
proto.grass.demos.v1.ResetUserPreferencesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.ResetUserPreferencesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.ResetUserPreferencesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.ResetUserPreferencesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.ResetUserPreferencesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.LocalizationPreferences.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.LocalizationPreferences.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.LocalizationPreferences} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.LocalizationPreferences.toObject = function(includeInstance, msg) {
  var f, obj = {
    language: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.LocalizationPreferences}
 */
proto.grass.demos.v1.LocalizationPreferences.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.LocalizationPreferences;
  return proto.grass.demos.v1.LocalizationPreferences.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.LocalizationPreferences} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.LocalizationPreferences}
 */
proto.grass.demos.v1.LocalizationPreferences.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguage(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.LocalizationPreferences.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.LocalizationPreferences.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.LocalizationPreferences} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.LocalizationPreferences.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLanguage();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string language = 1;
 * @return {string}
 */
proto.grass.demos.v1.LocalizationPreferences.prototype.getLanguage = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.grass.demos.v1.LocalizationPreferences} returns this
 */
proto.grass.demos.v1.LocalizationPreferences.prototype.setLanguage = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.WebUIPreferences.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.WebUIPreferences.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.WebUIPreferences} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.WebUIPreferences.toObject = function(includeInstance, msg) {
  var f, obj = {
    leftBarExpanded: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.WebUIPreferences}
 */
proto.grass.demos.v1.WebUIPreferences.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.WebUIPreferences;
  return proto.grass.demos.v1.WebUIPreferences.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.WebUIPreferences} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.WebUIPreferences}
 */
proto.grass.demos.v1.WebUIPreferences.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setLeftBarExpanded(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.WebUIPreferences.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.WebUIPreferences.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.WebUIPreferences} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.WebUIPreferences.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLeftBarExpanded();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool left_bar_expanded = 1;
 * @return {boolean}
 */
proto.grass.demos.v1.WebUIPreferences.prototype.getLeftBarExpanded = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grass.demos.v1.WebUIPreferences} returns this
 */
proto.grass.demos.v1.WebUIPreferences.prototype.setLeftBarExpanded = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.Preferences.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.Preferences.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.Preferences} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.Preferences.toObject = function(includeInstance, msg) {
  var f, obj = {
    localization: (f = msg.getLocalization()) && proto.grass.demos.v1.LocalizationPreferences.toObject(includeInstance, f),
    webUi: (f = msg.getWebUi()) && proto.grass.demos.v1.WebUIPreferences.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.Preferences}
 */
proto.grass.demos.v1.Preferences.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.Preferences;
  return proto.grass.demos.v1.Preferences.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.Preferences} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.Preferences}
 */
proto.grass.demos.v1.Preferences.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grass.demos.v1.LocalizationPreferences;
      reader.readMessage(value,proto.grass.demos.v1.LocalizationPreferences.deserializeBinaryFromReader);
      msg.setLocalization(value);
      break;
    case 2:
      var value = new proto.grass.demos.v1.WebUIPreferences;
      reader.readMessage(value,proto.grass.demos.v1.WebUIPreferences.deserializeBinaryFromReader);
      msg.setWebUi(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.Preferences.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.Preferences.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.Preferences} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.Preferences.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLocalization();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grass.demos.v1.LocalizationPreferences.serializeBinaryToWriter
    );
  }
  f = message.getWebUi();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.grass.demos.v1.WebUIPreferences.serializeBinaryToWriter
    );
  }
};


/**
 * optional LocalizationPreferences localization = 1;
 * @return {?proto.grass.demos.v1.LocalizationPreferences}
 */
proto.grass.demos.v1.Preferences.prototype.getLocalization = function() {
  return /** @type{?proto.grass.demos.v1.LocalizationPreferences} */ (
    jspb.Message.getWrapperField(this, proto.grass.demos.v1.LocalizationPreferences, 1));
};


/**
 * @param {?proto.grass.demos.v1.LocalizationPreferences|undefined} value
 * @return {!proto.grass.demos.v1.Preferences} returns this
*/
proto.grass.demos.v1.Preferences.prototype.setLocalization = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.demos.v1.Preferences} returns this
 */
proto.grass.demos.v1.Preferences.prototype.clearLocalization = function() {
  return this.setLocalization(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.demos.v1.Preferences.prototype.hasLocalization = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional WebUIPreferences web_ui = 2;
 * @return {?proto.grass.demos.v1.WebUIPreferences}
 */
proto.grass.demos.v1.Preferences.prototype.getWebUi = function() {
  return /** @type{?proto.grass.demos.v1.WebUIPreferences} */ (
    jspb.Message.getWrapperField(this, proto.grass.demos.v1.WebUIPreferences, 2));
};


/**
 * @param {?proto.grass.demos.v1.WebUIPreferences|undefined} value
 * @return {!proto.grass.demos.v1.Preferences} returns this
*/
proto.grass.demos.v1.Preferences.prototype.setWebUi = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.demos.v1.Preferences} returns this
 */
proto.grass.demos.v1.Preferences.prototype.clearWebUi = function() {
  return this.setWebUi(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.demos.v1.Preferences.prototype.hasWebUi = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.PreferencesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.PreferencesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.PreferencesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.PreferencesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.PreferencesRequest}
 */
proto.grass.demos.v1.PreferencesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.PreferencesRequest;
  return proto.grass.demos.v1.PreferencesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.PreferencesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.PreferencesRequest}
 */
proto.grass.demos.v1.PreferencesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.PreferencesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.PreferencesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.PreferencesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.PreferencesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.PreferencesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.PreferencesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.PreferencesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.PreferencesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    preferences: (f = msg.getPreferences()) && proto.grass.demos.v1.Preferences.toObject(includeInstance, f),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 101, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.PreferencesResponse}
 */
proto.grass.demos.v1.PreferencesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.PreferencesResponse;
  return proto.grass.demos.v1.PreferencesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.PreferencesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.PreferencesResponse}
 */
proto.grass.demos.v1.PreferencesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grass.demos.v1.Preferences;
      reader.readMessage(value,proto.grass.demos.v1.Preferences.deserializeBinaryFromReader);
      msg.setPreferences(value);
      break;
    case 101:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.PreferencesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.PreferencesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.PreferencesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.PreferencesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreferences();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grass.demos.v1.Preferences.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeUint64(
      101,
      f
    );
  }
};


/**
 * optional Preferences preferences = 1;
 * @return {?proto.grass.demos.v1.Preferences}
 */
proto.grass.demos.v1.PreferencesResponse.prototype.getPreferences = function() {
  return /** @type{?proto.grass.demos.v1.Preferences} */ (
    jspb.Message.getWrapperField(this, proto.grass.demos.v1.Preferences, 1));
};


/**
 * @param {?proto.grass.demos.v1.Preferences|undefined} value
 * @return {!proto.grass.demos.v1.PreferencesResponse} returns this
*/
proto.grass.demos.v1.PreferencesResponse.prototype.setPreferences = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.demos.v1.PreferencesResponse} returns this
 */
proto.grass.demos.v1.PreferencesResponse.prototype.clearPreferences = function() {
  return this.setPreferences(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.demos.v1.PreferencesResponse.prototype.hasPreferences = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 updated_at = 101;
 * @return {number}
 */
proto.grass.demos.v1.PreferencesResponse.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 101, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.demos.v1.PreferencesResponse} returns this
 */
proto.grass.demos.v1.PreferencesResponse.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 101, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.UpdatePreferencesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.UpdatePreferencesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.UpdatePreferencesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.UpdatePreferencesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    preferences: (f = msg.getPreferences()) && proto.grass.demos.v1.Preferences.toObject(includeInstance, f),
    updatedAt: jspb.Message.getFieldWithDefault(msg, 101, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.UpdatePreferencesRequest}
 */
proto.grass.demos.v1.UpdatePreferencesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.UpdatePreferencesRequest;
  return proto.grass.demos.v1.UpdatePreferencesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.UpdatePreferencesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.UpdatePreferencesRequest}
 */
proto.grass.demos.v1.UpdatePreferencesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.grass.demos.v1.Preferences;
      reader.readMessage(value,proto.grass.demos.v1.Preferences.deserializeBinaryFromReader);
      msg.setPreferences(value);
      break;
    case 101:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setUpdatedAt(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.UpdatePreferencesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.UpdatePreferencesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.UpdatePreferencesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.UpdatePreferencesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPreferences();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.grass.demos.v1.Preferences.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedAt();
  if (f !== 0) {
    writer.writeUint64(
      101,
      f
    );
  }
};


/**
 * optional Preferences preferences = 1;
 * @return {?proto.grass.demos.v1.Preferences}
 */
proto.grass.demos.v1.UpdatePreferencesRequest.prototype.getPreferences = function() {
  return /** @type{?proto.grass.demos.v1.Preferences} */ (
    jspb.Message.getWrapperField(this, proto.grass.demos.v1.Preferences, 1));
};


/**
 * @param {?proto.grass.demos.v1.Preferences|undefined} value
 * @return {!proto.grass.demos.v1.UpdatePreferencesRequest} returns this
*/
proto.grass.demos.v1.UpdatePreferencesRequest.prototype.setPreferences = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.grass.demos.v1.UpdatePreferencesRequest} returns this
 */
proto.grass.demos.v1.UpdatePreferencesRequest.prototype.clearPreferences = function() {
  return this.setPreferences(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.grass.demos.v1.UpdatePreferencesRequest.prototype.hasPreferences = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 updated_at = 101;
 * @return {number}
 */
proto.grass.demos.v1.UpdatePreferencesRequest.prototype.getUpdatedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 101, 0));
};


/**
 * @param {number} value
 * @return {!proto.grass.demos.v1.UpdatePreferencesRequest} returns this
 */
proto.grass.demos.v1.UpdatePreferencesRequest.prototype.setUpdatedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 101, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.UpdatePreferencesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.UpdatePreferencesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.UpdatePreferencesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.UpdatePreferencesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.UpdatePreferencesResponse}
 */
proto.grass.demos.v1.UpdatePreferencesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.UpdatePreferencesResponse;
  return proto.grass.demos.v1.UpdatePreferencesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.UpdatePreferencesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.UpdatePreferencesResponse}
 */
proto.grass.demos.v1.UpdatePreferencesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.UpdatePreferencesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.UpdatePreferencesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.UpdatePreferencesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.UpdatePreferencesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.FeaturesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.FeaturesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.FeaturesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.FeaturesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.FeaturesRequest}
 */
proto.grass.demos.v1.FeaturesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.FeaturesRequest;
  return proto.grass.demos.v1.FeaturesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.FeaturesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.FeaturesRequest}
 */
proto.grass.demos.v1.FeaturesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.FeaturesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.FeaturesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.FeaturesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.FeaturesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.grass.demos.v1.FeaturesResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.grass.demos.v1.FeaturesResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.grass.demos.v1.FeaturesResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.grass.demos.v1.FeaturesResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.FeaturesResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    capabilitiesList: (f = jspb.Message.getRepeatedField(msg, 1)) == null ? undefined : f,
    prescriptions: jspb.Message.getBooleanFieldWithDefault(msg, 2, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.grass.demos.v1.FeaturesResponse}
 */
proto.grass.demos.v1.FeaturesResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.grass.demos.v1.FeaturesResponse;
  return proto.grass.demos.v1.FeaturesResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.grass.demos.v1.FeaturesResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.grass.demos.v1.FeaturesResponse}
 */
proto.grass.demos.v1.FeaturesResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.addCapabilities(value);
      break;
    case 2:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrescriptions(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.grass.demos.v1.FeaturesResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.grass.demos.v1.FeaturesResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.grass.demos.v1.FeaturesResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.grass.demos.v1.FeaturesResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCapabilitiesList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      1,
      f
    );
  }
  f = message.getPrescriptions();
  if (f) {
    writer.writeBool(
      2,
      f
    );
  }
};


/**
 * repeated string capabilities = 1;
 * @return {!Array<string>}
 */
proto.grass.demos.v1.FeaturesResponse.prototype.getCapabilitiesList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.grass.demos.v1.FeaturesResponse} returns this
 */
proto.grass.demos.v1.FeaturesResponse.prototype.setCapabilitiesList = function(value) {
  return jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.grass.demos.v1.FeaturesResponse} returns this
 */
proto.grass.demos.v1.FeaturesResponse.prototype.addCapabilities = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.grass.demos.v1.FeaturesResponse} returns this
 */
proto.grass.demos.v1.FeaturesResponse.prototype.clearCapabilitiesList = function() {
  return this.setCapabilitiesList([]);
};


/**
 * optional bool prescriptions = 2;
 * @return {boolean}
 */
proto.grass.demos.v1.FeaturesResponse.prototype.getPrescriptions = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 2, false));
};


/**
 * @param {boolean} value
 * @return {!proto.grass.demos.v1.FeaturesResponse} returns this
 */
proto.grass.demos.v1.FeaturesResponse.prototype.setPrescriptions = function(value) {
  return jspb.Message.setProto3BooleanField(this, 2, value);
};


goog.object.extend(exports, proto.grass.demos.v1);
