import React, { useMemo } from "react";
import useVM from "@src/hooks/useVM";
import { makeAutoObservable } from "mobx";
import { RootStore, useStores } from "@stores";
import * as fieldsService from "@services/fieldsService";
import { TCategory, TCreateCropRequest, TCrop } from "@services/fieldsService";

const ctx = React.createContext<CropsScreenVM | null>(null);

export const CropsScreenVMProvider: React.FC = ({ children }) => {
  const rootStore = useStores();
  const store = useMemo(() => new CropsScreenVM(rootStore), [rootStore]);
  return <ctx.Provider value={store}>{children}</ctx.Provider>;
};

export const useCropsScreenVM = () => useVM(ctx);

class CropsScreenVM {
  search: string = "";
  setSearch = (v: string) => (this.search = v);

  loading: boolean = true;
  private setLoading = (v: boolean) => (this.loading = v);

  crops: TCrop[] = [];
  private setCrops = (crops: TCrop[]) => (this.crops = crops);

  categories: TCategory[] = [];
  private setCategories = (categories: TCategory[]) =>
    (this.categories = categories);

  sync = async () => {
    const [crops, categories]: [TCrop[], TCategory[]] = await Promise.all([
      fieldsService.getCropsData(),
      fieldsService.getCategories(),
    ]);
    this.setCrops(crops);
    this.setCategories(categories);
  };

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
    this.sync().then(() => this.setLoading(false));
  }

  createCrop = (culture: TCreateCropRequest) =>
    fieldsService.createCrop(culture).then(this.sync);

  removeCrop = (id: string) => fieldsService.removeCrop(id).then(this.sync);
  editCrop = (id: string, culture: TCreateCropRequest) =>
    fieldsService.editCrop(id, culture).then(this.sync);
}
