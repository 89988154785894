import styled from "@emotion/styled";
import React, { HTMLAttributes, useMemo } from "react";
import { Column, Row } from "@components/Flex";
import { ReactComponent as Logo } from "@assets/icons/logo.svg";
import { ReactComponent as ToggleMenuIcon } from "@assets/icons/toggleMenu.svg";
import { ReactComponent as MapIcon } from "@assets/icons/map.svg";
import { ReactComponent as RouterIcon } from "@assets/icons/router.svg";
import { ReactComponent as TrackIcon } from "@assets/icons/track.svg";
import { ReactComponent as TreeIcon } from "@assets/icons/tree.svg";
import { ReactComponent as ProfileIcon } from "@assets/icons/profile.svg";
import { ROUTES } from "@stores/AccountStore";
import LeftBarMenuItem from "./LeftBarMenuItem";
import useWindowSize from "@src/hooks/useWindowSize";
import { observer } from "mobx-react-lite";
import { useStores } from "@stores";
import useHover from "@src/hooks/useHover";
import { useLang } from "@src/hooks/useLang";
import LeftBarSettings from "./LeftBarSettings";
import Seasons from "@components/Layout/Seasons";

interface IProps extends HTMLAttributes<HTMLDivElement> { }

const calcMargin = (width: number) => {
  switch (width) {
    case 272:
      return 24;
    case 186:
      return 20;
    case 52:
      return 32;
    default:
      return 24;
  }
};

const Root = styled.div<{ width: number; expanded: boolean }>`
  display: flex;
  flex-direction: column;
  background: #f8f8f8;
  min-width: ${({ width, expanded }) => (expanded ? width : 52)}px;
  max-width: ${({ width, expanded }) => (expanded ? width : 52)}px;
  margin-right: ${({ width }) => width};
  transition: 0.4s;
  margin-right: ${({ width, expanded }) => calcMargin(expanded ? width : 52)}px;
  z-index: 1;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  padding: 0 16px;
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px solid rgba(137, 144, 147, 0.3);
  height: 94px;
`;

const Body = styled.div`
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 16px 0;
`;

// const ClosedMenuLogoHoverDropdown = styled.div<{ enabled: boolean }>`
//   position: absolute;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   width: ${({ enabled }) => (enabled ? 24 : 0)}px;
//   height: 24px;
//   transition: 0.4s;
//   left: 52px;
//   top: 18px;
//   overflow: hidden;
//   background: #f8f8f8;
//   border-radius: 0 4px 4px 0;
//   cursor: pointer;
// `;

const LeftBar: React.FC<IProps> = ({ ...rest }) => {
  const { settingsStore } = useStores();
  const lang = useLang();
  const size = useWindowSize();
  const width = size.width ?? document.documentElement.clientWidth;
  const expanded = settingsStore.leftBarExpanded && (width ?? 1280) > 1140;
  // const [hoverRef, isLogoHovered] = useHover<HTMLDivElement>();
  const [hoverRef] = useHover<HTMLDivElement>();
  const expand = () => settingsStore.setLeftBarExpanded(true);
  const close = () => settingsStore.setLeftBarExpanded(false);
  const currentWidth = hoverRef?.current?.offsetWidth;
  const rootTransition = width != null && currentWidth != null ? "0.4s" : "0";

  const navigationLinks = useMemo(() => {
    return [
      {
        title: lang.menu.fieldMenuOption,
        icon: <MapIcon />,
        route: ROUTES.FIELDS,
      },
      {
        title: lang.menu.devicesMenuOption,
        icon: <RouterIcon />,
        route: ROUTES.DEVICES,
      },
      {
        title: lang.menu.cropsMenuOption,
        icon: <TreeIcon />,
        route: ROUTES.CROPS,
      },
      {
        title: lang.menu.tracksMenuOption,
        icon: <TrackIcon />,
        route: ROUTES.TRACKS,
      },
    ];
  }, [
    lang.menu.cropsMenuOption,
    lang.menu.devicesMenuOption,
    lang.menu.fieldMenuOption,
    lang.menu.tracksMenuOption,
  ]);

  if (width == null) return null;

  const menu_width = (width ?? 1281) > 1280 ? 200 : 150;

  return (
    <Root
      style={{ transition: rootTransition }}
      width={menu_width}
      expanded={expanded}
      ref={hoverRef}
      {...rest}
    >
      {/*{(width ?? 1280) > 1140 && !expanded && currentWidth === 52 && (*/}
      {/*  <ClosedMenuLogoHoverDropdown enabled={isLogoHovered} onClick={expand}>*/}
      {/*    <ToggleMenuIcon style={{ transform: "rotate(180deg)" }} />*/}
      {/*  </ClosedMenuLogoHoverDropdown>*/}
      {/*)}*/}
      <Header>
        <Row
          alignItems="center"
          justifyContent="space-between"
          style={{ height: 36 }}
        >
          <Logo style={{ minWidth: 24, width: 24 }} />
          {expanded && (
            <ToggleMenuIcon style={{ cursor: "pointer" }} onClick={close} />
          )}
        </Row>
        {expanded ? (
          <Seasons
            expanded={expanded}
            menuWidth={menu_width}
          />
        ) : (
          <ToggleMenuIcon
            onClick={expand}
            style={{
              transform: "rotate(180deg)",
              height: 38,
              cursor: "pointer",
              opacity: (width ?? 1280) > 1140 ? 1 : 0,
            }}
          />
        )}
      </Header>
      <Body>
        <Column crossAxisSize="max">
          {navigationLinks.map((link) => (
            <LeftBarMenuItem expanded={expanded} {...link} key={link.route} />
          ))}
        </Column>
        <Column crossAxisSize="max">
          <LeftBarSettings
            expanded={expanded}
            menuWidth={menu_width}
          />
          <LeftBarMenuItem
            expanded={expanded}
            title={lang.menu.profileMenuOption}
            icon={<ProfileIcon />}
            route={ROUTES.PROFILE}
            type="secondary"
          />
        </Column>
      </Body>
    </Root>
  );
};
export default observer(LeftBar);
