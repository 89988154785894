
export type ConvertFn = (i: number) => number;

export type Codec = {
  encode: ConvertFn;
  decode: ConvertFn;
};

export const precision = (exp: number): Codec => {
  if (exp < 6 || 16 < exp) {
    throw new Error("Invalid precision: precision must be in range [6; 16]");
  }

  const prec = Math.pow(10, exp);
  return {
    encode: (i: number): number => { return Math.round(i * prec); },
    decode: (i: number): number => { return i / prec; },
  };
}
