import React, { useState } from "react";
import Dialog from "@components/Dialog";
import Text from "@components/Text";
import SizedBox from "@components/SizedBox";
import { Row } from "@components/Flex";
import Button from "@components/Button";
import Input from "@components/Input";
import { observer } from "mobx-react-lite";
import { useLang } from "@src/hooks/useLang";
import { useProfileScreenVM } from "@screens/ProfileScreen/ProfileScreenVM";
import validatePassword from "@src/utils/validatePassword";

interface IProps {
  visible: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

const SaveDataPasswordConfirmDialog: React.FC<IProps> = ({
  onSubmit,
  ...rest
}) => {
  const [pass, setPass] = useState("");
  const [error, setError] = useState<string | null>(null);
  const lang = useLang();
  const vm = useProfileScreenVM();
  const handleSubmit = async () => {
    const succeed = await vm.validatePassword(pass);
    return succeed ? onSubmit() : setError("Incorrect password");
  };
  return (
    <Dialog
      error={error}
      style={{ width: 336 }}
      title={lang.profile.changeMailPassword}
      {...rest}
    >
      <Text size={15} color="#899093">
        {lang.profile.changeMailAlert}
      </Text>
      <SizedBox height={16} />
      <Input
        value={pass}
        onChange={(e) => setPass(e.target.value)}
        label={lang.profile.changeMailTitle}
        placeholder={lang.profile.changeMailFill}
        type="password"
      />
      <SizedBox height={24} />
      <Row>
        <Button kind="outline" onClick={rest.onClose}>
          {lang.profile.changeMailCancelButton}
        </Button>
        <SizedBox width={16} />
        <Button onClick={handleSubmit} disabled={!validatePassword(pass)}>
          {lang.profile.changeMailSaveButton}
        </Button>
      </Row>
    </Dialog>
  );
};
export default observer(SaveDataPasswordConfirmDialog);
