import RootStore from "@stores/RootStore";
import { makeAutoObservable } from "mobx";
import * as peopleService from "@services/peopleService";
import { IRegisterRequestParams } from "@services/peopleService";
import {
  boot as bootIntercom,
  shutdown as shutdownIntercom,
} from "@src/utils/intercom";

export interface ISerializedAccountStore { }

export enum ROUTES {
  LOGIN = "/login",
  REGISTER = "/register",
  PASSWORD_RESET = "/password-reset",
  PASSWORD_FORGOT = "/password-forgot",
  PROFILE = "/profile",
  FIELDS = "/fields",
  CROPS = "/crops",
  DEVICES = "/devices",
  TRACKS = "/tracks",
  DEVICES_CALIBRATION = "/devices/calibration/:id",
  PRESCRIPTION = "/prescription/:field_id"
}

class AccountStore {
  public readonly rootStore: RootStore;

  initialized: boolean = false;
  private setInitialized = (v: boolean) => (this.initialized = v);
  authenticated: boolean = false;
  private setAuthenticated = (v: boolean) => (this.authenticated = v);

  constructor(rootStore: RootStore, initState?: ISerializedAccountStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.auth().then(() => this.setInitialized(true));
  }

  serialize = (): ISerializedAccountStore => ({});

  auth = async () => {
    const state = await peopleService.auth();
    if (state !== null) {
      bootIntercom({
        email: state.info.email,
        name: state.info.name,
        created_at: new Date().getTime() / 1000,
      });
      this.setAuthenticated(true);

    } else {
      await this.logout();
    }
  }

  register = async (params: IRegisterRequestParams) => {
    return peopleService.register(params).then(this.auth)
  }

  login = async (params: IRegisterRequestParams) => {
    return peopleService.login(params).then(this.auth)
  }

  logout = () => peopleService.logout().then(() => {
    shutdownIntercom();
    this.setAuthenticated(false)
  });
}

export default AccountStore;
