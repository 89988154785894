import styled from "@emotion/styled";

type TButtonType = "primary" | "outline" | "secondary";

const Button = styled.button<{ kind?: TButtonType }>`
  cursor: pointer;
  outline: none;
  height: 36px;
  border-radius: 6px;
  font-family: Nunito Sans, sans-serif;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s;
  padding: 0 16px;
  box-sizing: border-box;
  background: ${({ kind }) => {
    switch (kind) {
      case "outline":
      case "secondary":
        return "#fff";
      default:
        return "#68ad18";
    }
  }};
  color: ${({ kind }) => {
    switch (kind) {
      case "outline":
        return "#68AD18";
      case "secondary":
        return "#494C5B";
      default:
        return "#fff";
    }
  }};
  border: ${({ kind }) => {
    switch (kind) {
      case "outline":
        return "1px solid #68AD18";
      default:
        return "none";
    }
  }};
  box-shadow: ${({ kind }) => {
    switch (kind) {
      case "secondary":
        return "0px 1px 4px rgba(0, 0, 0, 0.16)";
      default:
        return "none";
    }
  }};

  & path {
    fill: ${({ kind }) => {
      switch (kind) {
        case "outline":
          return "#68AD18";
        case "secondary":
          return "#494C5B";
        default:
          return "#fff";
      }
    }};
  }

  :hover {
    background: ${({ kind }) => {
      switch (kind) {
        case "outline":
          return "#F8F8F8";
        case "secondary":
          return "#fff";
        default:
          return "#68ad18";
      }
    }};
    color: ${({ kind }) => {
      switch (kind) {
        case "outline":
          return "#68AD18";
        case "secondary":
          return "#417900";
        default:
          return "#fff";
      }
    }};
    border: ${({ kind }) => {
      switch (kind) {
        case "outline":
          return "1px solid #68AD18";
        default:
          return "none";
      }
    }};
    box-shadow: ${({ kind }) => {
      switch (kind) {
        case "outline":
        case "secondary":
          return "none";
        default:
          return "inset 0px 0px 5px rgba(0, 0, 0, 0.3)";
      }
    }};

    & path {
      fill: ${({ kind }) => {
        switch (kind) {
          case "outline":
            return "#68AD18";
          case "secondary":
            return "#417900";
          default:
            return "#fff";
        }
      }};
    }
  }

  :active {
    background: ${({ kind }) => {
      switch (kind) {
        case "outline":
          return "#F8F8F8";
        case "secondary":
          return "#F8F8F8";
        default:
          return "#417900";
      }
    }};
    color: ${({ kind }) => {
      switch (kind) {
        case "outline":
          return "#417900";
        case "secondary":
          return "#68AD18";
        default:
          return "#fff";
      }
    }};
    border: ${({ kind }) => {
      switch (kind) {
        case "outline":
          return "1px solid #417900";
        default:
          return "none";
      }
    }};
    box-shadow: ${({ kind }) => {
      switch (kind) {
        case "outline":
        case "secondary":
          return "none";
        default:
          return "inset 0px 0px 5px rgba(0, 0, 0, 0.3)";
      }
    }};

    & path {
      fill: ${({ kind }) => {
        switch (kind) {
          case "outline":
            return "#417900";
          case "secondary":
            return "#68AD18";
          default:
            return "#fff";
        }
      }};
    }
  }

  :disabled {
    cursor: not-allowed;
    background: ${({ kind }) => {
      switch (kind) {
        case "outline":
        case "secondary":
          return "#fff";
        default:
          return "#F8F8F8";
      }
    }};
    color: #cbd5d8;
    border: ${({ kind }) => {
      switch (kind) {
        case "outline":
          return "1px solid #F8F8F8";
        default:
          return "none";
      }
    }};
    box-shadow: none;
    & path {
      fill: #cbd5d8;
    }
  }
`;

export default Button;
