import styled from "@emotion/styled";
import React, { useRef, useState } from "react";
import useOnClickOutside from "@src/hooks/useOnClickOutside";
import { ColorBox } from "@screens/CropsScreen/CropsScreen";
import SizedBox from "@components/SizedBox";
import Text from "@components/Text";
import { TCategory } from "@services/fieldsService";

interface IProps {
  label?: string;
  categories: TCategory[];
  value?: TCategory | null;
  onChange?: (categories: TCategory) => void;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const Tooltip = styled.div<{ opened: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  top: 72px;
  background: #ffffff;
  height: ${({ opened }) => (opened ? "auto" : 0)};
  overflow: ${({ opened }) => (opened ? "auto" : "hidden")};
  border: ${({ opened }) => (opened ? 1 : 0)}px solid #cbd5d8;
  padding: ${({ opened }) => (opened ? "8px 0" : "0")};
  box-sizing: border-box;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  max-height: 300px;
  overflow: auto;
  transition: 0.4s;
  z-index: 1;
`;

const SelectItem = styled.div<{ selected: boolean }>`
  display: flex;
  align-items: center;
  height: 38px;
  padding: 0 16px;
  background: ${({ selected }) => (selected ? "#f8f8f8" : "#fff")};
  transition: 0.4s;
  cursor: pointer;
  :hover {
    background: #f8f8f8;
  }
`;

const InputField = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  outline: none;
  padding: 0 12px 0 16px;
  height: 44px;
  background: #f8f8f8;
  border-radius: 4px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #494c5b;
  box-sizing: border-box;
  border: 1px solid transparent;
  transition: 0.4s;
  :hover,
  :focus {
    border: 1px solid #899093;
  }
  ::placeholder {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #cbd5d8;
  }
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;
`;

const CategoriesSelect: React.FC<IProps> = ({
  value,
  label,
  categories,
  onChange,
}) => {
  const [opened, setOpened] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => setOpened(false));

  const handleSelect = (category: TCategory) => {
    onChange && onChange(category);
    setOpened(false);
  };
  return (
    <Root>
      <Text size={14} type="heading" color="#494C5B">
        {label}
      </Text>
      <InputField ref={ref} onClick={() => setOpened(true)}>
        {value && (
          <>
            <Icon src={value.icon.url} alt={value.name} />
            <SizedBox width={14} />
            <ColorBox color={value.color} />
          </>
        )}
        <Tooltip opened={opened}>
          {categories.map((category, i) => (
            <SelectItem
              key={i}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleSelect(category);
              }}
              selected={category.id === value?.id}
            >
              <Icon src={category.icon.url} alt={category.name} />
              <SizedBox width={14} />
              <ColorBox color={category.color} />
            </SelectItem>
          ))}
        </Tooltip>
      </InputField>
    </Root>
  );
};
export default CategoriesSelect;
