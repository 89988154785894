import React from "react";

import _ from "lodash";
import moment from "moment";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";

import { ReactComponent as SearchIcon } from "@assets/icons/search.svg";

import { Column, Row } from "@src/components/Flex";
import Text from "@components/Text";
import SizedBox from "@components/SizedBox";
import SearchInput from "@components/SearchInput";
import { useContext } from "@screens/TracksScreen/Context";
import { AttachedDevice } from "@services/devicesService";
import * as dot from "@components/Dot";
import { useLang } from "@src/hooks/useLang";


const Root = styled.div<{hidden?: boolean}>`
  flex-shrink: 0;
  display: ${({hidden}) => hidden ? "none" : "flex"};
  flex-direction: column;
  box-sizing: border-box;
  padding: 16px 0;
  width: 268px;
  max-height: 100vh;
  box-shadow: 0 2px 20px rgba(78, 78, 81, 0.1);
`;

const StyleHeader = styled.div`
  width: 100%;
  margin-bottom: 12px;
  padding: 0 16px;
  box-sizing: border-box;
`;


const Header: React.FC<{ count?: number }> = () => {
  const ctx = useContext();
  const lang = useLang();

  return (
    <StyleHeader>
      <Column crossAxisSize="max">
        <Text color="#494C5B" type="heading" size={24}>Devices</Text>
        <SizedBox height={12} />
        {ctx.devices.length > 5 ? <SearchInput
          style={{ width: "100%" }}
          value={ctx.query}
          onChange={(e) => ctx.setQuery(e.target.value)}
          placeholder={"Search"}
          icon={<SearchIcon />}
        /> : null}
        {ctx.devices.length === 0 ? (
          <Text style={{ marginTop: 8 }} size={15} color="#899093">
            {lang.tracks.noDevices}
          </Text>
        ) : null}
        </Column>
    </StyleHeader>
  );
}


const StyleRow = styled.div<{ selected?: boolean, highlighted?: boolean }>`
  display: flex;
  padding: 12px 16px;
  box-sizing: border-box;
  border-top: 1px solid rgba(137, 144, 147, 0.3);
  border-right: 2px solid ${({ highlighted }) => (
    highlighted ? "rgb(104, 173, 24)" : "rgb(255, 255, 255)"
  )};
  min-height: 70px;
  background: ${({ highlighted, selected }) => {
    if (highlighted) {
      return selected ? "hsl(88 76% 90% / 1)" : "hsl(88 76% 95% / 1)";
    }
    return selected ? "#f8f8f8" : "#ffffff";
  }};
  transition: 0.4s;
  position: relative;
  cursor: pointer;
  width: 100%;
  :hover {
    background: #f8f8f8;
  }

  & > .more {
    position: absolute;
    inset: 8px 8px auto auto;
    cursor: pointer;
  }
`;

const StyleTitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Nunito Sans, sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1rem;
  color: rgba(73, 76, 91, 1);
`;

const StyleSubtitle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Nunito Sans, sans-serif;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1rem;
  color: rgba(73, 76, 91, 1);
  color: rgba(137, 144, 147, 1);
  text-transform: uppercase;
`;

// Status is a simple dot that changes color depending on the time
// since device was connected fro the last time.
const Status: React.FC<{ device: AttachedDevice }> = ({ device }) => {
  const last_seen = device.getLastSeenAt();
  if (last_seen === undefined) {
    return <dot.Red />;
  }

  const m = moment(last_seen.toDate());
  if (m.isValid()) {
    const hours = moment().diff(m, "h");
    if (hours <= 1) {
      return <dot.Green />
    }

    if (hours <= 24) {
      return <dot.Yellow />
    }

    return <dot.Gray />;
  }

  return <dot.Red />;
};

// LastSeen displays time when device was online for the last time
const LastSeen: React.FC<{ device: AttachedDevice }> = ({ device }) => {
  const last_seen = device.getLastSeenAt();
  if (last_seen === undefined) {
    return null;
  }

  const mseen = moment(last_seen.toDate());
  return <StyleSubtitle>
    <span style={{ color: "rgba(73, 76, 91, 1)" }}>{mseen.fromNow()}</span>
  </StyleSubtitle>;
};

// DeviceRow is an element of the Menu.
const DeviceRow: React.FC<{
  name: string,
  device: AttachedDevice;
  selected: boolean;
} & React.HTMLAttributes<HTMLDivElement>> = ({ device, name, ...props }) => {
  return (
    <StyleRow {...props}>
      <Column
        justifyContent="space-between"
        crossAxisSize="max"
        mainAxisSize="stretch"
      >
        <StyleTitle><Status device={device} /> {name}</StyleTitle>
        <Row
          mainAxisSize="stretch"
          justifyContent="space-between"
        >
          <StyleSubtitle>{device.getDescription()}</StyleSubtitle>
          <LastSeen device={device} />
        </Row>
      </Column>
    </StyleRow>
  );
};

const StyleBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  justify-content: space-between;
`;


const Menu: React.FC<{ 
  onDeviceSelect: (device: AttachedDevice) => void;
  hidden?: boolean;
}> = ({ onDeviceSelect, hidden }) => {
  const ctx = useContext();

  const [esn, setEsn] = React.useState<string | null>(null);
  const devices = ctx.getDevices();
  return (
    <Root hidden={hidden}>
      <Column mainAxisSize="stretch" crossAxisSize="max">
        <Header />
        <StyleBody>
          {devices.map((device) => {
            const name = _.isEmpty(device.getName()) 
              ? device.getEsn().toUpperCase()
              : device.getName();

            return (<DeviceRow
              key={device.getEsn()}
              name={name}
              device={device}
              selected={device.getEsn() === esn}
              onClick={() => {
                setEsn(device.getEsn());
                onDeviceSelect(device);
              }}
            />);
          })}
        </StyleBody>
      </Column>
    </Root>
  );
};

export default observer(Menu);
