import styled from "@emotion/styled";
import React, { useState } from "react";
import { ReactComponent as LocationArrowIcon } from "@assets/icons/locationArrow.svg";
import { ReactComponent as SearchMinusIcon } from "@assets/icons/searchMinus.svg";
import { ReactComponent as SearchPlusIcon } from "@assets/icons/searchPlus.svg";
import { ReactComponent as PenIcon } from "@assets/icons/pen.svg";
import SizedBox from "@components/SizedBox";
import { observer } from "mobx-react-lite";
import { useFieldsScreenVM } from "@screens/FieldsScreen/FieldsScreenVM";
import { transform } from "ol/proj";
import { toast } from "react-toastify";
import Spinner from "@components/Spinner";

interface IProps {}

const Root = styled.div`
  position: absolute;
  inset: 0 16px 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  & > * {
    margin-bottom: 8px;
  }
  :last-child {
    margin-bottom: 0;
  }
`;

const Button = styled.button<{ active?: boolean }>`
  outline: none;
  padding: 0;
  box-sizing: border-box;
  border: none;
  box-shadow: 0 2px 20px rgba(78, 78, 81, 0.1);
  border-radius: 4px;
  width: 28px;
  height: 28px;
  background: ${({ active }) => (active ? "#262626" : "#fff")};
  path {
    fill: ${({ active }) => (active ? "#ACDD73" : "#262626")};
  }
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  background: #fff;
  box-shadow: 0 2px 20px rgba(78, 78, 81, 0.1);
  border-radius: 4px;
`;

const MapToolbar: React.FC<IProps> = () => {
  const vm = useFieldsScreenVM();
  const [findMeLoading, setFindMeLoading] = useState(false);
  const changeZoomUp = (diff: number) => {
    const zoom = vm.map.getView().getZoom();
    zoom && vm.map.getView().setZoom(zoom + diff);
  };
  const findMe = async () => {
    setFindMeLoading(true);
    await new Promise<[number, number]>((resolve, reject) =>
      navigator.geolocation.getCurrentPosition(
        ({ coords }) => resolve([coords.longitude, coords.latitude]),
        (err) => reject(err)
      )
    )
      .then((coordinate) => {
        const center = transform(coordinate, "EPSG:4326", "EPSG:3857");
        center.every((v) => !isNaN(v)) && vm.map.getView().setCenter(center);
      })
      .catch((e) => toast(e.message ?? e.toString(), { type: "error" }))
      .finally(() => setFindMeLoading(false));
  };

  return (
    <Root>
      <Button active={vm.isFieldEdit} onClick={vm.handleFieldCreate}>
        <PenIcon />
      </Button>
      {/*<Button>*/}
      {/*  <RulerIcon />*/}
      {/*</Button>*/}
      <ButtonGroup>
        <Button style={{ boxShadow: "none" }} onClick={() => changeZoomUp(1)}>
          <SearchPlusIcon />
        </Button>
        <SizedBox height={4} />
        <Button style={{ boxShadow: "none" }} onClick={() => changeZoomUp(-1)}>
          <SearchMinusIcon />
        </Button>
      </ButtonGroup>
      <Button onClick={!findMeLoading ? findMe : undefined}>
        {findMeLoading ? <Spinner size={14} /> : <LocationArrowIcon />}
      </Button>
    </Root>
  );
};
export default observer(MapToolbar);
