import React, { useState } from "react";
import AuthLayout from "@components/AuthLayout";
import SizedBox from "@components/SizedBox";
import Button from "@components/Button";
import Input from "@components/Input";
import validateEmail from "@src/utils/validateEmail";
import validatePass from "@src/utils/validatePassword";
import { Navigate, useNavigate } from "react-router-dom";
import { ROUTES } from "@stores/AccountStore";
import { observer } from "mobx-react-lite";
import { useLang } from "@src/hooks/useLang";
import { useStores } from "@stores";
import Loading from "@components/Loading";
import { pageView } from "@src/utils/analytics";

interface IProps { }

const LoginScreen: React.FC<IProps> = () => {
  pageView("Login");

  const navigate = useNavigate();
  const lang = useLang();
  const { accountStore } = useStores();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const disabled = !validateEmail(email) || !validatePass(password);
  const handleSubmit = async () => {
    if (disabled) return;
    setError(undefined);
    setLoading(true);
    await accountStore
      .login({ email, password })
      .then(() => navigate(ROUTES.PROFILE))
      .catch((e) => {
        console.error(e);
        setError(e.toString());
        setLoading(false);
      });
  };
  if (accountStore.authenticated) return <Navigate to={ROUTES.PROFILE} />;
  return (
    <AuthLayout error={error}>
      <Input
        onChange={(e) => setEmail(e.target.value)}
        value={email}
        placeholder={lang.auth.emailField}
        label={lang.auth.emailTitle}
        type="email"
      />
      <SizedBox height={16} />
      <Input
        onChange={(e) => setPassword(e.target.value)}
        value={password}
        type="password"
        placeholder={lang.auth.passwordField}
        label={lang.auth.passwordTitle}
        //todo enable when backend will support this
        // onExtraClick={() => navigate(ROUTES.PASSWORD_FORGOT)}
        // extra={lang.auth.passwordRestore}
        onKeyDown={(e) => e.key.toLowerCase() === "enter" && handleSubmit()}
      />
      <SizedBox height={32} />
      <Button onClick={handleSubmit} disabled={disabled}>
        {!loading ? lang.auth.signInButton : <Loading />}
      </Button>
    </AuthLayout>
  );
};
export default observer(LoginScreen);
