import React, { useEffect, useState } from "react";
import Map from "./Map";
import LeftBar from "@components/Layout/LeftBar";
import styled from "@emotion/styled";
import FieldsMenu from "./FieldsMenu";
import { FieldsScreenVMProvider } from "@screens/FieldsScreen/FieldsScreenVM";
import { useStores } from "@stores";
import { observer } from "mobx-react-lite";
import { pageView } from "@src/utils/analytics";

interface IProps {}

const Root = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  align-items: stretch;
`;
// min-width: 1152px;

const FieldsScreenImpl: React.FC<IProps> = () => {
  pageView("Fields");

  return (
    <FieldsScreenVMProvider>
      <Root>
        <LeftBar style={{ marginRight: 0 }} />
        <FieldsMenu />
        <Map />
      </Root>
    </FieldsScreenVMProvider>
  );
};

const FieldsScreen = () => {
  const { settingsStore } = useStores();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    new Promise((r) => setTimeout(r, 1)).then(() => setLoading(false));
  }, [settingsStore.leftBarExpanded]);

  return !loading ? <FieldsScreenImpl /> : null;
};

export default observer(FieldsScreen);
