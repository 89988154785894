import React from "react";
import { Row } from "@components/Flex";
import Text from "@components/Text";
import { ReactComponent as ArrowDown } from "@assets/icons/arrowDown.svg";
import Tooltip from "@components/Tooltip";
import { useStores } from "@stores";
import LanguageSelectBody from "@components/LanguageSelectTooltip/LanguageSelectBody";
import { LANGUAGE_SHORT_NAMES } from "@stores/SettingsStore";

interface IProps {}

const LanguageSelectTooltip: React.FC<IProps> = () => {
  const { settingsStore } = useStores();
  return (
    <Tooltip
      contentWrapperStyles={{ maxWidth: 150 }}
      config={{ placement: "top", trigger: "click" }}
      content={<LanguageSelectBody />}
    >
      <Row style={{ userSelect: "none" }}>
        <Text color="#899093" size={10} fitContent>
          {LANGUAGE_SHORT_NAMES[settingsStore.language]}
        </Text>
        <ArrowDown />
      </Row>
    </Tooltip>
  );
};

export default LanguageSelectTooltip;
