import React, { useState } from "react";
import moment from "moment";
import { observer } from "mobx-react-lite";

import Button from "@components/Button";
import DatePickerInput from "@components/DatePicker/DatePickerInput";
import Dialog from "@components/Dialog";
import Input from "@components/Input";
import SizedBox from "@components/SizedBox";
import { Row } from "@components/Flex";
import { useLang } from "@src/hooks/useLang";
import { seasonName } from "@services/fieldsService";

interface IProps {
  visible: boolean;
  endOfTheLastSeason?: Date;
  onClose?: () => void;
  onSubmit: (from: Date, to: Date) => void;
}

const CreateSeasonDialog: React.FC<IProps> = ({ onSubmit, endOfTheLastSeason, ...rest }) => {
  const lang = useLang();

  let start = moment().startOf("year");
  if (endOfTheLastSeason) {
    start = moment(endOfTheLastSeason).add(1, "day");
  }
  const end = start.clone().add(1, "year");


  const [from, setStartDate] = useState<Date | null>(start.toDate());
  const [to, setEndDate] = useState<Date | null>(end.toDate());
  const disabled = from == null || to == null || from.getTime() >= to.getTime();
  return (
    <Dialog
      style={{ width: 336 }}
      title={lang.seasons.createDialogTitle}
      {...rest}
    >
      <Input
        value={(from && to) ? seasonName(from!, to!, false) : ""}
        disabled={true}
        label={lang.seasons.nameTitle}
      />
      <SizedBox height={16} />
      <Row>
        <DatePickerInput value={from} onChange={setStartDate} />
        <SizedBox width={16} />
        <DatePickerInput value={to} onChange={setEndDate} />
      </Row>
      <SizedBox height={24} />
      <Row>
        <Button kind="outline" onClick={rest.onClose}>
          {lang.seasons.cancelButton}
        </Button>
        <SizedBox width={16} />
        <Button onClick={() => onSubmit(from!, to!)} disabled={disabled}>
          {lang.seasons.createButton}
        </Button>
      </Row>
    </Dialog>
  );
};
export default observer(CreateSeasonDialog);
