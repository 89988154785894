import React from "react";

import { observer } from "mobx-react-lite";

import { useLang } from "@src/hooks/useLang";
import { useFieldsScreenVM } from "@screens/FieldsScreen/FieldsScreenVM";
import Dropdown from "@components/MapDropdown";

interface IProps { }

const FieldViewSelect: React.FC<IProps> = () => {
  const lang = useLang();
  const vm = useFieldsScreenVM();

  if (vm.selectedFieldId == null) {
    return null;
  }

  return (
    <Dropdown
      title={lang.fields.fieldViewTitle}
      options={[
        {
          title: lang.fields.mapSelecterBoundaries,
          value: "border",
        },
        {
          title: lang.fields.mapSelecterYield,
          value: "yield",
        }
      ]}
      selected={vm.selectedFieldView}
      onSelect={(value: string) => {
        vm.setSelectedFieldView(value as "border" | "yield");
      }}
    />
  );
};

export default observer(FieldViewSelect);
