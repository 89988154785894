import React from "react";
import { useDevicesScreenVM } from "@screens/DevicesScreen/DevicesScreenVM";
import { observer } from "mobx-react-lite";
import { useLang } from "@src/hooks/useLang";
import Layout from "@components/Layout";
import CreateDeviceButton from "@screens/DevicesScreen/CreateDeviceButton";
import { Row } from "@components/Flex";
import Spinner from "@components/Spinner";
import DevicesListHeader from "@screens/DevicesScreen/DevicesListHeader";
import DevicesList from "@screens/DevicesScreen/DevicesList";
import CreateDeviceDialog from "@screens/DevicesScreen/dialogs/CreateDeviceDialog";

const DevicesScreenImpl: React.FC = () => {
  const vm = useDevicesScreenVM();
  const lang = useLang();
  // const subtitle = vm.initialized
  //   ? vm.devices.length > 0
  //     ? lang.devices.devicesTagsSubtitle
  //     : lang.devices.devicesInitialSubtitle
  //   : "";
  const isNoDevices = vm.devices.length === 0;

  const handleCloseCreateDialog = () => {
    vm.setEditedDevice(null);
    vm.setCreateDialogOpened(false);
  };

  return (
    <Layout
      title={lang.devices.devicesTitle}
      // subtitle={subtitle}
      extra={vm.initialized && !isNoDevices ? <CreateDeviceButton /> : null}
    >
      {!vm.initialized && (
        <Row alignItems="center" justifyContent="center" crossAxisSize="max">
          <Spinner />
        </Row>
      )}
      {vm.initialized && !isNoDevices && (
        <>
          <DevicesListHeader />
          <DevicesList />
        </>
      )}
      {vm.initialized && isNoDevices && <CreateDeviceButton />}
      <CreateDeviceDialog
        onSubmit={vm.editedDevice != null ? vm.edit : vm.create}
        visible={vm.createDialogOpened}
        onClose={handleCloseCreateDialog}
      />
    </Layout>
  );
};

export default observer(DevicesScreenImpl);
