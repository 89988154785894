import React, { useEffect, useState } from "react";
import Dialog from "@components/Dialog";
import SizedBox from "@components/SizedBox";
import Input from "@components/Input";
import { observer } from "mobx-react-lite";
import { useLang } from "@src/hooks/useLang";
import CategoriesSelect from "@screens/CropsScreen/CategoriesSelect";
import Button from "@components/Button";
import { Row } from "@src/components/Flex";
import { TCategory, TCreateCropRequest } from "@services/fieldsService";
import { useCropsScreenVM } from "@screens/CropsScreen/CropsScreenVM";
import Loading from "@components/Loading";

interface IProps {
  visible: boolean;
  onClose: () => void;
  id?: string;
  onSubmit: (id: string, crop: TCreateCropRequest) => void;
}

const EditCropDialog: React.FC<IProps> = ({ id, onSubmit, ...rest }) => {
  const lang = useLang();
  const vm = useCropsScreenVM();
  const [name, setName] = useState("");
  const [category, setCategory] = useState<TCategory | null>(null);

  const [loading, setLoading] = useState(false);
  const crop = vm.crops.find((crop) => crop.id === id);
  useEffect(() => {
    if (rest.visible && crop == null && rest.onClose) {
      rest.onClose();
    }
  }, [crop, rest]);

  useEffect(() => {
    if (crop != null) {
      setName(crop.name);
      setCategory(crop.category);
    }
  }, [crop, rest.visible, vm.categories]);

  const handleSubmit = async () => {
    if (id == null || category == null) return;
    setLoading(true);
    if (onSubmit) await onSubmit(id, { category_id: category.id, name: name });
    setLoading(false);
  };
  return (
    <Dialog style={{ width: 336 }} title={lang.crops.editCropTitle} {...rest}>
      <Input
        value={name}
        onChange={(e) => setName(e.target.value)}
        label={lang.crops.addCropName}
        defaultValue={crop?.name}
      />
      <SizedBox height={16} />
      <CategoriesSelect
        value={category}
        categories={vm.categories}
        onChange={setCategory}
        label={lang.crops.addCropCategory}
      />

      <SizedBox height={24} />
      <Row>
        <Button kind="outline" onClick={rest.onClose}>
          {lang.crops.addCropCancelButton}
        </Button>
        <SizedBox width={16} />
        <Button onClick={handleSubmit} disabled={!category || !name}>
          {!loading ? lang.crops.editCropSaveButton : <Loading />}
        </Button>
      </Row>
    </Dialog>
  );
};
export default observer(EditCropDialog);
