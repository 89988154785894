import styled from "@emotion/styled";
import React from "react";
import { observer } from "mobx-react-lite";
import { useFieldsScreenVM } from "@screens/FieldsScreen/FieldsScreenVM";
import Text from "@components/Text";
import SizedBox from "@components/SizedBox";
import { Row } from "@src/components/Flex";
import { useLang } from "@src/hooks/useLang";
import getTextWidth from "@src/utils/getTextWidth";
interface IProps {}

const Root = styled.div<{ width?: number }>`
  position: absolute;
  inset: 18px 16px auto auto;
  background: #fff;
  padding: 6px 12px;
  width: ${({ width }) => width ?? 184}px;
  border-radius: 6px;
  z-index: 2;
`;

const font =
  "font-family: Nunito Sans; font-weight: bold;font-size: 12px; line-height: 18px;white-space:nowrap";

const MapYieldLegend: React.FC<IProps> = () => {
  const vm = useFieldsScreenVM();
  const lang = useLang();
  const legend =
    vm.selectedFieldId != null
      ? vm.heatmapLegend[String(vm.selectedFieldId)]
      : undefined;
  const max =
    legend != null ? Math.max(...legend.map(({ percent }) => percent)) : 0;

  const maxWidth = Math.max(
    ...(legend ?? []).map(({ range }) => getTextWidth(range, font))
  );
  return vm.selectedFieldView === "yield" && legend != null ? (
    <Root width={maxWidth * 2 + 56}>
      <Text type="heading" color="#899093" size={12}>
        {lang.fields.legendTitle}
      </Text>
      <SizedBox height={4} />
      {legend.map((v, i) => (
        <Row style={{ height: 16, marginBottom: 6 }} key={i}>
          <Text
            color="#494C5B"
            size={11}
            type="heading"
            style={{ marginRight: 8 }}
            nowrap
          >
            {v.range}
          </Text>
          <Row
            style={{ height: "100%", padding: "0 4px", position: "relative" }}
            alignItems="center"
          >
            <div
              style={{
                position: "absolute",
                background: v.color,
                top: 0,
                bottom: 0,
                left: 0,
                width: `${(v.percent / max) * 100}%`,
              }}
            />
            <Text
              color="#494C5B"
              size={11}
              type="heading"
              style={{
                marginRight: 8,
                zIndex: 1,
                textShadow:
                  "1px 0 0 #fff, -1px 0 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff, 1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff",
              }}
            >
              {v.percent} %
            </Text>
          </Row>
        </Row>
      ))}
    </Root>
  ) : null;
};
export default observer(MapYieldLegend);
