import React, { useEffect, useState } from "react";
import Dialog from "@components/Dialog";
import SizedBox from "@components/SizedBox";
import { observer } from "mobx-react-lite";
import { useLang } from "@src/hooks/useLang";
import Button from "@components/Button";
import { Row } from "@src/components/Flex";
import Text from "@components/Text";
import { useCropsScreenVM } from "@screens/CropsScreen/CropsScreenVM";
import Loading from "@components/Loading";

interface IProps {
  id?: string;
  visible: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
}

const RemoveConfirmDialog: React.FC<IProps> = ({ onSubmit, id, ...rest }) => {
  const lang = useLang();
  const vm = useCropsScreenVM();
  const [loading, setLoading] = useState(false);
  const crop = vm.crops.find((crop) => crop.id === id);
  useEffect(() => {
    if (rest.visible && crop == null && rest.onClose) rest.onClose();
  }, [crop, rest]);

  const handleSubmit = async () => {
    setLoading(true);
    if (onSubmit) await onSubmit();
    setLoading(false);
  };
  return (
    <Dialog style={{ width: 408 }} title={lang.crops.deleteCropTitle} {...rest}>
      <Text size={15} color="#899093" style={{ width: 250 }}>
        {lang.crops.deleteCropSubtitle.replace("{{name}}", crop?.name ?? "")}
      </Text>
      <SizedBox height={24} />
      <Row>
        <Button kind="outline" onClick={handleSubmit}>
          {!loading ? lang.crops.deleteCropDelButton : <Loading />}
        </Button>
        <SizedBox width={16} />
        <Button onClick={rest.onClose}>
          {lang.crops.deleteCropCancelButton}
        </Button>
      </Row>
    </Dialog>
  );
};
export default observer(RemoveConfirmDialog);
