import React from "react";

import _ from "lodash";
import moment from "moment";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { makeAutoObservable } from "mobx";
import {
  Area,
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceArea,
} from 'recharts';

import { ReactComponent as CloseIcon } from "@src/assets/icons/close.svg";
import useVM from "@src/hooks/useVM";
import { click } from "@src/utils/analytics";
import { useLang } from "@src/hooks/useLang";
import { DeviceStatisticsResponse } from "@services/devicesService";
import { sensorColor, speedColor, normalizeSensor } from "@screens/TracksScreen/utils";
import Text from "@components/Text";
import Button from "@components/Button";

const CHART_HEIGHT = 180;
const MAX_DURATION_BETWEEN_POINTS = 5 * 60;


////////////////////////////////////////////////////////////////////////////////
// CONTEXT
////////////////////////////////////////////////////////////////////////////////

const CONTEXT = React.createContext<Context | null>(null);

class Context {
  data: any[] = [];
  on_zoom: ZoomCallback;

  left: number;
  right: number;

  // Edges of the reference area
  ref_area_left?: number;
  ref_area_right?: number;

  constructor(data: any[], on_zoom: ZoomCallback) {
    makeAutoObservable(this);
    this.data = data;
    this.left = 0;
    this.right = data.length - 1;
    this.on_zoom = on_zoom;
  }

  getData() {
    if (_.isNull(this.left) || _.isNull(this.right)) {
      return this.data;
    }
    return this.data.slice(this.left, this.right);
  }

  setRefAreaLeft(val: number | undefined) {
    this.ref_area_left = val;
  }

  setRefAreaRight(val: number | undefined) {
    if (!_.isUndefined(this.ref_area_left)) {
      this.ref_area_right = val;
    }
  }

  isZoomApplied(): boolean {
    return this.left > 0 || this.right < this.data.length - 1;
  }

  zoom() {
    if (!_.isUndefined(this.ref_area_left) && !_.isUndefined(this.ref_area_right)) {
      let left = this.ref_area_left;
      let right = this.ref_area_right;
      if (left > right) {
        [left, right] = [right, left];
      }

      this.right = this.left + right;
      this.left += left;

      this.on_zoom(
        moment.unix(this.data[this.left].time).toDate(),
        moment.unix(this.data[this.right].time).toDate()
      );
    } else {
      this.left = 0;
      this.right = this.data.length - 1;
    }

    this.ref_area_left = undefined;
    this.ref_area_right = undefined;
  }

  reset() {
    this.left = 0;
    this.right = this.data.length - 1;
    this.on_zoom();
  }
}

type ZoomCallback = (from?: Date, to?: Date) => void;

// Contenxt provider for the the tracks screen state.
const ContextProvider: React.FC<{
  data: any[];
  onZoom: ZoomCallback;
}> = ({ data, onZoom, children }) => {
  return (
    <CONTEXT.Provider value={new Context(data, onZoom)}>
      {children}
    </CONTEXT.Provider>
  );
};

export const useContext = () => useVM(CONTEXT);

////////////////////////////////////////////////////////////////////////////////
// STYLES
////////////////////////////////////////////////////////////////////////////////

const StyleWindow = styled.div<{ opened: boolean }>`
  position: absolute;
  width: 98%;
  left: 1%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  background: rgb(255, 255, 255);
  z-index: 10;
  height: 450px;
  border-radius: 6px 6px 0 0;
  top: calc(100%);

  transition: transform 0.2s ease-out;
  transform: translate(0, ${({ opened }) => opened ? "-450px" : "-41px"});
  :hover {
    transform: translate(0, ${({ opened }) => opened ? "-450px" : "-80px"});
  }
`;


const StyleHeader = styled.div`
  width: 100%;
  margin-bottom: 12px;
  padding: 5px 16px;
  box-sizing: border-box;
  display: flex;
`;

const StyleChartContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 0 16px;
  box-sizing: border-box;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
`;

const StyleBorder = styled.div`
  border-bottom: 1px solid #000;
  margin: 10px 20px;
`

const Icon = styled(Text)`
  :hover {
    color: #68ad18;
  }
`;

////////////////////////////////////////////////////////////////////////////////
// UTILITY FUNCTIONS
////////////////////////////////////////////////////////////////////////////////

// Remap input value within input range to output range
function remap(
  value: number,
  in_start: number,
  in_end: number,
  out_start: number,
  out_end: number
) {
  return (value - in_start) / (in_end - in_start) * (out_end - out_start) + out_start;
}

function prepareData(statistics: DeviceStatisticsResponse): any[] {
  let data: any[] = [];
  if (statistics.getLength() < 2) {
    return data;
  }

  const time = statistics.getTimeList();
  const speed = statistics.getSpeedList();
  const norms = statistics.getNormList();

  for (let i = 1; i < statistics.getLength(); i++) {
    if (time[i] === time[i - 1]) {
      continue;
    }

    if (data.length > 0 && Math.abs(time[i] - data[data.length - 1].time) > MAX_DURATION_BETWEEN_POINTS) {
      data.push({
        time: time[i - 1] + 1,
        sensor: null,
        speed: null,
      });
      data.push({
        time: time[i] - 1,
        sensor: null,
        speed: null,
      });
    }

    data.push({
      time: time[i],
      sensor: Math.floor(norms[i] * 100),
      speed: Math.min(speed[i], 32),
    });
  }

  return data
}

////////////////////////////////////////////////////////////////////////////////
// COMPONENTS
////////////////////////////////////////////////////////////////////////////////

const SensorChart: React.FC = observer(() => {
  const ctx = useContext();
  const data = ctx.getData();

  let max = data.length === 0 ? 0 : data[0].sensor;
  for (let i = 1; i < data.length; i++) {
    max = Math.max(max, data[i].sensor);
  }

  const length = Math.round(remap(max, 0, 100, 0, 10));

  return (
    <StyleChartContainer>
      <ResponsiveContainer width="100%" height={CHART_HEIGHT} debounce={100}>
        <AreaChart
          width={500}
          height={500}
          data={data}
          syncId="1"
          onMouseDown={(e) => ctx.setRefAreaLeft(e.activeTooltipIndex)}
          onMouseMove={(e) => ctx.setRefAreaRight(e.activeTooltipIndex)}
          onMouseUp={() => {
            click("tracks.zoom-on-sensor");
            ctx.zoom();
          }}
        >
          <defs>
            <linearGradient id="sensor_fill" x1="0" y1="0" x2="0" y2="1">
              {
                Array.from({ length: length }, (_, i) => {
                  const from = remap(i, 0, length, 0, 1);
                  const to = remap(i + 1, 0, length, 0, 1);
                  const value = normalizeSensor(
                    remap(length - i, 0, length, 0, max / 100));
                  if (i === length - 1) {
                    return (
                      <React.Fragment key={i}>
                        <stop
                          offset={from}
                          stopColor={sensorColor(value)}
                          stopOpacity={1} />
                      </React.Fragment>
                    );
                  }
                  return (
                    <React.Fragment key={i}>
                      <stop
                        offset={from}
                        stopColor={sensorColor(value)}
                        stopOpacity={1} />
                      <stop
                        offset={to}
                        stopColor={sensorColor(value)}
                        stopOpacity={1} />
                    </React.Fragment>
                  );
                })
              }
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis
            yAxisId="sensor_y"
            dataKey="sensor"
            tickFormatter={val => Math.round(val).toString()}
          />
          <XAxis
            scale="linear"
            interval="equidistantPreserveStart"
            xAxisId="sensor_x"
            dataKey="time"
            tickFormatter={val => moment.unix(val).format('HH:mm')}
          />
          <Area
            type="monotone"
            yAxisId="sensor_y"
            xAxisId="sensor_x"
            dataKey="sensor"
            connectNulls={false}
            activeDot={{ r: 3 }}
            stroke="url(#sensor_fill)"
            fill="url(#sensor_fill)"
            fillOpacity={1}
            isAnimationActive={false}
          />
          <Tooltip
            labelFormatter={(label) => {
              return moment.unix(label).format('HH:mm:ss');
            }}
          />
          {(!_.isUndefined(ctx.ref_area_left) && !_.isUndefined(ctx.ref_area_right)) &&
            <ReferenceArea
              yAxisId="sensor_y"
              xAxisId="sensor_x"
              x1={data[ctx.ref_area_left].time}
              x2={data[ctx.ref_area_right].time}
              strokeOpacity={0.5} />
          }
        </AreaChart>
      </ResponsiveContainer>
    </StyleChartContainer>
  );
});


const SpeedChart: React.FC = observer(() => {
  const lang = useLang();
  const ctx = useContext();
  const data = ctx.getData();

  let max = data.length === 0 ? 0 : data[0].speed;
  for (let i = 0; i < data.length; i++) {
    max = Math.max(max, data[i].speed);
  }

  const length = Math.round(remap(max, 0, 30, 0, 8));

  return (
    <StyleChartContainer>
      <ResponsiveContainer width="100%" height={CHART_HEIGHT} debounce={100}>
        <AreaChart
          width={500}
          height={500}
          data={data}
          syncId="1"
          onMouseDown={(e) => ctx.setRefAreaLeft(e.activeTooltipIndex)}
          onMouseMove={(e) => ctx.setRefAreaRight(e.activeTooltipIndex)}
          onMouseUp={() => {
            click("tracks.zoom-on-speed");
            ctx.zoom()
          }}
        >
          <defs>
            <linearGradient id="speed_fill" x1="0" y1="0" x2="0" y2="1">
              {
                Array.from({ length: length }, (_, i) => {
                  const from = remap(i, 0, length, 0, 1);
                  const to = remap(i + 1, 0, length, 0, 1);
                  const value = length - 1 - i;

                  return (
                    <React.Fragment key={i}>
                      <stop
                        offset={from}
                        stopColor={speedColor(value, 7)}
                        stopOpacity={1} />
                      <stop
                        offset={to}
                        stopColor={speedColor(value, 7)}
                        stopOpacity={1} />
                    </React.Fragment>
                  );
                })
              }
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" />
          <YAxis
            yAxisId="speed_y"
            unit={lang.tracks.speedUnit}
            dataKey="speed" />
          <XAxis
            scale="linear"
            xAxisId="speed_x"
            interval="equidistantPreserveStart"
            dataKey="time"
            tickFormatter={val => moment.unix(val).format('HH:mm')}
          />
          <Area
            yAxisId="speed_y"
            xAxisId="speed_x"
            connectNulls={false}
            isAnimationActive={false}
            dataKey="speed"
            activeDot={{ r: 3 }}
            stroke="url(#speed_fill)"
            fill="url(#speed_fill)"
            fillOpacity={1}
          />
          <Tooltip
            labelFormatter={(label) => {
              return moment.unix(label).format('HH:mm:ss');
            }}
          />
          {(!_.isUndefined(ctx.ref_area_left) && !_.isUndefined(ctx.ref_area_right)) &&
            <ReferenceArea
              yAxisId="speed_y"
              xAxisId="speed_x"
              x1={data[ctx.ref_area_left].time}
              x2={data[ctx.ref_area_right].time}
              strokeOpacity={0.5} />
          }
        </AreaChart>
      </ResponsiveContainer>
    </StyleChartContainer>
  );
});


const Charts: React.FC = observer(() => {
  const ctx = useContext();
  const lang = useLang();
  const [open, setOpen] = React.useState(false);

  return (
    <StyleWindow opened={open} onClick={() => {
      if (!open) {
        click("tracks.details-open");
        setOpen(true);
      }
    }}>
      <StyleHeader onClick={() => {
        if (open) {
          click("tracks.details-close");
          setOpen(false);
        }
      }}>
        <Text color="#494C5B" type="heading" size={24}>
          {lang.tracks.bottomSheetHeader}
        </Text>
        {open && <Icon textAlign="right" size={22}>
          <CloseIcon
            fill="currentColor"
            stroke="currentColor"
          />
        </Icon>}
      </StyleHeader>
      <div style={{
        height: "100%",
        overflowY: "auto",
        padding: "0 5px",
        display: open ? "block" : "none",
      }}>
        <div style={{ display: "flex" }} >
          <div style={{
            width: "30%",
            padding: "1rem",
          }}>
            <div style={{ marginBottom: "1rem" }}>
              <Text type="text">{lang.tracks.chartDescription1}</Text>
              <br />
              <Text type="text">{lang.tracks.chartDescription2}</Text>
            </div>
            {(ctx.isZoomApplied()) &&
              <Button onClick={() => {
                click("tracks.reset-zoom");
                ctx.reset()
              }}>{lang.tracks.zoomResetButton}</Button>
            }
          </div>
          <div style={{ width: "70%" }}>
            <SensorChart />
            <StyleBorder />
            <SpeedChart />
          </div>
        </div>
      </div>
    </StyleWindow >
  );
});


const Window: React.FC<{
  statistics: DeviceStatisticsResponse;
  // FIXME(nk2ge5k): I do not like this but it is the only way I can think of
  onZoom: ZoomCallback;
}> = ({ statistics, onZoom }) => {
  let data = React.useMemo(() => prepareData(statistics), [statistics]);
  return (
    <ContextProvider data={data} onZoom={onZoom}>
      <Charts />
    </ContextProvider>
  );
};

export default Window;
