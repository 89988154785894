export type TCalibration = {
  id: string;
  device: {
    id: string;
    name: string;
  };
  culture: {
    id: string;
    name: string;
  };
  start: string;
  stop: string;
  reaper_width: {
    value: 0;
    unit: string;
  };
  weight: {
    value: 0;
    unit: string;
  };
};

export type TCreateCalibrationParams = {
  culture_id: string;
  start: string;
  stop: string;
  reaper_width: {
    value: number;
    unit: "m";
  };
  weight: {
    value: number;
    unit: "kg";
  };
};

export const getDeviceCalibrations = async (
  deviceId: string
): Promise<Array<TCalibration>> => {
  const request = `https://at.greengrowth.tech/api/devices/v1/device/${deviceId}/calibration`;
  const response = await fetch(request, { credentials: "include" });
  const body = await response.json();
  if (response.status !== 200) {
    throw new Error(body.message);
  }
  return body.calibrations;
};

export const createCalibration = async (
  deviceId: string,
  calibration: TCreateCalibrationParams
) => {
  const request = `https://at.greengrowth.tech/api/devices/v1/device/${deviceId}/calibration`;
  const res = await fetch(request, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ calibration }),
    mode: "cors",
    credentials: "include",
  });
  if (!res.ok) {
    const err = await res.json();
    console.error("Failed to create calibration", err);
    throw new Error(err.message ?? err.toString());
  }
};

export const editCalibration = async (
  deviceId: string,
  calibrationId: string,
  calibration: TCreateCalibrationParams
) => {
  const request = `https://at.greengrowth.tech/api/devices/v1/device/${deviceId}/calibration/${calibrationId}`;
  const res = await fetch(request, {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ calibration }),
    mode: "cors",
    credentials: "include",
  });
  if (!res.ok) {
    const err = await res.json();
    console.error("Failed to edit calibration", err);
    throw new Error(err.message ?? err.toString());
  }
};

export const deleteCalibration = async (
  deviceId: string,
  calibrationId: string
) => {
  const request = `https://at.greengrowth.tech/api/devices/v1/device/${deviceId}/calibration/${calibrationId}`;
  const res = await fetch(request, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    mode: "cors",
    credentials: "include",
  });
  if (!res.ok) {
    const err = await res.json();
    console.error("Failed to edit calibration", err);
    throw new Error(err.message ?? err.toString());
  }
};
