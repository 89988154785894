import React, { useCallback, useEffect, useState } from "react";
import Dialog from "@components/Dialog";
import Input from "@components/Input";
import SizedBox from "@components/SizedBox";
import { Row } from "@components/Flex";
import Button from "@components/Button";
import { observer } from "mobx-react-lite";
import { useLang } from "@src/hooks/useLang";
import { IDialogPropTypes } from "rc-dialog/es/IDialogPropTypes";
import { TDevice } from "@services/devicesService";
import Loading from "@components/Loading";
import { useDevicesScreenVM } from "@screens/DevicesScreen/DevicesScreenVM";
import usePrevious from "@src/hooks/usePrevious";

interface IProps extends IDialogPropTypes {
  onSubmit?: (params: TDevice) => Promise<void>;
  onClose?: () => void;
}

const CreateDeviceDialog: React.FC<IProps> = ({
  onSubmit,
  onClose,
  ...rest
}) => {
  const lang = useLang();
  const vm = useDevicesScreenVM();
  const [initialized, setInitialized] = useState(false);
  const [serial, setSerial] = useState<string | undefined>();
  const [name, setName] = useState<string | undefined>();
  const [model, setModel] = useState<string | undefined>();
  const [width, setWidth] = useState<string | number | undefined>();
  const disabled = [serial, name, model, width].some((v) => !v);
  const prevVisible = usePrevious(rest.visible);

  const reset = () => {
    setSerial(undefined);
    setName(undefined);
    setModel(undefined);
    setWidth(undefined);
  };

  const handleSubmit = () => {
    if (!serial || !name || !model || !width) return;
    const params: TDevice = {
      id: serial,
      esn: serial,
      harvester: { name, model, default_reaper: { width: Number(width) } },
      tags: [],
      status: "inactive",
    };
    onSubmit && onSubmit(params);
  };

  const handleClose = useCallback(() => {
    setInitialized(false);
    reset();
    onClose && onClose();
  }, [onClose]);

  useEffect(() => {
    if (!initialized && vm.editedDevice) {
      setSerial(vm.editedDevice.id);
      setName(vm.editedDevice.harvester.name);
      setModel(vm.editedDevice.harvester.model);
      setWidth(vm.editedDevice.harvester.default_reaper.width);
      setInitialized(true);
    }
  }, [initialized, vm.editedDevice]);

  useEffect(() => {
    if (prevVisible && !rest.visible) handleClose();
  }, [handleClose, prevVisible, rest.visible]);

  return (
    <Dialog
      destroyOnClose
      style={{ width: 384 }}
      title={
        vm.editedDevice == null
          ? lang.devices.newDeviceTitle
          : lang.devices.editDeviceTitle
      }
      onClose={handleClose}
      {...rest}
    >
      {vm.editedDevice == null && (
        <>
          <Input
            value={serial}
            onChange={(e) => setSerial(e.target.value)}
            label={lang.devices.serialNumberTitle}
            placeholder={lang.devices.serialNumberSuggest}
            tip={lang.devices.serialNumberHelp}
          />
          <SizedBox height={24} />
        </>
      )}
      <Input
        value={name}
        onChange={(e) => setName(e.target.value)}
        label={lang.devices.harvesterNumberTitle}
        placeholder={lang.devices.harvesterNumberSuggest}
        tip={lang.devices.harvesterNumberHelp}
      />
      <SizedBox height={24} />
      <Input
        value={model}
        onChange={(e) => setModel(e.target.value)}
        label={lang.devices.harvesterModelTitle}
        placeholder={lang.devices.harvesterModelSuggestion}
      />
      <SizedBox height={24} />
      <Input
        value={width}
        onChange={(e) => setWidth(e.target.value)}
        label={lang.devices.headerWidthTitle}
        placeholder={"0"}
        type={"number"}
      />
      <SizedBox height={24} />
      <Row>
        <Button kind="outline" onClick={handleClose}>
          {lang.devices.deviceCancelButton}
        </Button>
        <SizedBox width={16} />
        <Button
          onClick={!vm.loading ? handleSubmit : undefined}
          disabled={vm.loading ?? disabled}
        >
          {!vm.loading ? lang.devices.deviceSaveButton : <Loading />}
        </Button>
      </Row>
    </Dialog>
  );
};
export default observer(CreateDeviceDialog);
