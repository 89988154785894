import React from "react";
import ReactDatePicker, {registerLocale, ReactDatePickerProps } from "react-datepicker";
import "./styles.css";
import { LANGUAGE } from "@stores/SettingsStore";
import { useStores } from "@stores";
import { useObserver } from "mobx-react-lite";
import {ru, es, enGB, uk, de, fr, pl} from 'date-fns/locale';

registerLocale(LANGUAGE.RU, ru);
registerLocale(LANGUAGE.ES, es);
registerLocale(LANGUAGE.EN, enGB);
registerLocale(LANGUAGE.UK, uk);
registerLocale(LANGUAGE.DE, de);
registerLocale(LANGUAGE.FR, fr);
registerLocale(LANGUAGE.PL, pl);


interface IProps extends ReactDatePickerProps { }

const DatePicker: React.FC<IProps> = ({ ...rest }) => {
  const { settingsStore } = useStores();
  const lang = useObserver(() => settingsStore.language);
  return (
    <ReactDatePicker
      locale={lang}
      renderDayContents={(dayOfMonth) => (
        <div className="day-container">{dayOfMonth}</div>
      )}
      calendarStartDay={1}
      inline
      {...rest}
    />
  );
};

export default DatePicker;
