import React from "react";
import styled from "@emotion/styled";
// import { ReactComponent as PlusIcon } from "@assets/icons/PlusIcon.svg";
import { TTag } from "@services/devicesService";

interface IProps {
  tags: TTag[];
}

const Root = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > * {
    margin-right: 8px;
    margin-bottom: 8px;
  }
  :last-child {
    margin-right: 0;
  }
`;

// const PlusButton = styled.div`
//   width: 24px;
//   height: 24px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background: #f8f8f8;
//   border-radius: 4px;
//   transition: 0.4s;
//   cursor: pointer;
// `;

const Tag = styled.div<{ background?: string; color?: string }>`
  font-family: Nunito Sans, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  height: 24px;
  padding: 0 12px;
  background: ${({ background }) => background ?? "#F8F8F8"};
  color: ${({ color }) => color ?? "#494C5B"};
  border-radius: 4px;
  white-space: nowrap;
`;

const DeviceTagsList: React.FC<IProps> = ({ tags }) => (
  <Root>
    {/*<PlusButton className="plus-button" onClick={(e) => e.stopPropagation()}>*/}
    {/*  <PlusIcon />*/}
    {/*</PlusButton>*/}
    {tags.map((tag) => (
      <Tag
        key={tag.id}
        background={tag.color.background}
        color={tag.color.text}
      >
        {tag.text}
      </Tag>
    ))}
  </Root>
);

export default DeviceTagsList;
