import { GeoJSON } from "geojson";

import { APIClient } from "@proto/apis/layers/v1/ApiServiceClientPb";
import { CreateRequest } from "@proto/apis/layers/v1/api_pb";

import { encode } from "@geobuf";

export * from "@proto/apis/layers/v1/api_pb";

const baseURL = "https://at.greengrowth.tech";
const LayersClient = new APIClient(baseURL, null, null);

export const saveLayer = async (
  field_id: string,
  type: string,
  data: GeoJSON
): Promise<void> => {
  await LayersClient.create(
    new CreateRequest()
      .setFieldId(field_id)
      .setType(type)
      .setLayer(encode(data, 16)),
    null,
  );
};


export const downloadURL = (field_id: string, type: string): string => {
  return baseURL + `/api/layers/v1/layer/${field_id}/${type}/`;
};
