import styled from "@emotion/styled";
import React, { HTMLAttributes } from "react";
// import { ReactComponent as MoreIcon } from "@assets/icons/more.svg";
// import { ReactComponent as EditIcon } from "@assets/icons/edit.svg";
// import { ReactComponent as DeleteIcon } from "@assets/icons/trash.svg";
import { Column, Row } from "@src/components/Flex";
import FieldPreview from "./FieldPreview";
import { Geometry } from "ol/geom";
import FieldName from "@screens/FieldsScreen/FieldName";

interface IProps extends Omit<HTMLAttributes<HTMLDivElement>, "id"> {
  id?: string | number;
  extra?: JSX.Element | null | "";
  title?: string;
  crop?: string;
  area?: string;
  geometry?: Geometry;
  selected?: boolean;
  highlighted?: boolean;
}

const Root = styled.div<{ selected?: boolean, highlighted?: boolean }>`
  display: flex;
  padding: 12px 16px;
  box-sizing: border-box;
  border-top: 1px solid rgba(137, 144, 147, 0.3);
  border-right: 2px solid ${({ highlighted }) => (
    highlighted ? "rgb(104, 173, 24)" : "rgb(255, 255, 255)"
  )};
  min-height: 70px;
  background: ${({ highlighted, selected }) => {
    if (highlighted) {
      return selected ? "hsl(88 76% 90% / 1)" : "hsl(88 76% 95% / 1)";
    }
    return selected ? "#f8f8f8" : "#ffffff";
  }};
  transition: 0.4s;
  position: relative;
  cursor: pointer;
  width: 100%;
  :hover {
    background: #f8f8f8;
  }

  & > .more {
    position: absolute;
    inset: 8px 8px auto auto;
    cursor: pointer;
  }
`;

const Title = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: Nunito Sans, sans-serif;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #494c5b;
`;

const SubTitle = styled(Title)`
  font-size: 13px;
  line-height: 20px;
  color: #899093;
`;

const FieldRow: React.FC<IProps> = ({
  id,
  title,
  crop,
  extra,
  area,
  geometry,
  ...rest
}) => {
  return (
    <Root {...rest}>
      <FieldPreview id={id} geometry={geometry} />
      <Column
        justifyContent="space-between"
        crossAxisSize="max"
        mainAxisSize="stretch"
      >
        <FieldName title={title} area={area} />
        <Row
          mainAxisSize="stretch"
          justifyContent="space-between"
        >
          <SubTitle>{crop}</SubTitle>
          {extra}
        </Row>
      </Column>
    </Root>
  );
};
export default FieldRow;
