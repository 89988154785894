import React from "react";

import { Navigate, Route, Routes } from "react-router-dom";
import { load as loadIntercom, boot as bootIntercom } from "@src/utils/intercom";
import styled from "@emotion/styled";
import { observer } from "mobx-react-lite";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "moment/locale/ru";
import "moment/locale/es";
import "moment/locale/en-gb";
import "moment/locale/fr";
import "moment/locale/de";
import "moment/locale/pl";
import "moment/locale/uk";

import { ROUTES } from "@stores/AccountStore";
import { ReactComponent as CloseIcon } from "@assets/icons/close.svg";

import CropsScreen from "@screens/CropsScreen";
import DeviceCalibrationScreen from "@screens/DeviceCalibrationScreen";
import DevicesScreen from "@screens/DevicesScreen";
import FieldsScreen from "@screens/FieldsScreen";
import ForgotPasswordScreen from "@screens/ForgotPasswordScreen";
import LoginScreen from "@screens/LoginScreen";
import PasswordResetScreen from "@screens/PasswordResetScreen";
import PrivateRoute from "@components/PrivateRoute";
import ProfileScreen from "@screens/ProfileScreen";
import RegisterScreen from "@screens/RegisterScreen";
import TracksScreen from "@screens/TracksScreen";
import PrescriptionScreen from "@screens/PrescriptionScreen";


const Root = styled.div`
  width: 100%;
  min-height: 100vh;
`;

const App: React.FC = () => {
  loadIntercom();
  bootIntercom();

  return (
    <Root>
      <Routes>
        <Route path={ROUTES.LOGIN} element={<LoginScreen />} />
        <Route path={ROUTES.REGISTER} element={<RegisterScreen />} />
        <Route path={ROUTES.PASSWORD_RESET} element={<PasswordResetScreen />} />
        <Route
          path={ROUTES.PROFILE}
          element={
            <PrivateRoute>
              <ProfileScreen />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.FIELDS}
          element={
            <PrivateRoute>
              <FieldsScreen />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.DEVICES}
          element={
            <PrivateRoute>
              <DevicesScreen />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.TRACKS}
          element={
            <PrivateRoute>
              <TracksScreen />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.DEVICES_CALIBRATION}
          element={
            <PrivateRoute>
              <DeviceCalibrationScreen />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.CROPS}
          element={
            <PrivateRoute>
              <CropsScreen />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.PRESCRIPTION}
          element={
            <PrivateRoute>
              <PrescriptionScreen />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.PASSWORD_FORGOT}
          element={<ForgotPasswordScreen />}
        />
        <Route path="*" element={<Navigate to={ROUTES.LOGIN} />} />
      </Routes>
      <ToastContainer
        closeButton={<CloseIcon style={{ width: 20, height: 20 }} />}
      />
    </Root>
  );
};

export default observer(App);
