import moment from "moment";

import RootStore from "@stores/RootStore";
import { makeAutoObservable, when } from "mobx";
import {
  preferences,
  updatePreferences,
  loadFromLocalOrDefault,
  userFeatures,
  defaultUserFeatures,
  Features,
} from "@services/demosService";

export enum LANGUAGE {
  RU = "RU",
  EN = "EN",
  ES = "ES",
  UK = "UK",
  PL = "PL",
  DE = "DE",
  FR = "FR",
  RO = "RO",
  HU = "HU",
}
export const LANGUAGE_NAMES = {
  [LANGUAGE.EN]: "English",
  [LANGUAGE.ES]: "Español",
  [LANGUAGE.RU]: "Русский",
  [LANGUAGE.UK]: "Україньска",
  [LANGUAGE.PL]: "Polski",
  [LANGUAGE.DE]: "Deutsch",
  [LANGUAGE.FR]: "Français",
  [LANGUAGE.RO]: "Română",
  [LANGUAGE.HU]: "Magyar",
};
export const LANGUAGE_SHORT_NAMES = {
  [LANGUAGE.EN]: "En",
  [LANGUAGE.ES]: "Es",
  [LANGUAGE.RU]: "Ру",
  [LANGUAGE.UK]: "Ук",
  [LANGUAGE.PL]: "Po",
  [LANGUAGE.DE]: "De",
  [LANGUAGE.FR]: "Fr",
  [LANGUAGE.RO]: "Ro",
  [LANGUAGE.HU]: "Ma",
};
export interface ISerializedSettingsStore {
  language: LANGUAGE;
  leftBarExpanded: boolean;
}

class SettingsStore {
  public readonly rootStore: RootStore;

  language: LANGUAGE;
  setLanguage = (lang: LANGUAGE) => {
    moment.locale(lang.toLowerCase());
    this.language = lang;
    this.savePreferences();
  };

  leftBarExpanded: boolean;
  setLeftBarExpanded = (v: boolean) => {
    this.leftBarExpanded = v;
    this.savePreferences();
  }

  features: Features;
  private setFeatures = (features: Features) => {
    this.features = features;
  }

  constructor(rootStore: RootStore, initState?: ISerializedSettingsStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;

    // Features
    this.features = defaultUserFeatures();
    // Preferences
    const local = loadFromLocalOrDefault();
    if (initState !== undefined) {
      this.language = initState.language;
      this.leftBarExpanded = initState.leftBarExpanded;
      localStorage.removeItem("gg-store");
      this.savePreferences();
    } else {
      this.language = local.localization.language;
      this.leftBarExpanded = local.ui.left_bar_expanded;
    }

    when(() => this.rootStore.accountStore.authenticated, () => {
      userFeatures().then((features) => {
        this.setFeatures(features);
      }).catch((e) => {
        console.error("Failed to get user features", e);
      });

      // @question: how to ensure that settings loaded before user can see
      //  anything?
      preferences().then((prefs) => {
        if (!prefs.updated_at) {
          return;
        }

        if (local.updated_at === undefined || local.updated_at < prefs.updated_at) {
          this.language = prefs.localization.language;
          this.leftBarExpanded = prefs.ui.left_bar_expanded;
        }
      }).catch((e) => {
        console.warn("Failed to load user preferences", e);
      });
    });
  }

  private savePreferences() {
    updatePreferences({
      localization: { language: this.language },
      ui: { left_bar_expanded: this.leftBarExpanded },
    }).catch((e) => {
      console.error("Failed to save preferences", e);
    });
  }

  serialize = (): ISerializedSettingsStore => ({
    language: this.language,
    leftBarExpanded: this.leftBarExpanded,
  });
}

export default SettingsStore;
