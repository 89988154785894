import moment from "moment";

import { APIClient } from "@proto/apis/demos/v1/ApiServiceClientPb";
import {
  UpdatePreferencesRequest,
  PreferencesRequest,
  Preferences as PreferencesProto,
  WebUIPreferences,
  LocalizationPreferences,
  FeaturesRequest,
} from "@proto/apis/demos/v1/api_pb";
import { LANGUAGE } from "@stores/SettingsStore";
import getLanguageByCode from "@src/utils/getLanguageByCode";

export * from "@proto/apis/demos/v1/api_pb";

const DemosClient = new APIClient("https://at.greengrowth.tech", null, null);

// Localizaion contains localization preferences such as language, unit of
// measurement, date format etc.
export type Localization = {
  // Language preferred by the user.
  language: LANGUAGE;
};

// UI contains UI preferences
export type UI = {
  // Should left bar be expanded?
  left_bar_expanded: boolean;
};

// Preferences contains all of the user preferences.
//
// @question: Can i do partial update since it would be more and more
//  complicated to update all preferences every single time?
export type Preferences = {
  // Localization preferences
  localization: Localization;
  // UI preferences
  ui: UI;
  // Time when preferences has been updated for the last time.
  // NOTE(nk2ge5k): required for us to now which settings are correct -
  // local or remote. Basically this field should be only modified by
  // the updatePreferences function.
  updated_at?: number;
};

// Key for the local storage where we store user preferences.
const LS_PREFERENCES_KEY = "gg_prefs";

// defaultPreferences return default preferences.
function defaultPreferences(): Preferences {
  return {
    localization: {
      // TODO(nk2ge5k): may be there is a better way
      language: getLanguageByCode(navigator.language),
    },
    ui: {
      left_bar_expanded: true,
    },
    updated_at: 0,
  };
}

// loadFromLocalOrDefault loads preferences from the local storage or returns default
// preferences if local storage does not contain desired key.
export function loadFromLocalOrDefault(): Preferences {
  const stored = localStorage.getItem(LS_PREFERENCES_KEY);
  if (stored) {
    try {
      const parsed: Preferences = JSON.parse(stored);
      return parsed;
    } catch { }
  }
  return defaultPreferences();
}

// preferences returns user preferences.
export async function preferences(): Promise<Preferences> {
  const response = await DemosClient.preferences(new PreferencesRequest(), null);
  const preferences = response.getPreferences()!;

  const prefs: Preferences = {
    localization: {
      language: preferences.getLocalization()!.getLanguage() as LANGUAGE,
    },
    ui: {
      left_bar_expanded: preferences.getWebUi()!.getLeftBarExpanded(),
    },
    updated_at: response.getUpdatedAt(),
  };

  return prefs;
}

// updatePreferences updates user preferences in the local storage and
// attempts to store them in the demos.
export async function updatePreferences(preferences: Preferences): Promise<void> {
  preferences.updated_at = moment().unix();
  localStorage.setItem(LS_PREFERENCES_KEY, JSON.stringify(preferences));

  const localization = new LocalizationPreferences()
    .setLanguage(preferences.localization.language);
  const ui = new WebUIPreferences()
    .setLeftBarExpanded(preferences.ui.left_bar_expanded);
  const request = new UpdatePreferencesRequest()
    .setPreferences(new PreferencesProto().setLocalization(localization).setWebUi(ui))
    .setUpdatedAt(preferences.updated_at!);

  await DemosClient.updatePreferences(request, null);
}

export type Features = {
  // Is prescription maps available?
  prescription_maps: boolean;
};

export function defaultUserFeatures(): Features {
  return {
    prescription_maps: false,
  };
}

export async function userFeatures(): Promise<Features> {
  try {
    const response = await DemosClient.features(new FeaturesRequest(), null);
    const features: Features = {
      prescription_maps: response.getPrescriptions(),
    };

    return features;
  } catch (e) {
    console.error("Failed to get user features - attempting to return defaults", e);
    return defaultUserFeatures();
  }
}
