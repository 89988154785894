import React  from "react";

import { toast } from "react-toastify";
import { makeAutoObservable } from "mobx";

import useVM from "@src/hooks/useVM";
import { RootStore, useStores } from "@stores";
import { userDevices, AttachedDevice, BBox } from "@services/devicesService";

const ctx = React.createContext<Context | null>(null);

class Context {
  // Context state
  loading: boolean = true;
  setLoading = (v: boolean) => (this.loading = v);

  // Search query
  query: string = "";
  setQuery = (query: string) => (this.query = query);

  // List of user devices
  devices: AttachedDevice[] = [];
  private setDevices = (v: AttachedDevice[]) => (this.devices = v);

  bbox: BBox | null = null;
  private setBBox = (v: BBox | null) => (this.bbox = v);

  constructor(private rootStore: RootStore) {
    makeAutoObservable(this);
    console.time("loading");
    this.loadUserDevices().then(() => {
      this.setLoading(false)
      console.timeEnd("loading");
    });
  }

  loadUserDevices () {
    return userDevices().then((response) => {
      this.setDevices(response.devices);
      this.setBBox(response.bbox);
    }).catch((e) => {
      console.error("ERROR [API.Devices]:", e);
      toast.error(`Failed to get devices list: ${e.message}`);
      throw e;
    });
  }

  // getDevices returns list of devices with search query applied
  getDevices(): AttachedDevice[] {
    const query = this.query.trim().toLowerCase();
    if (query.length === 0) {
      return this.devices;
    }

    return this.devices.filter((device) => {
      if (device.getName().toLowerCase().includes(query)) {
        return true;
      }
      if (device.getDescription().toLowerCase().includes(query)) {
        return true;
      }
      if (device.getEsn().toLowerCase().includes(query)) {
        return true;
      }
      return false;
    });
  }
};

// Contenxt provider for the the tracks screen state.
export const ContextProvider: React.FC = ({ children }) => {
  // TODO(nk2ge5k): why do we need rootStore here?
  const rootStore = useStores();
  const store = React.useMemo(() => new Context(rootStore), [rootStore]);
  return <ctx.Provider value={store}>{children}</ctx.Provider>;
};

export const useContext = () => useVM(ctx);
