import styled from "@emotion/styled";
import React, { HTMLAttributes } from "react";

interface IProps extends HTMLAttributes<HTMLDivElement> {
  title: string;
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0 2px 20px rgba(78, 78, 81, 0.1);
  border-radius: 6px;
  width: 100%;
  flex-shrink: 0;
  height: 100%;
  //flex: 1;
`;

const Header = styled.div`
  padding: 0 16px;
  height: 46px;
  border-bottom: 1px solid rgba(137, 144, 147, 0.3);
  font-family: Nunito Sans, sans-serif;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #899093;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Card: React.FC<IProps> = ({ title, children, ...rest }) => {
  return (
    <Root {...rest}>
      <Header>{title}</Header>
      <Content>{children}</Content>
    </Root>
  );
};
export default Card;
